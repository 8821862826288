import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "@app/core/services/authentication/authentication.service";
import { EndpointListService } from "@app/core/services/endpoint-list/endpoint-list.service";

@Injectable({
  providedIn: "root",
})
export class LivechatApiService {

  public clientId: number;
  public isRefreshAgentList = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private authSvc: AuthenticationService,
    private endpointListService: EndpointListService
  ) {

  }

  public getClientDepartments = (): Observable<any> => {
    const url = `${this.authSvc.client.api_gateway_url}/clientmanagement/getClientDepartments`;
    return this.http.get<any>(url, {
      observe: "response",
    });
  }

  public getAllAgentList = (): Observable<any> => {
    const url = `${this.authSvc.client.api_gateway_url}/livechatmanagement/getAgentsList?cluster=${this.authSvc.client.cluster}`;
    return this.http.get<any>(url, {
      observe: "response",
    });
  }

  public listAllAgents = () => {
    return this.http.post<any>(`${this.authSvc.client.api_gateway_url}/rtmmanagement/${this.endpointListService.listAgents}?cluster=${this.authSvc.client.cluster}`,
      { userSubId: this.authSvc.currentUser.cognitoUserId });
  }

  public agentWorkload = () => {
    return this.http.post<any>(`${this.authSvc.client.api_gateway_url}/rtmmanagement/${this.endpointListService.agentWorkload}?cluster=${this.authSvc.client.cluster}`,
      { clientId: this.authSvc.client.id, freshness: 360000 });
  }

  getAgentStatus() {
    return this.http.post<any>(`${this.authSvc.client.api_gateway_url}/rtmmanagement/agentStatistics`,
      { clientId: this.authSvc.client.id });
  }

  public changeAgentStatus(data): Observable<any> {
    data.email = this.authSvc.currentUser.email;
    const url = `${this.authSvc.client.api_gateway_url}/livechatmanagement/updateStatus?clientId=${this.authSvc.client.id}&cluster=${this.authSvc.client.cluster}`;
    return this.http.post(url, data, {
      observe: "response",
      responseType: "text",
    });
  }

  public getAgentCurrentStatus(data): Observable<any> {
    data.email = this.authSvc.currentUser.email;
    const url = `${this.authSvc.client.api_gateway_url}/livechatmanagement/agentStatus?cluster=${this.authSvc.client.cluster}`;
    return this.http.post(url, data, {
      observe: "response",
      responseType: "text",
    });
  }

  public getAiAssistanceResponse(data): Observable<any> {
    const url = `${this.authSvc.client.api_gateway_url}/ainlpprocessmanagement/getResponse`;
    return this.http.post(url, data, {
      observe: "response",
    });
  }

  public getManualResponseFromAi(data): Observable<any> {
    const url = `${this.authSvc.client.api_gateway_url}/ainlpprocessmanagement/getManualResponse`;
    return this.http.post(url, data, {
      observe: "response",
    });
  }

  public addPinnedUtterance = (body: any): Observable<any> => {
    return this.http.post(`${this.authSvc.client.api_gateway_url}/ainlpprocessmanagement/addPinnedUtterance`,
      body);
  }
}
