import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-common-help-document",
  templateUrl: "./common-help-document.component.html",
  styleUrls: ["./common-help-document.component.scss"]
})
export class CommonHelpDocumentComponent implements OnInit {
  public isTabMode: boolean = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  ngOnInit(): void {
    if (this.dialogData && this.dialogData.isTabMode) {
      this.isTabMode = true;
    }

  }

}
