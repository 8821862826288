import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AssetConstant } from "@app/asset/constants/asset-constant";
import { AssetInfo } from "@app/asset/misc/asset-info";
import { AuthenticationService } from "@app/core/services/authentication/authentication.service";
import { Constants } from "@app/core/utils/const";
import { environment } from "@environments/environment";
import { PreviewDialogComponent } from "@app/shared/components/preview-dialog/preview-dialog.component";
import { UnsplashImagesDialogComponent } from "@app/shared/components/unsplash-images-dialog/unsplash-images-dialog.component";
import { CloudUploadRequest } from "@app/core/misctypes/cloud-upload-request";
import { UnsplashImageInfo } from "@app/asset/misc/unsplash-image-info";
import { AssetService } from "@app/asset/services/asset.service";

@Component({
  selector: "app-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.scss"]
})
export class UploadComponent implements OnInit, OnChanges {

  @Input() public label: string;
  @Input() public assetUrl: string;
  @Input() public assetId: number;
  @Input() public assetType: string;
  @Input() public canLinkUrl = false;
  @Input() public isMasterTrigger = false;
  @Input() public allAssetList: AssetInfo[] = [];
  @Input() public isShowChameleonLogo = false;
  @Input() public isUploadDisabled = false;
  @Input() public displayPreviewBtn = true;
  @Input() public disablePreviewBtn = false;
  @Input() public displayDeleteBtn = true;
  @Input() public disableDeleteBtn = false;
  @Input() public isRequired = false;
  @Input() public fileCategory: string;

  @Output() public uploadingFile = new EventEmitter();
  @Output() public deletingFile = new EventEmitter();
  @Output() public assetChanged = new EventEmitter();
  @Output() public urlLink = new EventEmitter();

  @ViewChild("uploadFileBtn") uploadFileBtn: ElementRef<HTMLInputElement>;

  public imgBaseUrl: string = environment.behaviourUrl;
  public assetList: AssetInfo[] = [];
  public assetIdUrl: string;
  public clientId: number;
  public assetLink: string;
  public safeLinkUrl: any;
  public isAssetApiCalled = false;
  public isAssetTypeChanged = false;
  public addClassExtra = false;

  public _const: Constants;
  public _assetConst: AssetConstant;

  constructor(
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private _authService: AuthenticationService,
    private _assetService: AssetService
  ) {
    this._const = new Constants();
    this.clientId = this._authService.client.id;
    this._assetConst = new AssetConstant();
  }

  ngOnInit(): void {
    const assetTypes: string[] = this.assetType?.split(",").map(element => element.trim());
    this.assetList = this.allAssetList?.filter(asset => assetTypes?.includes(asset.assetType));
    this.setAssetIdUrl(this.assetId);
    this.isAssetApiCalled = true;
    this.isAssetTypeChanged = true;
    if (this.canLinkUrl) {
      const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      const vimeoRegex = /^(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/;
      if (youtubeRegex.test(this.assetUrl) || vimeoRegex.test(this.assetUrl)) {
        this.assetLink = this.assetUrl;
        this.safeLinkUrl = this.transform(this.assetUrl);
      } else {
        this.assetLink = "";
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const assetTypes = this.assetType?.split(",");
    this.assetList = [];
    this.isAssetTypeChanged = false;
    setTimeout(() => {
      this.assetList = this.allAssetList.filter(asset => assetTypes.includes(asset.assetType));
      this.setAssetIdUrl(this.assetId);
      this.isAssetTypeChanged = true;
    }, 0);
  }

  public acceptableFileTypes = (): string => {
    let acceptableFileTypes: string;
    const assetTypes = this.assetType?.split(",");
    let allAcceptableFileTypes = "";
    assetTypes?.forEach(assetType => {
      switch (assetType.trim()) {
        case this._const.DOCUMENT:
          acceptableFileTypes = this._const.ACCEPTABLE_DOCUMENT_TYPES.toString();
          break;
        case this._const.VIDEO:
          acceptableFileTypes = this._const.ACCEPTABLE_VIDEO_TYPES.toString();
          break;
        case this._const.IMAGE:
          acceptableFileTypes = this._const.ACCEPTABLE_IMAGE_TYPES.toString();
          break;
        default:
          break;
      }
      allAcceptableFileTypes = allAcceptableFileTypes ? `${allAcceptableFileTypes},${acceptableFileTypes}` : acceptableFileTypes;
    });
    return allAcceptableFileTypes;
  }

  public uploadFileFormDevice = (event) => {
    const selectedFile = event && event.target && event.target.files && event.target.files.length > 0 ? event.target.files[0] : null;
    const fileName = selectedFile ? selectedFile.name : "";
    if (fileName && this.getExtensions(fileName)) {
      this.assetLink = "";
      this.uploadingFile.emit(event);
    }
  }

  private uploadFileFromCloud = (fileInfo: UnsplashImageInfo) => {
    const payloadRequest: CloudUploadRequest = new CloudUploadRequest();
    payloadRequest.fileName = "";
    payloadRequest.clientId = this.clientId;
    payloadRequest.category = this.fileCategory;
    payloadRequest.cloudUpload = true;
    payloadRequest.unsplashFileId = fileInfo.id;
    this._assetService.uploadFileFromCloud(payloadRequest).subscribe((response) => {
      if (response && response.exeStatus) {
        this.assetUrl = "";
        this.urlLink.emit(response.itemUrl);
      } else {
        this.urlLink.emit(this._const.NOT_UPLOADED);
      }
    });
  }

  public getExtensions = (fileName): boolean => {
    let isAcceptable = false;
    const urlParts = fileName ? fileName.split(".") : [];
    const urlType = urlParts && urlParts.length > 0 ? `.${urlParts[urlParts.length - 1]}` : "";
    const assetTypes = this.assetType?.split(",");
    assetTypes?.forEach(assetType => {
      switch (assetType.trim()) {
        case this._const.DOCUMENT:
          isAcceptable = isAcceptable ? isAcceptable : this._const.ACCEPTABLE_DOCUMENT_TYPES.includes(urlType);
          break;
        case this._const.VIDEO:
          isAcceptable = isAcceptable ? isAcceptable : this._const.ACCEPTABLE_VIDEO_TYPES.includes(urlType);
          break;
        case this._const.IMAGE:
          isAcceptable = isAcceptable ? isAcceptable : this._const.ACCEPTABLE_IMAGE_TYPES.includes(urlType);
          break;
        default:
          break;
      }
    });
    return isAcceptable;
  }

  public clickEvent = () => {
    this.addClassExtra = !this.addClassExtra;
  }

  public deleteButtonImg = () => {
    this.assetIdUrl = "";
    this.deletingFile.emit(true)
  }

  public assetSelected = (id) => {
    this.assetLink = "";
    this.setAssetIdUrl(id);
    this.assetChanged.emit(id);
  }

  public setAssetIdUrl = (id: number) => {
    const selectedAssets = this.assetList.filter(asset => asset.id === id);
    if (selectedAssets && selectedAssets.length > 0) {
      this.assetIdUrl = selectedAssets[0].assetUrl;
    }
  }

  public getUrlType = (): string => {
    const urlParts = this.assetId ? this.assetIdUrl?.split(".") : this.assetUrl?.split(".");
    const urlType = urlParts && urlParts.length > 0 ? urlParts[urlParts.length - 1] : "";
    let type = null;
    if (urlType) {
      if (this._const.IMAGE_EXTENSIONS.includes(urlType)) {
        type = this._const.IMAGE;
      } else if (this._const.VIDEO_EXTENSIONS.includes(urlType)) {
        type = this._const.VIDEO;
      } else if (this._const.DOCUMENT_EXTENSION.includes(urlType)) {
        type = this._const.DOCUMENT;
      } else {
        type = null;
      }
    }

    return type;
  }

  private transform = (url: string): SafeResourceUrl => {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public previewAsset = () => {
    const dialogRef = this.dialog.open(PreviewDialogComponent, {
      panelClass: "custom-asset-preview",
      data: {
        type: this.getAssetType(),
        url: this.assetId ? this.assetIdUrl : this.assetUrl,
        isExternalLink: !!this.assetLink
      },
    });

    dialogRef.afterClosed();
  }

  public getAssetType = () => {
    let type = "";
    switch (this.assetType) {
      case this._assetConst.IMAGE_ATTR:
        type = this._assetConst.IMAGE_ATTR;
        break;
      case this._assetConst.VIDEO_ATTR:
        type = this._assetConst.VIDEO_ATTR;
        break;
      case this._assetConst.DOCUMENT_ATTR:
        type = this._assetConst.DOCUMENT_ATTR;
        break;
      default:
        type = this.getUrlType();
        break;
    }
    return type;
  }

  public fetchFromDevice = () => {
    if (this.uploadFileBtn && this.uploadFileBtn.nativeElement) {
      this.uploadFileBtn.nativeElement.value = "";
      this.uploadFileBtn.nativeElement.click();
    }
  }

  public fetchFromLibrary = () => {
    const unsplashDialogRef = this.dialog.open(UnsplashImagesDialogComponent, {
      panelClass: "library-dialog-container"
    });

    unsplashDialogRef.afterClosed().subscribe(async (result: UnsplashImageInfo) => {
      if (result && result.url) {
        await this.uploadFileFromCloud(result);
      }
    });
  }
}
