import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from "@angular/core";
import { Router, NavigationStart, Event as NavigationEvent } from "@angular/router";
import { filter } from "rxjs/operators";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { LivechatApiService } from "@app/live-chat/services/livechat-api/livechat-api.service";
import { StorageService } from "@app/core/services/storage/storage.service";
import { AuthToken } from "@app/core/models/auth-token";
import { CoreUtils } from "@app/core/utils/core-utils";
import { Constants } from "@app/core/utils/const";
import { User } from "@app/core/misctypes/user";
import { ClientSubModule } from "@app/core/misctypes/client-sub-module";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {
  @Input() toggle = false;
  @Output() toggleEvent = new EventEmitter();
  @Output() changeTabName = new EventEmitter();
  @ViewChild("convDropdown", { static: false }) convDropdown: ElementRef;

  public currentUser: User;
  public isCollapsed = true;
  public mouseOvered: string;
  public permissions: ClientSubModule[] = [];
  public iconVisible = false;
  public userRoles: string[] = [];
  public userModules: string[] = [];
  public displayName: string = "";
  public userFirstName: string = "";

  private _cnst: Constants;
  public _coreUtils: CoreUtils;

  constructor(
    private router: Router,
    private authSvc: AuthenticationService,
    private lcApiSvc: LivechatApiService,
    private _storage: StorageService
  ) {
    this._cnst = new Constants();
    this.currentUser = this.authSvc.currentUser;
    this.permissions = this.currentUser.clientSubmoduleDtos;
    this._coreUtils = new CoreUtils(authSvc);
    this.displayName = this.authSvc.getUserFullName();
    this.userFirstName = this.authSvc.getUserFirstName();
    this.routeEventSubscription();
  }

  ngOnInit(): void {
    this.handleUserModules();
  }

  private handleUserModules = () => {
    this.userRoles = this._coreUtils.getUserRoles();
    this.userModules = this._coreUtils.getUserModulesReadAccess(this.userRoles);
  }

  public logout = () => {
    const data = {
      clientId: this._storage.storedClientId(),
      agentAlias: "",
      agentName: this.currentUser.userName,
      agentId: this.currentUser.userName,
      status: 1,
      email: this.currentUser.email,
    };
    this.lcApiSvc.changeAgentStatus(data).subscribe();
    const logoutUrl: string = this._storage.storedLogOutUrl();
    this._storage.cleanStorage();
    this.currentUser.resetUser();
    this.authSvc.tokens = new AuthToken("", "");
    console.log(`Log out URL is ${logoutUrl}`);
    window.location.href = logoutUrl;
  }

  public sidebarCollapse = (event: boolean) => {
    this.toggle = event;
  }

  public routeEventSubscription = () => {
    this.router.events.pipe(filter((event: NavigationEvent) => {
      return event instanceof NavigationStart;
    })).subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === this._cnst.POP_STATE) {
        this.router.navigate[event.url];
      }
    });
  }

  public onClickedOutside = (event: Event) => {
    this.isCollapsed = false;
  }

  public mouseEnter = () => {
    this.iconVisible = true;
  }

  public mouseLeave = () => {
    this.iconVisible = false;
  }

  get isAdmin() {
    const currentRole: string = this._coreUtils.isMaster() ? this._cnst.MASTER : this._cnst.CLIENT;
    return (currentRole === this._cnst.MASTER);
  }

  public setSelectedTriggerTypes = () => {
    this._storage.storeSelectedTriggerTypes([]);
    this._storage.storeSelectedTRGTabIndex(0);
    this._storage.storeTriggerNameSearched("");
    this.resetClientSelection();
  }

  public setSelectedApiTypes = () => {
    this._storage.storeSelectedApiTypes([]);
    this._storage.storeAPIDefTabIndex(0);
    this._storage.storeApiDefNameSearched("");
    this.resetClientSelection();
  }

  public setSelectedBehaviourTypes = () => {
    this._storage.storeSelectedBHVTabIndex(0);
    this._storage.storeBhvNameSearched("");
    this.resetClientSelection();
  }

  public setSelectedAssetTab = () => {
    this._storage.storeSelectedAssetTabIndex(0);
    this._storage.storeAssetNameSearched("");
    this.resetClientSelection();
    this._storage.storeCMSAssetTabIndex(0);
  }

  private resetClientSelection = () => {
    this._storage.storeSelectedClientId(null);
    this._storage.storeClientNameSearched("");
  }

  public setSelectedJourneyTab = () => {
    this._storage.storeSelectedWFTabIndex(0);
    this.resetClientSelection();
    this._storage.storeWorkflowNameSearched("");
  }

  public setSelectedClientTab = () => {
    this._storage.storeSelectedClientTypes([]);
    this._storage.storeClientListRequest(null);
  }

  public setSelectedNLPTab = () => {
    this._storage.storeSelectedNLPTabIndex(0);
    this._storage.storeNLPNameSearched("");
    this.resetClientSelection();
  }
}
