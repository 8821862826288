import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "./core/services/authentication/authentication.service";
import { Router } from "@angular/router";
import { AuthConstants } from "@app/core/constant/auth-constants";

@Component({ selector: "app", templateUrl: "app.component.html" })
export class AppComponent {

  private languages: string[] = ["en", "fr"];
  private _const: AuthConstants;

  constructor(
    private readonly translateSvc: TranslateService,
    private authSvc: AuthenticationService,
    private router: Router
  ) {
    this._const = new AuthConstants();
    const browserLang: string = this.translateSvc.getBrowserLang();
    if (this.languages.indexOf(browserLang) > -1) {
      this.translateSvc.setDefaultLang(browserLang);
    } else {
      this.translateSvc.setDefaultLang("en");
    }
  }

  async ngOnInit(): Promise<void> {
    if (this.authSvc.sessionInactive()) {
      const cfgResp = await this.authSvc.negotiateSentinelLoginStrategy();
      const cfgOk = cfgResp && cfgResp.exeStatus && cfgResp.loginCfg;
      if (cfgOk) {
        // register gateway for the client
        this.authSvc.registerGateway(cfgResp.loginCfg.gatewayUrl);
        if (cfgResp.loginCfg.ssoEnabled && cfgResp.idpInfo) {
          // Load SSO providers login page
          this.renderSSOLogin(`${cfgResp.idpInfo.loginPageUrl}`);
        } else {
          // Use DC specific login. No redirection needed
          this.renderStandaloneLogin(`${cfgResp.loginCfg.anonAccessToken}`);
        }
      }
    }
  }

  public useLanguage = (lang: string): void => {
    this.translateSvc.setDefaultLang(lang);
  }

  private renderSSOLogin = (targetUrl: string) => {
    window.location.href = targetUrl;
  }

  private renderStandaloneLogin = (anonAccessToken: string) => {
    this.router.navigate([
      `${this._const.KEY_SLASH}${this._const.KEY_LOGIN}`],
      { queryParams: { accessToken: anonAccessToken } }
    );
  }
}
