<div class="text-center mainloader" *ngIf="loading; else loaded">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<ng-template #loaded>
  <div [ngStyle]="{'padding-left': applyStyle ? '12px' : '0px' }" class="page-container row-fluid container-fluid">
    <app-header *ngIf="showNavAndHead" (toggleEvent)='sidebarCollapse($event)' [toggle]="toggle" [tabName]="tabName">
    </app-header>
    <app-navigation *ngIf="showNavAndHead" [toggle]="toggle" (changeTabName)="changeTabName($event)">
    </app-navigation>
    <section id="main-content" class="sidebar_shift main-section-collapsible"
      [ngClass]="{'sidebar_shift': toggle, 'chat_shift': !toggle}">
      <section class="wrapper main-wrapper row">
        <router-outlet></router-outlet>
      </section>
    </section>
    <app-footer *ngIf="showNavAndHead" (toggleEvent)='sidebarCollapse($event)' [toggle]="toggle"></app-footer>
  </div>
  <div cdkDrag>
    <iframe id="chatbot" title="Sentinel Help Bot" class="chat-btn" allow="geolocation" allow="autoplay"
      allow="encrypted-media" src="https://dcdomain.co.in/front" referrerpolicy="origin-when-cross-origin"></iframe>
  </div>
</ng-template>