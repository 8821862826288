import { StatusNode } from "@app/core/misctypes/status-node";

export class Transitions {

  private transitionGraphClient: StatusNode [] = [];
  private transitionGraphGeneric: StatusNode [] = [];

  constructor() {
    this.transitionGraphClient.push(...this.clientTransitionGraph());
    this.transitionGraphGeneric.push(...this.genericTransitionGraph());
  }

  public clientTransitions = (): StatusNode[] => {
    return this.transitionGraphClient;
  }

  public genericTransitions = (): StatusNode[] => {
    return this.transitionGraphGeneric;
  }

  private clientTransitionGraph = (): StatusNode[] => {
    const graph: StatusNode [] = [];

    const draft: StatusNode = new StatusNode("DRAFT", ["SAVE", "SUBMIT", "DELETE"]);
    const submitted: StatusNode = new StatusNode("SUBMITTED", ["APPROVE", "REJECT"]);
    const prvRequested: StatusNode = new StatusNode("PROVISIONING_REQUESTED", ["COMPLETE"]);
    const provisioned: StatusNode = new StatusNode("PROVISIONED", ["APPROVE"]);
    const active: StatusNode = new StatusNode("ACTIVE", ["REQUEST_TO_DISABLE"]);
    const disableRequested: StatusNode = new StatusNode("DISABLE_REQUESTED", ["REJECT", "APPROVE"]);
    const disabled: StatusNode = new StatusNode("DISABLED", ["REQUEST_TO_ENABLE", "REQUEST_TO_DELETE"]);
    const enableRequested: StatusNode = new StatusNode("ENABLE_REQUESTED", ["REJECT", "APPROVE"]);
    const deleteRequested: StatusNode = new StatusNode("DELETE_REQUESTED", ["REJECT", "APPROVE"]);

    const paths: StatusNode [] = [
      draft,
      submitted,
      prvRequested,
      provisioned,
      active,
      disableRequested,
      disabled,
      enableRequested,
      deleteRequested
    ];
    graph.push(...paths);

    return graph;
  }

  private genericTransitionGraph = (): StatusNode[] => {
    const graph: StatusNode [] = [];

    const draft: StatusNode = new StatusNode("DRAFT", ["SAVE", "SUBMIT", "DELETE"]);
    const submitted: StatusNode = new StatusNode("SUBMITTED", ["APPROVE", "REJECT"]);
    const active: StatusNode = new StatusNode("ACTIVE", ["REQUEST_TO_DISABLE"]);
    const disableRequested: StatusNode = new StatusNode("DISABLE_REQUESTED", ["REJECT", "APPROVE"]);
    const disabled: StatusNode = new StatusNode("DISABLED", ["REQUEST_TO_ENABLE", "REQUEST_TO_DELETE"]);
    const enableRequested: StatusNode = new StatusNode("ENABLE_REQUESTED", ["REJECT", "APPROVE"]);
    const deleteRequested: StatusNode = new StatusNode("DELETE_REQUESTED", ["REJECT", "APPROVE"]);

    const paths: StatusNode [] = [
      draft,
      submitted,
      active,
      disableRequested,
      disabled,
      enableRequested,
      deleteRequested
    ];
    graph.push(...paths);

    return graph;
  }
}
