import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CommonModule } from "@angular/common";

import { JwtInterceptor } from "./interceptors/jwt/jwt.interceptor";
import { ErrorInterceptor } from "./interceptors/error/error.interceptor";

@NgModule({
  declarations: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  imports: [
    CommonModule
  ]
})
export class CoreModule { }
