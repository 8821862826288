export class Picked {

  private readonly channelId: string;
  private readonly clientId: string;
  private readonly usUUID: string;
  private readonly epiRefId: string;
  private readonly wfNodeId: string;
  private readonly wfId: string;
  private readonly accessToken: string;
  private readonly modeContext: string[]
  private readonly conversation: any[] = [];

  constructor(pCfg: any) {
    if (pCfg) {
      this.channelId = pCfg._chn;
      this.clientId = pCfg._clt;
      this.usUUID = pCfg._us;
      this.epiRefId = pCfg._erf;
      this.modeContext = [].concat(pCfg._modes);
      this.wfNodeId = pCfg._wfNodeId;
      this.wfId = pCfg._wId;
      this.accessToken = pCfg._accessToken;
    }
  }

  channel = (): string => {
    return this.channelId;
  };

  client = (): string => {
    return this.channelId;
  }

  channelSession = (): string => {
    return this.usUUID;
  }

  sessionEpisode = (): string => {
    return this.epiRefId;
  }

  accessTkn = (): string => {
    return this.accessToken;
  }


  preModes = (): string[] => {
    return (this.modeContext) ? this.modeContext : [];
  };

  workflowNode = (): string => {
    return this.wfNodeId;
  };

  workflowId = (): string => {
    return this.wfId;
  };

  enrichConversation = (msg: any) => {
    if (msg) {
      // add only if message key is not available
      // duplicate removal
      const duplicates = this.conversation.filter(cnv => cnv.messageKey === msg.messageKey);
      if (duplicates.length === 0) {
        this.conversation.push(msg);
      }
    }
  };

  bufferConversation = (messages: any[]) => {
    if (messages && messages.length > 0) {
      this.conversation.push(...messages);
    }
  };

  streamConversation = (): any[] => {
    return this.conversation;
  };

  clearConversation = () => {
    if (this.conversation) {
      this.conversation.splice(0, this.conversation.length);
    }
  }

  unreadCount = () => {
    let unread = 0;
    if (this.conversation.length > 0) {
      this.conversation.forEach((conv: any) => {
        if (conv.unread === true) {
          ++unread;
        }
      });
    }
    return unread;
  };

  markAllRead = () => {
    if (this.conversation.length > 0) {
      this.conversation.forEach((conv: any) => {
        conv.unread = false;
      });
    }
  };
}
