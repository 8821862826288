import { ClientSubModule } from "@app/core/misctypes/client-sub-module";
import { Role } from "@app/core/misctypes/role";
import { StatusNode } from "@app/core/misctypes/status-node";
import { UiPolicy } from "@app/core/misctypes/ui-policy";
import { UiPolicyContext } from "@app/core/misctypes/ui-policy-context";
import { UserModule } from "@app/core/models/user-module.model";
import { ChannelMessage } from "@app/core/msg/channel-message";
import { HbMessage } from "@app/core/msg/hb-message";
import { MessageContent } from "@app/core/msg/message-content";
import { MsgType } from "@app/core/msg/msg-type";
import { SRType } from "@app/core/msg/sr-type";
import { AuthenticationService } from "@app/core/services/authentication/authentication.service";
import { Constants } from "@app/core/utils/const";
import { Transitions } from "@app/core/utils/transitions";
import { CKEditor4 } from "ckeditor4-angular";
import * as moment from "moment";
import Swal, { SweetAlertIcon, SweetAlertResult } from "sweetalert2";
import { ServerPolicy } from "@app/core/misctypes/server-policy";
import { UploadResponse } from "../misctypes/upload-response";
import { LeaseMessage } from "@app/core/msg/lease-message";
import { LmHbMessage } from "@app/core/msg/lm-hb-message";

export class CoreUtils {

  private readonly _rolesList: string[] = [];
  private readonly _cns: Constants;
  private readonly _msgTypes: MsgType;
  private readonly _srType: SRType;
  private readonly _transitions: Transitions;

  constructor(private authService?: AuthenticationService) {
    this._cns = new Constants();
    this._msgTypes = new MsgType();
    this._srType = new SRType();
    this._rolesList = authService ? this.getUserRoles() : [];
    this._transitions = new Transitions();
  }

  public displayableErrors = (errors: string[], produceHtml: boolean): HTMLDivElement => {
    const divEl: HTMLDivElement = document.createElement("div");
    divEl.setAttribute("style", "list-style-position: inside;text-align: left;padding-top: 10px;");
    const listUl: HTMLUListElement = document.createElement("ul");
    if (errors && errors.length > 0) {
      errors.forEach(errMsg => {
        if (!!errMsg) {
          const listItem: HTMLLIElement = document.createElement("li");
          listItem.innerText = errMsg;
          listUl.appendChild(listItem);
        }
      });
    }
    divEl.appendChild(listUl);
    return divEl;
  }

  public displayableWarnings = (warnings: string[], produceHtml: boolean): HTMLDivElement => {
    const divEl: HTMLDivElement = document.createElement("div");
    divEl.setAttribute(
      "style",
      "list-style-position: inside;text-align: left;padding-top: 10px; font-color: red"
    );
    const listUl: HTMLUListElement = document.createElement("ul");
    if (warnings && warnings.length > 0) {
      warnings.forEach(warningMsg => {
        if (!!warningMsg) {
          const listItem: HTMLLIElement = document.createElement("li");
          listItem.innerText = warningMsg;
          listUl.appendChild(listItem);
        }
      });
    }
    divEl.appendChild(listUl);
    return divEl;
  }

  public encodeUploadedDoc = (docs: string): string[] => {
    let encodedDocs: string[] = [];
    if (docs) {
      try {
        const files: UploadResponse[] = JSON.parse(docs);
        if (files && files.length > 0) {
          files.forEach(f => {
            try {
              encodedDocs.push(btoa(JSON.stringify(f)));
            } catch (e) {
              console.log(`Error occurred in encoding & pushing the uploaded doc. Error is ${e}`);
            }
          });
        }
      } catch (e) {
        console.log(`Error occurred while parsing upload info json object. Error is ${e}`);
      }
    }
    return encodedDocs;
  }

  public decodeUploadedDoc = (docs: string): any[] => {
    let decodedDoc: any[] = [];
    if (docs) {
      try {
        const file: any = JSON.parse(atob(docs));
        if (file && file.itemUrl) {
          decodedDoc = [file];
        }
      } catch (e) {
        console.log("Error on decoding the uploaded docs");
      }
    }
    return decodedDoc;
  }

  public parseJSONArray = (rawData: string): any[] => {
    let effectiveResult: any[] = [];
    try {
      if (rawData) {
        const parsedArray: any[] = JSON.parse(rawData);
        if (parsedArray && parsedArray.length > 0) {
          effectiveResult = parsedArray.filter(e => !!e && e !== "null");
        }
      }
    } catch (e) {
      console.log(`Error in parsing received data into JSON array. Error is ${e}`);
    }
    return effectiveResult && effectiveResult.length > 0 ? effectiveResult : [];
  }

  public produceUTCTimestamp = (): number => {
    let timestamp = -1;
    try {
      const now = new Date();
      const nowInISO = now.toISOString();
      timestamp = Date.parse(nowInISO);
    } catch (e) {
      console.log(`Error in producing UTC timestamp value. Error is ${e}`);
    }
    return timestamp;
  }

  public generateHeartbeatMsg = (chn: string): HbMessage => {
    const timestamp: number = this.produceUTCTimestamp();
    let hbMsg: HbMessage = new HbMessage(this._msgTypes.MT_HEARTBEAT, chn, this._srType.SR_AGENT.toLowerCase());
    hbMsg = {
      ...hbMsg,
      ...{
        message: [new MessageContent(this._cns.MSG_HEARTBEAT, this._cns.MSG_HEARTBEAT)],
        createdTimestamp: timestamp,
        updatedTimestamp: timestamp,
        createdBy: this._cns.LEON,
        updatedBy: this._cns.LEON
      }
    }
    return hbMsg;
  }

  public generateTknLeaseHBMsg = (): LmHbMessage => {
    const timestamp: number = this.produceUTCTimestamp();
    let hbMsg: LmHbMessage = new LmHbMessage();
    hbMsg = {
      ...hbMsg,
      ...{
        messageType: this._msgTypes.MT_HEARTBEAT,
        message: [new MessageContent(this._cns.MSG_HEARTBEAT, this._cns.MSG_HEARTBEAT)],
        createdTimestamp: timestamp,
        updatedTimestamp: timestamp,
        createdBy: this._cns.LEON,
        updatedBy: this._cns.LEON
      }
    }
    return hbMsg;
  }

  public produceUTCDate = (): Date => {
    let utcDate: Date;
    try {
      const now = new Date();
      const nowInISO = now.toISOString();
      const utcTimestamp = Date.parse(nowInISO);
      utcDate = new Date(utcTimestamp);
    } catch (e) {
      console.log(`Error in producing UTC Date value. Error is ${e}`);
    }
    return utcDate;
  }


  public outboundChannelMessage = (
    incoming: ChannelMessage,
    ctrlType: number,
    overrides?: any
  ): ChannelMessage | undefined => {
    let chnMsg: ChannelMessage;
    const _overrides: any = (overrides) ? overrides : {};
    const timestamp: number = this.produceUTCTimestamp();
    if (incoming) {
      const _msg = new ChannelMessage();
      const _initOut = _msg.initOutbound();
      chnMsg = {
        ...incoming,
        ..._initOut,
        ...{
          messageType: ctrlType,
          createdTimestamp: timestamp,
          updatedTimestamp: timestamp,
          createdBy: this._cns.LEON,
          updatedBy: this._cns.LEON,
          modeContext: incoming.modeContext
        },
        ..._overrides
      };
      chnMsg = (chnMsg.healthy(chnMsg)
        || chnMsg.healthyTerminalMsg(chnMsg)
        || chnMsg.outboundForControlMsg(chnMsg, ctrlType))
        ? chnMsg
        : null;
    }
    return chnMsg;
  }

  public extendTokenLeaseMsg = (): LeaseMessage => {
    const timestamp: number = this.produceUTCTimestamp();
    let lmMsg: LeaseMessage = new LeaseMessage();
    lmMsg = {
      ...lmMsg,
      ...{
        messageType: this._msgTypes.MT_EXTEND_TOKEN_LEASE,
        message: [new MessageContent(this._cns.MSG_GET_NEW_TOKEN, this._cns.MSG_GET_NEW_TOKEN)],
        createdTimestamp: timestamp,
        updatedTimestamp: timestamp,
        createdBy: this._cns.LEON,
        updatedBy: this._cns.LEON
      }
    }
    return lmMsg;
  }

  public getRolesAndModules = (): UserModule[] => {
    const ROLE_PREFIX: string = this.getRolePrefix();
    const rolesAndModules: UserModule[] = [
      {
        roleName: `${ROLE_PREFIX}${this._cns._RTMC_ADMIN}`,
        module: "RTMC",
        moduleDesc: "RTMC",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._RTMC_READ}`,
        module: "RTMC",
        moduleDesc: "RTMC",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._RESP_ADMIN}`,
        module: "Responses",
        moduleDesc: "Responses",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._RESP_CREATE}`,
        module: "Responses",
        moduleDesc: "Responses",
        access: "CREATE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._RESP_DELETE}`,
        module: "Responses",
        moduleDesc: "Responses",
        access: "DELETE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._RESP_READ}`,
        module: "Responses",
        moduleDesc: "Responses",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._RESP_WRITE}`,
        module: "Responses",
        moduleDesc: "Responses",
        access: "WRITE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._BM_ADMIN}`,
        module: "Brands",
        moduleDesc: "Brands & Behaviours",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._BM_CREATE}`,
        module: "Brands",
        moduleDesc: "Brands & Behaviours",
        access: "CREATE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._BM_DELETE}`,
        module: "Brands",
        moduleDesc: "Brands & Behaviours",
        access: "DELETE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._BM_READ}`,
        module: "Brands",
        moduleDesc: "Brands & Behaviours",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._BM_WRITE}`,
        module: "Brands",
        moduleDesc: "Brands & Behaviours",
        access: "WRITE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._SESSION_ADMIN}`,
        module: "Sessions",
        moduleDesc: "Session Overview",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._SESSION_READ}`,
        module: "Sessions",
        moduleDesc: "Session Overview",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._CLIENT_READ}`,
        module: "Clients",
        moduleDesc: "Client Overview",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._UM_ADMIN}`,
        module: "Users",
        moduleDesc: "User & Agent Overview",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._UM_CREATE}`,
        module: "Users",
        moduleDesc: "User & Agent Overview",
        access: "CREATE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._UM_DELETE}`,
        module: "Users",
        moduleDesc: "User & Agent Overview",
        access: "DELETE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._UM_READ}`,
        module: "Users",
        moduleDesc: "User & Agent Overview",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._UM_WRITE}`,
        module: "Users",
        moduleDesc: "User & Agent Overview",
        access: "WRITE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._LC_CHAT_READ}`,
        module: "Live Chats",
        moduleDesc: "Live Chats",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._LC_TELE_READ}`,
        module: "Live Chats",
        moduleDesc: "Live Chats",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._LC_VC_READ}`,
        module: "Live Chats",
        moduleDesc: "Live Chats",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._INTENT_ADMIN}`,
        module: "Intents",
        moduleDesc: "Intents",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._INTENT_CREATE}`,
        module: "Intents",
        moduleDesc: "Intents",
        access: "CREATE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._INTENT_DELETE}`,
        module: "Intents",
        moduleDesc: "Intents",
        access: "DELETE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._INTENT_READ}`,
        module: "Intents",
        moduleDesc: "Intents",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._INTENT_WRITE}`,
        module: "Intents",
        moduleDesc: "Intents",
        access: "WRITE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._NLP_ADMIN}`,
        module: "Chatbots",
        moduleDesc: "NLP Engines",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._NLP_CREATE}`,
        module: "Chatbots",
        moduleDesc: "NLP Engines",
        access: "CREATE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._NLP_DELETE}`,
        module: "Chatbots",
        moduleDesc: "NLP Engines",
        access: "DELETE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._NLP_READ}`,
        module: "Chatbots",
        moduleDesc: "NLP Engines",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._NLP_WRITE}`,
        module: "Chatbots",
        moduleDesc: "NLP Engines",
        access: "WRITE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._JB_ADMIN}`,
        module: "Workflows",
        moduleDesc: "Workflows",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._JB_CREATE}`,
        module: "Workflows",
        moduleDesc: "Workflows",
        access: "CREATE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._JB_DELETE}`,
        module: "Workflows",
        moduleDesc: "Workflows",
        access: "DELETE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._JB_READ}`,
        module: "Workflows",
        moduleDesc: "Workflows",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._JB_WRITE}`,
        module: "Workflows",
        moduleDesc: "Workflows",
        access: "WRITE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ED_ADMIN}`,
        module: "Entities",
        moduleDesc: "Entities",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ED_CREATE}`,
        module: "Entities",
        moduleDesc: "Entities",
        access: "CREATE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ED_DELETE}`,
        module: "Entities",
        moduleDesc: "Entities",
        access: "DELETE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ED_READ}`,
        module: "Entities",
        moduleDesc: "Entities",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ED_WRITE}`,
        module: "Entities",
        moduleDesc: "Entities",
        access: "WRITE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ANALYTICS_ADMIN}`,
        module: "Analytics",
        moduleDesc: "Analytics",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ANALYTICS_CREATE}`,
        module: "Analytics",
        moduleDesc: "Analytics",
        access: "CREATE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ANALYTICS_DELETE}`,
        module: "Analytics",
        moduleDesc: "Analytics",
        access: "DELETE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ANALYTICS_READ}`,
        module: "Analytics",
        moduleDesc: "Analytics",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ANALYTICS_WRITE}`,
        module: "Analytics",
        moduleDesc: "Analytics",
        access: "WRITE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._TRIGGER_ADMIN}`,
        module: "Triggers",
        moduleDesc: "Triggers",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._TRIGGER_CREATE}`,
        module: "Triggers",
        moduleDesc: "Triggers",
        access: "CREATE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._TRIGGER_DELETE}`,
        module: "Triggers",
        moduleDesc: "Triggers",
        access: "DELETE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._TRIGGER_READ}`,
        module: "Triggers",
        moduleDesc: "Triggers",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._TRIGGER_WRITE}`,
        module: "Triggers",
        moduleDesc: "Triggers",
        access: "WRITE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._AUDIT_READ}`,
        module: "Audit",
        moduleDesc: "Audit",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._API_DEFINITION_READ}`,
        module: "APIs",
        moduleDesc: "API Management",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._API_DEFINITION_WRITE}`,
        module: "APIs",
        moduleDesc: "API Management",
        access: "WRITE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._API_DEFINITION_ADMIN}`,
        module: "APIs",
        moduleDesc: "API Management",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ML_ADMIN}`,
        module: "Train AI",
        moduleDesc: "Machine Learning",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ML_READ}`,
        module: "Train AI",
        moduleDesc: "Machine Learning",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ML_WRITE}`,
        module: "Train AI",
        moduleDesc: "Machine Learning",
        access: "WRITE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ML_CREATE}`,
        module: "Train AI",
        moduleDesc: "Machine Learning",
        access: "CREATE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ML_DELETE}`,
        module: "Train AI",
        moduleDesc: "Machine Learning",
        access: "DELETE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._API_HEADER_READ}`,
        module: "APIs",
        moduleDesc: "API Management",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._API_HEADER_WRITE}`,
        module: "APIs",
        moduleDesc: "API Management",
        access: "WRITE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._API_HEADER_ADMIN}`,
        module: "APIs",
        moduleDesc: "API Management",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ASSET_ADMIN}`,
        module: "Asset Hub",
        moduleDesc: "Asset Management",
        access: "ADMIN"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ASSET_CREATE}`,
        module: "Asset Hub",
        moduleDesc: "Asset Management",
        access: "CREATE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ASSET_DELETE}`,
        module: "Asset Hub",
        moduleDesc: "Asset Management",
        access: "DELETE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ASSET_READ}`,
        module: "Asset Hub",
        moduleDesc: "Asset Management",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._ASSET_WRITE}`,
        module: "Asset Hub",
        moduleDesc: "Asset Management",
        access: "WRITE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._POLICY_CREATE}`,
        module: "Policies",
        moduleDesc: "Policy Management",
        access: "CREATE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._POLICY_DELETE}`,
        module: "Policies",
        moduleDesc: "Policy Management",
        access: "DELETE"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._POLICY_READ}`,
        module: "Policies",
        moduleDesc: "Policy Management",
        access: "READ"
      },
      {
        roleName: `${ROLE_PREFIX}${this._cns._POLICY_WRITE}`,
        module: "Policies",
        moduleDesc: "Policy Management",
        access: "WRITE"
      }
    ];
    return rolesAndModules;
  }

  public getUserModulesReadAccess = (userRoles: string[]): string[] => {
    const userModules: string[] = [];
    const userRolesAndModules: UserModule[] = this.getRolesAndModules();
    if (userRoles && userRoles.length > 0) {
      userRolesAndModules.forEach(role => {
        if (userRoles.includes(role.roleName) && role.access === this._cns.READ) {
          userModules.push(role.module);
        }
      });
    }
    return userModules;
  }

  public isSentinelUser = () => {
    const roles: string[] = this.getUserRoles();
    const isSentinel: boolean = roles && roles.some(r => new RegExp(/ROLE_PL_/g).test(r) || new RegExp(/ROLE_PLATFORM_ADMIN/g).test(r));
    return isSentinel;
  }

  public isUserEditor = (moduleRoles: string[]): boolean => {
    let isEditor = false;
    if (moduleRoles && moduleRoles.length > 0) {
      const userRoles: Role[] = this.authService?.currentUser?.roles
        && this.authService.currentUser.roles.length > 0
        ? this.authService.currentUser.roles
        : [];
      userRoles.forEach((role: Role) => {
        if (role && moduleRoles.includes(role.roleName)) {
          isEditor = true;
        }
      });
    }
    return isEditor;
  }

  public isMaster = (): boolean => {
    const roles: string[] = this.getUserRoles();
    const isSentinelAdmin: boolean = roles && roles.some(r => new RegExp(/ROLE_PLATFORM_ADMIN/g).test(r));
    return isSentinelAdmin;
  }

  public getEditorConfig = (): CKEditor4.Config => {
    const editorConfig: CKEditor4.Config = {
      extraPlugins: "lineheight",
      height: 300,
      forceEnterMode: true,
      extraAllowedContent: "div(*)",
      allowedContent: true,
      removePlugins: "magicline",
      scayt_autoStartup: true,
      shiftEnterMode: Number(1),
      enterMode: Number(2),
      title: false,
      format_tags: "div;h1;h2;h3;h4;h5;h6;p",
      versionCheck: false,
      toolbar: [
        { name: "basicstyles", items: ["Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript", "RemoveFormat"] },
        { name: "paragraph", items: ["NumberedList", "BulletedList", "Blockquote", "JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"] },
        { name: "colorbutton", items: ["TextColor", "BGColor"] },
        { name: "indentation", items: ["Indent", "Outdent"] },
        { name: "links", items: ["Link", "Unlink"] },
        { name: "insert", items: ["HorizontalRule", "Table", "Smiley"] },
        { name: "undo", items: ["Undo", "Redo"] },
        { name: "styles", items: ["Styles", "Format", "Font", "FontSize", "lineheight", "Source"] },
      ],
      font_names: "Segoe UI/Segoe UI, sans-serif;" + "Nunito/Nunito Sans, sans-serif;" + "Noto/Noto Serif, serif;" + "Raleway/Raleway, sans-serif;" + "Lato/Lato, sans-serif;"
        + "Roboto/Roboto, sans-serif;" + "EB Garamond/EB Garamond, serif;" + "Comfortaa/Comfortaa, cursive;" + "Montserrat/Montserrat, sans-serif;"
    };
    return editorConfig;
  }

  public getRolePrefix = (): string => {
    return (this.isSentinelUser() ? this._cns.SENTINEL_ROLE_PREFIX : this._cns.USER_ROLE_PREFIX);
  }

  public getRoleWithPrefix = (roleName: string): string => {
    return `${this.getRolePrefix()}${roleName}`;
  }

  public getUserRoles = (): string[] => {
    let roles: string[] = [];
    if (this.authService && this.authService.currentUser && this.authService.currentUser.roles) {
      const userRoles: string[] = Object.assign(this.authService.currentUser.roles);
      if (userRoles && userRoles.includes(this._cns.ROLE_PLATFORM_ADMIN)) {
        roles = Object.assign(this.authService.currentUser.roles);
      } else {
        this.authService.currentUser.roles.forEach(element => {
          if (element.roleName) {
            roles.push(element.roleName);
          }
        });
      }
    }
    return roles;
  }

  public hasRole = (requiredRole: string) => {
    let isRoleAllowed = false;
    const accessRole: string = this.getRoleWithPrefix(requiredRole);
    isRoleAllowed = this._rolesList.some(r => r === this._cns.ROLE_PLATFORM_ADMIN || r === accessRole);
    return isRoleAllowed;
  }

  public showUserName = (userName: string, ownerShip: string): string => {
    let user: string;
    const userType: string = this.getUserType();
    if ((userName === `${this.getRolePrefix()}${this._cns._INTERNAL_SCHEDULER}`)
      || (userType === this._cns.CLIENT && ownerShip === this._cns.MASTER)
    ) {
      user = this._cns.CHAMELEON;
    } else {
      user = userName ? userName : this._cns.UNDEFINED_DATA;
    }
    return user;
  }

  public getUserType = (): string => {
    let userType: string;
    userType = this.isSentinelUser() ? this._cns.MASTER : this._cns.CLIENT;
    return userType;
  }

  public showPopup = (text: string, icon: SweetAlertIcon) => {
    if (text && icon) {
      Swal.fire({
        text: text,
        icon: icon
      });
    }
  }

  public showPopupWithErrors = (icon: SweetAlertIcon, title: string, html: HTMLDivElement) => {
    Swal.fire({
      icon: icon,
      title: title,
      html: html
    });
  }

  public getRanges = (): any => {
    const ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, this._cns.DAYS), moment().subtract(1, this._cns.DAYS)],
      "Last 7 Days": [moment().subtract(6, this._cns.DAYS), moment()],
      "Last 30 Days": [moment().subtract(29, this._cns.DAYS), moment()],
      "This Month": [moment().startOf(this._cns.MONTH), moment().endOf(this._cns.MONTH)],
      "Last Month": [
        moment().subtract(1, this._cns.MONTH).startOf(this._cns.MONTH),
        moment().subtract(1, this._cns.MONTH).endOf(this._cns.MONTH),
      ],
    };
    return ranges;
  }

  public getConfigWithoutCustomClass = (): CKEditor4.Config => {
    const editorConfig: CKEditor4.Config = {
      extraPlugins: "lineheight",
      height: 100,
      forceEnterMode: true,
      shiftEnterMode: Number(1),
      enterMode: Number(2),
      extraAllowedContent: "div(*)",
      allowedContent: true,
      removePlugins: "magicline",
      scayt_autoStartup: true,
      title: false,
      format_tags: "div;h1;h2;h3;h4;h5;h6;p",
      versionCheck: false,
      toolbar: [
        { name: "basicstyles", items: ["Bold", "Italic", "Underline", "RemoveFormat"] },
        { name: "paragraph", items: ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"] },
        { name: "colorbutton", items: ["TextColor", "BGColor"] },
        { name: "links", items: ["Link", "Unlink"] },
        { name: "styles", items: ["Styles", "Format", "Font", "FontSize", "lineheight"] },
      ],
      font_names: "Segoe UI/Segoe UI, sans-serif;" + "Nunito/Nunito Sans, sans-serif;" + "Noto/Noto Serif, serif;" + "Raleway/Raleway, sans-serif;" + "Lato/Lato, sans-serif;"
        + "Roboto/Roboto, sans-serif;" + "EB Garamond/EB Garamond, serif;" + "Comfortaa/Comfortaa, cursive;" + "Montserrat/Montserrat, sans-serif;"
    };
    return editorConfig;
  }

  public getEmojiCodes = (): number[] => {
    const emojiCodes: number[] = [
      128512,
      128513,
      128514,
      128515,
      128516,
      128517,
      128518,
      128519,
      128520,
      128521,
      128522,
      128523,
      128524,
      128525,
      128526,
      128527,
      128528,
      128529,
      128530,
      128531,
      128532,
      128533,
      128534,
      128535,
      128536,
      128537,
      128538,
      128539,
      128540,
      128541,
      128542,
      128543,
      128544,
      128545,
      128546,
      128547,
      128548,
      128549,
      128550,
      128551,
      128552,
      128553,
      128554,
      128555,
      128556,
      128557,
      128558,
      128559,
      128560,
      128561,
      128562,
      128563,
      128564,
      128565,
      128566,
      128567,
      128577,
      128578,
      128579,
      128580,
      129296,
      129297,
      129298,
      129299,
      129300,
      129301,
      129312,
      129313,
      129314,
      129315,
      129316,
      129317,
      129319,
      129320,
      129321,
      129322,
      129323,
      129324,
      129325,
      129326,
      129327,
      129488,
    ];
    return emojiCodes;
  }

  public modifyStringToDisplay = (str: string): string => {
    let modifiedStr = "";
    if (str) {
      str = `${str[0].toUpperCase()}${str.slice(1)}`;
      modifiedStr = str.split(/(?=[A-Z])/).join(" ");
    }
    return modifiedStr;
  }

  public sleep = async (delay: number) => {
    const delayPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, delay);
    });

    try {
      await delayPromise;
    } catch (e) {
      console.log(`Error occurred in delay promise. Error is ${e}`);
    }
  }

  public popUpWithNoOkBtn = (title: string) => {
    if (title) {
      Swal.fire({
        title: title,
        icon: this._cns.SUCCESS,
        showConfirmButton: false,
        timer: 3000
      });
    }
  }

  public popUpWithNoOkBtnAndWarnings = (title: string, divEl: HTMLDivElement) => {
    if (title) {
      if (divEl) {
        Swal.fire({
          title: title,
          icon: this._cns.SUCCESS,
          showConfirmButton: false,
          timer: 3000,
          html: divEl
        });
      } else {
        Swal.fire({
          title: title,
          icon: this._cns.SUCCESS,
          showConfirmButton: false,
          timer: 3000
        });
      }
    }
  }

  public popUpWithConfirmBtn = (title: string, confirmBtnText: string): Promise<SweetAlertResult> => {
    if (title && confirmBtnText) {
      return Swal.fire({
        title: title,
        icon: this._cns.WARNING,
        showCancelButton: true,
        confirmButtonColor: this._cns.DELETE_CONFIRM_BTN_COLOR,
        cancelButtonColor: this._cns.DELETE_CANCEL_BTN_COLOR,
        confirmButtonText: confirmBtnText
      });
    }
  }

  public popUpWithYesNoBtn = (
    title: string,
    confirmBtnText: string,
    cancelBtnText: string
  ): Promise<SweetAlertResult> => {
    if (title && confirmBtnText && cancelBtnText) {
      return Swal.fire({
        title: title,
        icon: this._cns.WARNING,
        showCancelButton: true,
        confirmButtonColor: this._cns.DELETE_CONFIRM_BTN_COLOR,
        cancelButtonColor: this._cns.DELETE_CANCEL_BTN_COLOR,
        confirmButtonText: confirmBtnText,
        cancelButtonText: cancelBtnText
      });
    }
  }

  public getUserAlias = (): string => {
    let userAlias = "";
    if (this.authService && this.authService.currentUser) {
      userAlias = this.authService.currentUser.alias ? this.authService.currentUser.alias : this.authService.currentUser.firstName;
    }
    return userAlias;
  }

  public isLCEnabledClient = (): boolean => {
    let isLCEnabled = false;
    if (this.authService && this.authService.currentUser
      && this.authService.currentUser.clientSubmoduleDtos
      && this.authService.currentUser.clientSubmoduleDtos.length > 0) {
      const clientModules: ClientSubModule[] = this.authService.currentUser.clientSubmoduleDtos;
      const lcModule: ClientSubModule[] = clientModules.filter(
        (module: ClientSubModule) => module && module.submodulesDto && module.submodulesDto.id === 7
      );
      isLCEnabled = !!(lcModule && lcModule.length > 0);
    }
    return isLCEnabled;
  }

  public produceUiPolicyContext = (policies: UiPolicy[]): UiPolicyContext => {
    let uiPCtx: UiPolicyContext;
    if (!!policies && Array.isArray(policies) && policies.length > 0) {
      // A.) if a generous context and specific context is available in ui policy list
      // generous context will be ignored.
      // Ex: { context: "*"}, { context: "client-management"} => { context: "*"} ignored
      //
      // B.) If in any grants a generous grant and a specific grant is available, then
      // generous grant will be ignored
      // Ex:
      // { context: "client-management", grants: { view: ["*"]}}
      // { context: "client-management", grants: { view: ["dc:cm:ccs:fs:btn:uuid-0000-000-000-00"]}}
      // => { context: "client-management", grants: { view: ["*"]}} is ignored
      try {
        if (policies.length > 0) {
          const nonGenerousList: UiPolicy[] = [];
          const generousList: UiPolicy[] = [];
          policies.forEach(p => p.generousPolicyContext() ? generousList.push(p) : nonGenerousList.push(p));
          uiPCtx = new UiPolicyContext(generousList, nonGenerousList);
        }
      } catch (e) {
        console.log(`Error in sanitizing ui policies. Error is ${e}`);
      }
    }
    return uiPCtx;
  }

  // Entity type 1: client, 2: generic (until a specific type is introduced)
  public applicableTransition = (transitionAware: any, actionAttempted: string, entityType: number): boolean => {
    let applicable = false;
    if (!!transitionAware && !!actionAttempted && !!entityType && entityType > 0) {
      try {
        const entityStatus: string = transitionAware["status"];
        if (!!entityStatus) {
          let graph: StatusNode[] = [];
          if (entityType === 1) {
            graph = this._transitions.clientTransitions();
          } else {
            graph = this._transitions.genericTransitions();
          }
          for (const branch of graph) {
            if (entityStatus === branch.fromStatus()) {
              applicable = branch.isApplicable(actionAttempted);
              break;
            }
          }
        }
      } catch (e) {
        console.log(`Unable to determine applicable transitions of the given object. Error is ${e}`);
      }
    }
    return applicable;
  }

  public getOptionListOrder = (allOptions: any[], selectedList: any, filterVal: string, sortVal?: string): any[] => {
    let updatedList: any[] = [];
    if (allOptions && allOptions.length > 0) {
      updatedList = allOptions;
      if (selectedList) {
        let checkedList: any[] = [];
        let notCheckedList: any[] = [];
        const sortValue: string = sortVal ? sortVal : filterVal;
        checkedList = allOptions.filter((opt) => opt && opt[filterVal] && selectedList.includes(opt[filterVal]));
        checkedList = this.sortOptions(checkedList, sortValue);
        notCheckedList = allOptions.filter((opt) => opt && opt[filterVal] && !selectedList.includes(opt[filterVal]));
        notCheckedList = this.sortOptions(notCheckedList, sortValue);
        updatedList = checkedList.concat(notCheckedList);
      } else {
        updatedList = this.sortOptions(allOptions, filterVal);
      }
    }
    return updatedList;
  }

  private sortOptions = (options: any[], sortVal: string): string[] => {
    let sortedOptions: any[] = [];
    if (options && options.length > 0) {
      sortedOptions = options;
      if (sortVal) {
        sortedOptions = options.sort((option1, option2) => {
          if (option1 && option2) {
            const optionValue1 = sortVal ? option1[sortVal] : option1;
            const optionValue2 = sortVal ? option2[sortVal] : option2;
            let returnVal: number;
            if (optionValue1.toLowerCase() > optionValue2.toLowerCase()) {
              returnVal = 1;
            } else if (optionValue2.toLowerCase() > optionValue1.toLowerCase()) {
              returnVal = -1;
            } else {
              returnVal = 0;
            }
            return returnVal;
          }
        });
      }
    }
    return sortedOptions;
  }

  public getNormalisedUserName = (): string => {
    // There is a limitation in sending query parameters when # is in the user name
    // Hence normalize # with an allowed character
    const normalizedUserName: string = this.authService?.currentUser?.userName
      ? this.authService.currentUser.userName
        .replace("#", "~")
        .replace("#", "~")
      : "";
    return normalizedUserName;
  }

  public getFileExtension = (fileName: string): string => {
    let extension: string = null;
    const fPart = fileName ? fileName.split(".") : [];
    extension = fPart && fPart.length > 0 ? `${fPart[fPart.length - 1]}` : "";
    return extension;
  }

  public handleOrientation = (msg: MessageEvent) => {
    const iframe: HTMLIFrameElement = document.getElementById(this._cns.CHATBOT) as HTMLIFrameElement;
    if (msg && msg.origin === this._cns.HELP_CAMIE_URL) {
      const postableEvt = msg && msg.data && msg.data.evtName ? msg.data : null;
      if (postableEvt && iframe) {
        if (postableEvt.evtName === this._cns.LOADED) {
          iframe?.contentWindow?.postMessage({
            evtName: this._cns.IFRAME,
            autoLaunch: false,
          }, "*");
        } else if (postableEvt.evtName === this._cns.CHAT_WINDOW) {
          iframe?.classList?.add(this._cns.CLS_CHAT_WINDOW);
          iframe?.classList?.remove(this._cns.CLS_CHAT_BTN);
          iframe?.classList?.remove(this._cns.CLS_CHAT_EXPAND);
        } else if (postableEvt.evtName == this._cns.EXPAND) {
          iframe?.classList?.add(this._cns.CLS_CHAT_EXPAND);
          iframe?.classList?.remove(this._cns.CLS_CHAT_WINDOW);
          iframe?.classList?.remove(this._cns.CLS_CHAT_BTN);
        } else {
          iframe?.classList?.add(this._cns.CLS_CHAT_BTN);
          iframe?.classList?.remove(this._cns.CLS_CHAT_WINDOW);
          iframe?.classList?.remove(this._cns.CLS_CHAT_EXPAND);
        }
      }
      iframe?.contentWindow?.postMessage({
        call: this._cns.PAGE_URL,
        value: document.location.href
      }, "*");
    }
  }

  public getSelectedPolicyNames = (policyIds: number[], policyList: (UiPolicy | ServerPolicy)[]): string[] => {
    const policyNames: string[] = [];
    if (policyIds && policyIds.length > 0 && policyList && policyList.length > 0) {
      const policies: (UiPolicy | ServerPolicy)[] = policyList.filter(
        (policy) => policy && policyIds.includes(policy[this._cns.ID])
      );
      if (policies && policies.length > 0) {
        policies.forEach((policy: UiPolicy | ServerPolicy) => {
          policyNames.push(policy[this._cns.NAME]);
        });
      }
    }
    return policyNames;
  }

  public getSelectedPolicyIds = (policyNames: string[], policyList: (UiPolicy | ServerPolicy)[]): number[] => {
    const policyIds: number[] = [];
    if (policyNames && policyNames.length > 0 && policyList && policyList.length > 0) {
      const policies: (UiPolicy | ServerPolicy)[] = policyList.filter(
        (policy) => policy && policyNames.includes(policy[this._cns.NAME])
      );
      if (policies && policies.length > 0) {
        policies.forEach((policy: UiPolicy | ServerPolicy) => {
          policyIds.push(policy[this._cns.ID]);
        });
      }
    }
    return policyIds;
  }

  public removeSelectAll = (selectedList: string[]): string[] => {
    let filteredList: string[] = [];
    if (selectedList && selectedList.length > 0) {
      filteredList = selectedList;
      if (selectedList.includes(this._cns.SELECT_ALL)) {
        filteredList = selectedList.filter((selected: string) => selected && selected !== this._cns.SELECT_ALL);
      }
    }
    return filteredList;
  }

  public parseJsonData = (rawData: string): any => {
    let parsedData = {};
    if (rawData) {
      try {
        parsedData = JSON.parse(rawData);
      } catch (error) {
        console.log(`Error occurred while parsing the data. Error is ${error}`);
      }
    }
    return parsedData;
  }

  public stringifyJsonData = (jsonData: any) => {
    let modifiedStr = "";
    if (jsonData) {
      try {
        modifiedStr = JSON.stringify(jsonData);
      } catch (error) {
        console.log(`Error occurred while stringify-ing the data. Error is ${error}`);
      }
    }
    return modifiedStr;
  }

  public produceFingerprint = (refCanvas: any): string | undefined => {
    let fp : string | undefined;
    try {
      if (!!refCanvas) {
        const context = refCanvas.getContext("2d");
        if (!!context) {
          context.fillStyle = "rgb(255, 0, 255)";
          context.beginPath();
          context.rect(20, 20, 150, 100);
          context.fill();
          context.stroke();
          context.closePath();
          context.beginPath();
          context.fillStyle = "rgb(0, 255, 255)";
          context.arc(50, 50, 50, 0, Math.PI * 2, true);
          context.fill();
          context.stroke();
          context.closePath();

          const txt = "abz180#$%^@£éú";
          context.textBaseline = "top";
          context.font = "17px 'Arial 17'";
          context.textBaseline = "alphabetic";
          context.fillStyle = "rgb(255, 5, 5)";
          context.rotate(.03);
          context.fillText(txt, 4, 17);
          context.fillStyle = "rgb(155, 255, 5)";
          context.shadowBlur = 8;
          context.shadowColor = "red";
          context.fillRect(20, 12, 100, 5);

          const src = refCanvas.toDataURL();
          let hash = 0;
          for (let i = 0; i < src.length; i++) {
            const char: number = src.charCodeAt(i);
            // tslint:disable-next-line:no-bitwise
            hash = ((hash << 5) - hash ) + char;
            // tslint:disable-next-line:no-bitwise
            hash = hash & hash;
            fp = `${hash}`;
          }
        }
      }
    } catch (e) {
      console.log(`Error when generating a fingerprint. Error is ${e}`);
    }
    return fp;
  }

  public getTmplTooltipText = (convertedToTemplate: number): string => {
    let text = "";
    switch (convertedToTemplate) {
      case 0:
        text = this._cns.TEMPLATE_NOT_CONVERTED_TEXT;
        break;
      case 1:
        text = this._cns.TEMPLATE_CONVERTED_CURRENTLY_TEXT;
        break;
      case 2:
        text = this._cns.TEMPLATE_CONVERTED_NOT_CURRENTLY_TEXT;
        break;
      default:
        text = this._cns.TEMPLATE_NOT_CONVERTED_TEXT;
        break;
    }
    return text;
  }

  public getTmplConvertedColor = (convertedToTemplate: number): string => {
    let statusColor = "";
      switch (convertedToTemplate) {
        case 0:
          statusColor = this._cns.TEMPLATE_NOT_CONVERTED;
          break;
        case 1:
          statusColor = this._cns.TEMPLATE_CONVERTED_CURRENTLY;
          break;
        case 2:
          statusColor = this._cns.TEMPLATE_CONVERTED_NOT_CURRENTLY;
          break;
        default:
          statusColor = this._cns.TEMPLATE_NOT_CONVERTED;
          break;
      }
    return statusColor;
  }

  public updatePromptView = (event?: PointerEvent | InputEvent): number => {
      const defHeight: number = this._cns.PROMPT_DEF_HEIGHT;
      const evTarget: HTMLInputElement = event ? event.target as HTMLInputElement : null;
      const areaScrollHeight: number = evTarget ? evTarget.scrollHeight : 0;
      const newHeight: number = areaScrollHeight && areaScrollHeight > defHeight ? areaScrollHeight : defHeight;
      return newHeight;
  }

  public prepareInboundLeaseMessage = (msgObj: any): LeaseMessage => {
    let lm: LeaseMessage;
    if (!!msgObj) {
      lm = LeaseMessage.initInbound(msgObj);
      if (!lm.healthy()) {
        lm = undefined;
      } else {
        const validLeaseMessage = lm.isLeaseMessageType();
        if (!validLeaseMessage) {
          lm = undefined;
        }
      }
    }
    return lm;
  }

  public prepareInboundLMHeartbeatMsg = (msgObj: any): LmHbMessage => {
    let lmHb: LmHbMessage = new LmHbMessage();
    if (!!msgObj) {
      lmHb = lmHb.initInbound(msgObj);
      if (!lmHb.healthy()) {
        lmHb = undefined;
      }
    }
    return lmHb;
  }
}
