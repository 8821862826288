import { Directive, Input, ElementRef } from "@angular/core";
import { ModuleList } from "@app/shared/module-list";
import { ModuleLink } from "@app/core/misctypes/module-link";
import { ClientSubModule } from "@app/core/misctypes/client-sub-module";
import { AuthenticationService } from "@app/core/services/authentication/authentication.service";

@Directive({
  selector: "[HideIfUnauthorised]",
})
export class HideIfUnauthorisedDirective {
  @Input() menuLink: string;

  constructor(
    private el: ElementRef,
    private authSvc: AuthenticationService
  ) { }

  ngOnInit() {
    if (!this.checkPrivilege(this.menuLink)) {
      this.el.nativeElement.style.display = "none";
    }
  }

  public checkPrivilege = (url: string) => {
    let approval = false;
    const modules: ModuleLink[] = url ? ModuleList.modules.filter((item) => { return item.path === url }) : [];
    if (modules && modules.length > 0) {
      modules.forEach((mod: ModuleLink) => {
        if (this.authSvc.currentUser.clientSubmoduleDtos) {
          this.authSvc.currentUser.clientSubmoduleDtos.forEach((dto: ClientSubModule) => {
            if (mod.moduleId === dto.submodulesDto.id) {
              approval = true;
            }
          });
        }
      });
    }
    return approval;
  }
}
