import { Injectable } from "@angular/core";
import { AuthenticationService } from "@app/core/services/authentication/authentication.service";
import { HttpClient } from "@angular/common/http";
import { EndpointListService } from "@app/core/services/endpoint-list/endpoint-list.service";
import { AnalyticsStatsParam } from "@app/analytics/types/analytics-stats-param";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  public webPageLoadVal = new BehaviorSubject("");
  public webPageMaxValue = new BehaviorSubject(0);
  public webPageData = new BehaviorSubject([]);
  public selectedTabIndex = new BehaviorSubject(null);

  public workflowLoad = new BehaviorSubject("");
  public wfStatsMaxValue = new BehaviorSubject(0);
  public workflowData = new BehaviorSubject([]);

  public chatbotLoad = new BehaviorSubject("");
  public chatbotMaxValue = new BehaviorSubject(0);
  public chatbotData = new BehaviorSubject([]);

  public livechatLoad = new BehaviorSubject("");
  public lcMaxValue = new BehaviorSubject(0);
  public livechatData = new BehaviorSubject([]);
  
  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private endpointListService: EndpointListService
  ) { }

  public getWorkflow = (statsParam: AnalyticsStatsParam) => {
    const url: string = `${this.authService.client.api_gateway_url}/chameleon-stat-consumer/${this.endpointListService.getWorkflow}`;
    return this.http.post<any>(url, statsParam, { observe: "response" });
  }

  public getLivechat = (statsParam: AnalyticsStatsParam) => {
    const url: string = `${this.authService.client.api_gateway_url}/chameleon-stat-consumer/${this.endpointListService.getLivechat}`;
    return this.http.post<any>(url, statsParam, { observe: "response" });
  }

  public getAI = (statsParam: AnalyticsStatsParam) => {
    const url: string = `${this.authService.client.api_gateway_url}/chameleon-stat-consumer/${this.endpointListService.getAI}`;
    return this.http.post<any>(url, statsParam, { observe: "response" });
  }

  public getForms = (statsParam: AnalyticsStatsParam) => {
    const url: string = `${this.authService.client.api_gateway_url}/chameleon-stat-consumer/${this.endpointListService.getForms}`;
    return this.http.post<any>(url, statsParam, { observe: "response" });
  }

  public getConsolidatedMetadata = (statsParam: AnalyticsStatsParam) => {
    const url: string = `${this.authService.client.api_gateway_url}/chameleon-stat-consumer/${this.endpointListService.getConsolidatedMetadata}`;
    return this.http.post<any>(url, statsParam, { observe: "response" });
  }

  public getWebpage = (statsParam: AnalyticsStatsParam) => {
    const url: string = `${this.authService.client.api_gateway_url}/chameleon-stat-consumer/${this.endpointListService.getWebpage}`;
    return this.http.post<any>(url, statsParam, { observe: "response" });
  }

  public getEvent = (statsParam: AnalyticsStatsParam) => {
    const url: string = `${this.authService.client.api_gateway_url}/chameleon-stat-consumer/${this.endpointListService.getEvent}`;
    return this.http.post<any>(url, statsParam, { observe: "response" });
  }

  public getSearchClients = (searchVal: string, searchBy: string) => {
    return this.http.get<any>(`${this.authService.client.api_gateway_url}/clientmanagement/${this.endpointListService.searchClients}?searchKey=${searchVal}&searchBy=${searchBy}`);
  }
}