import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { Constants } from "@app/core/utils/const";

@Component({
  selector: "app-chip-list-reorder",
  templateUrl: "./chip-list-reorder.component.html",
  styleUrls: ["./chip-list-reorder.component.scss"]
})
export class ChipListReorderComponent implements OnInit {

  public aiSuggestions: string[] = [];
  public removable = true;
  public separatorKeysCodes: number[] = [ENTER];
  private _originalClose: Function;

  public _const: Constants;

  constructor(
    public suggestions: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {
    this._const = new Constants();
    this._originalClose = this.suggestions.close;
    this.suggestions.close = this.saveReorderInfo.bind(this);
  }

  ngOnInit(): void {
    this.aiSuggestions = this.dialogData.aiSuggestions;
  }

  public dropSuggestion(event: CdkDragDrop<string[]>) {
    if (event) {
      moveItemInArray(this.aiSuggestions, event.previousIndex, event.currentIndex);
    }
  }

  public removeSuggestion = (suggestion: string) => {
    if (this.aiSuggestions && this.aiSuggestions.length > 0) {
      const index: number = this.aiSuggestions.indexOf(suggestion);
      if (index >= 0) {
        this.aiSuggestions.splice(index, 1);
      }
    }
  }

  public addSuggestion = (event: MatChipInputEvent) => {
    if (event) {
      const input: HTMLInputElement = event.input;
      const value: string = event.value;
      if ((value || "").trim()) {
        if (value.includes(",")) {
          const words: string[] = value.split(",");
          words.forEach((word: string) => {
            this.aiSuggestions.push(word.trim());
          });
        } else {
          this.aiSuggestions.push(value.trim());
        }
      }
      if (input) {
        input.value = "";
      }
    }
  }

  public saveReorderInfo = (type: string) => {
    if (type === this._const.CANCEL) {
      this._originalClose.bind(this.suggestions)({});
    }
    if (type === this._const.SAVE) {
      this._originalClose.bind(this.suggestions)({ event: this._const.SAVE, data: this.aiSuggestions });
    }
  }
}
