<div class="outer" *ngIf="selectedTabIndex === 0">
  <div class="d3-chartY" #chartY id="chartY"></div>
  <div class="d3-chart" #chart id="chart"></div>
</div>

<div class="wf-outer" *ngIf="selectedTabIndex === 2">
  <div class="wf-chartY" #wfChartY id="wfChartY"></div>
  <div class="wf-chart" #wfChart id="wfChart"></div>
</div>

<div class="chatbot-outer" *ngIf="selectedTabIndex === 3">
  <div class="chatbot-chartY" #chatbotChartY id="chatbotChartY"></div>
  <div class="chatbot-chart" #chatbotChart id="chatbotChart"></div>
</div>

<div class="lc-outer" *ngIf="selectedTabIndex === 4">
  <div class="lc-chartY" #chatbotChartY id="lcChartY"></div>
  <div class="lc-chart" #chatbotChart id="lcChart"></div>
</div>