<div class="page-sidebar fixedscroll collapseit no-print">
  <!-- MAIN MENU - START -->
  <div class="page-sidebar-wrapper" id="main-menu-wrapper">
    <div class="logo-area" (click)="isCollapsed = true">
      <img src="./assets/img/CCX-Logo-Black.png" *ngIf="toggle" alt="logo" />
      <div class="full-logo" *ngIf="!toggle">
        <img src="./assets/img/DC-Logo-Black-Version.png" width="80%" height="15%" alt="logo" />
      </div>
    </div>

    <!-- USER INFO - START -->
    <div class="profile-info row">
      <!-- Button trigger modal -->
      <button type="button" class="btn" data-toggle="modal" data-target="#exampleModal">
        <img alt="" *ngIf="currentUser.displayImage === null" src="assets/img/Profile-Default.png"
          class="img-fluid rounded-circle" />
        <img alt="" *ngIf="currentUser.displayImage !== null" [src]="currentUser?.displayImage"
          class="img-fluid rounded-circle" />
        {{ userFirstName ? userFirstName : currentUser?.email }}
      </button>
      <!-- Modal -->
      <div class="modal fade left" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <ul>
                <li>
                  <p class="username">
                    {{ displayName ? displayName : currentUser?.email }}
                  </p>
                </li>
                <li>
                  <a href="javascript:;" class="logout">
                    <span (click)="logout()" class="logouttext">Signout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- USER INFO - END -->
    <div class="menu-list" (click)="isCollapsed = true">
      <ul class="wraplist">

        <li class="main-option" *ngIf="isAdmin || userModules.includes('RTMC')">
          <a (click)="closeCal" HideIfUnauthorised [menuLink]="'/rtmc'" href="javascript:void(0);"
            [routerLinkActive]="['active']" routerLink="/rtmc">
            <span class="icon-rtmc navicon"></span>
            <span class="title m-t-5">Real-time</span>
          </a>
        </li>

        <li class="main-option" *ngIf="isAdmin || userModules.includes('Analytics')">
          <a HideIfUnauthorised [menuLink]="'/analytics'" href="javascript:void(0);" [routerLinkActive]="['active']"
            routerLink="/analytics">
            <span class="icon-analytics navicon"></span>
            <span class="title m-t-5">Analytics</span>
          </a>
        </li>

        <li *ngIf="isAdmin || (_coreUtils.isSentinelUser() && userModules.includes('Clients'))" class="main-option">
          <a HideIfUnauthorised [menuLink]="'/clientManagement'" href="javascript:void(0);"
            [routerLinkActive]="['active']" routerLink="/clientManagement" (click)="setSelectedClientTab()">
            <span class="icon-client-dashboard navicon"></span>
            <span class="title">Clients</span>
          </a>
        </li>

        <li class="main-option" *ngIf="isAdmin || userModules.includes('Sessions')">
          <a HideIfUnauthorised href="javascript:void(0);" [menuLink]="'/sessionManagement'"
            [routerLinkActive]="['active']" routerLink="/sessionManagement">
            <span class="icon-sessions navicon"></span>
            <span class="title m-t-3">Sessions</span>
          </a>
        </li>

        <li class="main-option" *ngIf="isAdmin || userModules.includes('Live Chats')">
          <a HideIfUnauthorised href="javascript:void(0);" [menuLink]="'/livechat'" [routerLinkActive]="['active']"
            routerLink="/livechat">
            <span class="icon-livechat navicon"></span>
            <span class="title m-t-5">Live Chats</span>
          </a>
        </li>

        <li class="main-option" *ngIf="isAdmin || userModules.includes('Users')">
          <a HideIfUnauthorised [menuLink]="'/userManagement'" href="javascript:void(0);"
            [routerLinkActive]="['active']" routerLink="/userManagement">
            <span class="icon-user-management navicon"></span>
            <span class="title m-t-6">Users</span>
          </a>
        </li>

        <li class="main-option" *ngIf="isAdmin || userModules.includes('Workflows')">
          <a HideIfUnauthorised href="javascript:void(0);" [menuLink]="'/journeyBuilder'"
            [routerLinkActive]="['active']" routerLink="/journeyBuilder" (click)="setSelectedJourneyTab()">
            <span class="icon-journey-builder navicon"></span>
            <span class="title m-t-5">Workflows</span>
          </a>
        </li>

        <li class="main-option" *ngIf="isAdmin || userModules.includes('Triggers')">
          <a HideIfUnauthorised [menuLink]="'/triggers'" (click)="setSelectedTriggerTypes()" href="javascript:void(0);"
            [routerLinkActive]="['active']" routerLink="/triggers">
            <span class="icon-triggers navicon"></span>
            <span class="title">Triggers</span>
          </a>
        </li>

        <li class="main-option" *ngIf="isAdmin || userModules.includes('APIs')">
          <a HideIfUnauthorised [menuLink]="'/apiManagement'" (click)="setSelectedApiTypes()" href="javascript:void(0);"
            [routerLinkActive]="['active']" routerLink="/apiManagement">
            <span class="icon-api-define navicon"></span>
            <span class="title m-t-5">APIs</span>
          </a>
        </li>

        <ul id="collapseExample" [ngClass]="mouseOvered" (mouseenter)="mouseOvered='show'" (mouseleave)="mouseOvered=''"
          (click)="$event.stopPropagation(); isCollapsed = true">
          <li class="drop-menu main-option behave" *ngIf="isAdmin || userModules.includes('Brands')">
            <a HideIfUnauthorised [routerLinkActive]="['active']" [menuLink]="'/conversationMgmt/behaviour-management'"
              href="javascript:void(0);" routerLink="/conversationMgmt/behaviour-management"
              (click)="setSelectedBehaviourTypes()">
              <span class="icon-bb-builder navicon"></span>
              <span class="title">Brands</span>
            </a>
          </li>

          <li class="main-option" *ngIf="isAdmin || userModules.includes('Train AI')">
            <a HideIfUnauthorised [routerLinkActive]="['active']" [menuLink]="'/machineLearning'"
              href="javascript:void(0);" routerLink="/machineLearning">
              <span class="icon-machine-learning navicon"></span>
              <span class="title m-t-5">Train AI</span>
            </a>
          </li>

          <li class="drop-menu main-option" *ngIf="isAdmin || userModules.includes('Responses')">
            <a HideIfUnauthorised [routerLinkActive]="['active']" [menuLink]="'/conversationMgmt/response-management'"
              href="javascript:void(0);" routerLink="/conversationMgmt/response-management">
              <span class="icon-response-management navicon"></span>
              <span class="title">Responses</span>
            </a>
          </li>
        </ul>

        <li class="main-option" *ngIf="isAdmin || userModules.includes('Chatbots')">
          <a HideIfUnauthorised [routerLinkActive]="['active']" [menuLink]="'/conversationMgmt/nlp-management'"
            href="javascript:void(0);" routerLink="/conversationMgmt/nlp-management" (click)="setSelectedNLPTab()">
            <span class="icon-nlp-engine-management navicon"></span>
            <span class="title m-t-5">Chatbots</span>
          </a>
        </li>

        <li class="main-option" *ngIf="isAdmin || userModules.includes('Intents')">
          <a HideIfUnauthorised [routerLinkActive]="['active']" href="javascript:void(0);"
            routerLink="/conversationMgmt/intent-management" [menuLink]="'/conversationMgmt/intent-management'">
            <span class="icon-intent-management navicon"></span>
            <span class="title">Intents</span>
          </a>
        </li>

        <li class="main-option" *ngIf="isAdmin || userModules.includes('Entities')">
          <a HideIfUnauthorised [menuLink]="'/entity'" href="javascript:void(0);" [routerLinkActive]="['active']"
            routerLink="/entity">
            <span class="icon-entities-dashboard navicon"></span>
            <span class="title m-t-3">Entities</span>
          </a>
        </li>

        <li class="main-option" *ngIf="isAdmin || userModules.includes('Audit')">
          <a HideIfUnauthorised [menuLink]="'/audit'" href="javascript:void(0);" [routerLinkActive]="['active']"
            routerLink="/audit">
            <span class="icon-audit-trail navicon"></span>
            <span class="title m-t-5">Audit</span>
          </a>
        </li>

        <li class="main-option" *ngIf="isAdmin || userModules.includes('Asset Hub')">
          <a HideIfUnauthorised class="assethub" [menuLink]="'/assets'" href="javascript:void(0);"
            [routerLinkActive]="['active']" routerLink="/assets" (click)="setSelectedAssetTab()">
            <span class="navicon">
              <img class="asseticon" src="./assets/img/asseticon.png" alt="asset hub" />
              <img class="asseticon-hover" src="./assets/img/asseticonhover.png" alt="asset hub" />
            </span>
            <span class="title">Asset Hub</span>
          </a>
        </li>

        <li class="main-option" *ngIf="isAdmin || userModules.includes('Policies')">
          <a HideIfUnauthorised class="assethub" href="javascript:void(0);" [menuLink]="'/policies'"
            [routerLinkActive]="['active']" routerLink="/policies">
            <span class="navicon">
              <mat-icon>format_list_bulleted</mat-icon>
            </span>
            <span class="title">Policies</span>
          </a>
        </li>

      </ul>
    </div>
    <div class="footer-copyright"><a href="http://digitalchameleon.ai/" target="_blank">©DigitalChameleon.ai</a></div>
  </div>
</div>