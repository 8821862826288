import { HttpParams } from "@angular/common/http";
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { AssetInfo } from "@app/asset/misc/asset-info";
import { BehaviourManagementService } from "@app/conversation-management/services/behaviour-management/behaviour-management.service";
import { BehaviourDetails } from "@app/conversation-management/types/behaviour-details";
import { Behaviour } from "@app/core/misctypes/behaviour";
import { BehaviourInfo } from "@app/core/misctypes/behaviour-info";
import { JourneyBuilderService } from "@app/journey-builder/services/journey-builder.service";
import { TriggerConstant } from "@app/trigger/constants/trigger.constant";
import { Buttons } from "@app/trigger/types/buttons";
import { LandingPage } from "@app/trigger/types/landing-page";
import { LandingSection } from "@app/trigger/types/landing-section";
import { environment } from "@environments/environment";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { AssetService } from "@app/asset/services/asset.service";
import { AssetListRequest } from "@app/asset/misc/asset-list-request";

@Component({
  selector: "app-web-preview",
  templateUrl: "./web-preview.component.html",
  styleUrls: ["./web-preview.component.scss"]
})
export class WebPreviewComponent implements OnInit, AfterViewInit {
  public behaviour: BehaviourDetails;
  public landingWidget: LandingSection;
  public landingPage: LandingPage[] = [];
  public hover: string;
  public isMenuShown = false;
  public showHeader = true;
  public showHeaderElement = true;
  public showHamburger = true;
  public sExpandedIndex = "none";
  public mExpandedIndex = "none";
  public leftBtnArray: Buttons[][] = [];
  public rightBtnArray: Buttons[][] = [];
  public centerBtnArray: Buttons[][] = [];
  public sandwichBtnArray: Buttons[][] = [];
  public toggleBtnColor: any;
  public headerSectionIndex = -1;
  public toggleSearch = false;
  public hamBgType: string;
  public hamBgStyle: any;
  public hamBgVideo: string;
  public selectedBehaviour: any;
  public behaviourList: Behaviour[] = [];
  public triggerViewable = false;
  public behaviourUrl: string = environment.behaviourUrl;
  public imageBaseUrl: string = environment.behaviourUrl;
  public isSectionIframeOpened: boolean[] = [];
  public backgroundStyle: any = {};
  public dashboardVideoUrl: string = "";
  public clientId: number;
  public assets: AssetInfo[] = [];
  public selectedAssetUrl: string | null = null;
  public imageAssetId: string;

  public slideHorizontalVerConfig = {
    slidesToShow: 5,
    slidesToScroll: 5,
    rows: 1,
    slidesPerRow: 4,
    dots: true,
    infinite: false,
    autoplay: false,
  };

  public slideHorizontalExpandedConfig = {
    slidesToShow: 5,
    slidesToScroll: 5,
    rows: 1,
    slidesPerRow: 4,
    infinite: true,
    autoplay: true,
    dots: true,
    autoplaySpeed: 2000,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 821,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  public slideHorizontalMobileConfig = {
    slidesToShow: 5,
    slidesToScroll: 5,
    rows: 1,
    slidesPerRow: 4,
    infinite: true,
    autoplay: true,
    dots: true,
    autoplaySpeed: 2000,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  public slideBannerConfig = {
    slidesToShow: 3,
    slidesToScroll: 3,
    rows: 1,
    slidesPerRow: 3,
    infinite: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: false,
    speed: 700,
    dots: true,
    responsive: [
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 3,
          slidesPerRow: 1,
          infinite: true,
        }
      }
    ]
  };

  private _const: TriggerConstant;
  private _originalClose: Function;

  @ViewChild("slickModal") slickModal1: SlickCarouselComponent;

  constructor(
    public buttonBox: MatDialogRef<WebPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private _jbService: JourneyBuilderService,
    private _behaviourService: BehaviourManagementService,
    private _assetService: AssetService
  ) {
    this._const = new TriggerConstant();
    this._originalClose = this.buttonBox.close;
    this.buttonBox.close = this.cancel.bind(this);
  }

  ngOnInit(): void {
    const clientId = this.dialogData.clientId;
    const ownerType = this.dialogData.ownerType;
    this.getBehaviours(clientId, ownerType);
    this.getAssets();
  }

  ngAfterViewInit(): void {
    this.slickModal1?.unslick();
    setTimeout(() => {
      this.slickModal1?.initSlick();
      this.stickySidebarToggle();
      this.stickyFooterToggle();
      this.onScreenResize(1025)
    }, 500);
  }

  initialiseTriggerView = () => {
    this.landingWidget = this.dialogData?.landingScreen;
    this.landingPage = this.landingWidget?.landingPage;
    this.showHeader = this.behaviour?.behaviourDesignDto && !this.behaviour?.behaviourDesignDto.header ? false : true;
    this.showHeaderElement = this.behaviour?.behaviourDesignDto && !this.behaviour?.behaviourDesignDto.headerElements ? false : true;
    this.showHamburger = this.behaviour?.behaviourDesignDto && !this.behaviour?.behaviourDesignDto.hamburger ? false : true;
    this.toggleBtnColor = {
      color: this.behaviour?.behaviourDesignDto.customerBubbleFontColour,
      background: this.behaviour?.behaviourDesignDto.customerBubbleBg,
      "font-size": this.behaviour?.behaviourDesignDto.paragraphFontSize + this._const.PIXEL
    };
    this.imageBaseUrl = environment.behaviourUrl;
    this.resolveLandingPage();
  }

  public outerClick = () => {
    this.sExpandedIndex = this._const.BA_NONE;
    this.mExpandedIndex = this._const.BA_NONE;
  }

  public sidebarToggle = (sectionId: string, pSecId: string) => {
    document.getElementById(sectionId).classList.remove("hide");
    document.getElementById(pSecId).classList.toggle("show-sidebar");
  }

  public getSectionStyles = (section: LandingPage): any => {
    let sectionStyle: any = {};
    if (section) {
      if (section.backgroundType === this._const.MM_TYPE_IMAGE) {

        sectionStyle = {
          "background-image": section.backgroundSrc
            ? `url(${this.behaviourUrl}${section.backgroundSrc})`
            : this.behaviour?.behaviourDesignDto.dashBgImgVideo,
          width: this._const.PERCENT_100,
          "min-height": `${section.height}${this._const.VIEW_HEIGHT}`,
          "background-repeat": this._const.BG_NO_REPEAT,
          "background-position": this._const.CENTER,
          "background-size": this._const.DEFAULT_BG_SIZE
        };
      } else if (section.backgroundType === this._const.MM_TYPE_VIDEO) {
        sectionStyle = {
          width: this._const.PERCENT_100,
          "min-height": `${section.height}${this._const.VIEW_HEIGHT}`,
          "background-repeat": this._const.BG_NO_REPEAT,
          "background-position": this._const.CENTER,
          "background-size": this._const.DEFAULT_BG_SIZE
        };
      } else {
        sectionStyle = {
          "background-color": section.backgroundSrc,
          width: this._const.PERCENT_100,
          "min-height": `${section.height}${this._const.VIEW_HEIGHT}`
        };
      }
    }
    return sectionStyle;
  }

  private resolveLandingPage = () => {
    if (this.landingWidget && this.landingWidget.landingPage && this.landingWidget.landingPage.length > 0) {
      this.landingPage = this.landingWidget.landingPage;
      this.resolveLandingButtons();
    }
  }

  private resolveLandingButtons = () => {
    let secHeaderHeight = 0;
    let secFooterHeight = 0;
    this.landingPage.forEach(async (section: any, sIndex: number) => {
      this.isSectionIframeOpened[sIndex] = false;
      if (section) {
        this.leftBtnArray[sIndex] ? this.leftBtnArray[sIndex] = [] : this.leftBtnArray.push([]);
        this.rightBtnArray[sIndex] ? this.rightBtnArray[sIndex] = [] : this.rightBtnArray.push([]);
        this.centerBtnArray[sIndex] ? this.centerBtnArray[sIndex] = [] : this.centerBtnArray.push([]);
        this.sandwichBtnArray[sIndex] ? this.sandwichBtnArray[sIndex] = [] : this.sandwichBtnArray.push([]);

        if (section.buttons && section.buttons.length > 0) {
          section.buttons.forEach((button: any, bIndex: number) => {
            if (button) {
              this.htmlStringParser(button.displayText);
              if (button.action === this._const.BA_GO_TO_SECTION) {
                button.goToSecId = this.getGoToSecId(button.nextAction);
              }

              if (button && button.useBrandLogo && this.behaviour && this.behaviour.behaviourPersonaDto) {
                button.imageUrl = this.behaviour.behaviourPersonaDto.logo ? this.behaviour.behaviourPersonaDto.logo : "";
              }

              if (button && button.useProfileLogo && this.behaviour && this.behaviour.behaviourPersonaDto) {
                button.imageUrl = this.behaviour.behaviourPersonaDto.profile ? this.behaviour.behaviourPersonaDto.profile : "";
              }

              if (button?.useNoLogo) {
                const assetUrl = button.imageAssetId ? this.getAssetUrlById(button.imageAssetId) : null;

                if (assetUrl) {
                  button.imageUrl = assetUrl;
                } else {
                  console.log('Asset URL not found for imageAssetId:', button?.imageAssetId);
                }
              }

              if (button.action === this._const.BA_NONE && button.buttons.length > 0) {
                button.buttons.forEach(b => b.action === this._const.BA_GO_TO_SECTION
                  ? b.goToSecId = this.getGoToSecId(b.nextAction) : null);
              }

              if (button.openNewTab === this._const.BA_BUTTON_AUTO) {
                this.resolveButton(sIndex, bIndex);
              }

              if (button && button.uniqueBtn
                && button.uniqueBtnAlignment === this._const.LEFT
                && section.buttonLayout !== this._const.VERTICAL
              ) {
                this.leftBtnArray[sIndex].push(button);
              } else if (button.uniqueBtn
                && button.uniqueBtnAlignment === this._const.RIGHT
                && section.buttonLayout !== this._const.VERTICAL
              ) {
                this.rightBtnArray[sIndex].push(button);
              } else {
                this.centerBtnArray[sIndex].push(button);
              }

              if (button.uniqueBtn && button.uniqueBtnAlignment === this._const.CENTER) {
                section["uniqueBtnCenter"] = this._const.CENTER;
              }
            }
          });
        }

        if (section.sectionStick === this._const.HEADER) {
          const cHeight = document.getElementById(`parent-sec-${sIndex}`).clientHeight;
          this.headerSectionIndex = this.headerSectionIndex + 1;
          section.style = {
            top: `${this.headerSectionIndex === 0 ? 0 : secHeaderHeight}${this._const.PIXEL}`
          };
          secHeaderHeight = secHeaderHeight + cHeight;
        }

        if (section.sectionStick === this._const.FOOTER) {
          const cHeight = document.getElementById(`parent-sec-${sIndex}`).clientHeight;
          this.headerSectionIndex = this.headerSectionIndex + 1;
          section.style = {
            "z-index": this.headerSectionIndex === 0 ? this._const.Z_INDEX_9991 : this._const.Z_INDEX_99
          };
          secFooterHeight = secFooterHeight + cHeight;
        }

        if (!section.backgroundSrc && section.bgAssetId) {
          const assetInfo: AssetInfo = this.dialogData.assetList.find(a => a.id === section.bgAssetId);
          section.backgroundSrc = assetInfo && assetInfo.assetUrl ? assetInfo.assetUrl : "";
        }
      }
    });
  }

  private htmlStringParser = (htmlStr: string): string => {
    const doc: HTMLDivElement = document.createElement("div");
    doc.innerHTML = htmlStr;
    const links: HTMLCollectionOf<HTMLAnchorElement> = document.getElementsByTagName("a");
    const linkList: any[] = Array.prototype.slice.call(links);
    if (links && links.length > 0) {
      linkList.forEach((link) => {
        link.setAttribute("onclick", "event.stopPropagation()");
      });
    }
    return doc.innerHTML;
  }

  private getGoToSecId = (sectionName: string): string => {
    let sId = "";
    const sIndex: number = this.landingPage.findIndex(lp => lp.name === sectionName);
    if (sIndex > -1) {
      sId = `parent-sec-${sIndex}`;
    }
    return sId;
  }

  private resolveButton = (sIndex: number, bIndex: number) => {
    setTimeout(() => {
      const buttonDocument = document.getElementById(`${this._const.SECTION}-${sIndex}-btn-${bIndex}`);
      if (buttonDocument) {
        buttonDocument.style.display = "block";
      }
    }, 500);
  }

  public getSectionBtnXYAxis = (button: Buttons): any => {
    const style = {
      left: button.xAxis ? `${button.xAxis}${this._const.PIXEL}` : `0${this._const.PIXEL} !important`,
      bottom: button.yAxis ? `${button.yAxis}${this._const.PIXEL}` : `0${this._const.PIXEL}`,
      "z-index": button.yAxis || button.xAxis ? `1` : `0`,

    };
    return style;
  }

  public getSectionBtnWidth = (button: Buttons): any => {
    const style = {
      width: button && button.width ? `${button.width}${this._const.PIXEL}` : this._const.PERCENT_100,
    };
    return style;
  }

  public getSectionButtonStyle = (button: Buttons, transition: string, section: LandingPage): any => {
    let style: any = {};
    if (button && section) {
      style = {
        "border-radius": button.radius ? `${button.radius}${this._const.PIXEL}` : `0${this._const.PIXEL}`,
        height: `${button.height}${this._const.PIXEL}`,
        width: `${button.width}${this._const.PIXEL}`,
        padding: this._const.DEFAULT_BTN_PADDING,
        "text-align": this._const.DEFAULT_BG_POSITION,
        border: button.borderWeight ? `${button.borderWeight}${this._const.PIXEL}` : `0${this._const.PIXEL}`,
        "border-color": button.borderColor ? `${button.borderColor}` : `${this._const.BUTTON_TRANSPARENT}`,
        position: this._const.DEFAULT_BTN_POSITION,
        margin: this._const.DEFAULT_BTN_MARGIN,
        "min-height": `${button.height}${this._const.PIXEL}}`,
        "margin-left": button.xAxis ? `${button.xAxis}${this._const.PIXEL}` : this._const.PX_0,
        bottom: button.yAxis ? `${button.yAxis}${this._const.PIXEL}` : this._const.PX_0,
      };

      if (this.behaviour && this.behaviour.behaviourDesignDto) {
        style["background-color"] = button.buttonBgColor
          ? button.buttonBgColor : this.behaviour.behaviourDesignDto.optionButtonBgColour;
        style["color"] = button.buttonFontColor
          ? button.buttonFontColor : this.behaviour.behaviourDesignDto.optionButtonFontColour;
      }
      const isTransparentOrCardLook = section.buttonLook === this._const.BUTTON_LOOK_TRANSPARENT
        || section.buttonLook === this._const.BUTTON_LOOK_CARD;


      if (!button.buttonDescription
        && transition === this._const.HOVER && button.action !== this._const.NONE
        || (button.buttonDescription && isTransparentOrCardLook && transition === this._const.HOVER && button.action !== this._const.NONE)
      ) {
        style["background-color"] = button.buttonHoverColor
          ? button.buttonHoverColor : this.behaviour.behaviourDesignDto.optionButtonHoverColour;

        style["color"] = button.buttonHoverFontColor
          ? button.buttonHoverFontColor : this.behaviour.behaviourDesignDto.optionButtonHoverFontColour;

        style["border-color"] = button.borderHoverColor
          ? button.borderHoverColor : this._const.BUTTON_LOOK_TRANSPARENT;
      } else if (section && section.buttonLook === this._const.BUTTON_LOOK_TRANSPARENT) {
        style["color"] = button.buttonFontColor
          ? button.buttonFontColor : this.behaviour?.behaviourDesignDto?.dashTextElColour;
      } else {
        style["background-color"] = button && button.buttonBgColor
          ? button.buttonBgColor : this.behaviour?.behaviourDesignDto?.optionButtonBgColour;

        style["color"] = button && button.buttonFontColor
          ? button.buttonFontColor : this.behaviour?.behaviourDesignDto?.optionButtonFontColour;
      }

      const buttonLookTOrC = section.buttonLook === this._const.BUTTON_LOOK_TRANSPARENT
        || section.buttonLook === this._const.BUTTON_LOOK_CARD;
      if (buttonLookTOrC || transition === this._const.HOVER && button && button.height) {
        style["min-height"] = `${button.height}${this._const.PIXEL}`;
      }
    }
    return style;
  }

  public checkUploadItemType = (button: Buttons): boolean => {
    let isVideo = false;
    if (button && button.imageUrl) {
      const itemUrl: URL = new URL(`${this.imageBaseUrl}${button.imageUrl}`);
      const ext: string = itemUrl.pathname.split(".")[1];
      isVideo = (ext && ["mp4", "3gp", "ogg"].includes(ext)) ? true : false;
    }
    return isVideo;
  }

  public getOptionImage = (button: Buttons, section: LandingPage): any => {
    let style: any = {};
    if (button && section) {
      style = {
        "background-image": `url(${this.imageBaseUrl}${button.imageUrl})`,
        "border-radius": `${button.radius ? button.radius : 0}${this._const.PIXEL}`,
      };

      if (section.buttonLook === this._const.POPUP) {
        style["height"] = `${button.width ? button.width : 100}${this._const.PIXEL}`;
        style["width"] = `${button.width ? button.width : 100}${this._const.PIXEL}`;
      }

      if (section.buttonLook === this._const.BUTTON_LOOK_CARD) {
        style["height"] = button.width ? `calc(${button.width}${this._const.PIXEL} / 1.3)` : this._const.PX_100;
        style["width"] = button.width ? `calc(${button.width}${this._const.PIXEL} / 1.3)` : this._const.PX_100;
      }

      const isBtnTOrC = !button.displayText && !button.buttonDescription
        && (section.buttonLook === this._const.BUTTON_LOOK_CARD || section.buttonLook === this._const.BUTTON_LOOK_TRANSPARENT);

      const isBtnNotTAndC = !button.displayText && section.buttonLook !== this._const.BUTTON_LOOK_CARD
        && section.buttonLook !== this._const.BUTTON_LOOK_TRANSPARENT;

      if (isBtnTOrC || isBtnNotTAndC) {
        style["height"] = `${button.height ? button.height : 100}${this._const.PIXEL}`;
        style["width"] = `${button.height ? button.height : 100}${this._const.PIXEL}`;
      } else if (section && button && section.buttonLook === this._const.BUTTON_LOOK_EXPANDABLE) {
        style["height"] = `${button.height ? button.height : 100}${this._const.PIXEL}`;
        style["width"] = `${button.height ? button.height : 100}${this._const.PIXEL}`;
        style["min-width"] = `${button.height ? button.height : 100}${this._const.PIXEL}`;
      }
    }
    return style;
  }

  public getButtonAlignment = (section: LandingPage): any => {
    const style = {
      "text-align": this._const.CENTER
    };
    if (section) {
      if (section.buttonLayout === this._const.VERTICAL || section.buttonLayout === this._const.BUTTON_LOOK_HORIZONTAL) {
        style["text-align"] = section.buttonAlignment;
      }
      if (section.backgroundType === this._const.MM_TYPE_IMAGE
        && (section.buttonLook === this._const.BUTTON_LOOK_CIRCLE || section.sandwichBtnMobile)
      ) {
        style["background-image"] = section.backgroundSrc
          ? `url(${environment.behaviourUrl}${section.backgroundSrc})`
          : this.behaviour.behaviourDesignDto.dashBgImgVideo;
        style["width"] = this._const.PERCENT_100;
        style["background-repeat"] = this._const.BG_NO_REPEAT;
        style["background-position"] = this._const.CENTER;
        style["background-size"] = this._const.DEFAULT_BG_SIZE;
      } else if (section.backgroundType === this._const.MM_TYPE_VIDEO
        && (section.buttonLook === this._const.BUTTON_LOOK_CIRCLE || section.sandwichBtnMobile)
      ) {
        style["width"] = this._const.PERCENT_100;
        style["background-repeat"] = this._const.BG_NO_REPEAT;
        style["background-position"] = this._const.CENTER;
        style["background-size"] = this._const.DEFAULT_BG_SIZE;
      } else if (section.backgroundType === this._const.MM_TYPE_VIDEO
        && (section.buttonLook === this._const.BUTTON_LOOK_CIRCLE || section.sandwichBtnMobile)
      ) {
        style["width"] = this._const.PERCENT_100;
        style["background-repeat"] = this._const.BG_NO_REPEAT;
        style["background-position"] = this._const.CENTER;
        style["background-size"] = this._const.DEFAULT_BG_SIZE;
      } else if (section.backgroundType === this._const.NONE && window.screen.width <= this._const.SCREEN_1025
        && (section.buttonLook === this._const.BUTTON_LOOK_CIRCLE || section.sandwichBtnMobile)
      ) {
        this.hamBgType = this.behaviour.behaviourDesignDto.hamNavBgType;
        if (this.hamBgType === this._const.MM_TYPE_IMAGE && this.behaviour.behaviourDesignDto.dashBgImgVideo) {
          style["background-image"] = this.behaviour.behaviourDesignDto.dashBgImgVideo
            ? `url(${environment.behaviourUrl}${this.behaviour.behaviourDesignDto.hamNavBgUrl})`
            : this.behaviour.behaviourDesignDto.dashBgImgVideo;
          style["width"] = this._const.PERCENT_100;
          style["background-repeat"] = this._const.BG_NO_REPEAT;
          style["background-position"] = this._const.DEFAULT_BG_POSITION;
          style["background-size"] = this._const.DEFAULT_BG_SIZE;
        } else if (this.hamBgType === this._const.MM_TYPE_VIDEO && this.behaviour.behaviourDesignDto.dashBgImgVideo) {
          this.hamBgVideo = `${this.behaviour.behaviourDesignDto.dashBgImgVideo}`;
          style["width"] = this._const.DEFAULT_SCTN_WIDTH;
          style["background-repeat"] = this._const.BG_NO_REPEAT;
          style["background-position"] = this._const.DEFAULT_BG_POSITION;
          style["background-size"] = this._const.DEFAULT_BG_SIZE;
        } else if (this.hamBgType === this._const.BT_COLOR && this.behaviour.behaviourDesignDto.hamNavBgColor) {
          style["background"] = this.behaviour.behaviourDesignDto.hamNavBgColor;
        }
      } else {
        style["background-color"] = section.backgroundSrc;
        style["width"] = this._const.PERCENT_100;
      }
    }
    return style;
  }

  public getVideoHeight = (button: Buttons, section: LandingPage): any => {
    let style: any = {};
    if (button && section) {
      style = {
        "text-align": this._const.LEFT,
        "border-radius": `${button && button.radius ? button.radius : 0}${this._const.PIXEL}`,
      };

      if (section.buttonLook === (this._const.BUTTON_LOOK_CARD)) {
        style["height"] = button.width ? `calc(${button.width}${this._const.PIXEL} / 1.3)` : this._const.PX_100;
        style["width"] = button.width ? `calc(${button.width}${this._const.PIXEL} / 1.3)` : this._const.PX_100;
      }

      if (section.buttonLook === (this._const.POPUP)) {
        style["height"] = `${button.width ? button.width : 100}${this._const.PIXEL}`;
        style["width"] = `${button.width ? button.width : 100}${this._const.PIXEL}`;
      }

      if (!button.displayText) {
        style["height"] = button.height ? `${button.height}${this._const.PIXEL}` : this._const.AUTO;
        style["width"] = button.height ? `${button.height}${this._const.PIXEL}` : this._const.AUTO;
      } else if (section.buttonLook === this._const.BUTTON_LOOK_EXPANDABLE) {
        style["height"] = `${button.height ? button.height : 100}${this._const.PIXEL}`;
        style["width"] = `${button.height ? button.height : 100}${this._const.PIXEL}`;
        style["min-width"] = `${button.height ? button.height : 100}${this._const.PIXEL}`;
      }
    }
    return style;
  }

  public getSecExpandableStyle = (button: Buttons, section: LandingPage): any => {
    let style: any = {};

    if (section && button) {
      style = {
        "border-radius": button.radius ? `${button.radius}${this._const.PIXEL}` : `0${this._const.PIXEL}`,
        padding: this._const.DEFAULT_BTN_PADDING,
        "text-align": this._const.DEFAULT_BG_POSITION,
        border: button.borderWeight ? `${button.borderWeight}${this._const.PIXEL}` : `0${this._const.PIXEL}`,
        "border-color": button.borderColor ? `${button.borderColor}` : this._const.BUTTON_LOOK_TRANSPARENT,
        position: this._const.DEFAULT_BTN_POSITION,
        margin: this._const.DEFAULT_BTN_MARGIN,
        "background-color": button.buttonBgColor ? button.buttonBgColor : this.behaviour?.behaviourDesignDto?.optionButtonBgColour,
        color: button.buttonFontColor ? button.buttonFontColor : this.behaviour?.behaviourDesignDto?.optionButtonFontColour,
      };

      if (button.xAxis) {
        style["margin-left"] = `${button.xAxis}${this._const.VIEW_WIDTH}`;
      }
      if (button.yAxis) {
        style["bottom"] = `${button.yAxis}${this._const.VIEW_HEIGHT}`;
      }
      if (button.height) {
        style["height"] = button.buttons.length >= 1 ? this._const.PERCENT_100 : `${button.height}${this._const.PIXEL}`;
      }

      if (button.width) {
        if (section.sandwichBtnMobile && button.width) {
          if (window.screen.width >= this._const.SCREEN_1025 && window.screen.width <= this._const.SCREEN_1125 && button.action !== this._const.SEARCH) {
            style["width"] = `calc(${button.width}${this._const.PIXEL} - ${this._const.FONT_REDUCE_47}${this._const.PIXEL})`;
          } else if (window.screen.width >= this._const.SCREEN_1125 && window.screen.width <= this._const.SCREEN_1185 && button.action !== this._const.SEARCH) {
            style["width"] = `calc(${button.width}${this._const.PIXEL} - ${this._const.FONT_REDUCE_30}${this._const.PIXEL})`;
          } else if (window.screen.width >= this._const.SCREEN_1186 && window.screen.width <= this._const.SCREEN_1250 && button.action !== this._const.SEARCH) {
            style["width"] = `calc(${button.width}${this._const.PIXEL} - ${this._const.FONT_REDUCE_20}${this._const.PIXEL})`;
          } else if (window.screen.width >= 1250 && window.screen.width <= this._const.SCREEN_1320 && button.action !== this._const.SEARCH) {
            style["width"] = `calc(${button.width}${this._const.PIXEL} - ${this._const.FONT_REDUCE_8}${this._const.PIXEL})`;
          } else {
            style["width"] = `${button.width}${this._const.PIXEL}`;
          }
        } else {
          style["width"] = `${button.width}${this._const.PIXEL}`;
        }
      }
    }
    return style;
  }

  public getSecExpandableHeaderStyle = (button: Buttons): any => {
    const style = {
      "min-height": `${button && button.height ? button.height : 0}${this._const.PIXEL}`
    };
    return style;
  }

  public getExpandBtnOverlayStyle = (button: Buttons, section: LandingPage): any => {
    const style = {
      animation: this._const.BA_NONE,
    };
    if (this.behaviour
      && this.behaviour.behaviourDesignDto
      && this.behaviour.behaviourDesignDto.optionButtonHoverFontColour
      && this.behaviour.behaviourDesignDto.optionButtonHoverColour
      && section && section.buttonLook === this._const.BUTTON_LOOK_EXPANDABLE
    ) {
      style["background-color"] = button && button.buttonHoverColor
        ? button.buttonHoverColor
        : this.behaviour.behaviourDesignDto.optionButtonHoverColour;
      style["color"] = button && button.buttonHoverFontColor
        ? button.buttonHoverFontColor
        : this.behaviour.behaviourDesignDto.optionButtonHoverFontColour;
    }
    return style;
  }

  public getSlickSliderConfig = (section: LandingPage): boolean => {
    const buttons: any[] = section && section.buttons;
    let isMobileView = false;
    if (section.buttonLayout === this._const.BA_CHAMELEON) {
      if (buttons && buttons.length > 0) {
        const btnWidth: number[] = buttons.map(b => b.width && !isNaN(b.width) ? Number(b.width) : 0);
        const maxBtnWidth: number = Math.max(...btnWidth);
        isMobileView = maxBtnWidth > 250 ? true : false;
      }
    }
    return isMobileView;
  }

  public getSectionButtonOverlayStyle = (button: Buttons, transition: string, section: LandingPage): any => {
    const style = {
      animation: this._const.BA_NONE,
    };
    if (section && button) {
      if (this.behaviour && this.behaviour.behaviourDesignDto) {
        style["background-color"] = button.buttonBgColor
          ? button.buttonBgColor : this.behaviour?.behaviourDesignDto?.optionButtonBgColour;
        style["color"] = button.buttonFontColor
          ? button.buttonFontColor : this.behaviour?.behaviourDesignDto?.optionButtonFontColour;
      }

      const isActionPresent = button.action && button.action !== this._const.BA_NONE;
      if (transition === this._const.HOVER && section.buttonLook !== this._const.BUTTON_LOOK_TRANSPARENT
        && (isActionPresent || button.buttonDescription)
      ) {
        style["background-color"] = button.buttonHoverColor
          ? button.buttonHoverColor
          : this.behaviour?.behaviourDesignDto?.optionButtonHoverColour;
        style["color"] = button.buttonHoverFontColor
          ? button.buttonHoverFontColor
          : this.behaviour?.behaviourDesignDto?.optionButtonHoverFontColour;
        style["border-color"] = button.borderHoverColor
          ? button.borderHoverColor
          : this._const.BUTTON_LOOK_TRANSPARENT;
      } else if (section.buttonLook === this._const.BUTTON_LOOK_TRANSPARENT && transition === this._const.HOVER) {
        style["color"] = button.buttonHoverFontColor
          ? button.buttonHoverFontColor
          : this.behaviour?.behaviourDesignDto?.optionButtonHoverFontColour;
      } else if (section.buttonLook === this._const.BUTTON_LOOK_TRANSPARENT && transition === this._const.NORMAL) {
        style["color"] = button.buttonFontColor
          ? button.buttonFontColor
          : this.behaviour?.behaviourDesignDto?.dashTextElColour;
      } else {
        style["background-color"] = button.buttonBgColor
          ? button.buttonBgColor : this.behaviour?.behaviourDesignDto?.optionButtonBgColour;
        style["color"] = button.buttonFontColor
          ? button.buttonFontColor : this.behaviour?.behaviourDesignDto?.optionButtonFontColour;
      }
    }
    return style;
  }

  public expandableHover = (elementId: string, section: LandingPage, button: Buttons, transition: string): any => {
    let style: any = {};
    if (button && section) {
      style = {
        "border-radius": `${button.radius ? button.radius : 0}${this._const.PIXEL}`,
        padding: this._const.DEFAULT_BTN_PADDING,
        "text-align": this._const.CENTER,
        border: `${button.borderWeight ? button.borderWeight : 0}${this._const.PIXEL}`,
        position: this._const.DEFAULT_BTN_POSITION,
        margin: this._const.PX_0,
      };
      if (elementId && document.getElementById(elementId)
        && this.behaviour && this.behaviour.behaviourDesignDto
        && transition === this._const.HOVER
      ) {
        document.getElementById(elementId).style.backgroundColor = button.buttonHoverColor
          ? button.buttonHoverColor
          : this.behaviour.behaviourDesignDto.optionButtonHoverColour;

        document.getElementById(elementId).style.color = button.buttonHoverFontColor
          ? button.buttonHoverFontColor
          : this.behaviour.behaviourDesignDto.optionButtonHoverFontColour;

        document.getElementById(elementId).style["border-color"] = button.borderHoverColor
          ? button.borderHoverColor
          : this._const.BUTTON_LOOK_TRANSPARENT;
      }

      if (button.xAxis) {
        style["margin-left"] = `${button.xAxis}${this._const.VIEW_WIDTH}`;
      }
      if (button.yAxis) {
        style["bottom"] = `${button.yAxis}${this._const.VIEW_HEIGHT}`;
      }

      if (button.height) {
        style["height"] = button.buttons.length >= 1 ? this._const.PERCENT_100 : `${button.height}${this._const.PIXEL}`;
      }

      if (button.width) {
        if (section.sandwichBtnMobile) {
          if (window.screen.width >= 1025 && window.screen.width <= 1125 && button.action !== this._const.SEARCH) {
            style["width"] = `calc(${button.width}${this._const.PIXEL} - ${this._const.FONT_REDUCE_47}${this._const.PIXEL})`;
          } else if (window.screen.width >= 1125 && window.screen.width <= 1185 && button.action !== this._const.SEARCH) {
            style["width"] = `calc(${button.width}${this._const.PIXEL} - ${this._const.FONT_REDUCE_30}${this._const.PIXEL})`;
          } else if (window.screen.width >= 1186 && window.screen.width <= 1250 && button.action !== this._const.SEARCH) {
            style["width"] = `calc(${button.width}${this._const.PIXEL} - ${this._const.FONT_REDUCE_20}${this._const.PIXEL})`;
          } else if (window.screen.width >= 1250 && window.screen.width <= 1320 && button.action !== this._const.SEARCH) {
            style["width"] = `calc(${button.width}${this._const.PIXEL} - ${this._const.FONT_REDUCE_8}${this._const.PIXEL})`;
          } else {
            style["width"] = `${button.width}${this._const.PIXEL}`;
          }
        } else {
          style["width"] = `${button.width}${this._const.PIXEL}`;
        }
      }
    }
    return style;
  }

  public getButtonStyle = (section: LandingPage): any => {
    const style = {
      border: 0
    };
    if (section && this.behaviour && this.behaviour.behaviourDesignDto) {
      style["color"] = section.sectionElementColor
        ? section.sectionElementColor : this.behaviour.behaviourDesignDto.optionButtonFontColour;
    }
    return style;
  }

  public setExpandableStyle = (elementId: string, button: Buttons, expand: boolean, transition: string): any => {
    if (elementId && button
      && this.behaviour && this.behaviour.behaviourDesignDto
      && this.behaviour.behaviourDesignDto.optionButtonBgColour
      && this.behaviour.behaviourDesignDto.optionButtonFontColour
      && document.getElementById(elementId)
    ) {
      if (expand) {
        document.getElementById(elementId).style.backgroundColor = button.buttonBgColor
          ? button.buttonBgColor : this.behaviour.behaviourDesignDto.optionButtonBgColour;
        document.getElementById(elementId).style.color = button.buttonFontColor
          ? button.buttonFontColor : this.behaviour.behaviourDesignDto.optionButtonFontColour;
        if (button && !button.buttonDescription && button.height) {
          document.getElementById(elementId).style.height = button.buttons.length > 0
            ? this._const.PERCENT_100 : `${button.height}${this._const.PIXEL}`;
        } else {
          document.getElementById(elementId).style.height = this._const.PERCENT_100;
        }
      } else if (transition === this._const.HOVER) {
        document.getElementById(elementId).style["border-color"] = button.borderHoverColor
          ? button.borderHoverColor : this._const.BUTTON_LOOK_TRANSPARENT;
      } else {
        document.getElementById(elementId).style.backgroundColor = button.buttonBgColor
          ? button.buttonBgColor
          : this.behaviour.behaviourDesignDto.optionButtonBgColour;

        document.getElementById(elementId).style.color = button.buttonFontColor
          ? button.buttonFontColor
          : this.behaviour.behaviourDesignDto.optionButtonFontColour;

        document.getElementById(elementId).style.height = button.buttonDescription ? `${button.height}${this._const.PIXEL}` : this._const.PERCENT_100;
      }
    }
  }

  private stickySidebarToggle = () => {
    const cHeight: number = Math.round(window.innerHeight / (100 / 100));
    const elementsToFindByScroll: HTMLElement[] = Array.prototype.slice.call(document.getElementsByClassName("sec-sidebar"));
    const positionsToIdsMap: any = elementsToFindByScroll.reduce((result, item) => {
      let top: number = item.getBoundingClientRect().top;
      if (top >= cHeight) {
        top -= cHeight;
      } else if (top < cHeight) {
        top = 0;
      }
      result[top] = item.id;
      return result;
    }, {});

    document.getElementById("landing")?.addEventListener("scroll", (event) => {
      const scrollValue: number = document.getElementById("landing").scrollTop;
      let elementId: string = null;
      const keys: string[] = Object.keys(positionsToIdsMap);
      keys.forEach((k, i) => {
        if (Number(k) <= scrollValue) {
          elementId = positionsToIdsMap[k];
          document.getElementById(elementId).classList.add("sidebar-visible");
        }
        if (Number(k) > scrollValue) {
          elementId = positionsToIdsMap[k];
          document.getElementById(elementId).classList.remove("sidebar-visible");
        }
      });
    });
  }

  public stickyFooterToggle = () => {
    const cHeight: number = Math.round(window.innerHeight / (100 / 100));
    const elementsToFindByScroll: HTMLElement[] = Array.prototype.slice.call(document.getElementsByClassName("stick-sec-footer"));
    const positionsToIdsMap: any = elementsToFindByScroll.reduce((result, item) => {
      let top: number = item.getBoundingClientRect().top;
      if (top >= cHeight) {
        top -= cHeight;
      } else if (top < cHeight) {
        top = 0;
      }
      result[top] = item.id;
      return result;
    }, {});

    document.getElementById("landing")?.addEventListener("scroll", (event) => {
      const scrollValue: number = document.getElementById("landing").scrollTop;
      let elementId: string = null;
      const keys: string[] = Object.keys(positionsToIdsMap);
      keys.forEach((k, i) => {
        if (Number(k) > scrollValue) {
          elementId = positionsToIdsMap[k];
          document.getElementById(elementId).classList.remove("footer-visible");
          const secIndex: number = Number(elementId.split("parent-sec-")[1]);
          document.getElementById(elementId).style.bottom = this._const.PX_0;
        }
      });
    });
  }

  public getBgVideo = (section: LandingPage): any => {
    const style = {
      "min-height": section && section.height ? `${section.height}${this._const.VIEW_HEIGHT}` : this._const.AUTO,
      height: section && section.height ? `${section.height}${this._const.VIEW_HEIGHT}` : this._const.AUTO
    };
    return style;
  }

  public toggleShow = () => {
    this.isMenuShown = !this.isMenuShown;
  }

  public allowClose = (sectionId: string, pSecId: string, sidebarId: string) => {
    if (sectionId && document.getElementById(sectionId)) {
      document.getElementById(sectionId).classList.add("hide");
    }
    if (pSecId && document.getElementById(pSecId)) {
      document.getElementById(pSecId).classList.remove("show-sidebar");
    }
    if (sidebarId && document.getElementById(sidebarId)) {
      document.getElementById(sidebarId).classList.add("hide");
    }
  }

  public openSearch = (widget: any, sIndex: number) => {
    this.toggleSearch = !this.toggleSearch;
    const searchSec = document.getElementById(`parent-sec-${sIndex}`);
    if (searchSec) {
      if (this.toggleSearch) {
        searchSec.classList.add("search-open");
      } else {
        searchSec.classList.remove("search-open");
      }
    }
  }

  public onScreenResize = (width: number) => {
    if (width > -1) {
      this.leftBtnArray = [];
      this.rightBtnArray = [];
      this.centerBtnArray = [];
      this.sandwichBtnArray = [];
      this.landingPage.forEach(async (section: LandingPage, sIndex: number) => {
        this.isSectionIframeOpened[sIndex] = false;
        this.leftBtnArray[sIndex] ? this.leftBtnArray[sIndex] = [] : this.leftBtnArray.push([]);
        this.rightBtnArray[sIndex] ? this.rightBtnArray[sIndex] = [] : this.rightBtnArray.push([]);
        this.centerBtnArray[sIndex] ? this.centerBtnArray[sIndex] = [] : this.centerBtnArray.push([]);
        this.sandwichBtnArray[sIndex] ? this.sandwichBtnArray[sIndex] = [] : this.sandwichBtnArray.push([]);
        section.buttons.forEach((button: Buttons, bIndex: number) => {

          if (button && button.uniqueBtn && button.uniqueBtnAlignment === this._const.LEFT) {
            if (width <= 1025 && !button.excludeSandwichBtn && section && section.sandwichBtnMobile) {
              this.sandwichBtnArray[sIndex].push(button);
            } else {
              this.leftBtnArray[sIndex].push(button);
            }
          } else if (button.uniqueBtn && button.uniqueBtnAlignment === this._const.RIGHT) {
            if (width <= 1025 && !button.excludeSandwichBtn && section.sandwichBtnMobile) {
              this.sandwichBtnArray[sIndex].push(button);
            } else {
              this.rightBtnArray[sIndex].push(button);
            }
          } else if (width <= 1025 && !button.excludeSandwichBtn && section.sandwichBtnMobile) {
            this.sandwichBtnArray[sIndex].push(button);
          } else {
            this.centerBtnArray[sIndex].push(button);
          }
        });
      });
    }
  }

  public cancel = () => {
    this._originalClose.bind(this.buttonBox)({});
  }

  private getBehaviours = (desiredClientId: number, ownerType: string) => {
    this._jbService.getBehaviours(desiredClientId, ownerType).subscribe((response: BehaviourInfo) => {
      this.behaviourList = response && response.behaviourResponse && response.behaviourResponse.length > 0 ? response.behaviourResponse : [];
    });
  }

  public behaviourChanged = (ev: MatSelectChange) => {
    this.getBehaviourDetail(ev.value);
  }

  private getBehaviourDetail = async (id: number) => {
    this.triggerViewable = false;
    let param: HttpParams = new HttpParams();
    param = param.set("behaviourId", `${id}`);
    param = param.set("behaviourType", this.dialogData.ownerType);
    const data: BehaviourDetails = await this._behaviourService.getBehaviourDetail(param).toPromise();
    this.behaviour = data;
    if (this.behaviour && this.behaviour.behaviourDesignDto) {
      this.initialiseTriggerView();
      this.setPreviewStyle();
      this.setBackgroundStyle(this.behaviour.behaviourDesignDto);
      this.triggerViewable = true;
    }
  }

  private setPreviewStyle = () => {
    if (this.behaviour) {
      const bodyStyles = document.body.style;
      bodyStyles.setProperty("--title-font-size", this.behaviour?.behaviourDesignDto?.titleFontSize + this._const.PIXEL);
      bodyStyles.setProperty("--h1-font-size", this.behaviour?.behaviourDesignDto?.h1FontSize + this._const.PIXEL);
      bodyStyles.setProperty("--h2-font-size", this.behaviour?.behaviourDesignDto?.paragraphFontSize + this._const.PIXEL);
      bodyStyles.setProperty("--h3-font-size", this.behaviour?.behaviourDesignDto?.footnoteFontSize + this._const.PIXEL);
      bodyStyles.setProperty("--fontStyle", this.behaviour?.behaviourDesignDto?.fontStyle);
      bodyStyles.setProperty("--bgfill", this.behaviour.behaviourDesignDto?.optionButtonBgColour);
      bodyStyles.setProperty("--bgfillcolor", this.behaviour?.behaviourDesignDto?.optionButtonFontColour);
    }
  }

  private getAssets = (): void => {
    const assetListRequest: AssetListRequest = { ownedBy: this.clientId };

    this._assetService.listAssetsUnsafe(assetListRequest).subscribe(
      (response: any) => {
        this.assets = response.assetData;
      },
      (error: any) => {
        console.error('Error fetching assets:', error);
      }
    );
  }

  private getAssetUrlById = (assetId: number): string | null => {
    let url: string = null;
    if (assetId) {
      url = this.assets.find(a => a.id === assetId)?.assetUrl;
    }
    return url;
  }

  private setBackgroundStyle = (designDto: any): void => {

    if (designDto.dashboardBackground === "colour" && designDto.dashBgColour) {
      this.backgroundStyle = {
        "background-color": designDto.dashBgColour
      };
    } else if (designDto.dashboardBackground === "image") {
      this.backgroundStyle = {
        "background-repeat": this._const.BG_NO_REPEAT,
        "background-position": this._const.CENTER,
        "background-size": this._const.DEFAULT_BG_SIZE,
        "background-attachment": "fixed"
      };
      if (designDto.dashBgImgVideo) {
        this.backgroundStyle["background-image"] = `url(${this.behaviourUrl}${designDto.dashBgImgVideo})`;
      } else {
        this.backgroundStyle["background-image"] = `url(${this.behaviourUrl}${this.getAssetUrlById(designDto.dashBgAssetId)})`;
      }
    } else if (designDto.dashboardBackground === "video") {
      if (designDto.dashBgImgVideo) {
        this.dashboardVideoUrl = `${this.behaviourUrl}${designDto.dashBgImgVideo}`;
      } else {
        this.dashboardVideoUrl = `${this.behaviourUrl}${this.getAssetUrlById(designDto.dashBgAssetId)}`;
      }
    } else {
      this.backgroundStyle = {
        "background-color": "none"
      };
    }
  }

}
