export const responseCode = {
  sentinel: {
    LOGIN_SUCCESS_CODE: 99000006,
    LOGIN_GENERIC_FAILURE_CODE: 99000000,
    LOGIN_FAILURE_CODE: 1700001,
    LOGIN_NEW_USER_FAILURE_CODE: 1700002,
    LOGIN_CONFIRM_EMAIL_FAILURE_CODE: 99000003,
    INITIATE_FORGOT_PASSWORD_SUCCESS_CODE: 990000020,
    INITIATE_FORGOT_PASSWORD_FAILURE_CODE: 990000019,
    COMPLETE_FORGOT_PASSWORD_FAILURE_CODE: 990000021,
    RESET_PASSWORD_FAILURE_CODE: 990000011,
    RESET_PASSWORD_SUCCESS_CODE: 990000012,
    LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE: 99000005,
    LOGIN_FORCE_PWD_RESET_FAILURE_CODE: 99000004,
    LOGIN_PWD_RESET_REQUIRED_FAILURE_CODE: 99000009,
    VERIFY_CONFIRMATION_CODE_FAILURE_CODE: 990000013,
    LOGIN_PWD_RESET_CONFIRMATION_SUCCESS_CODE: 990000014,
    LOGIN_BOTH_USERNAME_PHONE_CONFIRMATION_SUCCESS_CODE: 990000016,
    LOGIN_ONLY_USERNAME_CONFIRMED_ERROR_CODE: 990000033,
    VERIFY_CONFIRMATION_NEW_CODE_REQUIRED_ERROR_CODE: 990000017,
    COMPLETE_FORCED_RESET_PASSWORD_FAILURE_CODE: 990000023,
    COMPLETE_FORCED_RESET_PASSWORD_SUCCESS_CODE: 990000024,
    SMS_MFA_REQUIRED_ERROR_CODE: 990000025,
    VERIFY_SMS_MFA_CODE_SUCCESS_CODE: 990000026,
    VERIFY_SMS_MFA_CODE_FAILURE_CODE: 990000027,
    VERIFY_SMS_MFA_CODE_SESSION_EXPIRED_CODE: 990000029,
    LOGIN_INACTIVE_USER_FAILURE_CODE: 990000047,
    RESEND_OTP_SUCCESS_CODE: 990000050,
    RESEND_OTP_FAILURE_CODE: 990000051,
  },
  cms: {
    LOGIN_SUCCESS_CODE: 1700042,
    LOGIN_GENERIC_FAILURE_CODE: 1700043,
    LOGIN_CONFIRM_EMAIL_FAILURE_CODE: 1700044,
    LOGIN_FORCE_PWD_RESET_FAILURE_CODE: 1700045,
    LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE: 1700046,
    LOGIN_USERNAME_CONFIRMATION_FAILURE_CODE: 1700047,
    LOGIN_USERNAME_CONFIRMATION_SUCCESS_CODE: 1700048,
    INITIATE_FORGOT_PASSWORD_FAILURE_CODE: 1700049,
    INITIATE_FORGOT_PASSWORD_SUCCESS_CODE: 1700050,
    COMPLETE_FORGOT_PASSWORD_FAILURE_CODE: 1700051,
    COMPLETE_FORGOT_PASSWORD_SUCCESS_CODE: 1700052,
    RESET_PASSWORD_FAILURE_CODE: 1700053,
    RESET_PASSWORD_SUCCESS_CODE: 1700054,
    COMPLETE_FORCED_RESET_PASSWORD_FAILURE_CODE: 1700081,
    COMPLETE_FORCED_RESET_PASSWORD_SUCCESS_CODE: 1700080,
    SMS_MFA_REQUIRED_ERROR_CODE: 1700054,
    VERIFY_SMS_MFA_CODE_SUCCESS_CODE: 1700058,
    VERIFY_SMS_MFA_CODE_FAILURE_CODE: 1700057,
    VERIFY_SMS_MFA_CODE_SESSION_EXPIRED_CODE: 1700059,
    LOGIN_INACTIVE_USER_FAILURE_CODE: 1700077,
    RESEND_OTP_SUCCESS_CODE: 1700120,
    RESEND_OTP_FAILURE_CODE: 1700121
  }
};
