import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { StorageService } from "@app/core/services/storage/storage.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private _storage: StorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if ([401, 403].indexOf(err.status) !== -1) {
          console.log("Error response received");
        }
        return throwError(err);
      })
    );
  }
}
