import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "@environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { Client } from "@app/core/models/client.model";
import { StorageService } from "@app/core/services/storage/storage.service";
import { AuthToken } from "@app/core/models/auth-token";
import { JwtInfo } from "@app/core/models/jwt-info";
import { User } from "@app/core/misctypes/user";
import { ResetPwdInfo } from "@app-auth/pages/reset-password/reset-pwd-info";
import { LoginConfigResp } from "@app/core/misctypes/login-config-resp";
import { LoginCfg } from "@app/core/misctypes/login-cfg";
import { IdpInfo } from "@app/core/misctypes/idp-info";
import { Constants } from "@app/core/utils/const";
import { Role } from "@app/core/misctypes/role";
import { OAuthToken } from "@app/authentication/pages/types/o-auth-token";
import { WebSocketSubject, webSocket } from "rxjs/webSocket";
import { CoreUtils } from "@app/core/utils/core-utils";
import { MsgType } from "@app/core/msg/msg-type";
import { LeaseMessage } from "@app/core/msg/lease-message";
import { LmHbMessage } from "@app/core/msg/lm-hb-message";
import Swal, { SweetAlertResult } from "sweetalert2";

@Injectable({ providedIn: "root" })
export class AuthenticationService {

  public currentUser = new User();
  public client = new Client();
  public tokens: AuthToken;
  public sessionTimeout = new Subject();
  public timeoutObservable = this.sessionTimeout.asObservable();
  public apiUrl: string;
  public host: string;
  public rtmcSessionStarted = false;
  public userLoginPassword = new BehaviorSubject("");
  public tokenLeaseWs: WebSocketSubject<any>;
  private readonly _coreUtils: CoreUtils;
  private _msgTypes: MsgType;

  private _cns: Constants = new Constants();
  private _localHostPattern = /^(http)[s]?(:\/\/)[A-Za-z0-9]{0,10}(.)?(localhost)[\-A-Za-z0-9]{0,10}(:)[0-9]{4,6}$/;
  private _localHostRegEx: RegExp = new RegExp(this._localHostPattern);

  constructor(
    private readonly http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private _storage: StorageService
  ) {
    this.host = window.location.origin;
    const isLocalHost: boolean = this._localHostRegEx.test(this.host);
    this.host = isLocalHost ? this.host + "/login" : this.host + "/";
    this._coreUtils = new CoreUtils();
    this._msgTypes = new MsgType();
  }

  public registerGateway = (gwy: string) => {
    if (gwy) {
      this.client.api_gateway_url = `${gwy}/api/v1`;
    }
  }

  public modifyString = (str: string): string => {
    let modifiedStr = "";
    if (str) {
      modifiedStr = str
        .replace(/'/g, "")
        .replace(/‘/g, "")
        .replace(/’/g, "")
        .replace(/“/g, "")
        .replace(/”/g, "");
    }
    return modifiedStr;
  }

  logout = () => {
    const url = `${environment.apiUrl}${this._cns.LOGOUT_URL}?hostUrl=${this.host}`;
    return this.http.get<any>(url, { observe: "response" });
  }

  public resetPassword = (rpInfo: ResetPwdInfo, token: string) => {
    const url = `${environment.apiUrl}${this._cns.RESET_PASSWORD_URL}`;
    return this.http.post<any>(url,
      {
        userName: rpInfo.getUserName(),
        password: rpInfo.getPassword(),
        newPassword: rpInfo.getNewPassword()
      }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token,
        "Content-Type": "application/json"
      }),
      observe: "response",
    });
  }

  public completeForcedResetPassword = (
    un: string, pwd: string, newPwd: string, session: string, token: string
  ): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.COMPLETE_FORCED_RESET_PASS_URL}`;
    return this.http.post<any>(url, { userName: un, password: pwd, newPassword: newPwd, sessionId: session }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token,
        "Content-Type": "application/json"
      }),
      observe: "response",
    });
  }

  public sentinelResetPassword = (rpInfo: ResetPwdInfo, token: string) => {
    const url = `${environment.apiUrl}${this._cns.SENTINEL_RESET_PASSWORD_URL}`;
    return this.http.post<any>(url,
      {
        userName: rpInfo.getUserName(),
        password: rpInfo.getPassword(),
        newPassword: rpInfo.getNewPassword()
      }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token,
        "Content-Type": "application/json"
      }),
      observe: "response",
    });
  }

  public newSentinelResetPassword = (rpInfo: ResetPwdInfo, sessionId: string) => {
    const url = `${environment.apiUrl}${this._cns.SENTINEL_LOGIN_NEW_URL}`;
    return this.http.post<any>(url,
      {
        userName: rpInfo.getUserName(),
        userPass: rpInfo.getPassword(),
        newPass: rpInfo.getNewPassword(),
        sessionId: `${sessionId}`
      }, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      }),
      observe: "response",
    });
  }

  initiateForgotPassword = (un: string, token: string) => {
    const url = `${environment.apiUrl}${this._cns.INIT_FORGOT_PASS_URL}`;
    return this.http.post<any>(url, { userName: un }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token,
        "Content-Type": "application/json"
      }),
      observe: "response",
    });
  }

  completeForgotPassword = (un: string, pwd: string, code: string, token: string): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.COMPLETE_FORGOT_PASS_URL}`;
    return this.http.post<any>(url, { userName: un, confirmationCode: code, password: pwd }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token
      }),
      observe: "response",
    });
  }

  public negotiateSentinelLoginStrategy = async (): Promise<LoginConfigResp> => {
    let resp: LoginConfigResp = new LoginConfigResp(false, 1700041);
    const renderLoginPromise: Promise<LoginConfigResp> = new Promise((resolve) => {
      const cfgResp = new LoginConfigResp(false, 1700041);
      this.getSentinelLoginConfig().subscribe((response) => {
        if (response.status === 200) {
          if (!!response.body) {
            cfgResp.id = response.body.platformId;
            cfgResp.clientId = response.body.clientId;
            cfgResp.exeStatus = response.body.exeStatus;
            cfgResp.exeCode = response.body.code;

            const loginCfg = new LoginCfg();
            cfgResp.loginCfg = loginCfg;

            const idpInfo = new IdpInfo();
            cfgResp.idpInfo = idpInfo;

            if (!!response.body.loginCfg) {
              loginCfg.idpType = response.body.loginCfg.idpType;
              loginCfg.ssoEnabled = response.body.loginCfg.ssoEnabled;
              loginCfg.clientId = response.body.loginCfg.clientId;
              loginCfg.anonAccessToken = response.body.loginCfg.anonAccessToken;
              loginCfg.gatewayUrl = response.body.loginCfg.gatewayUrl;
            }
          }
        }
        resolve(cfgResp);
      },
        (error) => {
          console.log(error);
          resolve(cfgResp);
        }
      );
    });

    try {
      resp = await renderLoginPromise;
    } catch (e) {
      console.log(`Error in resolving render-login promise. Error is ${e}`);
    }

    return Promise.resolve(resp);
  }

  public negotiateLoginStrategy = async (): Promise<LoginConfigResp> => {
    let resp: LoginConfigResp = new LoginConfigResp(false, 1700041);
    const renderLoginPromise: Promise<LoginConfigResp> = new Promise((resolve) => {
      const cfgResp = new LoginConfigResp(false, 1700041);
      this.getLoginConfig().subscribe((response) => {
        if (response.status === 200) {
          if (!!response.body) {
            cfgResp.id = response.body.id;
            cfgResp.clientId = response.body.clientId;
            cfgResp.exeStatus = response.body.exeStatus;
            cfgResp.exeCode = response.body.exeCode;

            const loginCfg = new LoginCfg();
            cfgResp.loginCfg = loginCfg;

            const idpInfo = new IdpInfo();
            cfgResp.idpInfo = idpInfo;

            if (!!response.body.loginCfg) {
              loginCfg.idpType = response.body.loginCfg.idpType;
              loginCfg.ssoEnabled = response.body.loginCfg.ssoEnabled;
              loginCfg.clientId = response.body.loginCfg.clientId;
              loginCfg.anonAccessToken = response.body.loginCfg.anonAccessToken;
              loginCfg.gatewayUrl = response.body.loginCfg.gatewayUrl;
              if (loginCfg.ssoEnabled && response.body.idpProperties) {
                idpInfo.loginPageUrl = response.body.idpProperties.loginPageUrl;
                idpInfo.logoutPageUrl = response.body.idpProperties.logoutPageUrl;
                idpInfo.callbackUrl = response.body.idpProperties.callbackUrl;
              }
            }
          }
        }
        resolve(cfgResp);
      },
        (error) => {
          console.log(error);
          resolve(cfgResp);
        }
      );
    });

    try {
      resp = await renderLoginPromise;
    } catch (e) {
      console.log(`Error in resolving render-login promise. Error is ${e}`);
    }

    return Promise.resolve(resp);
  }

  private getSentinelLoginConfig = (): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.SENTINEL_LOGIN_CONFIG_URL}`;
    return this.http.get<any>(url, { observe: "response" });
  }

  private getLoginConfig = (): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.CMS_LOGIN_CONFIG_URL}?hostUrl=${this.host}`;
    return this.http.get<any>(url, { observe: "response" });
  }

  public parseJwt = (token: string): JwtInfo => {
    let jsonPayload = null;
    let jwtInfo: JwtInfo;
    try {
      if (token) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        if (base64) {
          jsonPayload = decodeURIComponent(
            atob(base64)
              .split("")
              .map((c) => {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join("")
          );
          // assign values
          const jwtObj: any = JSON.parse(jsonPayload);
          if (jwtObj) {
            jwtInfo = new JwtInfo();
            jwtInfo = {
              ...jwtInfo,
              ...{
                exp: jwtObj.exp ? jwtObj.exp : undefined,
                sub: jwtObj.sub ? jwtObj.sub : "",
                tokenUse: jwtObj.token_use ? jwtObj.token_use : "",
                scope: jwtObj.scope ? jwtObj.scope : "",
                authTime: jwtObj.auth_time ? jwtObj.auth_time : undefined,
                iss: jwtObj.iss ? jwtObj.iss : undefined,
                iat: jwtObj.iat ? jwtObj.iat : undefined,
                jti: jwtObj.jti ? jwtObj.jti : undefined,
                clientId: jwtObj.client_id ? jwtObj.client_id : undefined,
                userName: jwtObj.user_name ? jwtObj.user_name : undefined
              }
            }
          }
        }
      }
    } catch (e) {
      jwtInfo = undefined;
      console.log(`Error in parsing token string into JWT token. Error is ${e}`);
    }
    return jwtInfo;
  }

  public storeAuthTokens = () => {
    const fragment = this.route.snapshot.fragment;
    if (fragment) {
      const fragmentTokens = fragment.split("&");
      const accessToken = fragmentTokens[0].replace("access_token=", "");
      const idToken = fragmentTokens[1].replace("id_token=", "");
      this.tokens = new AuthToken(accessToken, idToken);
      this._storage.storeJWTTokens(JSON.stringify(this.tokens))
    }
    return this.tokens;
  }

  public loadInitialCMSLoginInfo = async () => {
    const clientPromise: Promise<boolean> = new Promise<boolean>((resolve) => {
      this.getBackendUrl().subscribe(
        (urlResp) => {
          if (urlResp.status === 200) {
            this.client.setClientProperties(urlResp.body, false);
            resolve(true);
          } else {
            resolve(false);
          }
        },
        (error) => {
          console.log(error);
          resolve(false);
        }
      );
    });
    const clientLoaded: boolean = await clientPromise;
    console.log(`Has client data loaded ? ${clientLoaded}`);

    if (clientLoaded) {

      const userPromise: Promise<boolean> = new Promise<boolean>((resolve) => {
        this.monitorAccessToken();
        this.getUserDetail().subscribe(
          (userDetResp) => {
            if (userDetResp.status === 200) {
              this.currentUser.setUser(userDetResp.body);
              this.sessionTimeout.next(false);
              resolve(true);
            } else {
              resolve(false);
            }
          },
          async (error) => {
            console.log("Error in loading user specific data.");
            if (error && error.status === 403) {
              await this.goToLoginPageWithNewTkn();
            }
            resolve(false);
          }
        );
      });

      let logoutUrlStored = false;
      if (this.client.idp_properties && !!this.client.idp_properties.logoutPageUrl) {
        // validate this to be a URL
        this._storage.storeLogOutUrl(this.client.idp_properties.logoutPageUrl);
        logoutUrlStored = true;
      }

      const userLoaded: boolean = await userPromise;
      console.log((`Has user data loaded ? ${userLoaded}. Has logout-url stored ${logoutUrlStored}`));
    }
  }

  public loadInitialSentinelLoginInfo = async (body) => {
    if (body.user) {
      this.sessionTimeout.next(false);
      this.setSentinelDetails({
        ...{ platformId: body.platformId },
        ...{ apiBaseUrl: body.apiBaseUrl },
        ...body.user
      });
    } else {
      const tokenData: AuthToken = JSON.parse(this._storage.storedJWTTokens());
      if (tokenData.idToken && tokenData.accessToken) {
        const sentinelPromise = new Promise<boolean>((resolve) => {
          this.monitorSentinelAccessToken();
          this.getSentinelDetail().subscribe((response) => {
            if (response.status === 200) {
              this.setSentinelDetails({
                ...{ platformId: response.body.platformId },
                ...{ apiBaseUrl: response.body.apiBaseUrl },
                ...response.body.user
              });
              resolve(true);
            }
          }, async (error) => {
            console.log(error);
            if (error && error.status === 403) {
              await this.sentinelLoginWithNewTkn();
            }
            resolve(false);
          });
        });
        const done: boolean = await sentinelPromise;
      }
    }
  }

  public setSentinelDetails = (data) => {
    this.client.id = data.platformId;
    this.client.api_gateway_url = data.apiBaseUrl;
    this.client.nlp_engines_limit = 30;
    this.client.behavior_limit = 30;
    this.currentUser.cognitoUserId = data && data.cognitoUserId ? data.cognitoUserId : "NA";
    this.currentUser.departments = data.departments;
    this.currentUser.firstName = data.firstName;
    this.currentUser.lastName = data.lastName;
    this.currentUser.alias = data.alias;
    this.currentUser.displayImage = "/assets/img/Profile-Default.png";
    this.currentUser.userName = data.userName;
    this.currentUser.status = data.userStatus;
    this.currentUser.enabled = data.enabled;
    this.currentUser.email = data.email;
    this.currentUser.phone = data.phone;
    this.currentUser.roles = this.setSentinelRoles(data.roles);
    this.currentUser.threshold = data.threshold;
    this.currentUser.tierLevel = data.tierLevel;
    this.currentUser.userCreateDate = data.userCreateDate;
    this.currentUser.userLastModifiedDate = data.userLastModifiedDate;
    this.currentUser.clientSubmoduleDtos = !(data) ? [] : data.clientSubmoduleDtos;
    this.sessionTimeout.next(false);
  }

  public sessionInactive = () => {
    let timeOut = true;
    this.tokens = JSON.parse(this._storage.storedJWTTokens());
    if (this.tokens && this.tokens.accessToken) {
      const accessInfo: JwtInfo = this.parseJwt(this.tokens.accessToken);
      const idToken: JwtInfo = this.parseJwt(this.tokens.idToken);
      if (accessInfo && accessInfo.exp < new Date().getTime() / 1000) {
        timeOut = true;
        this.currentUser.resetUser();
        this.client.resetClientProperties();
      } else {
        timeOut = false;
      }
    }
    return timeOut;
  }

  public getBackendUrl = (): Observable<any> => {
    const url1 = `${environment.apiUrl}${this._cns.HOST_URL}?hostUrl=${this.host}`;
    return this.http.get<any>(url1, { observe: "response" });
  }

  public getClientApiGatewayUrl = (token: string): Observable<any> => {
    const getApiEndpointUrl = `${environment.apiUrl}${this._cns.API_GATEWAY_URL}?hostUrl=${this.host}`;
    const headerDict = {
      "X-Anon-Authorization-Access": token,
      "Content-Type": "application/json"
    }

    return this.http.get<any>(getApiEndpointUrl, {
      headers: new HttpHeaders(headerDict),
      observe: "response",
    });
  }

  loginSentinel = (un: string, pwd: string): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.SENTINEL_LOGIN_URL}`;
    return this.http.post<any>(url, { userName: un, userPass: pwd }, {
      headers: new HttpHeaders({}),
      observe: "response",
    });
  }

  public loginStandaloneSentinel = (un: string, pwd: string, token: string): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.SENTINEL_AUTHENTICATE_USER_URL}`;
    return this.http.post<any>(url, { userName: un, password: pwd }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token
      }),
      observe: "response",
    });
  }

  public loginStandaloneCMS = (un: string, pwd: string, token: string): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.AUTHENTICATE_USER_URL}`;
    return this.http.post<any>(url, { userName: un, password: pwd }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token
      }),
      observe: "response",
    });
  }

  public verifyOTPCode = (un: string, mt: number, mc: string, sId: string, token: string): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.VERIFY_OTP}`;
    return this.http.post<any>(url, { userName: un, mfaType: mt, mfaCode: mc, sessionId: sId }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token
      }),
      observe: "response",
    });
  }

  public confirmUsername = (un: string, code: string, token: string): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.CONFIRM_USER_URL}`;
    return this.http.post<any>(url, { userName: un, confirmationCode: code }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token
      }),
      observe: "response",
    });
  }

  public initiateSentinelForgotPassword = (un: string, token: string) => {
    const url = `${environment.apiUrl}${this._cns.SENTINEL_INIT_FORGOT_PASS_URL}`;
    return this.http.post<any>(url, { userName: un }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token,
        "Content-Type": "application/json"
      }),
      observe: "response",
    });
  }

  public completeSentinelForgotPassword = (un: string, pwd: string, code: string, token: string): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.SENTINEL_COMPLETE_FORGOT_PASS_URL}`;
    return this.http.post<any>(url, { userName: un, confirmationCode: code, newPassword: pwd }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token,
        "Content-Type": "application/json"
      }),
      observe: "response",
    });
  }

  public completeSentinelForcedResetPassword = (
    un: string, pwd: string, newPwd: string, session: string, token: string
  ): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.SENTINEL_COMPLETE_FORCED_RESET_PASS_URL}`;
    return this.http.post<any>(url, { userName: un, password: pwd, newPassword: newPwd, sessionId: session }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token,
        "Content-Type": "application/json"
      }),
      observe: "response",
    });
  }

  public verifyConfirmationCode = (un: string, code: string, token: string, ct: number): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.SENTINEL_CONFIRM_USER_URL}`;
    return this.http.post<any>(url, { userName: un, confirmationCode: code, confirmationType: ct }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token
      }),
      observe: "response",
    });
  }

  public verifyMFACode = (un: string, sId: string, code: string, token: string, mt: number): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.SENTINEL_VERIFY_MFA_CODE_URL}`;
    return this.http.post<any>(url, { userName: un, sessionId: sId, mfaCode: code, mfaType: mt }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token
      }),
      observe: "response",
    });
  }

  public getUserAlias = (): string => {
    const alias = (this.currentUser && this.currentUser.alias ? this.currentUser.alias : "").trim();
    return alias;
  }

  public getUserFullName = (): string => {
    let fullName = "";
    const fName: string = this.currentUser && this.currentUser.firstName ? this.currentUser.firstName : "";
    const lName: string = this.currentUser && this.currentUser.lastName ? this.currentUser.lastName : "";
    const uname: string = this.currentUser && this.currentUser.userName ? this.currentUser.userName : "";
    fullName = `${(fName + lName) ? (fName + " " + lName) : uname}`.trim();
    return fullName;
  }

  public getUserFirstName = (): string => {
    const fName: string = this.currentUser && this.currentUser.firstName ? this.currentUser.firstName : "";
    return fName;
  }

  public getStoredAuthToken = (): AuthToken => {
    let autToken: AuthToken;
    try {
      const tokenString = this._storage.storedJWTTokens();
      if (!!tokenString) {
        autToken = JSON.parse(tokenString);
      }
    } catch (e) {
      console.log(`Unable to find any stored-tokens. Error is ${e}`);
    }
    return autToken;
  }

  public resendOTPCMS = (un: string, pwd: string, token: string): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.RESEND_OTP}`;
    return this.http.post<any>(url, { userName: un, password: pwd }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token
      }),
      observe: "response",
    });
  }

  public resendOTPSentinel = (un: string, pwd: string, token: string): Observable<any> => {
    const url = `${environment.apiUrl}${this._cns.SENTINEL_RESEND_OTP}`;
    return this.http.post<any>(url, { userName: un, password: pwd }, {
      headers: new HttpHeaders({
        "X-Anon-Authorization-Access": token
      }),
      observe: "response",
    });
  }

  public monitorAccessToken = async () => {

    const hbRefs: number[] =  [];

    try {
      const tokenLeaseWsObserver: Observable<any> = this.initTokenLeaseWs(hbRefs);
      tokenLeaseWsObserver.subscribe(
        (res) => {
          this.onWsMessage(res);
        },
        (err: any) => {
          console.log(`Error occurred in lease-monitor websocket. Error is ${err}`);
        },
        async () => {
          if (this.tokenLeaseWs.closed && this.tokenLeaseWs.isStopped) {
            if (hbRefs && hbRefs.length > 0) {
              hbRefs.forEach(hbRef => clearInterval(hbRef));
            }
          }
        });

      while (true) {
        await this._coreUtils.sleep(9000);
        const keepChecking: boolean = await this.checkStoredTokenValidity();
        if (!keepChecking) {
          break;
        }
      }
    } catch (e) {
      console.log(`error initiating lease-monitor ws. Error is ${e}`);
    }
  }

  public monitorSentinelAccessToken = async () => {

    const hbRefs: number[] =  [];
    try {
      const tokenLeaseWsObserver: Observable<any> = this.initSentinelTokenLeaseWs(hbRefs);
      tokenLeaseWsObserver.subscribe(
        (res) => {
          this.onWsMessage(res);
        },
        (err: any) => {
          console.log(`Error occurred in lease-monitor websocket. Error is ${err}`);
        },
        async () => {
          if (this.tokenLeaseWs.closed && this.tokenLeaseWs.isStopped) {
            if (hbRefs && hbRefs.length > 0) {
              hbRefs.forEach(hbRef => clearInterval(hbRef));
            }
          }
        });

      while (true) {
        await this._coreUtils.sleep(9000);
        const keepChecking: boolean = await this.checkStoredTokenValidity();
        if (!keepChecking) {
          break;
        }
      }

    } catch(e) {
      console.log(`error initiating lease-monitor ws. Error is ${e}`);
    }
  }

  // ==================================================================================
  // ==================================================================================
  // ================Private functions from this point onwards=========================
  // ==================================================================================
  // ==================================================================================

  private getUserDetail = (): Observable<any> => {
    const jwtToken: AuthToken = JSON.parse(this._storage.storedJWTTokens());
    const headerDict = {
      accessToken: jwtToken.accessToken,
      "X-Authorization": jwtToken.idToken,
      "Content-Type": "application/json"
    }
    this._storage.storeClientId(this.client.id);
    const url = `${environment.apiUrl}${this._cns.VERIFY_ACCESS_TOKEN_URL}?clientId=${this.client.id}`;
    return this.http.post<any>(url, null, {
      headers: new HttpHeaders(headerDict),
      observe: "response",
    });
  }

  private getSentinelDetail = (): Observable<any> => {
    const jwtToken: AuthToken = JSON.parse(this._storage.storedJWTTokens());
    const headers = {
      "X-Authorization": jwtToken.idToken,
      "X-Authorization-Access": jwtToken.accessToken,
      "Content-Type": "application/json"
    }
    const url = `${environment.apiUrl}${this._cns.SENTINEL_VERIFY_ACCESS_TOKEN_URL}`;
    return this.http.post<any>(url, {}, {
      headers: new HttpHeaders(headers),
      observe: "response",
    });
  }

  private initTokenLeaseWs = (hbRefs: number[]) => {
    const jwtToken: AuthToken = this.getStoredAuthToken();
    const idToken: string = !!jwtToken ? jwtToken.idToken : "";
    const accessToken: string = !!jwtToken ? jwtToken.accessToken : "";

    const socketUrlTokens: string [] = [
      `${environment.webSocketClientUrl}/leaseMonitor?id=${idToken}`, `acc=${accessToken}`
    ];
    const webSocketUrlForLeaseMonitor = `${socketUrlTokens.join("&")}`;
    if (this.tokenLeaseWs === undefined || this.tokenLeaseWs.closed) {
      this.tokenLeaseWs = webSocket({url: webSocketUrlForLeaseMonitor});
      const hbRef: any = setInterval(
        () => this.tokenLeaseWs.next(this._coreUtils.generateTknLeaseHBMsg()),
        30000
      );
      hbRefs.push(hbRef);
    }
    return this.tokenLeaseWs.asObservable();
  }

  private initSentinelTokenLeaseWs = (hbRefs: number[]) => {
    const jwtToken: AuthToken = this.getStoredAuthToken();
    const idToken: string = !!jwtToken ? jwtToken.idToken : "";
    const accessToken: string = !!jwtToken ? jwtToken.accessToken : "";

    const socketUrlTokens: string [] = [
      `${environment.webSocketSentinelUrl}/leaseMonitor?id=${idToken}`, `acc=${accessToken}`
    ];
    const webSocketUrlForLeaseMonitor = `${socketUrlTokens.join("&")}`;
    if (this.tokenLeaseWs === undefined || this.tokenLeaseWs.closed) {
      this.tokenLeaseWs = webSocket({url: webSocketUrlForLeaseMonitor});
      const hbRef: any = setInterval(
        () => this.tokenLeaseWs.next(this._coreUtils.generateTknLeaseHBMsg()),
        30000
      );
      hbRefs.push(hbRef);
    }
    return this.tokenLeaseWs.asObservable();
  }

  private checkStoredTokenValidity = async (): Promise<boolean> => {
    let valid = false;
    try {
      const storedTokenJ = this._storage.storedJWTTokens();
      if (!storedTokenJ) {
        throw new Error(`Unable to find a stored token`);
      }

      try {
        const storedTokenObj: AuthToken = JSON.parse(storedTokenJ);
        if (!storedTokenObj || !storedTokenObj.idToken || !storedTokenObj.accessToken) {
          throw new Error("Invalid stored auth-token content");
        }

        const accessInfo: JwtInfo = this.parseJwt(storedTokenObj.accessToken);
        if (!accessInfo) {
          throw new Error("Invalid stored access token");
        }

        let aboutToExpire = accessInfo.exp < ((new Date().getTime() + this._cns.FIVE_MINS_IN_MILLIS)/1000);
        if (aboutToExpire) {
          const showChoiceToExtend = this._coreUtils.popUpWithYesNoBtn(
            this._cns.MSG_SESSION_ABOUT_TO_EXPIRE_ALERT, "Yes", "No"
          );
          const result: SweetAlertResult = await showChoiceToExtend;
          const shouldExtend: boolean = result && result.value;
          Swal.close();
          if (shouldExtend) {
            this.requestTokenExtension();
          } else {
            this.disconnectLeaseMonitor();
          }
        } else {
          // Existing token is still valid
          valid = true;
        }
      } catch (pErr) {
        console.log(`Error parsing stored token. Error is ${pErr}`);
      }
    } catch (e) {
      console.log(`Error when checking token validity. Error is ${e}`);
    }

    return Promise.resolve(valid);
  }

  private requestTokenExtension = () => {
    this.tokenLeaseWs.next(this._coreUtils.extendTokenLeaseMsg());
  }

  private onWsMessage = async (msgObj: any) => {
    try {
      if (!msgObj) {
        throw new Error("Inbound lease message does not exist");
      }

      const isHB: boolean = !isNaN(msgObj.messageType) && msgObj.messageType === this._msgTypes.MT_HEARTBEAT;
      const msg: LeaseMessage | LmHbMessage = isHB
        ? this._coreUtils.prepareInboundLMHeartbeatMsg(msgObj) : this._coreUtils.prepareInboundLeaseMessage(msgObj);

      if (!msg) {
        throw new Error("Inbound message object is invalid");
      }

      switch (msg.messageType) {
        case this._msgTypes.MT_EXTEND_TOKEN_LEASE:

          console.log("Extended token lease message received");
          const qualifiedLMMsg = msg as LeaseMessage;
          if (!qualifiedLMMsg.isLeaseExtensionHealthy()) {
            throw new Error("Invalid lease extension message received");
          }

          const jwtToken: AuthToken = new AuthToken(msg.accessToken, msg.idToken);
          this._storage.storeJWTTokens(JSON.stringify(jwtToken));
          // Disconnect the existing ws-socket and start monitor again
          this.disconnectLeaseMonitor();
          // ensure websocket is closed before making a new one
          await this._coreUtils.sleep(1000);
          // re-create a new web-socket for lease monitoring as we should get
          // new attributes to be populated at the server end
          if (qualifiedLMMsg.getCreatedBy() === this._cns.SYSTEM) {
            this.monitorAccessToken();
          } else if (qualifiedLMMsg.getCreatedBy() === this._cns.SENTINEL) {
            this.monitorSentinelAccessToken()
          }

          break;
        case this._msgTypes.MT_HEARTBEAT:
          break;
        case this._msgTypes.MT_END_TOKEN_LEASE:
          this.disconnectLeaseMonitor();
          console.log("End token lease message received");
          break;
        case this._msgTypes.CONNECTION_TIMED_OUT:
          throw new Error("Lease monitor websocket has timed out");
          break;
        case this._msgTypes.WEB_SOCKET_UNKNOWN_ERROR:
          throw new Error("Lease monitor websocket has encountered an unknown error");
          break;
        case this._msgTypes.WEB_SOCKET_INVALID_ATTRIBUTES:
          throw new Error("Lease monitor websocket does not have valid attributes");
          break;
        default:
          throw new Error("Lease monitor websocket delivered an unknown message type");
      }
    } catch (e) {
      this.disconnectLeaseMonitor();
      console.log(`Error receiving a lease message from the lease monitor ws. Error is ${e}`);
    }
  }

  private disconnectLeaseMonitor = () => {
    if (this.tokenLeaseWs && !this.tokenLeaseWs.isStopped) {
      console.log("Disconnecting leaseMonitor-ws");
      this.tokenLeaseWs.complete();
      this.tokenLeaseWs.unsubscribe();
    }
  }

  private setSentinelRoles = (roles: string[]): Role[] => {
    const newRoles: Role[] = [];
    if (roles && roles.length > 0) {
      roles.forEach((role) => {
        newRoles.push({
          id: null,
          uuid: null,
          roleName: role,
          roleDesc: role,
          mask: null
        });
      });
    }
    return newRoles;
  }

  private goToLoginPageWithNewTkn = async () => {
    const cfgResp: LoginConfigResp = await this.negotiateLoginStrategy();
    const cfgOk: LoginCfg = cfgResp && cfgResp.exeStatus && cfgResp.loginCfg;
    if (cfgOk) {
      this.router.navigate(["login"], { queryParams: { accessToken: cfgResp.loginCfg.anonAccessToken } });
    }
  }

  private sentinelLoginWithNewTkn = async () => {
    const cfgResp: LoginConfigResp = await this.negotiateSentinelLoginStrategy();
    const cfgOk: LoginCfg = cfgResp && cfgResp.exeStatus && cfgResp.loginCfg;
    if (cfgOk) {
      this.router.navigate(["login"], { queryParams: { accessToken: cfgResp.loginCfg.anonAccessToken } });
    }
  }
}
