import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MatSelectChange } from "@angular/material/select";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-multi-select-search",
  templateUrl: "./multi-select-search.component.html",
  styleUrls: ["./multi-select-search.component.scss"]
})
export class MultiSelectSearchComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() options: any[] = [];
  @Input() selectedValue: string[] = [];
  @Input() displayName: string;
  @Input() displayValue: string;
  @Input() isRequired = false;
  @Input() isNoneRequired = false;
  @Input() isSelectAllRequired = false;
  @Input() isSelectTriggerRequired = false;
  @Input() selectTriggerValue: string[] = [];
  @Input() isReadOnlyDropdown = false;
  @Output() optionSelected = new EventEmitter();

  @ViewChild("allSelected") private allSelected: MatOption;

  public searchText: string;
  public searchCtrl: FormControl = new FormControl();
  public multiSelectCtrl: FormControl = new FormControl();
  public filteredOptions: any[] = [];
  protected _onDestroy = new Subject<void>();

  constructor() { }

  ngOnInit(): void {
    if (this.options && this.options.length > 0) {
      const selectedOptions: string[] = [];
      if (this.selectedValue && this.selectedValue.length > 0) {
        this.selectedValue.forEach((value: string) => {
          const opt: any = this.options.filter(option => option[this.displayValue] === value);
          if (opt && opt.length > 0) {
            selectedOptions.push(opt[0][this.displayValue]);
          }
        });
      }
      this.multiSelectCtrl.setValue(selectedOptions);
    }
    this.filteredOptions = this.options.slice();
    this.searchCtrl.valueChanges.pipe(debounceTime(500)).pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterOptionList();
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterOptionList() {
    if (!this.options) {
      return;
    }
    this.searchText = this.searchCtrl?.value?.trim()?.toLowerCase() ? this.searchCtrl.value.trim().toLowerCase() : "";
    this.getFilteredOptions();
  }

  private getFilteredOptions = () => {
    if (!this.searchText) {
      this.filteredOptions = this.options.slice();
      return;
    }
    this.filteredOptions = this.options.filter(opt => opt[this.displayName].toLowerCase().indexOf(this.searchText) > -1);
  }

  public toggleAllSelection = () => {
    let selectedOptions: any[] = [];
    if (this.allSelected) {
      if (this.allSelected.selected) {
        this.multiSelectCtrl.setValue([...this.options.map(item => item[this.displayValue]), "selectAll"]);
        selectedOptions = this.options.map(item => item[this.displayValue]);
      } else {
        this.multiSelectCtrl.setValue([]);
      }
    }
    this.optionSelected.emit(selectedOptions);
  }

  public toggleOneOption = () => {
    let selectedOptions: any[] = [];
    if (this.allSelected) {
      if (this.allSelected.selected) {
        this.allSelected.deselect();
        this.sortOptions();
        this.optionSelected.emit(this.multiSelectCtrl.value);
        return false;
      }
      if (this.multiSelectCtrl.value?.length === this.options.length) {
        this.allSelected.select();
      } else {
        this.sortOptions();
      }
    } else {
      this.sortOptions();
    }
    selectedOptions = this.multiSelectCtrl.value?.filter(o => o !== "selectAll");
    this.optionSelected.emit(selectedOptions);
  }

  public openedChange = () => {
    const hasMultiSelect: boolean = !!(this.multiSelectCtrl && this.multiSelectCtrl.value);
    const hasOptions: boolean = !!this.options;
    const excludeSelectAllOptions = hasMultiSelect && this.multiSelectCtrl.value.filter(o => o !== "selectAll");
    if (this.allSelected && hasMultiSelect && hasOptions && excludeSelectAllOptions.length === this.options.length) {
      this.allSelected.select();
    } else {
      this.sortOptions();
    }
  }

  public closedChange = () => {
    if (this.allSelected && this.allSelected.selected) {
      this.multiSelectCtrl.setValue([...this.options.map(item => item[this.displayValue])]);
    }
  }

  public changeOptionSequence(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.multiSelectCtrl.value, event.previousIndex, event.currentIndex);
    moveItemInArray(this.filteredOptions, event.previousIndex, event.currentIndex);
  }

  public sortOptions = () => {
    const selectedItem: any[] = [];
    const unselectedItem: any[] = [];
    if (this.multiSelectCtrl.value && this.multiSelectCtrl.value.length > 0) {
      this.filteredOptions.forEach(option => {
        if (option && this.multiSelectCtrl.value.includes(option[this.displayValue])) {
          const optionIndex = this.multiSelectCtrl.value.indexOf(option[this.displayValue]);
          if (optionIndex > -1) {
            selectedItem[optionIndex] = option;
          }
        } else {
          unselectedItem.push(option);
        }
      });
      this.filteredOptions = [...selectedItem, ...unselectedItem];
    }
  }
}
