import { BehaviorSubject } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import { AuthenticationService } from "@app/core/services/authentication/authentication.service";
import { EndpointListService } from "@app/core/services/endpoint-list/endpoint-list.service";
import { FetchJourneyList } from "@app/journey-builder/fetch-journey-list";
import { JourneyCreateRequest } from "@app/journey-builder/journey-create-request";
import { JourneyUpdateRequest } from "@app/journey-builder/journey-update-request";
import { JourneyDuplicateRequest } from "@app/journey-builder/journey-duplicate-request";
import { JourneyUpdateStatusReq } from "@app/journey-builder/journey-update-status-req";
import { JourneyDeleteRequest } from "@app/journey-builder/journey-delete-request";
import { CoreUtils } from "@app/core/utils/core-utils";
import { TriggerListRequest } from "@app/core/misctypes/trigger-list-request";

@Injectable({
  providedIn: "root"
})
export class JourneyBuilderService {
  public endPoint;
  public param;
  public clientId;
  public userType: string;
  public currentPage = null;
  public currentUserRole: string;
  public desiredClientId = new BehaviorSubject(null);
  public effectiveViewPolicies = new BehaviorSubject([]);
  public effectiveTriggerPolicies = new BehaviorSubject([]);
  public subscribedWfList = new BehaviorSubject([]);

  private _coreUtils: CoreUtils;

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private endpointListService: EndpointListService
  ) {
    this.param = new HttpParams();
    this._coreUtils = new CoreUtils(authService);
    this.clientId = this.authService.client.id;
    this.userType = this._coreUtils.getUserType();
  }

  getJourneyList = (data) => {
    this.param = new HttpParams();
    this.param = this.param.set("clientId", this.clientId);
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/jbbemanagement/${this.endpointListService.getJourneyList}`, data, "POST", this.param);
  }

  public getStackList = (desiredClientId: number, ownerType: string, journeyType?: string) => {
    this.param = new HttpParams();
    this.param = this.param.set("clientId", desiredClientId);
    this.param = this.param.set("ownerType", ownerType);
    this.param = this.param.set("journeyType", journeyType ? journeyType : "");
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/jbbemanagement/${this.endpointListService.getStackList}`, this.param, "GET_WITH_PARAM");
  }

  public getSelectedJourney = (jnyId: string, desiredClientId: number, ownerType: string) => {
    this.param = new HttpParams();
    this.param = this.param.set("clientId", desiredClientId);
    this.param = this.param.set("jnyId", jnyId);
    this.param = this.param.set("ownerType", ownerType);
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/jbbemanagement/${this.endpointListService.viewJbById}`, this.param, "GET_WITH_PARAM");
  }

  getIntents = () => {
    this.param = new HttpParams();
    this.param = this.param.set("clientId", this.clientId);
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/nlpintentmanagement/${this.endpointListService.getIntentList}`, this.param, "GET_WITH_PARAM");
  }

  public getBehaviours = (desiredClientId: number, ownerType: string) => {
    this.param = new HttpParams();
    this.param = this.param.set("clientId", desiredClientId);
    this.param = this.param.set("ownership", ownerType);
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/behaviourresponsemanagement/${this.endpointListService.listAllowedBehaviours}`, this.param, "GET_WITH_PARAM");
  }

  public getNLPEngines = (desiredClientId: number, ownerType: string) => {
    this.param = new HttpParams();
    this.param = this.param.set("clientId", desiredClientId);
    this.param = this.param.set("ownership", ownerType);
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/nlpintentmanagement/${this.endpointListService.getNLPEngines}`, this.param, "GET_WITH_PARAM");
  }

  public getDepartments = (desiredClientId: number) => {
    this.param = new HttpParams();
    this.param = this.param.set("clientId", desiredClientId);
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/clientmanagement/${this.endpointListService.getDepartments}`, this.param, "GET_WITH_PARAM");
  }

  public getCountries = (desiredClientId: number) => {
    this.param = new HttpParams();
    this.param = this.param.set("clientId", desiredClientId);
    return this.apiService.apicall(
      `${this.authService.client.api_gateway_url}/clientmanagement/${this.endpointListService.getCountryCodes}`,
      this.param,
      "GET_WITH_PARAM"
    );
  }

  public getCurrencies = (desiredClientId: number) => {
    this.param = new HttpParams();
    this.param = this.param.set("clientId", desiredClientId);
    return this.apiService.apicall(
      `${this.authService.client.api_gateway_url}/clientmanagement/${this.endpointListService.getCurrencies}`,
      this.param,
      "GET_WITH_PARAM"
    );
  }

  public getAllTimezones = () => {
    this.endPoint = `${this.authService.client.api_gateway_url}/clientmanagement/${this.endpointListService.getAllTimezones}`;
    return this.apiService.apicall(this.endPoint, "", "GET");
  }

  public createJourney = (data: JourneyCreateRequest) => {
    this.param = new HttpParams();
    // There is a limitation in sending query parameters when # is in the user name
    // Hence normalize # with an allowed character
    const normalizedUserName: string = this.authService.currentUser.userName
      ? this.authService.currentUser.userName
        .replace("#", "~")
        .replace("#", "~") : "";
    data.userName = normalizedUserName;
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/jbbemanagement/${this.endpointListService.createJourney}`, data, "POST", this.param);
  }

  public updateJourney = (data: JourneyUpdateRequest) => {
    this.param = new HttpParams();
    // There is a limitation in sending query parameters when # is in the user name
    // Hence normalize # with an allowed character
    const normalizedUserName: string = this.authService.currentUser.userName
      ? this.authService.currentUser.userName
        .replace("#", "~")
        .replace("#", "~") : "";
    data.userName = normalizedUserName;
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/jbbemanagement/${this.endpointListService.updateJourney}`, data, "POST", this.param);
  }

  uploadJourney = (data) => {
    this.param = new HttpParams();
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/jbbemanagement/${this.endpointListService.uploadJourney}`, data, "POST", this.param);
  }

  public duplicateJourney = (duplicateReq: JourneyDuplicateRequest) => {
    this.param = new HttpParams();
    return this.apiService.apicall(
      `${this.authService.client.api_gateway_url}/jbbemanagement/${this.endpointListService.duplicateJourney}`, duplicateReq, "POST", this.param
    );
  }

  public updateStatus = (updateStatusReq: JourneyUpdateStatusReq) => {
    this.param = new HttpParams();
    return this.apiService.apicall(
      `${this.authService.client.api_gateway_url}/jbbemanagement/${this.endpointListService.updateStatus}`, updateStatusReq, "POST", this.param
    );
  }

  public deleteJourney = (deleteReq: JourneyDeleteRequest) => {
    this.param = new HttpParams();

    // There is a limitation in sending query parameters when # is in the user name
    // Hence normalize # with an allowed character
    const normalizedUserName: string = this.authService.currentUser.userName
      ? this.authService.currentUser.userName
        .replace("#", "~")
        .replace("#", "~") : "";

    this.param = this.param.set("userName", normalizedUserName);
    return this.apiService.apicall(
      `${this.authService.client.api_gateway_url}/jbbemanagement/${this.endpointListService.deleteJourney}`, deleteReq, "POST", this.param
    );
  }

  getSections = (wfId) => {
    this.param = new HttpParams();
    this.param = this.param.set("jnyId", wfId);
    this.param = this.param.set("clientId", this.clientId);
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/jbbemanagement/${this.endpointListService.getSections}`, this.param, "GET_WITH_PARAM");
  }

  createSection = (data) => {
    this.param = new HttpParams();
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/jbbemanagement/${this.endpointListService.createSection}`, data, "POST", this.param);
  }

  public getMilestones = (wfId, desiredClientId: number, ownerType: string) => {
    this.param = new HttpParams();
    this.param = this.param.set("jnyId", wfId);
    this.param = this.param.set("clientId", desiredClientId);
    this.param = this.param.set("ownerType", ownerType);
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/jbbemanagement/${this.endpointListService.getMilestones}`, this.param, "GET_WITH_PARAM");
  }

  createMilestone = (data) => {
    this.param = new HttpParams();
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/jbbemanagement/${this.endpointListService.createMilestone}`, data, "POST", this.param);
  }

  getEntities = (entityScope?: string) => {
    this.param = new HttpParams();
    if (entityScope) {
      this.param = this.param.set("scope", entityScope);
    }
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/etmanagement/${this.endpointListService.allentities}`, this.param, "GET_WITH_PARAM");
  }

  public listAllowedTriggers = (data: TriggerListRequest) => {
    this.param = new HttpParams();
    this.endPoint = `${this.authService.client.api_gateway_url}/etmanagement/${this.endpointListService.listAllowedTriggers}`;
    return this.apiService.apicall(this.endPoint, data, "POST", this.param);
  }

  uploadFile = (data) => {
    return this.apiService.apicall(`${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.uploadFile}`, data, "POST");
  }

  public fetchAllWorkflows = (payload: FetchJourneyList) => {
    this.param = new HttpParams();
    this.endPoint = `${this.authService.client.api_gateway_url}/jbbemanagement/${this.endpointListService.fetchAllWorkflows}`;
    return this.apiService.apicall(this.endPoint, payload, "POST", this.param);
  }

  public getSearchClients = (searchVal: string, searchBy: string) => {
    this.endPoint = `${this.authService.client.api_gateway_url}/clientmanagement/${this.endpointListService.searchClients}?searchKey=${searchVal}&searchBy=${searchBy}`;
    return this.apiService.apicall(this.endPoint, "", "GET");
  }

  public getAllIntents = (desiredClientId: number, intentType: string) => {
    this.endPoint = `${this.authService.client.api_gateway_url}/nlpintentmanagement/${this.endpointListService.getAllClientIntents}`;
    this.param = new HttpParams();
    this.param = this.param.set("desiredClientId", desiredClientId);
    this.param = this.param.set("intentType", intentType);
    return this.apiService.apicall(this.endPoint, this.param, "GET_WITH_PARAM");
  }

  public getTriggersOwnedByClient = (desiredClientId: number) => {
    this.param = new HttpParams();
    this.endPoint =
      `${this.authService.client.api_gateway_url}/etmanagement/${this.endpointListService.triggersOwnedByClient}?ownedBy=${desiredClientId}`;
    return this.apiService.apicall(this.endPoint, this.param, "GET_WITH_PARAM");
  }

  public getMasterCountryCodes = () => {
    this.param = new HttpParams();
    return this.apiService.apicall(
      `${this.authService.client.api_gateway_url}/clientmanagement/${this.endpointListService.getCountryCodes}`,
      this.param,
      "GET_WITH_PARAM"
    );
  }

  public getMasterCurrencies = () => {
    this.param = new HttpParams();
    return this.apiService.apicall(
      `${this.authService.client.api_gateway_url}/clientmanagement/${this.endpointListService.getCurrencies}`,
      this.param,
      "GET_WITH_PARAM"
    );
  }

  public getUserDepartments = () => {
    this.param = new HttpParams();
    this.endPoint = `${this.authService.client.api_gateway_url}/clientmanagement/${this.endpointListService.getUserDepartments}`;
    return this.apiService.apicall(this.endPoint, this.param, "GET_WITH_PARAM");
  }
}
