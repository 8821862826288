import Swal from "sweetalert2";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { AuthConstants } from "@app/core/constant/auth-constants";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StorageService } from "@app/core/services/storage/storage.service";

@Component({
  selector: "app-callback",
  templateUrl: "./callback.component.html",
  styleUrls: ["./callback.component.scss"],
})
export class CallbackComponent implements OnInit {

  public loading = false;
  public submitted = false;
  public loginForm: FormGroup;
  public error = "";

  private _const: AuthConstants;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private authSvc: AuthenticationService,
    private _storage: StorageService
  ) {
    // handles the response from SSO
    this._const = new AuthConstants();
    // isLikelySSOError and likelySSOResponse are not mutually exclusive. Hence the else block
    // must be available to handle the other option
    if (this.isLikelySSOError()) {
      Swal.fire({ title: this._const.ERROR_LOGGING_USER, icon: this._const.ERROR });
      // Note: It is ideal if the user is redirected to a help page in this case
      this.router.navigate([`${this._const.SSO_HELP}`], {});
    } else {
      if (this.likelySSOResponse()) {
        this.authSvc.storeAuthTokens();
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.router.navigate([`${this._const.KEY_SLASH}`], {});
  }

  private likelySSOResponse = (): boolean => {
    return !!(this.router.url
      && this.route.snapshot.fragment
      && this.router.url !== `${this._const.KEY_SLASH}${this._const.KEY_LOGIN}`
    );
  }

  private isLikelySSOError = (): boolean => {
    let isError = true;
    if (this.router && this.router.url) {
      const fragments: string[] = this.router.url.split("#");
      if (fragments && fragments.length > 0) {
        const errorPart: string = fragments[1];
        isError = errorPart && errorPart.includes(this._const.ERROR_DESCRIPTION);
      }
    }
    return isError;
  }
}
