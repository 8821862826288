<div class="helpanim" *ngIf="!isTabMode">
    <div class="closehelp-popup" [mat-dialog-close]=""><mat-icon>cancel</mat-icon></div>
    <p><img [src]="dialogData.url" alt=""></p>
</div>

<div class="helpanim helpdoc-tab" *ngIf="isTabMode">
    <mat-tab-group>
        <mat-tab *ngFor="let tab of dialogData.info" [label]="tab.label"> <img [src]="tab.url"> </mat-tab>
    </mat-tab-group>
    <div class="closehelp-popup" [mat-dialog-close]=""><mat-icon>cancel</mat-icon></div>
</div>