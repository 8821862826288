<div class="cms-logo-bg">
  <div class="logo-bg">
    <mat-card class="logout-card">
      <mat-card-header>
        <mat-card-title>DigitalChameleon</mat-card-title>
        <mat-card-subtitle>The No-code Launchpad for Your Ideas</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <!--
        <form [formGroup]="loginForm" (ngSubmit)="authenticateLogin()">
          <mat-form-field class="mat-form-field-wrapper" appearance="legacy">
            <mat-label>Email</mat-label>
            <p></p>
            <input matInput type="email" placeholder="" formControlName="username" required>
            <mat-error>
              {{getEmailErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="mat-form-field-wrapper" appearance="legacy">
            <mat-label>Password</mat-label>
            <p></p>
            <input matInput type="password" placeholder="" formControlName="password" required>
            <mat-error>
              {{getPWDErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-card-actions>
            <button mat-raised-button color="primary" class="cms-btn" type="submit">Login</button>
            <button mat-raised-button color="basic" routerLink='/forgot-password' routerLinkActive="active" type="button">Forgot Password</button>
            <button mat-raised-button color="basic" routerLink='/reset-password' routerLinkActive="active" type="button">Reset Password</button>
          </mat-card-actions>
        </form>
        -->
      </mat-card-content>
      <mat-card-footer>
      </mat-card-footer>
    </mat-card>
    <p class="copyright-cms">DigitalChameleon.ai
    </p>
  </div>
</div>
