import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ClientSubModule } from "@app/core/misctypes/client-sub-module";
import { Role } from "@app/core/misctypes/role";
import { AuthenticationService } from "@app/core/services/authentication/authentication.service";
import { Constants } from "@app/core/utils/const";
import { CoreUtils } from "@app/core/utils/core-utils";
import { AiService } from "@app/live-chat/services/ai.service";
import { CKEditor4, CKEditorComponent } from "ckeditor4-angular";

@Component({
  selector: "app-editor",
  templateUrl: "./editor.component.html",
  styleUrls: ["./editor.component.scss"],
})
export class EditorComponent implements OnInit {
  @Input() component: string;
  @Output() getValue = new EventEmitter();
  @Output() getTypingEvent = new EventEmitter();
  @ViewChild("emojiContainer") emojiView: ElementRef;
  @ViewChild("ckEditor") ckEditor: CKEditorComponent;

  public editorForm: FormGroup;
  public isVideoChatAccess = false;
  public editorConfig: CKEditor4.Config;
  public emojiCodes: number[] = [];
  public displayVcall = false;
  public continueVideoCall = false;
  public dragPosition = { x: 0, y: 0 };
  public static startSendTyping: any;
  public static stopTyping = false;
  private _cnst: Constants;
  private _coreUtils: CoreUtils;

  constructor(
    public formBuilder: FormBuilder,
    public renderer: Renderer2,
    private aiService: AiService,
    private authService: AuthenticationService,
  ) {
    this._cnst = new Constants();
    this._coreUtils = new CoreUtils();
  }

  ngOnInit(): void {
    this.editorConfig = this._coreUtils.getConfigWithoutCustomClass();
    this.emojiCodes = this._coreUtils.getEmojiCodes();
    this.editorForm = this.formBuilder.group({
      textContent: ["", Validators.required],
    });
    this.aiService.setvalue().subscribe((res: string) => {
      this.setEditorValue(res);
    });
  }

  ngAfterViewInit() {
    this.initializeEmoji();
    this.hasVideoCallAccess();
  }

  public setEditorValue = (val: string) => {
    this.editorForm.get(this._cnst.TEXT_CONTENT).setValue(val);
  }

  public getEditorValue = () => {
    return this.editorForm.get(this._cnst.TEXT_CONTENT).value;
  }

  public onEnterPress = (event: KeyboardEvent) => {
    EditorComponent.stopTypingEvent();
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.sendMessage();
    return false;
  }

  public onKeydown = (event: KeyboardEvent) => {
    if (event.keyCode !== 13
      && event.keyCode !== 17
      && event.keyCode !== 18
      && event.keyCode !== 20
      && !EditorComponent.stopTyping
    ) {
      const startTime: number = new Date().getTime();
      EditorComponent.startSendTyping = setInterval(() => {
        const currentTime: number = new Date().getTime();
        if (currentTime - startTime > 6000) {
          EditorComponent.stopTypingEvent();
        } else {
          this.getTypingEvent.emit(true);
        }
      }, 1000);
      EditorComponent.stopTyping = true;
    }
  }

  public static stopTypingEvent() {
    clearInterval(EditorComponent.startSendTyping);
    EditorComponent.stopTyping = false;
  }

  public sendMessage = () => {
    const msg: string = this.getEditorValue();
    this.getValue.emit(msg);
    this.setEditorValue("");
  }

  public initializeEmoji = () => {
    if (this.emojiCodes && this.emojiCodes.length > 0) {
      this.emojiCodes.forEach(function (code: number) {
        const anchorTag: HTMLAnchorElement = this.renderer.createElement(`a`);
        const smileyText: Text = this.renderer.createText(String.fromCodePoint(code));
        this.renderer.setAttribute(anchorTag, `href`, `javascript:void(0)`);
        this.renderer.addClass(anchorTag, `emo-icon`);
        this.renderer.listen(anchorTag, `click`, this.addEmojiToEditor.bind(this));
        this.renderer.appendChild(anchorTag, smileyText);
        this.renderer.appendChild(this.emojiView.nativeElement, anchorTag);
      }.bind(this)
      );
    }
  }

  public addEmojiToEditor = (event: PointerEvent) => {
    if (event) {
      let msg: string = this.getEditorValue();
      msg += (event.target as HTMLAnchorElement).text;
      this.setEditorValue(msg);
    }
  }

  private hasVideoCallAccess = () => {
    let vcModule: ClientSubModule[] = [];
    let vcRole: Role[] = [];
    if (this.authService && this.authService.currentUser) {
      if (this.authService.currentUser.clientSubmoduleDtos && this.authService.currentUser.clientSubmoduleDtos.length > 0) {
        const clientModules: ClientSubModule[] = this.authService.currentUser.clientSubmoduleDtos;
        vcModule = clientModules.filter(
          (module: ClientSubModule) => module && module.submodulesDto && module.submodulesDto.id === 7);
      }
      if (this.authService.currentUser.roles && this.authService.currentUser.roles.length > 0) {
        const accessingRole: string = this._coreUtils.getRoleWithPrefix(this._cnst._LC_VC_READ);
        vcRole = this.authService.currentUser.roles.filter((role: Role) => role && role.roleName === accessingRole);
      }
    }
    this.isVideoChatAccess = !!(vcModule && vcModule.length > 0 && vcRole && vcRole.length > 0);
  }

  public closeVcall = () => {
    this.continueVideoCall = false;
    this.displayVcall = !this.displayVcall;
    this.setEditorValue("");
  }

  public openVideoCall = () => {
    if (this.continueVideoCall && document.getElementById(this._cnst.ID_VIDEOCALL)) {
      document.getElementById(this._cnst.ID_VIDEOCALL).style.display = this._cnst.DISPLAY_BLOCK;
    } else {
      this.displayVcall = !this.displayVcall;
      this.setEditorValue(this._cnst.VIDEO_CALL_URL_DIV);
    }
    this.dragPosition = { x: this.dragPosition.x + 700, y: this.dragPosition.y + 800 };
  }

  public minimizeVideoCall = () => {
    this.continueVideoCall = true;
    if (document.getElementById(this._cnst.ID_VIDEOCALL)) {
      document.getElementById(this._cnst.ID_VIDEOCALL).style.display = this._cnst.DISPLAY_NONE;
    }
  }

  public onMessageEdit = () => {
    if (this.ckEditor && this.ckEditor.instance) {
      this.ckEditor.instance.on(this._cnst.CK_EDITOR_KEY_EVENT, (event) => {
        if (event && event.data) {
          if (event.data.keyCode === this._cnst.ENTER_KEY_CODE
            || event.data.domEvent?.getKeystroke() === this._cnst.ENTER_KEY_CODE
          ) {
            event.stop();
            this.sendMessage();
          }
        }
      });
    }
  }
}