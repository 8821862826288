export class LoginCfg {

  public clientId: number;
  public ssoEnabled = false;
  public idpType: string;
  public anonAccessToken: string;
  public gatewayUrl: string;

  constructor() {}
}
