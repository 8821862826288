import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { Constants } from "@app/core/utils/const";
import { ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-single-select-search",
  templateUrl: "./single-select-search.component.html",
  styleUrls: ["./single-select-search.component.scss"]
})
export class SingleSelectSearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label: string;
  @Input() options: any[] = [];
  @Input() selectedValue: string;
  @Input() displayName: string;
  @Input() displayValue: string;
  @Input() isRequired = false;
  @Input() isSelectTriggerRequired = false;
  @Input() selectTriggerValue: string;
  @Input() isShowChameleonLogo? = false;
  @Input() isDisable? = false;
  @Input() isNoneRequired? = true;
  @Output() optionSelected = new EventEmitter();

  public singleSelectCtrl: FormControl = new FormControl({ value: "", disabled: this.isDisable });
  public searchCtrl: FormControl = new FormControl();
  public filteredOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();

  public _const: Constants;

  constructor() {
    this._const = new Constants();
  }

  ngOnInit(): void {
    this.bindSelectedValue();
  }

  private bindSelectedValue = () => {
    if (this.options && this.options.length > 0) {
      const opt = this.options.filter(option => option[this.displayValue] === this.selectedValue);
      this.singleSelectCtrl.setValue(opt && opt.length > 0 ? opt[0][this.displayValue] : "");
    }
    this.filteredOptions.next(this.options.slice());
    this.searchCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterOptionList();
    });
  }

  ngOnChanges() {
    const opt = this.options.filter(option => option[this.displayValue] === this.selectedValue);
    this.singleSelectCtrl.setValue(opt && opt.length > 0 ? opt[0][this.displayValue] : "");
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterOptionList() {
    if (!this.options) {
      return;
    }
    const search = this.searchCtrl.value.toLowerCase();
    if (!search) {
      this.filteredOptions.next(this.options.slice());
      return;
    }
    this.filteredOptions.next(
      this.options.filter(opt => opt[this.displayName].toLowerCase().indexOf(search) > -1)
    );
  }

  public selectedValueChanged = (changeEvent: MatSelectChange) => {
    if (changeEvent) {
      this.optionSelected.emit(changeEvent.value);
      this.singleSelectCtrl.setValue(changeEvent.value);
    }
  }

  public isAddChameleonLogo = (option: any): boolean => {
    let isAddLogo = false;
    if (this.isShowChameleonLogo) {
      if (option && option.ownership === this._const.MASTER && option.isLibrary) {
        isAddLogo = true;
      }
    }
    return isAddLogo;
  }

  public isAddDivider = (option: any): boolean => {
    let isAdd = false;
    if (this.isShowChameleonLogo) {
      try {
        this.filteredOptions.subscribe((options: any[]) => {
          const filteredOptions: any[] = options && options.length > 0 ? options : [];
          const masterOpt = filteredOptions.find((filtered) => {
            return filtered && filtered.ownership === this._const.MASTER && filtered.isLibrary;
          });
          if (option && masterOpt && JSON.stringify(masterOpt) === JSON.stringify(option)) {
            isAdd = true;
          }
        });
      } catch (e) {
        isAdd = false;
        console.log(`Error occured while adding divider in option list. Error is ${e}`);
      }
    }
    return isAdd;
  }
}