<div class="cms-logo-bg">
  <div class="logo-bg">
    <mat-card class="sso-help-card">
      <mat-card-header>
        <mat-card-title>DigitalChameleon</mat-card-title>
        <mat-card-subtitle>The No-code Launchpad for Your Ideas</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
      </mat-card-content>
      <mat-card-footer>
        <div class="mat-error">It seems like the user you attempted is invalid. Please contact admin if the issue persists</div>
      </mat-card-footer>
    </mat-card>
    <p class="copyright-cms">DigitalChameleon.ai
    </p>
  </div>
</div>
