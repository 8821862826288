<div class="footer-container">
  <div class="video-chat" *ngIf="isVideoChatAccess && component === 'chat'">
    <a target="_blank" (click)="openVideoCall()">
      <mat-icon>video_call</mat-icon>
    </a>
  </div>
  <div id="videoCall" *ngIf="displayVcall" cdkDrag>
    <div class="action-btn">
      <button class="remove-icon" (click)="minimizeVideoCall()">
        <mat-icon>remove</mat-icon>
      </button>

      <button class="close-icon" (click)="closeVcall()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <app-video-call></app-video-call>
  </div>

  <form [formGroup]="editorForm" (ngSubmit)="sendMessage()">
    <div class="email-editor">
      <ckeditor [config]="editorConfig" formControlName="textContent"
        (keydown)="onKeydown($event)" (keyup.enter)="onEnterPress($event)" ngDefaultControl class="jb-editor"
        (change)="onMessageEdit()" #ckEditor name="ckEditor">
      </ckeditor>
    </div>
    <button type="submit" id="send-btn" class="send-btn">
      <mat-icon class="text-black">send</mat-icon>
    </button>
    <div class="dropdown dropup emoti-icons">
      <button class="btn btn-link text-black dropdown-toggle" type="button" id="emoji-icons" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <mat-icon class="smilee f-20">insert_emoticons</mat-icon>
      </button>
      <div #emojiContainer class="dropdown-menu" aria-labelledby="emoji-icons"></div>
    </div>
  </form>
</div>