import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { AuthConstants } from "@app/core/constant/auth-constants";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { StorageService } from "@app/core/services/storage/storage.service";
import { LoginRespInfo } from "@app-auth/pages/login/login-resp-info";
import { responseCode } from "@app/core/services/endpoint-list/endpoint-resp-code";
import { responseMessages } from "@app/core/services/endpoint-list/endpoint-resp-messages";
import { CoreUtils } from "@app/core/utils/core-utils";
import { AuthToken } from "@app/core/models/auth-token";

@Component({
  selector: "app-forced-reset-complete",
  templateUrl: "./forced-reset-complete-sentinel.component.html",
  styleUrls: ["./forced-reset-complete-sentinel.component.scss"],
})
export class ForcedResetCompleteComponent implements OnInit {

  public loading = false;
  public submitted = false;
  public confirmCodeForm: FormGroup;

  public _username = new FormControl("", [Validators.required, Validators.email]);
  public _password = new FormControl("", [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]);
  public _newPassword = new FormControl("", [
    Validators.required,
    Validators.minLength(8),
    Validators.pattern(/[A-Z]/),
    Validators.pattern(/[a-z]/),
    Validators.pattern(/[0-9]/),
    Validators.pattern(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)
  ]);
  public _confirmNewPassword = new FormControl("", [
    Validators.required,
    Validators.minLength(8),
    Validators.pattern(/[A-Z]/),
    Validators.pattern(/[a-z]/),
    Validators.pattern(/[0-9]/),
    Validators.pattern(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)
  ]);

  public error = "";
  public hideTempPass = true;
  public hideNewPass = true;
  public hideConfPass = true;
  public isUpperCaseCharErr = true;
  public isLowerCaseCharErr = true;
  public isNumberCharErr = true;
  public isSpecialCharErr = true;
  private accessToken: string;
  private sessionId: string;
  private _const: AuthConstants;
  private _util: CoreUtils;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private authSvc: AuthenticationService,
    private _storage: StorageService
  ) {
    // handles the response from SSO
    this._const = new AuthConstants();
    this._util = new CoreUtils(authSvc);

    this.confirmCodeForm = this.fb.group({
      username: this._username,
      password: this._password,
      newPassword: this._newPassword,
      confirmNewPassword: this._confirmNewPassword
    }, {});

  }

  ngOnInit(): void {
    const queryParams: Params = this.route && this.route.snapshot && this.route.snapshot.queryParams
      ? this.route.snapshot.queryParams : null;
    this.accessToken = queryParams ? queryParams.accessToken : null;
    this.sessionId = queryParams ? queryParams.sessionId : null;

    if (queryParams.username) {
      this.confirmCodeForm.get(this._const.USER_NAME).setValue(queryParams.username);
    }

    if (queryParams.password) {
      this.confirmCodeForm.get(this._const.PASSWORD).setValue(queryParams.password);
    }
  }

  public confirmCode = async () => {
    this.submitted = true;
    if (this.confirmCodeForm.invalid) {
      return;
    }
    this.loading = true;

    if (this.confirmCodeForm.valid) {
      try {

        const un = this.confirmCodeForm.get(this._const.USER_NAME).value;
        const pwd = this.confirmCodeForm.get(this._const.PASSWORD).value;
        const npw = this.confirmCodeForm.get(this._const.NEW_PASSWORD).value;
        const cnfPwd = this.confirmCodeForm.get(this._const.CONFIRM_NEW_PASSWORD).value;

        if (!(cnfPwd && npw && cnfPwd === npw)) {
          this.error = "New password and confirm new password must match";
          return;
        }

        const completeForcedResetPasswordPromise: Promise<LoginRespInfo> = new Promise<LoginRespInfo>((resolve) => {
          this.authSvc.completeSentinelForcedResetPassword(un, pwd, npw, this.sessionId, this.accessToken)
            .subscribe((response) => {
              if (response.status === 200) {
                let lri: LoginRespInfo;
                if ([
                  responseCode.sentinel.COMPLETE_FORCED_RESET_PASSWORD_FAILURE_CODE,
                  responseCode.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE
                ].some(c => c === response.body.exeCode)) {
                  lri = new LoginRespInfo(response.body.exeCode, false);
                } else {
                  if (responseCode.sentinel.COMPLETE_FORCED_RESET_PASSWORD_SUCCESS_CODE === response.body.exeCode) {
                    lri = new LoginRespInfo(response.body.exeCode, true);
                  }
                  if (responseCode.sentinel.SMS_MFA_REQUIRED_ERROR_CODE === response.body.exeCode) {
                    lri = new LoginRespInfo(response.body.exeCode, false);
                    lri.registerSessions([response.body.challengeToken]);
                  }
                }

                if (responseCode.sentinel.COMPLETE_FORCED_RESET_PASSWORD_FAILURE_CODE === response.body.exeCode) {
                  this.error = responseMessages.sentinel.COMPLETE_FORCED_RESET_PASSWORD_FAILURE;
                  resolve(lri);
                } else if (responseCode.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE === response.body.code) {
                  this.error = responseMessages.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE;
                  resolve(lri);
                } else {
                  if (response.body && response.body.jwtAccessToken && response.body.jwtIdToken) {
                    const token: AuthToken = new AuthToken(response.body.jwtAccessToken, response.body.jwtIdToken);
                    this._storage.storeJWTTokens(JSON.stringify(token));
                  }
                  resolve(lri);
                }
              }
            }, (error) => {
              console.log(error);
              resolve(new LoginRespInfo(responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE, false));
            });
        });

        const confirmationOk: LoginRespInfo = await completeForcedResetPasswordPromise;
        if (confirmationOk && confirmationOk.healthy()) {
          if (confirmationOk.isForcedResetPwdOk()) {
            // Attempting to load the dashboard page based on the condition
            await this._util.sleep(2000);
            if (this._storage.storedJWTTokens()) {
              this.goToDashboard();
            } else {
              this.goToLogin();
            }
          } else {
            if (confirmationOk.withCode() === responseCode.sentinel.SMS_MFA_REQUIRED_ERROR_CODE) {
              await this._util.sleep(2000);
              await this.router.navigate([`${this._const.KEY_MFA}`], {
                queryParams: {
                  mfaType: `${0x02}`,
                  sessionId: `${confirmationOk.withChallengeToken()}`,
                  accessToken: `${this.accessToken}`,
                  username: un
                }
              });
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  public goToLogin = () => {
    this.router.navigate([`${this._const.KEY_LOGIN}`], { queryParams: { accessToken: this.accessToken } });
  }

  private goToDashboard = () => {
    this.router.navigate([`${this._const.KEY_SLASH}`], {});
  }

  public getEmailErrorMessage = (): string => {
    let errorMsg: string;
    if (this._username.hasError(this._const.REQUIRED)) {
      errorMsg = this._const.EMAIL_MANDATORY_MSG;
    } else {
      errorMsg = this._username.hasError(this._const.EMAIL) ? this._const.INVALID_EMAIL_ERROR_MSG : "";
    }
    return errorMsg;
  }

  getPWDErrorMessage = () => {
    let errorMsg: string;
    if (this._password.hasError(this._const.REQUIRED)) {
      errorMsg = this._const.TEMPORARY_PASSWORD_MANDATORY_MSG;
    } else {
      errorMsg = this._password.hasError(this._const.PASSWORD) ? this._const.INVALID_PASSWORD_ERROR_MSG : "";
    }
    return errorMsg;
  }

  getNewPWDErrorMessage = () => {
    let errorMsg: string;
    if (this._newPassword.hasError(this._const.REQUIRED)) {
      errorMsg = this._const.NEW_PASSWORD_MANDATORY_MSG;
    } else if ((this._newPassword.hasError(this._const.MIN_LENGTH))
      || (this._newPassword.hasError(this._const.PATTERN))) {
      errorMsg = "";
    } else {
      errorMsg = this._newPassword.hasError(this._const.PASSWORD) ? this._const.INVALID_PASSWORD_ERROR_MSG : "";
    }

    return errorMsg;
  }

  public getConfirmNewPWDErrorMessage = () => {
    let errorMsg: string;
    const npw: string = this.confirmCodeForm.get(this._const.NEW_PASSWORD).value;
    const cnfPwd: string = this.confirmCodeForm.get(this._const.CONFIRM_NEW_PASSWORD).value;
    if (this._confirmNewPassword.hasError(this._const.REQUIRED)) {
      errorMsg = this._const.CONFIRM_PASSWORD_MANDATORY_MSG;
    } else if (!(cnfPwd && npw && cnfPwd === npw)) {
      errorMsg = this._const.CONFIRM_PASSWORD_MATCH_ERROR_MSG;
    } else {
      errorMsg = this._confirmNewPassword.hasError(this._const.CONFIRM_NEW_PASSWORD) ? this._const.INVALID_PASSWORD_ERROR_MSG : "";
    }
    return errorMsg;
  }

  public checkPwdValidity = () => {
    this.isUpperCaseCharErr = !(this._const.UPPER_CASE_CHAR_REGEX.test(this._newPassword.value));
    this.isLowerCaseCharErr = !(this._const.LOWER_CASE_CHAR_REGEX.test(this._newPassword.value));
    this.isNumberCharErr = !(this._const.NUMBER_CHAR_REGEX.test(this._newPassword.value));
    this.isSpecialCharErr = !(this._const.SPECIAL_CHAR_REGEX.test(this._newPassword.value));
  }
}
