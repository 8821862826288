import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import * as d3 from "d3";
import * as d3Scale from "d3-scale";
import * as d3Shape from "d3-shape";
import * as d3Axis from "d3-axis";
import { AnalyticsConstant } from "@app/analytics/constants/analytics.constant";
import { SessionsCount } from "@app/analytics/types/sessions-count";
import { ZoomBehavior } from "d3";
import { AnalyticsService } from "@app/analytics/services/analytics.service";

@Component({
  selector: "app-linechart",
  templateUrl: "./linechart.component.html",
  styleUrls: ["./linechart.component.scss"]
})
export class LinechartComponent implements OnInit, AfterViewInit {
  @ViewChild("chart") public chartContainer: ElementRef;
  @Input() public label: string;
  public data: SessionsCount[] = [];
  public type: string;
  public maxValue: number;
  public selectedTabIndex: number;
  public _analyticsConst: AnalyticsConstant;

  private margin = { top: 15, right: 20, bottom: 30, left: 40 };
  private width = 1090;
  private height = 245;
  private xAxis: d3.ScaleLinear<number, number>;
  private yAxis: d3Scale.ScaleLinear<number, number, any>;
  private svg: any;
  private line: d3Shape.Line<[number, number]>;
  private xAxis2: d3.ScaleLinear<number, number>;
  private zoom: ZoomBehavior<Element, any>;
  private path: any;
  private spaceBtwnCircle: number;
  private htmlEl: HTMLElement;
  private svgY: any;
  private alternateMarginLeft = 3;
  private htmlElY: HTMLElement;

  constructor(private analyticsService: AnalyticsService) {
    this._analyticsConst = new AnalyticsConstant();
  }

  ngOnInit() {
    this.analyticsService.selectedTabIndex.subscribe((tabIndex: number) => {
      this.selectedTabIndex = tabIndex || tabIndex === 0 ? tabIndex : null;
    });
  }

  ngAfterViewInit() {
    this.getWebPageStats();
    this.getWorkflowStats();
    this.getChatbotStats();
    this.getLCStats();
  }

  ngAfterContentInit() {
    this.getWebPageStats();
    this.getWorkflowStats();
    this.getChatbotStats();
    this.getLCStats();
  }

  private getWebPageStats = () => {
    if (this.selectedTabIndex === 0) {
      this.analyticsService.webPageLoadVal.subscribe((pageLoad: string) => {
        this.type = pageLoad ? pageLoad : "";
      });
      this.analyticsService.webPageMaxValue.subscribe((maxVal: number) => {
        this.maxValue = maxVal ? maxVal : 0;
      });
      this.analyticsService.webPageData.subscribe((webData: SessionsCount[]) => {
        this.data = webData && webData.length > 0 ? webData : [];
        this.initChart();
      });
    }
  }

  private getWorkflowStats = () => {
    if (this.selectedTabIndex === 2) {
      this.analyticsService.workflowLoad.subscribe((pageLoad: string) => {
        this.type = pageLoad ? pageLoad : "";
      });
      this.analyticsService.wfStatsMaxValue.subscribe((maxVal: number) => {
        this.maxValue = maxVal ? maxVal : 0;
      });
      this.analyticsService.workflowData.subscribe((webData: SessionsCount[]) => {
        this.data = webData && webData.length > 0 ? webData : [];
        this.initChart();
      });
    }
  }

  private getChatbotStats = () => {
    if (this.selectedTabIndex === 3) {
      this.analyticsService.chatbotLoad.subscribe((pageLoad: string) => {
        this.type = pageLoad ? pageLoad : "";
      });
      this.analyticsService.chatbotMaxValue.subscribe((maxVal: number) => {
        this.maxValue = maxVal ? maxVal : 0;
      });
      this.analyticsService.chatbotData.subscribe((chatbotData: SessionsCount[]) => {
        this.data = chatbotData && chatbotData.length > 0 ? chatbotData : [];
        this.initChart();
      });
    }
  }

  private getLCStats = () => {
    if (this.selectedTabIndex === 4) {
      this.analyticsService.livechatLoad.subscribe((pageLoad: string) => {
        this.type = pageLoad ? pageLoad : "";
      });
      this.analyticsService.lcMaxValue.subscribe((maxVal: number) => {
        this.maxValue = maxVal ? maxVal : 0;
      });
      this.analyticsService.livechatData.subscribe((lcData: SessionsCount[]) => {
        this.data = lcData && lcData.length > 0 ? lcData : [];
        this.initChart();
      });
    }
  }

  private initChart = () => {
    this.initHTMLElement();
    this.initWidth();
    this.initSvg();
    this.initAxis();
    this.drawAxis();
    this.drawLine();
  }

  private initWidth = () => {
    const initialWidth: number = this.getInitialWidth();
    this.spaceBtwnCircle = this.type === this._analyticsConst.HOUR ? this._analyticsConst.HOUR_SPACE_BTWN_CIRCLE
      : this.type === this._analyticsConst.DAY ? this._analyticsConst.DAY_SPACE_BTWN_CIRCLE
        : this._analyticsConst.MONTH_SPACE_BTWN_CIRCLE;
    if (this.data && this.data.length > 0) {
      this.width = this.data.length * this.spaceBtwnCircle > initialWidth
        ? this.data.length * this.spaceBtwnCircle
        : initialWidth;
      this.data.forEach((session: SessionsCount, i: number) => {
        session.index = i;
      });
    }
  }

  private getInitialWidth = (): number => {
    let width: number = this._analyticsConst.WEBPAGE_CHART_INIT_WIDTH;
    if (this.htmlEl) {
      const pixel: string = d3?.select(this.htmlEl)?.style(this._analyticsConst.WIDTH);
      if (!!pixel) {
        width = parseInt(pixel);
      }
    }
    return width;
  }

  private initHTMLElement = () => {
    if (this.selectedTabIndex === 0) {
      this.htmlEl = document.getElementById(this._analyticsConst.CHART);
      this.htmlElY = document.getElementById(this._analyticsConst.CHART_Y);
    }
    if (this.selectedTabIndex === 2) {
      this.htmlEl = document.getElementById(this._analyticsConst.WF_CHART);
      this.htmlElY = document.getElementById(this._analyticsConst.WF_CHART_Y);
    }
    if (this.selectedTabIndex === 3) {
      this.htmlEl = document.getElementById(this._analyticsConst.CHATBOT_CHART);
      this.htmlElY = document.getElementById(this._analyticsConst.CHATBOT_CHART_Y);
    }
    if (this.selectedTabIndex === 4) {
      this.htmlEl = document.getElementById(this._analyticsConst.LC_CHART);
      this.htmlElY = document.getElementById(this._analyticsConst.LC_CHART_Y);
    }
    d3.select(this.htmlEl).selectAll("svg").remove();
    d3.select(this.htmlElY).selectAll("svg").remove();
    d3.selectAll(".chart-tooltip").remove();
  }

  private initSvg = () => {
    this.svg = d3.select(this.htmlEl)
      .append("svg")
      .attr("width", this.width + this.alternateMarginLeft + this.margin.right - this._analyticsConst.SVG_WIDTH_BUFFER)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .append("g")
      .attr("transform", "translate(" + this.alternateMarginLeft + "," + this.margin.top + ")");

    this.svgY = d3.select(this.htmlElY)
      .append("svg")
      .attr("width", this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .append("g")
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
  }

  private initAxis = () => {
    this.xAxis = d3Scale.scaleLinear().range([0, (this.width - this._analyticsConst.LINE_CHART_HORIZONTAL_BUFFER)]);
    this.xAxis2 = d3Scale.scaleLinear().range([0, (this.width - this._analyticsConst.LINE_CHART_HORIZONTAL_BUFFER)]);
    this.yAxis = d3Scale.scaleLinear().rangeRound([this.height, 0]);
  }

  private drawAxis = () => {
    const xAxisBottom: d3Axis.Axis<d3Axis.AxisDomain> = d3Axis
      .axisBottom(this.xAxis)
      .tickSizeInner(0)
      .tickValues(this.data.map((session: SessionsCount) => session.index))
      .tickFormat((d: d3Axis.AxisDomain, i: number) => {
        const found: SessionsCount = this.data.find((session: SessionsCount) => {
          return session.index === d;
        });
        const show: string = this.getTickLabel(found, i);
        return show;
      });

    const xAxisBottom2: d3Axis.Axis<d3Axis.AxisDomain> = d3Axis
      .axisBottom(this.xAxis)
      .tickSizeInner(0)
      .tickValues(this.data.map((session: SessionsCount) => session.index))
      .tickFormat((d: d3Axis.AxisDomain, i: number) => {
        const found: SessionsCount = this.data.find((session: SessionsCount) => {
          return session.index === d;
        });
        const show = this.getTickLabel(found, i);
        return show;
      });

    const yAxisLeft: d3Axis.Axis<d3Scale.NumberValue> = d3Axis
      .axisLeft(this.yAxis)
      .tickSizeInner(0)
      .tickFormat((d: d3Axis.AxisDomain, i: number) => {
        return parseInt(d.toString()) === d ? d.toString() : "";
      });

    this.xAxis.domain(d3.extent(this.data, (session: SessionsCount) => {
      return session.index;
    }));
    this.xAxis2.domain(this.xAxis.domain());
    this.yAxis.domain([0, this.maxValue]).nice();

    const xLine = this.svg.append("g")
      .attr("class", "axis axis--x")
      .attr("transform", "translate(0," + this.height + ")")
      .call(xAxisBottom);

    xLine.selectAll(".tick")
      .selectAll("text")
      .style("text-anchor", (d: number) => d === 0 ? "start" : "middle")
      .style("font-size", "11px");

    const yLine = this.svgY.append("g")
      .attr("class", "axis axis--y")
      .call(yAxisLeft)
      .append("text")
      .attr("class", "axis-title")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dy", ".71em")
      .style("text-anchor", "end")
      .style("font-size", "11px");

    const clip: Selection = this.svg
      .append("defs")
      .append("svg:clipPath")
      .attr("id", "clip")
      .append("svg:rect")
      .attr("width", this.width)
      .attr("height", this.height + 10)
      .attr("x", 0)
      .attr("y", 0);

    this.zoom = d3
      .zoom()
      .extent([
        [this.margin.left, this.margin.top],
        [this.width, this.height],
      ])
      .scaleExtent([1, 1])
      .on("zoom", () => {
        const transform = d3.event.transform;
        this.xAxis.domain(transform.rescaleX(this.xAxis2).domain());
        xLine.call(xAxisBottom)
          .attr("clip-path", "url(#clip)");

        xLine.selectAll(".tick")
          .attr("transform", (d: number, i: number) => {
            const pos: number = this.xAxis(i);
            return `translate(${pos},0)`;
          });

        this.path.attr("d", d3Shape
          .line()
          .x((d: SessionsCount | any, i: number) => {
            return this.xAxis(i);
          })
          .y((d: SessionsCount | any) => {
            return this.yAxis(d.value);
          }))
          .attr("clip-path", "url(#clip)");

        this.svg.selectAll("circle")
          .attr("cx", (d: SessionsCount, i: number) => {
            return this.xAxis(i);
          });
      });

    const rect: Selection = this.svg
      .append("rect")
      .attr("class", "zoom")
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .attr("width", this.width)
      .attr("height", this.height)
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)
      .call(this.zoom);
  }

  private drawLine = () => {
    this.line = d3Shape.line()
      .x((d: SessionsCount | any) => this.xAxis(d.index))
      .y((d: SessionsCount | any) => this.yAxis(d.value));

    this.path = this.svg.append("path")
      .datum(this.data)
      .attr("fill", "none")
      .attr("stroke", "#e99696")
      .attr("stroke-width", 2.5)
      .attr("d", this.line)
      .call(this.zoom);

    this.svg
      .selectAll("circle")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("fill", "#e99696")
      .attr("cx", (d: SessionsCount) => this.xAxis(d.index))
      .attr("cy", (d: SessionsCount) => this.yAxis(d.value))
      .attr("r", this.type === this._analyticsConst.HOUR ? 3 : 4)
      .call(this.zoom);

    d3.select(this.htmlEl)
      .append("div")
      .classed("chart-tooltip", true)
      .style("display", "none")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "5px")
      .style("position", "absolute")
      .style("z-index", "999");

    d3.select(this.htmlElY)
      .append("div")
      .classed("chart-tooltip", true)
      .style("display", "none")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "5px")
      .style("position", "absolute")
      .style("z-index", "999");

    this.svg
      .on("mouseover", () => {
        d3.select(".chart-tooltip").style("display", null);
      })
      .on("mouseout", () => {
        d3.select(".chart-tooltip").style("display", "none");
      })
      .on("mousemove", (d) => {
        if (d3 && d3.event && d3.event.srcElement
          && d3.event.srcElement.__data__
          && d3.event.srcElement.__data__.date
          && (d3.event.srcElement.__data__.value
            || d3.event.srcElement.__data__.value === 0)) {
          const showText: string = this.getTooltipDisplayText();
          const tooltipText: string = `${d3.event.srcElement.__data__.date} : ${d3.event.srcElement.__data__.value} ${showText}`;
          const topPx: number = this._analyticsConst.LINECHART_TOOLTIP_TOP;
          const leftPx: number = this._analyticsConst.LINECHART_TOOLTIP_left;
          d3.select(".chart-tooltip")
            .style("left", d3.event.pageX - leftPx + "px")
            .style("top", d3.event.pageY - topPx + "px")
            .html(tooltipText.trim());
        } else {
          d3.select(".chart-tooltip").style("display", "none");
        }
      });
  }

  private getTickLabel = (session: SessionsCount, i: number): string => {
    const interval: number = this.type === this._analyticsConst.HOUR ? this._analyticsConst.CIRCLE_HOUR_INTERVAL
      : this._analyticsConst.CIRCLE_DAY_INTERVAL;
    let tickLabel = "";
    if (session) {
      if (this.data && this.data.length > 2 && (i % interval) !== 0) {
        tickLabel = "";
      } else if (this.type === this._analyticsConst.HOUR) {
        tickLabel = session.displayDate && session.displayDate.split(",") && session.displayDate.split(",")[0] ? session.displayDate.split(",")[0] : "";
      } else {
        tickLabel = session.displayDate ? session.displayDate.toString() : "";
      }
    }
    return tickLabel;
  }

  private getTooltipDisplayText = (): string => {
    let text = "";
    if (this.selectedTabIndex === 0) {
      text = this._analyticsConst.PAGE_VIEWS;
    } else if (this.selectedTabIndex === 2) {
      text = this._analyticsConst.WORKFLOWS;
    } else if (this.selectedTabIndex === 3) {
      text = this._analyticsConst.CHATBOTS;
    } else if (this.selectedTabIndex === 4) {
      text = this._analyticsConst.LIVECHATS;
    }
    return text;
  }
}