import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthConstants } from "@app/core/constant/auth-constants";
import { CoreUtils } from "@app/core/utils/core-utils";
import { LoginRespInfo } from "@app-auth/pages/login/login-resp-info";
import { responseCode } from "@app/core/services/endpoint-list/endpoint-resp-code";
import { responseMessages } from "@app/core/services/endpoint-list/endpoint-resp-messages";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password-sentinel.component.html",
  styleUrls: ["./forgot-password-sentinel.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {

  public loading = false;
  public submitted = false;
  public forgotPasswordForm: FormGroup;
  public email = new FormControl("", [Validators.required, Validators.email]);
  public error: string;

  private _const: AuthConstants;
  private _util: CoreUtils;


  constructor(
    private fb: FormBuilder,
    public authSvc: AuthenticationService,
    public router: Router,
    private route: ActivatedRoute) {
    this._const = new AuthConstants();
    this._util = new CoreUtils(authSvc);
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      username: ["", [Validators.required]]
    }, {});
  }

  public forgotPassword = async () => {

    if (!this.forgotPasswordForm.valid) {
      return;
    }

    if (this.forgotPasswordForm.valid) {
      try {
        const username = this.forgotPasswordForm.get("username").value;
        // TODO: Anon token is not used at the moment in this case as it is not
        // TODO: implemented in SENTINEL
        const anonToken = this.route.snapshot.queryParams && this.route.snapshot.queryParams.accessToken
          ? this.route.snapshot.queryParams.accessToken : "";

        const initiateForgotPasswordPromise: Promise<LoginRespInfo> = new Promise<LoginRespInfo>((resolve) => {
          this.authSvc.initiateSentinelForgotPassword(username, anonToken)
            .subscribe((response) => {
              if (response.status === 200) {
                let lri;
                if ([
                  responseCode.sentinel.INITIATE_FORGOT_PASSWORD_FAILURE_CODE,
                  responseCode.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE
                ].some(c => c === response.body.exeCode)) {
                  lri = new LoginRespInfo(response.body.exeCode, false);
                  lri.registerErrors(response.body.exeErrorMsg);
                } else {
                  lri = new LoginRespInfo(response.body.exeCode, true);
                  lri.registerErrors(response.body.exeErrorMsg);
                }

                if (responseCode.sentinel.INITIATE_FORGOT_PASSWORD_FAILURE_CODE === response.body.exeCode) {
                  this.error = lri.hasErrors()
                    ? lri.listErrors().join("\n") : responseMessages.sentinel.INITIATE_RESET_PASSWORD_FAILURE;
                  resolve(lri);
                } else if (responseCode.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE === response.body.exeCode) {
                  this.error = lri.hasErrors()
                    ? lri.listErrors().join("\n") : responseMessages.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE;
                  resolve(lri);
                } else {
                  resolve(lri);
                }
              } else {
                resolve(new LoginRespInfo(responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE, false));
              }
            },
              (error) => {
                console.log(error);
                resolve(new LoginRespInfo(responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE, false));
              }
            );
        });

        const initiated: LoginRespInfo = await initiateForgotPasswordPromise;
        if (initiated && initiated.healthy()) {
          if (initiated.isInitiationOk()) {
            // Attempting to load the workspace
            this.error = responseMessages.sentinel.INITIATE_RESET_PASSWORD_SUCCESS;
            await this._util.sleep(3000);
            await this.router.navigate([`${this._const.KEY_FORGOT_PASSWORD_COMPLETE}`], {
              queryParams: {
                accessToken: `${anonToken}`,
                username: username
              }
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  public getEmailErrorMessage = () => {
    if (this.email.hasError("required")) {
      return "Required";
    }
    return this.email.hasError("email") ? "Not a valid email" : "";
  }

  public goHome = () => {
    const anonToken: string = this.route.snapshot.queryParams && this.route.snapshot.queryParams.accessToken
      ? this.route.snapshot.queryParams.accessToken : "";
    this.router.navigate([`${this._const.KEY_LOGIN}`], {
      queryParams: {
        accessToken: `${anonToken}`
      }
    });
  }

}
