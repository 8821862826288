import { MessageContent } from "@app/core/msg/message-content";

export class LeaseMessage {

  public sessionId: string;
  public messageType: number;
  public message: MessageContent[] = [];
  public idToken: string;
  public accessToken: string;
  public createdTimestamp: number;
  public updatedTimestamp: number;
  public createdBy: string;
  public updatedBy: string;

  public constructor () {}

  static initInbound = (msgObj: any): LeaseMessage => {
    const leaseMsg: LeaseMessage = new LeaseMessage();
    try {
      if (msgObj) {
        leaseMsg.message = (msgObj.message) ? msgObj.message as MessageContent[] : [new MessageContent("", "")];
        leaseMsg.sessionId = (msgObj.sessionId) ? msgObj.sessionId : "";
        leaseMsg.messageType = (msgObj.messageType) ? msgObj.messageType : "";
        leaseMsg.idToken = (msgObj.idToken) ? msgObj.idToken : "";
        leaseMsg.accessToken = (msgObj.accessToken) ? msgObj.accessToken : "";
        leaseMsg.createdTimestamp = (msgObj.createdTimestamp) ? msgObj.createdTimestamp : "";
        leaseMsg.updatedTimestamp = (msgObj.updatedTimestamp) ? msgObj.updatedTimestamp : "";
        leaseMsg.createdBy = (msgObj.createdBy) ? msgObj.createdBy : "";
        leaseMsg.updatedBy = (msgObj.updatedBy) ? msgObj.updatedBy : "";
      }
    } catch (e) {
      console.log(`Error in parsing the socket messages received. Error is ${e}`);
    }
    return leaseMsg;
  }

  public healthy = (): boolean => {
    return !isNaN(this.messageType)
      && !isNaN(this.createdTimestamp)
      && !isNaN(this.updatedTimestamp)
      && !!this.createdBy && !!this.updatedBy;
  }

  public isLeaseMessageType = (): boolean => {
    return this.healthy() && [33001, 33000, -204, -205, -206].some(x => x === this.messageType);
  }

  public isLeaseExtensionHealthy = (): boolean => {
    return this.isLeaseMessageType()
      && !!this.accessToken && this.accessToken.trim().length > 0
      && !!this.idToken && this.idToken.trim().length > 0;
  }

  public getCreatedBy = (): string => {
    return this.createdBy;
  }
}
