import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { AuthConstants } from "@app/core/constant/auth-constants";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { StorageService } from "@app/core/services/storage/storage.service";
import { LoginRespInfo } from "@app-auth/pages/login/login-resp-info";
import { responseCode } from "@app/core/services/endpoint-list/endpoint-resp-code";
import { responseMessages } from "@app/core/services/endpoint-list/endpoint-resp-messages";
import { CoreUtils } from "@app/core/utils/core-utils";

@Component({
  selector: "app-forgotpass-complete",
  templateUrl: "./forgotpass-complete-sentinel.component.html",
  styleUrls: ["./forgotpass-complete-sentinel.component.scss"],
})
export class ForgotpassCompleteComponent implements OnInit {

  public loading = false;
  public submitted = false;
  public confirmCodeForm: FormGroup;
  public _username = new FormControl("", [Validators.required, Validators.email]);
  public _code = new FormControl("", [Validators.required]);
  public _password = new FormControl("", [
    Validators.required,
    Validators.minLength(8),
    Validators.pattern(/[A-Z]/),
    Validators.pattern(/[a-z]/),
    Validators.pattern(/[0-9]/),
    Validators.pattern(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)
  ]);
  public error = "";
  public hidePassword = true;
  public isUpperCaseCharErr = true;
  public isLowerCaseCharErr = true;
  public isNumberCharErr = true;
  public isSpecialCharErr = true;

  private _const: AuthConstants;
  private _util: CoreUtils;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private authSvc: AuthenticationService,
    private _storage: StorageService
  ) {
    // handles the response from SSO
    this._const = new AuthConstants();
    this._util = new CoreUtils(authSvc);

    this.confirmCodeForm = this.fb.group({
      code: this._code,
      username: this._username,
      password: this._password
    }, {});
  }

  ngOnInit(): void {
    const queryParams: Params = this.route && this.route.snapshot
      && this.route.snapshot.queryParams ? this.route.snapshot.queryParams : null;
    if (queryParams && queryParams.username) {
      this.confirmCodeForm.get(this._const.USER_NAME).setValue(queryParams.username);
    }
  }

  public confirmCode = async () => {
    this.submitted = true;

    if (this.confirmCodeForm.invalid) {
      return;
    }

    this.loading = true;
    if (this.confirmCodeForm.valid) {
      try {
        const username = this.confirmCodeForm.get("username").value;
        const code = this.confirmCodeForm.get("code").value;
        const pass = this.confirmCodeForm.get("password").value;

        // TODO: Anon token is not used at the moment in this case as it is not
        // TODO: implemented in SENTINEL
        const anonToken = this.route.snapshot.queryParams && this.route.snapshot.queryParams.accessToken
          ? this.route.snapshot.queryParams.accessToken : "";

        const completeForgotPasswordPromise: Promise<LoginRespInfo> = new Promise<LoginRespInfo>((resolve) => {
          this.authSvc
            .completeSentinelForgotPassword(username, pass, code, anonToken)
            .subscribe((response) => {
              if (response.status === 200) {
                let lri;
                if ([
                  responseCode.sentinel.COMPLETE_FORGOT_PASSWORD_FAILURE_CODE,
                  responseCode.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE
                ].some(c => c === response.body.exeCode)) {
                  lri = new LoginRespInfo(response.body.exeCode, false);
                  lri.registerErrors(response.body.exeErrorMsg);
                } else {
                  lri = new LoginRespInfo(response.body.exeCode, true);
                  lri.registerErrors(response.body.exeErrorMsg);
                }

                if (responseCode.sentinel.COMPLETE_FORGOT_PASSWORD_FAILURE_CODE === response.body.exeCode) {
                  this.error = lri.hasErrors()
                    ? lri.listErrors().join("\n") : responseMessages.sentinel.COMPLETE_FORGOT_PASSWORD_FAILURE;
                  resolve(lri);
                } else if (responseCode.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE === response.body.exeCode) {
                  this.error = lri.hasErrors()
                    ? lri.listErrors().join("\n") : responseMessages.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE;
                  resolve(lri);
                } else {
                  resolve(lri);
                }
              } else {
                resolve(new LoginRespInfo(responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE, false));
              }
            },
              (error) => {
                console.log(error);
                resolve(new LoginRespInfo(responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE, false));
              });
        });

        const forgotPasswordCompleted: LoginRespInfo = await completeForgotPasswordPromise;
        if (forgotPasswordCompleted && forgotPasswordCompleted.healthy()) {
          if (forgotPasswordCompleted.isConfirmationOk()) {
            // Attempting to load the login page
            await this._util.sleep(2000);
            await this.router.navigate([`${this._const.KEY_LOGIN}`], {
              queryParams: {
                accessToken: `${anonToken}`
              }
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  public goHome = () => {
    const anonToken = this.route.snapshot.queryParams && this.route.snapshot.queryParams.accessToken
      ? this.route.snapshot.queryParams.accessToken : "";
    this.router.navigate([`${this._const.KEY_LOGIN}`], {
      queryParams: {
        accessToken: `${anonToken}`
      }
    });
  }

  public getEmailErrorMessage = () => {
    if (this._username.hasError("required")) {
      return "Required";
    }
    return this._username.hasError("email") ? "Not a valid email" : "";
  }

  public getCodeErrorMessage = () => {
    if (this._code.hasError("required")) {
      return "Required";
    }
    return this._code.hasError("code") ? "Code must be provided" : "";
  }

  public getPWDErrorMessage = (): string => {
    let errorMsg: string;
    if (this._password.hasError(this._const.REQUIRED)) {
      errorMsg = this._const.NEW_PASSWORD_MANDATORY_MSG;
    } else if ((this._password.hasError(this._const.MIN_LENGTH))
      || (this._password.hasError(this._const.PATTERN))) {
      errorMsg = "";
    } else {
      errorMsg = this._password.hasError(this._const.PASSWORD) ? this._const.INVALID_PASSWORD_ERROR_MSG : "";
    }
    return errorMsg;
  }

  public checkPwdValidity = () => {
    this.isUpperCaseCharErr = !(this._const.UPPER_CASE_CHAR_REGEX.test(this._password.value));
    this.isLowerCaseCharErr = !(this._const.LOWER_CASE_CHAR_REGEX.test(this._password.value));
    this.isNumberCharErr = !(this._const.NUMBER_CHAR_REGEX.test(this._password.value));
    this.isSpecialCharErr = !(this._const.SPECIAL_CHAR_REGEX.test(this._password.value));
  }
}
