export class MsgType {
  public readonly MT_CONNECT_TO_LC = 1;
  public readonly MT_CONVERSATION = 3;
  public readonly LEAVE = 5;
  public readonly MT_HEARTBEAT = 6;
  public readonly MT_CLOSE = 8;
  public readonly MT_TYPING = 9;
  public readonly MT_GREETING = 18;
  public readonly GUEST_PICKED_FROM_QUEUE_CONNECT = 26;
  public readonly MT_GUEST_AND_AGENT_CONNECTED = 27;
  public readonly MT_BACK_TO_LAST_KNOWN_MODE_REQUEST = 28;
  public readonly MT_BACK_TO_LAST_KNOWN_MODE_REQUEST_SUCCESS = 29;
  public readonly TRANSFER_CHAT = 31;
  public readonly MT_LC_TRANSFER_TO_ANOTHER_AGENT_SUCCESS = 32;
  public readonly GUEST_PICKED_FROM_QUEUE_TRANSFER = 43;
  public readonly MT_A2A_TRANSFER_QUEUED = 49;
  public readonly MT_CRUISE_CONTROL_REQUEST = 50;
  public readonly MT_CRUISE_CONTROL_REQUEST_SUCCESS = 51;
  public readonly MT_INTERVENTION_REQUESTED = 54;
  public readonly MT_INTERVENTION_ACCEPTED = 55;
  public readonly MT_INTERVENTION_SUCCESS = 56;

  public readonly MT_EXTEND_TOKEN_LEASE = 33000;
  public readonly MT_END_TOKEN_LEASE = 33001;

  public readonly CONNECTION_TIMED_OUT = -204;
  public readonly WEB_SOCKET_UNKNOWN_ERROR = -205;
  public readonly WEB_SOCKET_INVALID_ATTRIBUTES = -206;
  public readonly MT_NA_AGENT_TRANSFER = -12;
  public readonly MT_NO_PREVIOUS_MODE = -8;
}
