export class AssetConstant {
  public readonly ASSET_CATEGORY_LIST = [{
    DISPLAYNAME: "Icon",
    VALUE: "icon"
  },
  {
    DISPLAYNAME: "Banner",
    VALUE: "banner"
  },
  {
    DISPLAYNAME: "Profile Picture",
    VALUE: "profilePicture"
  },
  {
    DISPLAYNAME: "None",
    VALUE: "none"
  }];
  public readonly ALL = "A";
  public readonly DOCUMENT_ATTR = "document";
  public readonly VIDEO_ATTR = "video";
  public readonly IMAGE_ATTR = "image";

  public readonly ASSET_NAME = "assetName";
  public readonly ASSET_TYPE = "assetType";
  public readonly ASSET_CATEGORY = "assetCategory";
  public readonly ASSET_DISPLAY_NAME = "assetDisplayName";
  public readonly UPDATED_BY = "updatedBy";
  public readonly LAST_MODIFIED = "lastModified";
  public readonly LIBRARY = "isLibrary";

  public readonly ENTITY_TABLE_CONTENT = {
    COL_DEF: {
      ASSET_NAME: "assetName",
      ASSET_TYPE: "assetType",
      ASSET_CATEGORY: "assetCategory",
      ASSET_DISPLAY_NAME: "assetDisplayName",
      UPDATED_BY: "updatedBy",
      LAST_MODIFIED: "lastModified",
      STATUS: "status",
      LIBRARY: "isLibrary",
    },
    COL_HEADER: {
      NAME: "Name",
      TYPE: "Type",
      CATEGORY: "Category",
      DISPLAYNAME: "Display Name",
      UPDATED_BY: "Updated By",
      LAST_MODIFIED: "Last Modified",
      ASSET_STATUS: "Asset Status",
      ASSET_LIBRARY: "Library",
    }
  }

  public readonly UNDEFINED_DATA = "-";
  public readonly DATE_PIPE_MEDIUM = "medium";

  public readonly BLANK = "";
  public readonly SAVE = "save";
  public readonly ACTION = "action";
  public readonly CANCEL = "cancel";

  public readonly CREATE_ASSET_SUCCESS = "Asset created successfully";
  public readonly CREATE_ASSET_FAIL = "Unable to create Asset";
  public readonly UPDATE_ASSET_SUCCESS = "Asset updated successfully";
  public readonly UPDATE_ASSET_FAIL = "Unable to update Asset";
  public readonly DELETE_SUCCESS_MSG = "Asset deleted successfully";
  public readonly DELETE_FAILED_MSG = "Unable to delete Asset";
  public readonly DISABLE_SUCCESS_MSG = "Asset disabled successfully";
  public readonly ENABLE_SUCCESS_MSG = "Asset enabled successfully";
  public readonly DISABLE_FAILED_MSG = "Unable to disable Asset";
  public readonly ENABLE_FAILED_MSG = "Unable to enable Asset";
  public readonly GET_ASSET_DETAILS_FAIL_MSG = "Unable to get asset details";

  public readonly DISABLED = "DISABLED";
  public readonly ACTIVE = "ACTIVE";
  public readonly INACTIVE = "INACTIVE";
  public readonly SUBMITTED = "SUBMITTED";
  public readonly DISABLE_REQUESTED = "DISABLE_REQUESTED";
  public readonly DELETE_REQUESTED = "DELETE_REQUESTED";
  public readonly DELETED = "DELETED";

  public readonly ACTIVE_STATUS = "active-status";
  public readonly INACTIVE_STATUS = "inactive-status";
  public readonly SUBMIT_STATUS = "submit-status";
  public readonly DISABLE_REQUESTED_STATUS = "disable-requested-status";
  public readonly DISABLED_STATUS = "disabled-status";
  public readonly DELETE_REQUESTED_STATUS = "delete-requested-status";
  public readonly DELETED_STATUS = "deleted-status";
  public readonly OTHER_STATUS = "other-status";

  public readonly FULL_CLIENT_NAME = "fullClientName";
  public readonly UI_POLICY_MODULE_NAME_ASSET_MANAGEMENT = "asset-management";

  public readonly CREATE_ASSET_FROM_DEVICE = "createAssetFromDevice";
  public readonly CREATE_ASSET_FROM_CLOUD = "createAssetFromCloud";
  public readonly UPDATE_ASSET_FROM_DEVICE = "updateAssetFromDevice";
  public readonly UPDATE_ASSET_FROM_CLOUD = "updateAssetFromCloud";

  public readonly REQUIRED_ERROR_MSG = "You must enter a value";
  public readonly PATTERN_ERROR_MSG = "Asset name should be single word";

}