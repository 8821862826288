import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { webSocket } from "rxjs/webSocket";
import { environment } from "@environments/environment";
import { CustomerListService } from "../customer-list/customer-list.service";
import { Picked } from "@app/core/models/picked.model";
import Swal from "sweetalert2";
import { ChannelMessage } from "@app/core/msg/channel-message";
import { MsgType } from "@app/core/msg/msg-type";
import { MessageContent } from "@app/core/msg/message-content";
import { CoreUtils } from "@app/core/utils/core-utils";
import { Constants } from "@app/core/utils/const";
import { SRType } from "@app/core/msg/sr-type";
import { AuthenticationService } from "@app/core/services/authentication/authentication.service";
import { AuthToken } from "@app/core/models/auth-token";
import { StorageService } from "@app/core/services/storage/storage.service";
import { LcConstants } from "@app/live-chat/constants/lc-constants";

export const DISCONNECT = 8;
export const TYPING = 9;
export const TYPINGSOCKETTYPE = "typing";
export const SENDEROLE = "GUEST";
export const AGENTSENDEROLE = "ROLE_CC";
export const GUEST_AND_AGENT_CONNECTED = 27;
export const GREETING = 18;

@Injectable({
  providedIn: "root",
})
export class ChatService {
  public clients = [];
  public agents = [];
  public currentChannelId: string;
  public currentReceiverName: string;
  public aiResponse = new Subject();

  public mySubject = new Subject();
  public deadPanelSubject = new Subject();
  public typingEvent = new Subject();

  public ws = [];
  public wsTyping = [];
  public connectedGuests = [];
  public pickedGuests: Picked[] = [];
  public disconnected = [];
  public closedOnTransfer = false;
  public receiverAgent: string;
  public modeContext = {};
  public workflowNodeId: string;
  public currentWorkflowId: string;
  public typedData = [];
  public showDisconnectedMsg = true;
  public disconnectedGuests: Picked[] = [];

  private readonly _const: Constants;
  private readonly _lcConst: LcConstants;
  private readonly _coreUtils: CoreUtils;
  private readonly _msgTypes: MsgType;
  private readonly _srType: SRType;
  private readonly _storage: StorageService;

  constructor(
    public customerListService: CustomerListService,
    public readonly authenticationService: AuthenticationService
  ) {
    this.customerListService.getHeaderData().subscribe((response) => {
      if (response.channelId !== undefined) {
        this.currentReceiverName = response.senderName;
        this.currentChannelId = response.channelId;
      }
    });
    this._coreUtils = new CoreUtils();
    this._const = new Constants();
    this._lcConst = new LcConstants();
    this._msgTypes = new MsgType();
    this._srType = new SRType();
    this._storage = new StorageService();
  }

  pickInQGuest = (inQGuest) => {
    console.log(`Picking user ${inQGuest.channelId}`);
    const chn = inQGuest.channelId;
    if (this.connectedGuests.indexOf(chn) === -1) {
      this.connectedGuests.push(chn);
      const pCfg = {
        _chn: chn,
        _clt: this.customerListService.clientId,
        _us: inQGuest.userSessionUuid,
        _erf: inQGuest.episodeReferenceId,
        _modes: inQGuest.modeContext,
        _wfNodeId: inQGuest.workflowNodeId,
        _wId: inQGuest.currentWorkflowId,
        _accessToken: inQGuest.accessToken
      };
      this.pickedGuests.push(new Picked(pCfg));

      // web-socket headers
      const jwtToken: AuthToken = this.authenticationService.getStoredAuthToken();
      const idToken: string = !!jwtToken ? jwtToken.idToken : "";
      const accessToken: string = !!jwtToken ? jwtToken.accessToken : "";
      const fp: string = !!this._storage.storedFp() ? this._storage.storedFp() : "";
      const adi: string = !!this._storage.storedAdi() ? this._storage.storedAdi() : "";

      // Limitation in cognito pools when connected with SSO in Azure, user-names are padded with #
      // Those are not allowed to be used in socket-urls
      const normalizedUserName: string = this.customerListService.agentLoggedIn.userName
        .replace("#", "~")
        .replace("#", "~");
      const socketUrlTokens: string[] = [
        `${environment.webSocketBaseUrl}/pickqueued?cluster=${this.customerListService.cluster}`,
        `channelId=${chn}`,
        `eprId=${inQGuest.episodeReferenceId}`,
        `usId=${inQGuest.userSessionUuid}`,
        `fp=${fp}`,
        `adi=${adi}`,
        `id=${idToken}`,
        `acc=${accessToken}`
      ];
      this.ws[chn] = webSocket({
        url: `${socketUrlTokens.join("&")}`,
        closeObserver: {
          next: (e: CloseEvent) => {
            this.disconnected[chn] = true;
            this.closedOnTransfer = (e.code === 4101);
            console.log(`Websocket closed for channel ${chn} ${e.reason}`);
            this.connectedGuests.splice(this.connectedGuests.indexOf(chn), 1);
            this.deletePicked(chn);
            this.customerListService.showOffline(chn);
            this.customerListService.sendHeaderData({ message: e.reason });
          },
        },
      });
      this.disconnected[chn] = false;
      setInterval(() => this.ws[chn].next(this._coreUtils.generateHeartbeatMsg(chn)), 59000);
    }
    return this.ws[chn].asObservable();
  }

  public sendPicked = (chnMsg: ChannelMessage) => {
    console.log(`sending picked msg ${chnMsg.channelId}`);
    const msgType = chnMsg.messageType === this._msgTypes.MT_CONNECT_TO_LC
      ? this._msgTypes.GUEST_PICKED_FROM_QUEUE_CONNECT : this._msgTypes.GUEST_PICKED_FROM_QUEUE_TRANSFER;
    const dummyIncoming = new ChannelMessage();
    const ctrlMsg: ChannelMessage = this._coreUtils.outboundChannelMessage(
      dummyIncoming,
      msgType,
      {
        authToken: "",
        senderRole: this._srType.SR_AGENT.toLowerCase(),
        message: [new MessageContent(
          this._const.MSG_GUEST_CONNECTED_WITH_AGENT,
          this._const.MSG_GUEST_CONNECTED_WITH_AGENT
        )],
        channelId: chnMsg.channelId,
        clientId: chnMsg.clientId,
        userSessionUuid: chnMsg.userSessionUuid,
        episodeReferenceId: chnMsg.episodeReferenceId,
        receiverName: "",
        type: this._srType.SR_AGENT.toLowerCase(),
        accessToken: chnMsg.accessToken,
        guestId: chnMsg.guestId,
        modeContext: chnMsg.modeContext,
        defaultDepartment: chnMsg.defaultDepartment ? chnMsg.defaultDepartment : null,
        linkedDepartments: chnMsg.linkedDepartments ? chnMsg.linkedDepartments : [],
        responderMode: this._const._LC,
        senderName: this.customerListService.agentLoggedIn.userName,
        agentUserUUId: this.customerListService.agentLoggedIn.cognitoUserId,
        senderDisplayName: this.customerListService.agentLoggedIn.firstName
          ? this.customerListService.agentLoggedIn.firstName
          : this.customerListService.agentLoggedIn.alias,
        agentName: this.customerListService.agentLoggedIn.firstName
          ? this.customerListService.agentLoggedIn.firstName
          : this.customerListService.agentLoggedIn.alias
      });
    this.ws[chnMsg.channelId].next(ctrlMsg);
  }

  public pickInQTyping = (chn: string) => {
    // Limitation in cognito pools when connected with SSO in Azure, user-names are padded with #
    // Those are not allowed to be used in socket-urls
    const normalizedUserName: string = this.customerListService.agentLoggedIn.userName
      .replace("#", "~")
      .replace("#", "~");

    // web-socket headers
    const jwtToken: AuthToken = this.authenticationService.getStoredAuthToken();
    const idToken: string = !!jwtToken ? jwtToken.idToken : "";
    const accessToken: string = !!jwtToken ? jwtToken.accessToken : "";

    const socketUrlTokens: string[] = [
      `${environment.webSocketBaseUrl}/${TYPINGSOCKETTYPE}?username=${normalizedUserName}`,
      `clientId=${this.customerListService.clientId}`,
      `channelId=${chn}`,
      `userId=${this.customerListService.agentLoggedIn.cognitoUserId}`,
      `cluster=${this.customerListService.cluster}`
    ];
    this.wsTyping[chn] = webSocket({
      url: `${socketUrlTokens.join("&")}`,
      closeObserver: {
        next: (e: CloseEvent) => {
          console.log(`Typing Websocket closed for channel ${chn} ${e.reason}`);
        },
      },
    });

    setInterval(() => this.wsTyping[chn].next(this._coreUtils.generateHeartbeatMsg(chn)), 10000);
    return this.wsTyping[chn].asObservable();
  }

  public guestMessageSubject = (): Observable<any> => {
    return this.mySubject.asObservable();
  }

  public getTypingEvents = (): Observable<any> => {
    return this.typingEvent.asObservable();
  }

  public deadPanel = (): Observable<any> => {
    return this.deadPanelSubject.asObservable();
  }

  public disconnect = (channelId: string, reasonCode: number) => {

    console.log(`Chat-service disconnect for channel ${channelId}, with code ${reasonCode}`);

    let effReason = "";
    if (reasonCode === this._lcConst.REASON_CODE_AGENT_OFFLINE_ALL_DEVICES) {
      effReason = this._lcConst.REASON_DESC_AGENT_OFFLINE_ALL_DEVICES;
    } else if (reasonCode === this._lcConst.REASON_CODE_AGENT_OFFLINE_THIS_DEVICE) {
      effReason = this._lcConst.REASON_DESC_AGENT_OFFLINE_THIS_DEVICE;
    } else {
      effReason = this._lcConst.CHAT_CLOSED;
    }

    this.ws[channelId].error({ code: reasonCode, reason: effReason });
    this.wsTyping[channelId].error({ code: reasonCode, reason: effReason });
    this.getDisconnectedGuest(channelId);
    this.deletePicked(channelId);

    this.connectedGuests.splice(this.connectedGuests.indexOf(channelId), 1);
    this.deadPanelSubject.next(channelId);
    this.streamConversation(channelId);
    this.customerListService.activeIds = [];
    this.customerListService.removeInteracted(channelId);
  }

  public getDisconnectedGuest = (channelId: string) => {
    const disconnectedGuest: Picked[] = this.pickedGuests.filter(p => p.channel() === channelId);
    this.disconnectedGuests = this.disconnectedGuests.concat(disconnectedGuest);
  }

  public streamConversation = (channelId: string) => {
    const disconnectedGuest: Picked = this.alreadyDisconnectedGuest(channelId);
    this.mySubject.next(disconnectedGuest.streamConversation());
  }

  public onWsMessage = (message: ChannelMessage) => {
    if (message.messageType === this._msgTypes.MT_GREETING) {
      this.handleChat(message);
    } else if (message.messageType === this._msgTypes.MT_CONVERSATION) {
      this.handleChat(message);
    } else if (message.messageType === this._msgTypes.MT_TYPING) {
      this.handleSneakPeak(message);
    } else if (message.messageType === this._msgTypes.MT_CLOSE) {
      this.onCloseChat(message);
    } else if (
      message.messageType === this._msgTypes.MT_BACK_TO_LAST_KNOWN_MODE_REQUEST_SUCCESS
      || message.messageType === this._msgTypes.MT_LC_TRANSFER_TO_ANOTHER_AGENT_SUCCESS
    ) {
      this.onTransferChatSuccess(message);
    } else if (
      message.messageType === this._msgTypes.MT_BACK_TO_LAST_KNOWN_MODE_REQUEST
      || message.messageType === this._msgTypes.MT_CRUISE_CONTROL_REQUEST
    ) {
      this.backToModeOrCruiseControlReq(message);
    } else if (message.messageType === this._msgTypes.MT_CRUISE_CONTROL_REQUEST_SUCCESS) {
      this.onCruiseControlSuccess(message);
    } else if (message.messageType === this._msgTypes.MT_NA_AGENT_TRANSFER) {
      this.showErrorMsg(this._const.TRANSFER_NOT_PERMITTED);
    } else if (message.messageType === this._msgTypes.MT_NO_PREVIOUS_MODE) {
      this.showErrorMsg(message.message[0].displayText);
    } else if (message.messageType === this._msgTypes.MT_INTERVENTION_ACCEPTED) {
      this.onInterventionAcceptance(message);
    } else {
      if (message.messageType === this._msgTypes.MT_GUEST_AND_AGENT_CONNECTED) {
        message.senderName = this.customerListService.agentLoggedIn.userName;
        this.customerListService.guests.forEach((guestMsg: ChannelMessage) => {
          if (guestMsg.channelId === message.channelId) {
            guestMsg.isConnected = true;
          }
        });
      }
      if (this.ws[message.channelId]) {
        this.ws[message.channelId].next(message);
      }
    }
    const currentlyActivePick: Picked = this.alreadyPicked(this.currentChannelId);
    const disconnectedGuest: Picked = this.alreadyDisconnectedGuest(this.currentChannelId);
    const conversation: object[] = currentlyActivePick ? currentlyActivePick.streamConversation() : disconnectedGuest ? disconnectedGuest.streamConversation() : [];
    this.mySubject.next(conversation);
  }

  private onTransferChatSuccess = (message: ChannelMessage) => {
    this.disconnected[message.channelId] = true;
    this.connectedGuests.splice(this.connectedGuests.indexOf(message.channelId), 1);
    this.getDisconnectedGuest(message.channelId);
    this.deletePicked(message.channelId);
    this.connectedGuests.splice(this.connectedGuests.indexOf(message.channelId), 1);
    this.customerListService.showOffline(message.channelId);
    this.streamConversation(message.channelId);
    this.customerListService.sendHeaderData(message);
    this.customerListService.guests.forEach((guestMsg: ChannelMessage) => {
      if (guestMsg.channelId === message.channelId) {
        guestMsg.isConnected = false;
      }
    });
  }

  private onCloseChat = (message: ChannelMessage) => {
    this.disconnected[message.channelId] = true;
    this.connectedGuests.splice(this.connectedGuests.indexOf(message.channelId), 1);
    this.getDisconnectedGuest(message.channelId);
    this.deletePicked(message.channelId);
    this.connectedGuests.splice(this.connectedGuests.indexOf(message.channelId), 1);
    this.customerListService.showOffline(message.channelId);
    this.streamConversation(message.channelId);
    this.customerListService.sendHeaderData(message);
    this.customerListService.guests.forEach((guestMsg: ChannelMessage) => {
      if (guestMsg.channelId === message.channelId) {
        guestMsg.isConnected = false;
      }
    });
  }

  private backToModeOrCruiseControlReq = (message: ChannelMessage) => {
    const picked: Picked = this.alreadyPicked(message.channelId);
    const modes: string[] = picked ? picked.preModes() : [];
    const msg: object = {
      ...message,
      ...{
        modeContext: modes,
        currentWorkflowId: picked.workflowId(),
        workflowNodeId: picked.workflowNode(),
        accessToken: picked.accessTkn()
      }
    };
    this.ws[message.channelId].next(msg);
  }

  private onCruiseControlSuccess = (message: ChannelMessage) => {
    const cruiseMsg = this._msgTypes.MT_CRUISE_CONTROL_REQUEST_SUCCESS.toString();
    this.customerListService.sendHeaderData({ message: [new MessageContent(cruiseMsg, cruiseMsg)] });
    this.customerListService.onCruiseControl(message.channelId);
    this.showInfoMsg(this._const.MSG_ON_CRUISE_CONTROL_AND_INTERVENE_REQ);
  }

  private onInterventionAcceptance = (message: ChannelMessage) => {
    const interveneMsg: string = this._msgTypes.MT_INTERVENTION_ACCEPTED.toString();
    this._coreUtils.popUpWithNoOkBtn(this._const.MSG_ON_CRUISE_CONTROL_AND_INTERVENE_ACCEPTANCE);
    this.customerListService.sendHeaderData({ message: [new MessageContent(interveneMsg, interveneMsg)] });
    this.customerListService.onInterveneSucc(message.channelId);
  }

  public showErrorMsg = (displayText: string) => {
    Swal.fire({
      icon: "error",
      text: displayText,
    });
  }

  public showInfoMsg = (displayText: string) => {
    Swal.fire({
      icon: this._const.INFO,
      text: displayText,
      showConfirmButton: false,
      timer: 3000
    });
  }

  public disconnectAllGuestChats = (stateCode: number) => {
    // when agent goes offline the guests must be individual disconnected so that
    // appropriate error codes are sent to respective FE guest
    const effCode: number = (stateCode === 1)
      ? this._lcConst.REASON_CODE_AGENT_OFFLINE_THIS_DEVICE : this._lcConst.REASON_CODE_AGENT_OFFLINE_ALL_DEVICES;

    this.pickedGuests.forEach(p => p.clearConversation());
    this.pickedGuests.forEach(pkd => {
      this.disconnect(pkd.channel(), effCode);
    });
    this.connectedGuests = [];
    this.pickedGuests = [];
    if (this.disconnectedGuests.length > 0) {
      this.disconnectedGuests.forEach(p => p.clearConversation());
    }
    this.disconnectedGuests = [];
  }

  public shouldStreamMessages = (chn: string): boolean => {
    return chn && (this.connectedGuests.indexOf(chn) === -1)
      && (this.disconnected[chn] === undefined || this.disconnected[chn] === false);
  };

  public deletePicked = (chn: string) => {
    if (chn) {
      this.pickedGuests = this.pickedGuests.filter(p => p.channel() !== chn);
    }
  };

  public alreadyPicked = (chn: string): Picked => {
    let picked;
    if (chn) {
      const availableInPicked = this.pickedGuests.filter(p => p.channel() === chn);
      picked = (availableInPicked && availableInPicked.length > 0) ? availableInPicked[0] : undefined;
    }
    return picked;
  }

  public alreadyDisconnectedGuest = (channel: string): Picked => {
    let disconnectedGuest: Picked;
    if (channel) {
      const disconnectedGuests = this.disconnectedGuests.filter(guest => guest.channel() === channel);
      disconnectedGuest = (disconnectedGuests && disconnectedGuests.length > 0) ? disconnectedGuests[0] : null;
    }
    return disconnectedGuest;
  }

  public emptyAllGuests = () => {
    this.pickedGuests = [];
    this.disconnectedGuests = [];
    this.disconnected = [];
    this.customerListService.timeoutGuests = [];
  }

  private handleChat = (message) => {
    // find the appropriate picked user
    const pickedGuest = this.alreadyPicked(message.channelId);
    if (pickedGuest) {
      // enrich message with episode reference of picked guest
      message = {
        ...message,
        ...{
          episodeReferenceId: pickedGuest.sessionEpisode()
        }
      }
      switch (message.senderRole) {
        case this._srType.SR_GUEST:
          this.handleSenderGuest(message, pickedGuest);
          break;
        case this._srType.SR_LC:
          this.handleSenderAgent(message, pickedGuest);
          break;
        case this._srType.SR_AI:
          this.handleSenderAI(message, pickedGuest);
          break;
        case this._srType.SR_WF:
          this.handleSenderWF(message, pickedGuest);
          break;
      }
      // also trigger unread count reset
      this.customerListService.refreshAllGuestUnreadCount(this.pickedGuests);
    }
  }

  private handleSenderGuest = (message: any, pickedGuest: Picked) => {
    console.log(`Handling sender GUEST`);
    message = {
      ...message,
      ...{
        time: new Date(),
        unread: (message.channelId !== this.currentChannelId)
      }
    };
    console.log(`Am I unread ? ${message.unread}`);
    if (message.channelId === this.currentChannelId) {
      this.aiResponse.next(message);
    }
    pickedGuest.enrichConversation(message);
  }

  private handleSenderAgent = (message: any, pickedGuest: Picked) => {
    console.log(`Handling sender Agent`);
    if (message.isNew === true) {
      this.ws[message.channelId].next(message);
    } else {
      pickedGuest.enrichConversation(message);
    }
  }

  private handleSenderAI = (message: any, pickedGuest: Picked) => {
    console.log(`Handling sender AI`);
    pickedGuest.enrichConversation(message);
  }

  private handleSenderWF = (message: any, pickedGuest: Picked) => {
    console.log(`Handling sender WF`);
    if (message.message.length > 0) {
      pickedGuest.enrichConversation(message);
    } else if (message.workflowMessage.length > 0) {
      let question;
      message.workflowMessage.forEach(element => {
        const wfData = JSON.parse(element);
        if (wfData.question) {
          question = wfData.question;
        }
      });
      if (question && question.length > 0) {
        message.message = [{ displayText: question, value: question }];
        pickedGuest.enrichConversation(message);
      }
    }
  }

  private handleSneakPeak = (message: ChannelMessage) => {
    switch (message.senderRole) {
      case this._srType.SR_GUEST:
        this.typingEvent.next(message);
        break;
      case this._srType.SR_LC:
        if (message.isNew === true) {
          this.wsTyping[message.channelId].next(message);
        }
        break;
    }
  }
}
