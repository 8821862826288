import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { AnalyticsConstant } from "@app/analytics/constants/analytics.constant";
import { ClickedTypedCount } from "@app/analytics/types/clicked-typed-count";
import { LikedDislikedCount } from "@app/analytics/types/liked-disliked-count";
import * as d3 from "d3";

@Component({
  selector: "app-donutchart",
  templateUrl: "./donutchart.component.html",
  styleUrls: ["./donutchart.component.scss"]
})
export class DonutchartComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild("chart") public chartContainer: ElementRef;
  @Input() public data: LikedDislikedCount | ClickedTypedCount;
  @Input() public innerRadius: number;
  @Input() public label: string;
  public _analyticsConst: AnalyticsConstant;

  constructor() {
    this._analyticsConst = new AnalyticsConstant();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.chartContainer && this.data) {
      this.drawChart();
    }
  }

  ngOnChanges() {
    if (this.chartContainer && this.data) {
      this.drawChart();
    }
  }

  public drawChart = () => {
    let width = 260;
    let height = 260;
    let margin = 40;
    let radius: number = Math.min(width, height) / 2 - margin;
    let element: HTMLDivElement = this.chartContainer.nativeElement;

    d3.select(element).selectAll("svg").remove();
    let svg = (d3 as any).select(element)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")
      .attr("class", "area");

    let color = d3.scaleOrdinal()
      .domain(Object.keys(this.data))
      .range(<string[]>["#E6AAAA", "#CFDDF2"]);

    let pie = (d3 as any).pie().value(d => { return d.value });
    let dataReady = pie(d3.entries(this.data));

    svg
      .selectAll("area")
      .data(dataReady)
      .enter()
      .append("path")
      .attr("d", d3.arc()
        .innerRadius(this.innerRadius)
        .outerRadius(radius))
      .attr("fill", (d) => { return (color(d.data.key)) })
      .attr("stroke", "#e37d7d")
      .style("stroke-width", "2px")
      .style("opacity", 0.7);

    const labelLocation = d3
      .arc()
      .innerRadius(50)
      .outerRadius(radius);

    svg
      .selectAll("area")
      .data(dataReady)
      .enter()
      .append('text')
      .style('font-size', 12)
      .attr("transform", d => "translate(" + labelLocation.centroid(d) + ")")
      .style('text-anchor', 'middle')
      .text((d) => { return d.data.value; })
      .style('text-anchor', (d) => {
        let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? "start" : "end";
      });

    let tooltip = d3.select(element)
      .append("div")
      .classed("chart-tooltip", true)
      .style("display", "none")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "5px")
      .style("position", "absolute");

    svg
      .on("mouseover", () => {
        d3.select(".chart-tooltip").style("display", "block");
      })
      .on("mouseout", () => {
        d3.select(".chart-tooltip").style("display", "none");
      })
      .on("mousemove", () => {
        if (d3 && d3.event && d3.event.srcElement
          && d3.event.srcElement.__data__
          && d3.event.srcElement.__data__.data
          && d3.event.srcElement.__data__.data.key
          && d3.event.srcElement.__data__.data.value) {
          let html = "";
          if (this.label === this._analyticsConst.LIKED_DISLIKED_COUNT) {
            const key: string = this.getKey(d3.event.srcElement.__data__.data.key);
            html = `${key} : ${d3.event.srcElement.__data__.data.value}`;
          } else {
            html = `${d3.event.srcElement.__data__.data.key} : ${d3.event.srcElement.__data__.data.value}`;
          }
          d3.select(".chart-tooltip")
            .style("left", d3.event.pageX - 100 + "px")
            .style("top", d3.event.pageY - 200 + "px")
            .html(html.trim());
        } else {
          d3.select(".chart-tooltip").style("display", "none");
        }
      });
  }

  private getKey = (chartkey: number): string => {
    let key = "";
    if (chartkey === 400001) {
      key = this._analyticsConst.LIKED;
    } else if (chartkey === 400002) {
      key = this._analyticsConst.DISLIKED;
    } else {
      key = this._analyticsConst.NEUTRAL;
    }
    return key;
  }
}