import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { StorageService } from "@app/core/services/storage/storage.service";
import { AuthToken } from "@app/core/models/auth-token";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private _storage: StorageService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let jwtToken: AuthToken;
    const tokenString = this._storage.storedJWTTokens();
    if (tokenString) {
      jwtToken = JSON.parse(tokenString);
    }
    if (jwtToken) {
      request = request.clone({
        setHeaders: {
          "Cache-Control": "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
          Accept: "application/json",
          "X-Authorization": `${jwtToken.idToken}`,
          "X-Authorization-Access": `${jwtToken.accessToken}`,
          accessToken: `${jwtToken.accessToken}`
        },
      });
    }
    return next.handle(request);
  }
}
