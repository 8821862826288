import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Constants } from "@app/core/utils/const";

@Component({
  selector: "app-video-call",
  templateUrl: "./video-call.component.html",
  styleUrls: ["./video-call.component.scss"]
})

export class VideoCallComponent implements OnInit {
  public videoCallUrl: SafeResourceUrl;
  private _const: Constants;

  constructor(private sanitizer: DomSanitizer) {
    this._const = new Constants();
   }

  ngOnInit(): void {
    this.videoCallUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this._const.TWILIO_PASSCODE_URL);
   }
}