import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Constants } from "@app/core/utils/const";
import { CKEditor4 } from "ckeditor4-angular";
import * as ace from "ace-builds";

@Component({
  selector: "app-text-content",
  templateUrl: "./text-content.component.html",
  styleUrls: ["./text-content.component.scss"]
})
export class TextContentComponent implements OnInit {

  private _originalClose: Function;
  public textContent: string;
  public editorConfig: CKEditor4.Config;
  public mode: string;
  private _cnst: Constants;

  constructor(
    public buttonbox: MatDialogRef<TextContentComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {
    this._originalClose = this.buttonbox.close;
    this.buttonbox.close = this.submit.bind(this);
    this._cnst = new Constants();
    this.editorConfig = this.getEditorConfigWithHtml();
   }

  ngOnInit(): void {
    this.textContent = this.dialogData.textContent;
    this.mode = this.dialogData.mode;
  }

  public submit = (type: string) => {
    const submittedData = type === this._cnst.SAVE ? { event: this._cnst.SUBMIT, data: this.textContent } : {event: this._cnst.CANCEL}
    this._originalClose.bind(this.buttonbox)(submittedData);
  }

  public getEditorConfigWithHtml = (): CKEditor4.Config => {
    const editorConfig: CKEditor4.Config = {
      fullPage: true,
      extraPlugins: "docprops",
      height: "100%",
      allowedContent: true,
      title: false,
      versionCheck: false,
      toolbar: [
        { name: "styles", items: [ "Source"] },
      ]
    };
    return editorConfig;
  }
}
