export class LoginRespInfo {

  private readonly code: number;
  private readonly ok: boolean = false;
  private errors: string [] = [];
  private sessionIds: string [] = [];

  constructor(c: number, ok: boolean) {
    this.code = c;
    this.ok = ok;
  }

  public healthy = () => {
    return this.code !== undefined && this.ok !== undefined;
  }

  public registerErrors = (errors: string[]) => {
    const cleanErrors: string [] = [];
    if (errors && errors.length > 0) {
      errors.forEach(e => {
        if (!!e) {
          cleanErrors.push(e.trim());
        }
      });
    }
    this.errors.push(...cleanErrors);
  }

  public registerSessions = (sessions: string[]) => {
    if (sessions && sessions.length > 0) {
      this.sessionIds.push(...sessions);
    }
  }

  public hasErrors = (): boolean => {
    return this.errors && this.errors.length > 0;
  }

  public listErrors = (): string [] => {
    return this.errors;
  }

  public withCode = (): number => {
    return this.code;
  }

  public withChallengeToken = (): string => {
    return this.sessionIds && this.sessionIds.length > 0 ? this.sessionIds[0] : "";
  }

  public isLoginOk = (): boolean => {
    return this.ok;
  }

  public isConfirmationOk = (): boolean => {
    return this.ok;
  }

  public isInitiationOk = (): boolean => {
    return this.ok;
  }

  public isResetPwdOk = (): boolean => {
    return this.ok;
  }

  public isForcedResetPwdOk = (): boolean => {
    return this.ok;
  }

  public isMfaOk = (): boolean => {
    return this.ok;
  }

  public isResendOTPOk = (): boolean => {
    return this.ok;
  }
}
