import { Injectable } from "@angular/core";
import { ClientListRequest } from "@app/client-management/types/client-list-request";

@Injectable({
  providedIn: "root"
})
export class StorageService {

  constructor() { }

  public cleanStorage() {
    localStorage.clear();
  }

  public clearItems (itemKeys: string[]) {
    if (itemKeys && itemKeys.length > 0) {
      itemKeys.forEach(ik => {
        if (ik) {
          localStorage.removeItem(ik);
        }
      })
    }
  }

  // STORE into storage ========================================
  // store keys and values in local storage ====================
  // ===========================================================

  public storeFp = (fp: string) => {
    if (!!fp && fp.trim().length > 0) {
      localStorage.setItem("fp", fp);
    }
  }

  public storeAgentStatus = (agentStatus) => {
    localStorage.setItem("agentStatus", agentStatus);
  }

  public storeAdi = (adi: string) => {
    if (!!adi && adi.trim().length >= 16) {
      localStorage.setItem("adi", adi);
    }
  }

  public storeJWTTokens = (jwtTokensJson) => {
    localStorage.setItem("tokens", jwtTokensJson);
  }

  public storeClientId = (clientId) => {
    localStorage.setItem("clientId", clientId);
  }

  public storeCurrentSelectedUser = (cUser) => {
    localStorage.setItem("currentSelectUser", cUser);
  }

  public storeLogOutUrl = (logOutUrl) => {
    localStorage.setItem("logoutUrl", logOutUrl);
  }

  public storeNLPId = (nlpId) => {
    localStorage.setItem("nlpId", nlpId);
  }

  public storeCCPUrl = (ccpUrl) => {
    localStorage.setItem("ccpUrl", ccpUrl);
  }

  public storeHasTelephonyAccess = (hasAccess) => {
    localStorage.setItem("telephonyAccess", hasAccess);
  }

  public storeConnectUrl = (connectUrl) => {
    localStorage.setItem("connectUrl", connectUrl);
  }

  public storeIsEnableRequested = (isEnableRequested) => {
    localStorage.setItem("isEnableRequested", isEnableRequested);
  }

  public storeRecentColors = (recentColors: string[]) => {
    try {
      localStorage.setItem("recentColors", JSON.stringify(recentColors));
    } catch (error) {
      console.log(`Error in storing recent colors. Error is ${error}`);
    }
  }

  public storeSelectedTriggerTypes = (triggerTypes: string[]) => {
    try {
      localStorage.setItem("selectedTriggerTypes", JSON.stringify(triggerTypes));
    } catch (e) {
      console.log(`Error in storing selected-trigger-types. Error is ${e}`);
    }
  }

  public storeSelectedApiTypes = (apiTypes: string[]) => {
    try {
      localStorage.setItem("selectedApiTypes", JSON.stringify(apiTypes));
    } catch (e) {
      console.log(`Error in storing selected-api-types. Error is ${e}`);
    }
  }

  public storeSelectedTRGTabIndex = (trgTabIndex: number) => {
    try {
      if (trgTabIndex || trgTabIndex === 0) {
        localStorage.setItem("selectedTRGTabIndex", trgTabIndex.toString());
      }
    } catch (e) {
      console.log(`Error in storing selected trigger tab index. Error is ${e}`);
    }
  }

  public storeSelectedBHVTabIndex = (bhvTabIndex: number) => {
    try {
      if (bhvTabIndex || bhvTabIndex === 0) {
        localStorage.setItem("selectedBHVTabIndex", bhvTabIndex.toString());
      }
    } catch (e) {
      console.log(`Error in storing selected behaviour tab index. Error is ${e}`);
    }
  }

  public storeSelectedClientId = (clientId: number) => {
    try {
      localStorage.setItem("selectedClientId", clientId ? clientId.toString() : null);
    } catch (e) {
      console.log(`Error in storing selected clientId. Error is ${e}`);
    }
  }

  public storeClientNameSearched = (clientName: string) => {
    try {
      localStorage.setItem("clientNameSearched", clientName ? clientName : "");
    } catch (e) {
      console.log(`Error in storing client name searched. Error is ${e}`);
    }
  }

  public storeTriggerNameSearched = (trgName: string) => {
    try {
      localStorage.setItem("triggerNameSearched", trgName ? trgName : "");
    } catch (e) {
      console.log(`Error in storing trigger name searched. Error is ${e}`);
    }
  }

  public storeBhvNameSearched = (bhvName: string) => {
    try {
      localStorage.setItem("bhvNameSearched", bhvName ? bhvName : "");
    } catch (e) {
      console.log(`Error in storing Behaviour name searched. Error is ${e}`);
    }
  }

  public storeSelectedAssetTabIndex = (assetTabIndex: number) => {
    try {
      if (assetTabIndex || assetTabIndex === 0) {
        localStorage.setItem("selectedAssetTabIndex", assetTabIndex.toString());
      }
    } catch (e) {
      console.log(`Error in storing selected asset tab index. Error is ${e}`);
    }
  }

  public storeAssetNameSearched = (assetName: string) => {
    try {
      localStorage.setItem("assetNameSearched", assetName ? assetName : "");
    } catch (e) {
      console.log(`Error in storing asset name searched. Error is ${e}`);
    }
  }

  public storeSelectedWFTabIndex = (wfTabIndex: number) => {
    try {
      if (wfTabIndex || wfTabIndex === 0) {
        localStorage.setItem("selectedWFTabIndex", wfTabIndex.toString());
      }
    } catch (e) {
      console.log(`Error in storing selected Journey tab index. Error is ${e}`);
    }
  }

  public storeCMSAssetTabIndex = (assetTabIndex: number) => {
    try {
      if (assetTabIndex || assetTabIndex === 0) {
        localStorage.setItem("cmsAssetTabIndex", assetTabIndex.toString());
      }
    } catch (e) {
      console.log(`Error in storing selected asset tab index. Error is ${e}`);
    }
  }

  public storeClientListRequest = (clientListReq: ClientListRequest) => {
    try {
      localStorage.setItem("clientListRequest", JSON.stringify(clientListReq));
    } catch (e) {
      console.log(`Error in storing client list request. Error is ${e}`);
    }
  }

  public storeSelectedClientTypes = (clientTypes: string[]) => {
    try {
      localStorage.setItem("selectedClientTypes", clientTypes.toString());
    } catch (e) {
      console.log(`Error in storing selected client types. Error is ${e}`);
    }
  }

  public storeWorkflowNameSearched = (wfName: string) => {
    try {
      localStorage.setItem("workflowNameSearched", wfName ? wfName : "");
    } catch (e) {
      console.log(`Error in storing workflow name searched. Error is ${e}`);
    }
  }

  public storeSelectedNLPTabIndex = (tabIndex: number) => {
    try {
      if (tabIndex || tabIndex === 0) {
        localStorage.setItem("selectedNLPTabIndex", tabIndex.toString());
      }
    } catch (e) {
      console.log(`Error in storing selected NLP tab index. Error is ${e}`);
    }
  }

  public storeDesiredClientName = (clientName: string) => {
    try {
      localStorage.setItem("desiredClientName", clientName ? clientName : "");
    } catch (e) {
      console.log(`Error in storing desired client name. Error is ${e}`);
    }
  }

  public storeNLPNameSearched = (nlpName: string) => {
    try {
      localStorage.setItem("nlpNameSearched", nlpName ? nlpName : "");
    } catch (e) {
      console.log(`Error in storing nlp name searched. Error is ${e}`);
    }
  }

  public storeApiDefNameSearched = (apiDefName: string) => {
    try {
      localStorage.setItem("apiDefNameSearched", apiDefName ? apiDefName : "");
    } catch (e) {
      console.log(`Error in storing api def name searched. Error is ${e}`);
    }
  }

  public storeReconnectCounter = (reconnectCounter: number) => {
    try {
      localStorage.setItem("reconnectCounter", JSON.stringify(reconnectCounter));
    } catch (error) {
      console.log(`Error in storing reconnectCounter. Error is ${error}`);
    }
  }

  // stored items
  public storedFp = (): string => {
    return localStorage.getItem("fp");
  }

  public storedAgentStatus = (): string => {
    return localStorage.getItem("agentStatus");
  }

  public storedAdi = (): string => {
    return localStorage.getItem("adi");
  }

  public storedJWTTokens = (): string => {
    return localStorage.getItem("tokens");
  }

  public storedClientId = (): string => {
    return localStorage.getItem("clientId");
  }

  public storedCurrentUser = (): string => {
    return localStorage.getItem("currentUser");
  }

  public storedNLPId = (): string => {
    return localStorage.getItem("nlpId");
  }

  public storedCCPUrl = (): string => {
    return localStorage.getItem("ccpUrl");
  }

  public storedHasTelephonyAccess = (): string => {
    return localStorage.getItem("telephonyAccess");
  }

  public storedConnectUrl = (): string =>  {
    return localStorage.getItem("connectUrl");
  }

  public storedIsEnableRequested = (): string => {
    return localStorage.getItem("isEnableRequested");
  }

  public storedSelectedTriggerTypes = (): string[] => {
    let triggerTypes: string[] = [];
    try {
      const _triggerType: string = localStorage.getItem("selectedTriggerTypes");
      triggerTypes = JSON.parse(_triggerType);
    } catch (e) {
      console.log(`Error in getting selected-trigger-types from local storage. Error is ${e}`);
    }
    return (triggerTypes && triggerTypes.length > 0) ? triggerTypes : [];
  }

  public storedSelectedApiTypes = (): string[] => {
    let apiTypes: string[] = [];
    try {
      const _apiType: string = localStorage.getItem("selectedApiTypes");
      apiTypes = JSON.parse(_apiType);
    } catch (e) {
      console.log(`Error in getting selected-api-types from local storage. Error is ${e}`);
    }
    return (apiTypes && apiTypes.length > 0) ? apiTypes : [];
  }

  public storedWorkflowNameSearched = (): string => {
    let wfName = "";
    try {
      const wfNameSearched: string = localStorage.getItem("workflowNameSearched");
      wfName = wfNameSearched ? wfNameSearched : "";
    } catch (e) {
      console.log(`Error in getting workflow name searched from local storage. Error is ${e}`);
    }
    return wfName;
  }

  public storedSelectedClientTypes = (): string[] => {
    let clientTypes: string[] = [];
    try {
      const _clientType: string = localStorage.getItem("selectedClientTypes");
      clientTypes = _clientType ? _clientType.split(",") : [];
    } catch (e) {
      console.log(`Error in getting selected client types from local storage. Error is ${e}`);
    }
    return clientTypes;
  }

  public storedClientListRequest = (): ClientListRequest => {
    let clientListReq: ClientListRequest;
    try {
      const clientReq: string = localStorage.getItem("clientListRequest");
      clientListReq = clientReq ? JSON.parse(clientReq) : null;
    } catch (e) {
      console.log(`Error in getting client list request from local storage. Error is ${e}`);
    }
    return clientListReq;
  }

  public storedRecentColors = () :string[]=> {
    let recentColors: string[] = [];
    try {
      const rcntColors = localStorage.getItem("recentColors");
      recentColors = JSON.parse(rcntColors);
    } catch (error) {
      console.log(`Error in getting recent colors. Error is ${error}`);
    }
    return (recentColors && recentColors.length > 0) ? recentColors : [];
  }

  public storedCMSAssetTabIndex = (): number => {
    let assetTabIndex = 0;
    try {
      const tab: string = localStorage.getItem("cmsAssetTabIndex");
      assetTabIndex = tab ? parseInt(tab) : 0;
    } catch (e) {
      console.log(`Error in getting selected asset tab index from local storage. Error is ${e}`);
    }
    return assetTabIndex;
  }

  public storedAPIDefTabIndex = (): number => {
    let apiDefTabIndex = 0;
    try {
      const tabIndex: string = localStorage.getItem("apiDefTabIndex");
      apiDefTabIndex = tabIndex ? parseInt(tabIndex) : 0;
    } catch (e) {
      console.log(`Error in getting selected API Def tab index from local storage. Error is ${e}`);
    }
    return apiDefTabIndex;
  }

  public storedSelectedWFTabIndex = (): number => {
    let wfTabIndex = 0;
    try {
      const tabIndex: string = localStorage.getItem("selectedWFTabIndex");
      wfTabIndex = tabIndex ? parseInt(tabIndex) : 0;
    } catch (e) {
      console.log(`Error in getting selected journey tab from local storage. Error is ${e}`);
    }
    return wfTabIndex;
  }

  public storedAssetNameSearched = (): string => {
    let assetName = "";
    try {
      const assetNameSearched: string = localStorage.getItem("assetNameSearched");
      assetName = assetNameSearched ? assetNameSearched : "";
    } catch (e) {
      console.log(`Error in getting asset name searched from local storage. Error is ${e}`);
    }
    return assetName;
  }

  public storedSelectedAssetTabIndex = (): number => {
    let assetTabIndex = 0;
    try {
      const tab: string = localStorage.getItem("selectedAssetTabIndex");
      assetTabIndex = tab ? parseInt(tab) : 0;
    } catch (e) {
      console.log(`Error in getting selected asset tab index from local storage. Error is ${e}`);
    }
    return assetTabIndex;
  }

  public storeAPIDefTabIndex = (apiDefTabIndex: number) => {
    try {
      if (apiDefTabIndex || apiDefTabIndex === 0) {
        localStorage.setItem("apiDefTabIndex", apiDefTabIndex.toString());
      }
    } catch (e) {
      console.log(`Error in storing selected API Def tab index. Error is ${e}`);
    }
  }

  public storedSelectedTRGTabIndex = (): number => {
    let trgTabIndex = 0;
    try {
      const tabIndex: string = localStorage.getItem("selectedTRGTabIndex");
      trgTabIndex = tabIndex ? parseInt(tabIndex) : 0;
    } catch (e) {
      console.log(`Error in getting selected trigger tab index from local storage. Error is ${e}`);
    }
    return trgTabIndex;
  }

  public storedBhvNameSearched = (): string => {
    let bhvName = "";
    try {
      const bhvNameSearched: string = localStorage.getItem("bhvNameSearched");
      bhvName = bhvNameSearched ? bhvNameSearched : "";
    } catch (e) {
      console.log(`Error in getting behaviour name searched from local storage. Error is ${e}`);
    }
    return bhvName;
  }

  public storedLogOutUrl = (): string => {
    return localStorage.getItem("logoutUrl");
  }

  public storedTriggerNameSearched = (): string => {
    let trgName = "";
    try {
      const trgNameSearched: string = localStorage.getItem("triggerNameSearched");
      trgName = trgNameSearched ? trgNameSearched : "";
    } catch (e) {
      console.log(`Error in getting trigger name searched from local storage. Error is ${e}`);
    }
    return trgName;
  }

  public storedClientNameSearched = (): string => {
    let clientName = "";
    try {
      const clientNameSearched: string = localStorage.getItem("clientNameSearched");
      clientName = clientNameSearched ? clientNameSearched : "";
    } catch (e) {
      console.log(`Error in getting client name searched from local storage. Error is ${e}`);
    }
    return clientName;
  }

  public storedSelectedBHVTabIndex = (): number => {
    let bhvTabIndex = 0;
    try {
      const tabIndex: string = localStorage.getItem("selectedBHVTabIndex");
      bhvTabIndex = tabIndex ? parseInt(tabIndex) : 0;
    } catch (e) {
      console.log(`Error in getting selected behaviour tab index from local storage. Error is ${e}`);
    }
    return bhvTabIndex;
  }

  public storedSelectedClientId = (): number => {
    let selectedClientId = null;
    try {
      const clientId: string = localStorage.getItem("selectedClientId");
      selectedClientId = clientId ? parseInt(clientId) : null;
    } catch (e) {
      console.log(`Error in getting selected clientId from local storage. Error is ${e}`);
    }
    return selectedClientId;
  }

  public storedNLPTabIndex = (): number => {
    let nlpTabIndex = 0;
    try {
      const tabIndex: string = localStorage.getItem("selectedNLPTabIndex");
      nlpTabIndex = tabIndex ? parseInt(tabIndex) : 0;
    } catch (e) {
      console.log(`Error in getting selected NLP tab index from local storage. Error is ${e}`);
    }
    return nlpTabIndex;
  }

  public storedDesiredClientName = (): string => {
    let clientName = "";
    try {
      const desiredClientName: string = localStorage.getItem("desiredClientName");
      clientName = desiredClientName ? desiredClientName : "";
    } catch (e) {
      console.log(`Error in getting desired client name from local storage. Error is ${e}`);
    }
    return clientName;
  }

  public storedNLPNameSearched = (): string => {
    let nlpName = "";
    try {
      const nlpNameSearched: string = localStorage.getItem("nlpNameSearched");
      nlpName = nlpNameSearched ? nlpNameSearched : "";
    } catch (e) {
      console.log(`Error in getting nlp name searched from local storage. Error is ${e}`);
    }
    return nlpName;
  }

  public storedApiDefNameSearched = (): string => {
    let apiDefName = "";
    try {
      const apiDefNameSearched: string = localStorage.getItem("apiDefNameSearched");
      apiDefName = apiDefNameSearched ? apiDefNameSearched : "";
    } catch (e) {
      console.log(`Error in getting api def name searched from local storage. Error is ${e}`);
    }
    return apiDefName;
  }

  public storedReconnectCounter = (): number => {
    let reconnectCounter = 0;
    try {
      const reconnectCount = localStorage.getItem("reconnectCounter");
      reconnectCounter = Number(reconnectCount);
    } catch (error) {
      console.log(`Error in getting reconnect Counter from local storage. Error is ${error}`);
    }
    return reconnectCounter;
  }
}
