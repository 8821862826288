import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AssetConstant } from '@app/asset/constants/asset-constant';
import { AssetService } from '@app/asset/services/asset.service';
import { AuthenticationService } from '@app/core/services/authentication/authentication.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['./preview-dialog.component.scss']
})
export class PreviewDialogComponent implements OnInit {

  public fileType: string;
  public safeUrl: any;
  public isExternalLink = false;
  public dataLocalUrl: SafeResourceUrl;

  private _assetConst: AssetConstant;

  constructor(
    private _sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<PreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public previewData: any,
    private _http: HttpClient
  ) {
    this._assetConst = new AssetConstant();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.previewData) {
      const baseUrl = environment.behaviourUrl;
      this.fileType = this.previewData.type;
      this.isExternalLink = this.previewData.isExternalLink;
      if (this.previewData.uploadedFile) {
        setTimeout(() => {
          if (this.fileType !== this._assetConst.DOCUMENT_ATTR) {
            const newFile: Blob = new Blob([this.previewData.uploadedFile]);
            const blobUrl: string = window.URL.createObjectURL(newFile);
            this.dataLocalUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`${blobUrl}`);
          } else {
            const uploadedFileNameParts = this.previewData && this.previewData.uploadedFile && this.previewData.uploadedFile.name
              ? this.previewData.uploadedFile.name.split(".")
              : [];
            const urlParts = this.previewData && this.previewData.url ? this.previewData.url.split(".") : uploadedFileNameParts;
            const lastPart = urlParts && urlParts.length > 0 ? urlParts[urlParts.length - 1] : "";
            this.safeUrl = window.URL.createObjectURL(this.previewData.uploadedFile);
            const newFile: Blob = lastPart === "pdf" ? new Blob([this.previewData.uploadedFile], { type: "application/pdf" }) : new Blob([this.previewData.uploadedFile]);
            const blobUrl: string = window.URL.createObjectURL(newFile);
            this.dataLocalUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`${blobUrl}`);
          }
        }, 1000);
      } else if (this.isExternalLink) {
        this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.previewData.url);
      } else {
        const urlParts = this.previewData.url ? this.previewData.url.split(".") : [];
        const lastPart = urlParts && urlParts.length > 0 ? urlParts[urlParts.length - 1] : "";
        if (this.fileType === this._assetConst.IMAGE_ATTR) {
          this.dataLocalUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}${this.previewData.url}`);
        } else if (this.fileType !== this._assetConst.DOCUMENT_ATTR || (lastPart === "csv") || (lastPart === "txt")) {
          this._http.get(`${baseUrl}${this.previewData.url}`, {
            responseType: "arraybuffer",
          }).subscribe((res: any) => {
            const blobFile: Blob = lastPart === "pdf" ? new Blob([res], { type: "application/pdf" }) : new Blob([res]);
            const blobUrl: string = window.URL.createObjectURL(blobFile);
            this.dataLocalUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`${blobUrl}`);
          });
        } else if (lastPart == "pdf") {
          this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}${this.previewData.url}`);
        } else {
          const OFFICE_URL = "https://view.officeapps.live.com/op/embed.aspx";
          this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`${OFFICE_URL}?src=${baseUrl}${this.previewData.url}`);
        }

      }
    }
  }

  public closePreview = () => {
    this.dialogRef.close();
  }

}
