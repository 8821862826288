<mat-dialog-content>
  <div class="videoWrapper" *ngIf="fileType==='video'">
    <video *ngIf="!isExternalLink" [src]="dataLocalUrl" autoplay controls
      controlslist="nodownload noremoteplayback noplaybackrate" playsinline="false"
      disablePictureInPicture="true"></video>
    <iframe *ngIf="isExternalLink" [src]="safeUrl" title="external-file-link"></iframe>
  </div>
  <div *ngIf="fileType==='image'">
    <img class="imgBg" *ngIf="!isExternalLink && dataLocalUrl" [src]="dataLocalUrl" alt="Image File">
    <img class="imgBg" *ngIf="isExternalLink && safeUrl" [src]="safeUrl" alt="Image File">
  </div>
  <div *ngIf="fileType==='document'">
    <embed *ngIf="dataLocalUrl" [src]="dataLocalUrl">
    <embed *ngIf="safeUrl" [src]="safeUrl">
  </div>
  <button (click)="closePreview()">
    <mat-icon>close</mat-icon>
  </button>
</mat-dialog-content>