import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AssetDeleteRequest } from "@app/asset/misc/asset-delete-request";
import { AssetListRequest } from "@app/asset/misc/asset-list-request";
import { AssetRequest } from "@app/asset/misc/asset-request";
import { AssetStatusRequest } from "@app/asset/misc/asset-status-request";
import { FetchAssetBody } from "@app/asset/misc/fetch-asset-body";
import { UnsplashRequest } from "@app/asset/misc/unsplash-request";
import { CloudUploadRequest } from "@app/core/misctypes/cloud-upload-request";
import { ApiService } from "@app/core/services/api/api.service";
import { AuthenticationService } from "@app/core/services/authentication/authentication.service";
import { EndpointListService } from "@app/core/services/endpoint-list/endpoint-list.service";
import { BehaviorSubject } from "rxjs";
import { AssetListDetailsReq } from "@app/asset/misc/asset-list-details-req";
import { AssetInfo } from "@app/asset/misc/asset-info";
import { AssetResponse } from "@app/asset/misc/asset-response";
import { Constants } from "@app/core/utils/const";

@Injectable({
  providedIn: "root"
})
export class AssetService {

  public clientId: number;
  public effectiveViewPolicies = new BehaviorSubject([]);
  public effectiveTriggerPolicies = new BehaviorSubject([]);
  private cnst: Constants = new Constants();

  constructor(
    private _http: HttpClient,
    private apiService: ApiService,
    private authService: AuthenticationService,
    private endpointListService: EndpointListService
  ) {
  }

  public listAssets = (assetListRequest: AssetListRequest) => {
    let param = new HttpParams();
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.getAssetList}`;
    return this.apiService.apicall(endPoint, assetListRequest, "POST", param);
  }

  public listAssetsUnsafe = (assetListRequest: AssetListRequest) => {
    let param = new HttpParams();
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.listAssetsUnsafe}`;
    return this.apiService.apicall(endPoint, assetListRequest, "POST", param);
  }

  public createAssetFromDevice = (asset: FormData) => {
    const param = new HttpParams();
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.createAssetList}`;
    return this.apiService.apicall(endPoint, asset, "POST", param);
  }

  public createAssetFromCloud = (asset: AssetRequest) => {
    const param = new HttpParams();
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.createAssetFromCloud}`;
    return this.apiService.apicall(endPoint, asset, "POST", param);
  }

  public updateAssetFromDevice = (asset: FormData) => {
    const param = new HttpParams();
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.updateAssetList}`;
    return this.apiService.apicall(endPoint, asset, "POST", param);
  }

  public updateAssetFromCloud = (asset: AssetRequest) => {
    const param = new HttpParams();
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.updateAssetFromCloud}`;
    return this.apiService.apicall(endPoint, asset, "POST", param);
  }

  public deleteAsset = (asset: AssetDeleteRequest) => {
    const param = new HttpParams();
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.deleteAssetList}`;
    return this.apiService.apicall(endPoint, asset, "POST", param);
  }

  public downloadAsset = (assetUrl: string, assetId: string) => {
    const param = new HttpParams();
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/downloadAsset?assetUrl=${assetUrl}&id=${assetId}`;
    return this._http.get(endPoint);
  }

  public disableAsset = (AssetRequest: AssetStatusRequest) => {
    let param = new HttpParams();
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.disableAsset}`;
    return this.apiService.apicall(endPoint, AssetRequest, "POST", param);
  }

  public enableAsset = (AssetRequest: AssetStatusRequest) => {
    let param = new HttpParams();
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.enableAsset}`;
    return this.apiService.apicall(endPoint, AssetRequest, "POST", param);
  }

  public fetchAllAssets = (data: FetchAssetBody) => {
    let param = new HttpParams();
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.fetchAllAssets}`;
    return this.apiService.apicall(endPoint, data, "POST", param);
  }

  public getSearchClients = (searchVal: string, searchBy: string) => {
    const endPoint = `${this.authService.client.api_gateway_url}/clientmanagement/${this.endpointListService.searchClients}?searchKey=${searchVal}&searchBy=${searchBy}`;
    return this.apiService.apicall(endPoint, "", "GET");
  }

  public listLibraryAssets = () => {
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.listLibraryAssets}`;
    return this.apiService.apicall(endPoint, "", "GET");
  }

  public getUnsplashImages = (unsplashRequest: UnsplashRequest) => {
    let param = new HttpParams();
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.listUnsplashImages}`;
    return this.apiService.apicall(endPoint, unsplashRequest, "POST", param);
  }

  public uploadFileFromCloud = (uploadRequest: CloudUploadRequest) => {
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.uploadFileFromCloud}`;
    return this.apiService.apicall(endPoint, uploadRequest, "POST");
  }

  public getAllAssetList = async (clientId: number, ownership: string, belongsToClient: boolean): Promise<AssetInfo[]> => {
    let param = new HttpParams();
    let assetList: AssetInfo[] = [];
    const assetRequest: AssetListDetailsReq = {
      ownedBy: clientId,
      ownership: ownership
    };
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.listAssetsUnsafe}`;
    const assetRes: AssetResponse = await this.apiService.apicall(endPoint, assetRequest, "POST", param).toPromise();
    if (assetRes && assetRes.assetData && assetRes.assetData.length > 0) {
      if (belongsToClient) {
        const clientAssetList: AssetInfo[] = assetRes.assetData.filter((asset: AssetInfo) => asset && asset.ownership === this.cnst.CLIENT);
        const masterAssetList: AssetInfo[] = assetRes.assetData.filter((asset: AssetInfo) => asset && asset.ownership === this.cnst.MASTER && asset.isLibrary);
        assetList = clientAssetList.concat(masterAssetList);
      } else {
        assetList = assetRes.assetData;
      }
    } else {
      assetList = [];
    }

    return assetList;
  }

  public getUserDepartments = () => {
    const endPoint = `${this.authService.client.api_gateway_url}/clientmanagement/${this.endpointListService.getUserDepartments}`;
    return this.apiService.apicall(endPoint, "", "GET");
  }

  public getClientDepartments = (clientId: number) => {
    const endPoint = `${this.authService.client.api_gateway_url}/clientmanagement/${this.endpointListService.getDepartments}?clientId=${clientId}`;
    return this.apiService.apicall(endPoint, "", "GET");
  }

  public retrieveAsset = (id: number, ownership: string, ownedBy: number) => {
    const endPoint = `${this.authService.client.api_gateway_url}/asset-management/${this.endpointListService.retrieveAsset}?id=${id}&ownership=${ownership}&ownedBy=${ownedBy}`;
    return this.apiService.apicall(endPoint, "", "GET");
  }
}
