export class JwtInfo {

  public sub: string;
  public eventId: string;
  public tokenUse: string;
  public scope: string;
  public authTime: number;
  public iss: string;
  public exp: number;
  public iat: number;
  public jti: number;
  public clientId: string;
  public userName: string;

  constructor() {}
}
