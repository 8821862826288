<div class="sentinel-logo-bg confirm-page">
  <video autoplay class="cmsbgvdo">
    <source src="/assets/img/cmslogin.mp4">
  </video>

  <div class="logo-bg">
    <mat-card class="callback-card">
      <mat-card-content>
        <form [formGroup]="confirmCodeForm" (ngSubmit)="confirmCode()">
          <mat-form-field class="mat-form-field-wrapper" appearance="legacy">
            <mat-label>Code</mat-label>
            <p></p>
            <input matInput type="code" formControlName="code" required autofocus>
            <mat-error>
              {{getCodeErrorMessage()}}
            </mat-error>
          </mat-form-field>

          <mat-card-actions>
            <button mat-raised-button color="primary" class="sentinel-btn login-btn" type="submit">Confirm</button>
          </mat-card-actions>
        </form>
      </mat-card-content>
      <mat-card-footer>
        <div class="mat-error">{{error}}</div>
        <div class="mat-success">{{success}}</div>
      </mat-card-footer>
    </mat-card>

    <div class="camie-heading">
      <div class="camie-text">
        <h1>DigitalChameleon</h1>
        <h4>The No-code Launchpad for Your Ideas</h4>
      </div>
    </div>
    <p class="copyright-cms">DigitalChameleon.ai
    </p>
  </div>
</div>