import { SelectionModel } from "@angular/cdk/collections";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { FlatTreeControl } from "@angular/cdk/tree";
import { DOCUMENT } from "@angular/common";
import { Component, Inject, Injectable, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { TriggerConstant } from "@app/trigger/constants/trigger.constant";
import { Buttons } from "@app/trigger/types/buttons";
import { BehaviorSubject, Observable, of as observableOf } from "rxjs";
import Swal from "sweetalert2";

export interface TreeNode {
  id: string;
  buttons: TreeNode[];
  isExpanded?: boolean;
}

export interface DropInfo {
  targetId: string;
  action?: string;
}

@Component({
  selector: "app-button-drag-drop-tree",
  templateUrl: "./button-drag-drop-tree.component.html",
  styleUrls: ["./button-drag-drop-tree.component.scss"]
})

export class ButtonDragDropTreeComponent implements OnInit {

  public nodes: TreeNode[] = [];
  // ids for connected drop lists
  public dropTargetIds: string[] = [];
  public nodeLookup: any = {};
  public dropActionTodo: DropInfo = null;

  public _triggerConst: TriggerConstant;
  private _originalClose: Function;


  constructor(
    public buttons: MatDialogRef<Buttons>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._triggerConst = new TriggerConstant();
    this._originalClose = this.buttons.close;
    this.buttons.close = this.saveHierarchyInfo.bind(this);
  }

  ngOnInit(): void {
    this.nodes = this.dialogData.buttons;
    this.prepareDragDrop(this.nodes);
  }

  public prepareDragDrop = (nodes: TreeNode[]) => {
    nodes.forEach(node => {
      this.dropTargetIds.push(node.id);
      this.nodeLookup[node.id] = node;
      this.prepareDragDrop(node.buttons);
    });
  }

  public dragMoved = (event: any) => {
    let e: Element = this.document.elementFromPoint(event.pointerPosition.x, event.pointerPosition.y);
    if (!e) {
      this.clearDragInfo();
      return;
    }
    let container: Element = e.classList.contains(this._triggerConst.CLS_NODE_ITEM) ? e : e.closest(`.${this._triggerConst.CLS_NODE_ITEM}`);
    if (!container) {
      this.clearDragInfo();
      return;
    }
    this.dropActionTodo = {
      targetId: container.getAttribute(this._triggerConst.ID_DATA)
    };
    const targetRect: DOMRect = container.getBoundingClientRect();
    const oneThird: number = targetRect.height / 3;

    if (event.pointerPosition.y - targetRect.top < oneThird) {
      // before
      this.dropActionTodo[this._triggerConst.ACTION] = this._triggerConst.ACT_BEFORE;
    } else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
      // after
      this.dropActionTodo[this._triggerConst.ACTION] = this._triggerConst.ACT_AFTER;
    } else {
      // inside
      this.dropActionTodo[this._triggerConst.ACTION] = this._triggerConst.ACT_INSIDE;
    }
    this.showDragInfo();
  }

  public drop = (event: any) => {
    if (!this.dropActionTodo) return;
    const draggedItemId: string = event?.item?.data;
    const parentItemId: string = event.previousContainer.id;
    const targetListId: string = this.getParentNodeId(this.dropActionTodo?.targetId, this.nodes, this._triggerConst.ID_MAIN);
    const draggedItem: TreeNode = this.nodeLookup[draggedItemId];

    const oldItemContainer: TreeNode[] = parentItemId != this._triggerConst.ID_MAIN ? this.nodeLookup[parentItemId].buttons : this.nodes;
    const newContainer: TreeNode[] = targetListId != this._triggerConst.ID_MAIN ? this.nodeLookup[targetListId].buttons : this.nodes;

    switch (this.dropActionTodo.action) {
      case this._triggerConst.ACT_BEFORE:
      case this._triggerConst.ACT_AFTER:
        let parentTargetId: string = this.dropActionTodo.targetId;
        parentTargetId = this.getParentNodeId(parentTargetId, this.nodes, this._triggerConst.ID_MAIN);
        if ((newContainer.length < 15 && parentTargetId !== this._triggerConst.ID_MAIN) || (newContainer.length < 250 && parentTargetId == this._triggerConst.ID_MAIN)) {
          let i: number = oldItemContainer.findIndex(c => c.id === draggedItemId);
          oldItemContainer.splice(i, 1);
          const targetIndex: number = newContainer.findIndex(c => c.id === this.dropActionTodo.targetId);
          if (this.dropActionTodo.action == this._triggerConst.ACT_BEFORE) {
            newContainer.splice(targetIndex, 0, draggedItem);
          } else {
            newContainer.splice(targetIndex + 1, 0, draggedItem);
          }
        } else {
          return;
        }
        break;

      case this._triggerConst.ACT_INSIDE:
        let parentId: string = this.dropActionTodo.targetId;
        let level: number = 1;
        while (parentId != this._triggerConst.ID_MAIN) {
          parentId = this.getParentNodeId(parentId, this.nodes, this._triggerConst.ID_MAIN);
          level++;
        }
        const draggedItemDepth: number = this.count(draggedItem.buttons);
        if ((level + draggedItemDepth) <= 5 && this.nodeLookup[this.dropActionTodo.targetId].buttons.length < 15) {
          let i: number = oldItemContainer.findIndex(c => c.id === draggedItemId);
          oldItemContainer.splice(i, 1);
          this.nodeLookup[this.dropActionTodo.targetId].buttons.push(draggedItem)
          this.nodeLookup[this.dropActionTodo.targetId].isExpanded = true;
        } else {
          return;
        }
        break;
    }

    this.clearDragInfo(true)
  }

  public getParentNodeId = (id: string, nodesToSearch: TreeNode[], parentId: string): string => {
    for (let node of nodesToSearch) {
      if (node.id == id) return parentId;
      let ret: string = this.getParentNodeId(id, node.buttons, node.id);
      if (ret) return ret;
    }
    return null;
  }

  public showDragInfo = () => {
    this.clearDragInfo();
    if (this.dropActionTodo) {
      this.document.getElementById(this._triggerConst.ID_NODE +
        this.dropActionTodo.targetId).classList.add(this._triggerConst.CLS_DROP +
          this.dropActionTodo.action);
    }
  }

  public clearDragInfo = (dropped = false) => {
    if (dropped) {
      this.dropActionTodo = null;
    }
    this.document
      .querySelectorAll(".drop-before")
      .forEach(element => element.classList.remove("drop-before"));
    this.document
      .querySelectorAll(".drop-after")
      .forEach(element => element.classList.remove("drop-after"));
    this.document
      .querySelectorAll(".drop-inside")
      .forEach(element => element.classList.remove("drop-inside"));
  }

  public saveHierarchyInfo = (type: string) => {
    if (type === "cancel") {
      this._originalClose.bind(this.buttons)({});
    }
    if (type === "save") {
      if (this.nodes?.length <= 250) {
        this._originalClose.bind(this.buttons)({ event: "save", data: this.nodes });
      } else {
        Swal.fire({ text: `${"Every level can have only 250 sibling buttons"}`, icon: this._triggerConst.WARNING });
      }
    }
  }

  public count = (buttons: TreeNode[]) => {
    return buttons.reduce((depth, child) => {
      return Math.max(depth, 1 + this.count(child.buttons)); // increment depth of children by 1, and compare it with accumulated depth of other children within the same element
    }, 0); //default value 0 that"s returned if there are no children
  }

  public deleteButton = (pIndex: number, cIndex: number) => {
    if (pIndex > -1 && cIndex > -1) {
      this.nodes[pIndex].buttons.splice(cIndex, 1);
    }
    if (pIndex > -1 && cIndex === -1) {
      this.nodes.splice(pIndex, 1);
    }
  }
}