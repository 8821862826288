import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { AuthConstants } from "@app/core/constant/auth-constants";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StorageService } from "@app/core/services/storage/storage.service";

@Component({
  selector: "app-sso-help",
  templateUrl: "./sso-help.component.html",
  styleUrls: ["./sso-help.component.scss"],
})
export class SsoHelpComponent implements OnInit {

  public error = "";
  private _const: AuthConstants;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private authSvc: AuthenticationService,
    private _storage: StorageService
  ) {
    this._const = new AuthConstants();
  }

  async ngOnInit(): Promise<void> {}
}
