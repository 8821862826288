import Swal from "sweetalert2";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { AuthConstants } from "@app/core/constant/auth-constants";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StorageService } from "@app/core/services/storage/storage.service";

@Component({
  selector: "app-callback-oauth",
  templateUrl: "./callback-oauth.component.html",
  styleUrls: ["./callback-oauth.component.scss"],
})
export class CallbackOAuthComponent implements OnInit {

  public loading = false;
  public submitted = false;
  public loginForm: FormGroup;
  public error = "";

  private _const: AuthConstants;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private authSvc: AuthenticationService,
    private _storage: StorageService
  ) {
    this._const = new AuthConstants();
  }

  async ngOnInit(): Promise<void> {
    const param = new URLSearchParams(window.location.search);
    const msg: string = param.get("msg");

    let title = "Unable to store google calendar tokens";

    if (msg && msg.length > 0) {
      title = msg;
    }

    await this.authSvc.loadInitialCMSLoginInfo();
    Swal.fire({title, icon:"info"});
    this.router.navigate([this._const.USER_MANAGEMENT], {});
  }
}
