import { UiPolicy } from "@app/core/misctypes/ui-policy";

export class UiPolicyContext {

  private readonly asteriskContextPolicies: UiPolicy[] = [];
  private readonly specificContextPolicies: UiPolicy[] = [];

  constructor(asteriskP: UiPolicy[], specificP: UiPolicy[]) {
    this.asteriskContextPolicies = !!asteriskP && Array.isArray(asteriskP) && asteriskP.length > 0 ? asteriskP : [];
    this.specificContextPolicies = !!specificP && Array.isArray(specificP) && specificP.length > 0 ? specificP : [];
  }

  public getSpecific = (policyContext: string): UiPolicy[] => {
    const policies: UiPolicy[] = [];
    if (!!policyContext) {
      if (!!this.specificContextPolicies && this.specificContextPolicies.length > 0) {
        this.specificContextPolicies.forEach(sp => {
          if (sp.policyContext() === policyContext) {
            policies.push(sp);
          }
        });
      }
    }
    return policies;
  }

  public getViewPoliciesLeastPrivileged = (policyContext: string) => {
    const policies: UiPolicy[] = [];
    // specific to a context, ex: client-management, user-management etc..
    const asteriskViewPolicies: UiPolicy[] = [];
    const restrictiveViewPolicies: UiPolicy[] = [];
    if (!!policyContext) {
      const specificPolicies: UiPolicy[] = this.getSpecific(policyContext);
      if (!!specificPolicies && Array.isArray(specificPolicies) && specificPolicies.length > 0) {
        specificPolicies.forEach(sp => {
          if (sp.policyContext() === policyContext) {
            if (sp.generousViewGrant()) {
              asteriskViewPolicies.push(sp);
            } else {
              restrictiveViewPolicies.push(sp);
            }
          }
        });
        if (restrictiveViewPolicies.length > 0) {
          policies.push(...restrictiveViewPolicies);
        } else {
          policies.push(...asteriskViewPolicies);
        }
      }
      // When context specific policies are not available check for more
      // generous policies
      if (policies.length === 0) {
        if (this.asteriskContextPolicies.length > 0) {
          const _p: UiPolicy[] = this.getAsteriskPoliciesLeastPrivileged(0);
          if (_p.length > 0) {
            policies.push(..._p);
          }
        }
      }
    }
    return policies;
  }

  public getTriggerPoliciesLeastPrivileged = (policyContext: string) => {
    const policies: UiPolicy[] = [];
    const asteriskTriggerPolicies: UiPolicy[] = [];
    const restrictiveTriggerPolicies: UiPolicy[] = [];
    if (!!policyContext) {
      const specificPolicies: UiPolicy[] = this.getSpecific(policyContext);
      if (!!specificPolicies && Array.isArray(specificPolicies) && specificPolicies.length > 0) {
        specificPolicies.forEach(sp => {
          if (sp.policyContext() === policyContext) {
            if (sp.generousTriggerGrant()) {
              asteriskTriggerPolicies.push(sp);
            } else {
              restrictiveTriggerPolicies.push(sp);
            }
          }
        });
        if (restrictiveTriggerPolicies.length > 0) {
          policies.push(...restrictiveTriggerPolicies);
        } else {
          policies.push(...asteriskTriggerPolicies);
        }
      }
      // When context specific policies are not available check for more
      // generous policies
      if (policies.length === 0) {
        if (this.asteriskContextPolicies.length > 0) {
          const _p: UiPolicy[] = this.getAsteriskPoliciesLeastPrivileged(1);
          if (_p.length > 0) {
            policies.push(..._p);
          }
        }
      }
    }
    return policies;
  }

  private getAsteriskPoliciesLeastPrivileged = (type: number): UiPolicy[] => {
    // type = 0 => looking for view grants
    // type = 1 => looking for trigger grants
    const policies: UiPolicy[] = [];
    const asteriskViewPolicies: UiPolicy[] = [];
    const restrictiveViewPolicies: UiPolicy[] = [];
    const asteriskTriggerPolicies: UiPolicy[] = [];
    const restrictiveTriggerPolicies: UiPolicy[] = [];

    if (this.asteriskContextPolicies.length > 0) {

      if (type === 0) {
        this.asteriskContextPolicies.forEach(acp => {
          if (acp.hasViewGrants()) {
            if (acp.generousViewGrant()) {
              asteriskViewPolicies.push(acp);
            } else {
              restrictiveViewPolicies.push(acp);
            }
          }
        });
        if (restrictiveViewPolicies.length > 0) {
          policies.push(...restrictiveViewPolicies);
        } else {
          policies.push(...asteriskViewPolicies);
        }
      }

      if (type === 1) {
        this.asteriskContextPolicies.forEach(acp => {
          if (acp.hasTriggerGrants()) {
            if (acp.generousTriggerGrant()) {
              asteriskTriggerPolicies.push(acp);
            } else {
              restrictiveTriggerPolicies.push(acp);
            }
          }
        });

        if (restrictiveTriggerPolicies.length > 0) {
          policies.push(...restrictiveTriggerPolicies);
        } else {
          policies.push(...asteriskTriggerPolicies);
        }
      }
    }
    return policies;
  }
}
