export class MessageContent {

  public readonly displayText: string;
  public readonly value: string;

  constructor(dt?: string, val?: string) {
    this.displayText = dt;
    this.value = val;
  }

  display = (): string => {
    return (this.displayText) ? this.displayText : "";
  }

  val = (): string => {
    return (this.value) ? this.value : "";
  }
}
