export class ModuleLink {

  public moduleId: number;
  public moduleName: string;
  public path: string;

  constructor (mId: number, mN: string, p: string) {
    this.moduleId = mId;
    this.moduleName = mN;
    this.path = p;
  }
}
