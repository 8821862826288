<div class="preview-container preview-container-preview-trigger" [ngClass]="{ 'video-bg': dashboardVideoUrl }"
  [ngStyle]="!dashboardVideoUrl ? backgroundStyle : {}">
  <ng-container *ngIf="dashboardVideoUrl">
    <video autoplay muted loop class="background">
      <source [src]="dashboardVideoUrl" type="video/mp4">
    </video>
  </ng-container>
  <div class="preview-header row">
    <div class="col-sm-4">
      <h2>Webpage Preview</h2>
    </div>

    <div class="col"></div>
    <div class="col-md-4 p-r-0">
      <div class="multi-selection-dropdown dd-align-center row">
        <mat-label class="col-sm-3">Select brand</mat-label>
        <mat-form-field appearance="legacy" class="multi-select col-sm-8">
          <mat-select [(ngModel)]="selectedBehaviour" [ngModelOptions]="{standalone: true}"
            (selectionChange)="behaviourChanged($event)">
            <mat-option [value]="null">None</mat-option>
            <mat-option *ngFor="let behaviour of behaviourList" [value]="behaviour.behaviourId">
              {{behaviour.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div *ngIf="triggerViewable" id="landing" class="landing-page" (click)="outerClick()">
    <div class="continue-box">
      <div id="parent-sec-{{sIndex}}" *ngFor="let section of landingPage; let sIndex = index" class="section-box"
        [ngClass]="{'header-landing': !showHeader, 'no-header': showHamburger && !showHeaderElement, 'no-sticky-sec': section.sectionStick === 'none',
        'stick-sec-header': section.sectionStick === 'header', 'stick-sec-footer': section.sectionStick === 'footer', 'sec-sidebar': section.sectionStick === 'sidebar', 'stick-sec-top-left': section.sectionStick === 'sidebar' && section.sidebarPosition === 'topLeft',
        'stick-sec-middle-left': section.sectionStick === 'sidebar' && section.sidebarPosition === 'middleLeft', 'hide': section.linkedTriggerId !== -1,
        'stick-sec-bottom-left': section.sectionStick === 'sidebar' && section.sidebarPosition === 'bottomLeft', 'stick-sec-top-right': section.sectionStick === 'sidebar' && section.sidebarPosition === 'topRight', 'stick-sec-middle-right': section.sectionStick === 'sidebar' && section.sidebarPosition === 'middleRight',
        'stick-sec-bottom-right': section.sectionStick === 'sidebar' && section.sidebarPosition === 'bottomRight', 'single-btn-sidebar': section.buttons.length === 1, 'sidebar-with-header': showHeader, 'sidebar-overlap': isMenuShown, 'sandwich-show': isMenuShown, 'search-open': toggleSearch}"
        [ngStyle]="section.style">
        <h2 style="display: none;">{{section.name}}</h2>
        <button id="sidebar-sec-{{sIndex}}" class="sidebar-toggle" [ngStyle]="toggleBtnColor"
          *ngIf="section.sectionStick === 'sidebar' && section.buttons.length > 1 && !section.hideSection"
          [ngClass]="{'stick-bottom-right': section.sectionStick === 'sidebar' && (section.sidebarPosition === 'bottomRight' || section.sidebarPosition === 'middleRight' || section.sidebarPosition === 'topRight'), 
        'stick-bottom-left': section.sectionStick === 'sidebar' && (section.sidebarPosition === 'bottomLeft' || section.sidebarPosition === 'middleLeft' || section.sidebarPosition === 'topLeft')}"
          (click)="sidebarToggle('section-'+sIndex, 'parent-sec-'+sIndex)">
          <mat-icon>keyboard_arrow_up</mat-icon> {{section.name}}
        </button>
        <div id="section-{{sIndex}}" *ngIf="!section.hideSection" [ngStyle]="getSectionStyles(section)"
          class="sec-outer-box"
          [ngClass]="{ 'no-bg': section.backgroundType === 'none',
          'no-description-banner': !section.description && section.backgroundType !== 'none' && (section.backgroundSrc || hamBgVideo) && section.buttons.length === 0}"
          [attr.aria-label]="section.bgAltText">
          <video
            *ngIf="section && section.backgroundType && section.backgroundType === 'video' || (section.backgroundType === 'none' && hamBgType === 'video')"
            [ngStyle]="getBgVideo(section)" class="video-handler sec-video"
            [ngClass]="{'no-sec-bg': section.backgroundType === 'none'}"
            src="{{imageBaseUrl}}{{section.backgroundSrc ? section.backgroundSrc : hamBgVideo}}" autoplay="autoplay"
            loop="loop" [muted]="true" preload="auto" playsinline="" webkit-playsinline="">
          </video>
          <button class="allow-close" [ngStyle]="getButtonStyle(section)" *ngIf="section.allowSectionClose"
            (click)="allowClose('section-'+sIndex, 'parent-sec-'+sIndex, 'sidebar-sec-'+sIndex)">
            <mat-icon>close</mat-icon>
          </button>
          <button class="sandwich-menu" [ngStyle]="getButtonStyle(section)"
            [ngClass]="{'align-center': section.buttonAlignment == 'center', 'align-left': section.buttonAlignment == 'left'}"
            *ngIf="section.buttons.length > 0 && (section.buttonLook === 'circle' || section.sandwichBtnMobile)"
            mat-icon-button (click)="toggleShow()">
            <mat-icon>dehaze</mat-icon>
          </button>
          <div class="section-padding" [ngClass]="{'p-r-30': section.allowSectionClose}">
            <button class="sandwich-close" [ngStyle]="getButtonStyle(section)"
              [ngClass]="{'align-center': section.buttonAlignment == 'center', 'align-left': section.buttonAlignment == 'left'}"
              *ngIf="this.isMenuShown && (section.buttonLook === 'circle' || section.sandwichBtnMobile)" mat-icon-button
              (click)="toggleShow(); outerClick()">
              <mat-icon>close</mat-icon>
            </button>
            <div id="section-desc-{{sIndex}}"
              *ngIf="section && (section.description && (section.buttonPlacement !== 'beforeCanvas'))"
              [innerHtml]="section.description | safeHtml" class="fade-up-element section-content"
              [ngClass]="{'no-padding': !section.description}">
            </div>
            <!-----carousel button--->
            <ngx-slick-carousel
              *ngIf="section.buttonLayout === 'carousel' || section.buttonLayout === 'banner' || section.buttonLayout === 'chameleon'"
              class="carousel banner-slide-view no-sandwich" #slickModal1="slick-carousel"
              [config]="section.buttonLayout === 'banner' ? slideBannerConfig : (section.buttonLayout === 'horizontal' || section.buttonLayout === 'vertical') ? slideHorizontalVerConfig : getSlickSliderConfig(section) ? slideHorizontalMobileConfig : slideHorizontalExpandedConfig"
              [ngClass]="{'view-alloption': section.buttonLayout == 'horizontal', 'vertical-alignment-button': section.buttonLayout == 'vertical',
             'align-left': section.buttonLayout == 'horizontal' && section.buttonAlignment == 'left', 'slider-alignment-unique': section.buttons.length <= 5 && section.buttonLayout === 'chameleon',
             'align-center': section.buttonLayout == 'horizontal' && section.buttonAlignment == 'center',
             'slider-left': (section.buttonLayout == 'chameleon' || section.buttonLayout == 'banner') && section.buttonAlignment == 'left',
             'vertical-left': section.buttonLayout == 'vertical' && section.buttonAlignment == 'left',
             'align-right': section.buttonLayout == 'horizontal' && section.buttonAlignment == 'right',
             'slider-right': (section.buttonLayout == 'chameleon' || section.buttonLayout == 'banner') && section.buttonAlignment == 'right',
             'slider-center': (section.buttonLayout == 'chameleon' || section.buttonLayout == 'banner') && section.buttonAlignment == 'center',
             'vertical-right': section.buttonLayout == 'vertical' && section.buttonAlignment == 'right',
             'vertical-center': section.buttonLayout == 'vertical' && section.buttonAlignment == 'center',
             'chameleon-view': section.buttonLayout == 'chameleon', 'horizontal-view': section.buttonLayout == 'horizontal', 'banner-slide-view': section.buttonLayout == 'banner',
             'sandwich-hide': section.sandwichBtnMobile && !this.isMenuShown, 'hamburger-button-panel':  this.isMenuShown && section.sandwichBtnMobile,
             'no-display': section.buttonLook === 'expandable' && (section.buttonLayout === 'horizontal' || section.buttonLayout === 'vertical'), 'no-dot': section.buttons.length <= 5}"
              [ngStyle]="getButtonAlignment(section)" [attr.aria-label]="section.bgAltText">
              <video
                *ngIf="section && section.backgroundType && section.backgroundType === 'video' && this.isMenuShown && section.buttonLook == 'circle'"
                [ngStyle]="getBgVideo(section)" class="video-handler" src="{{imageBaseUrl}}{{section.backgroundSrc}}"
                autoplay="autoplay" loop="loop" [muted]="true" preload="auto" playsinline="" webkit-playsinline="">
              </video>
              <div ngxSlickItem class="button-div input-group no-flip" [ngClass]="{'button-card': section.buttonLook == 'card' || section.buttonLook == 'transparent' || (!button.imageUrl && !button.displayText), 'circle-button': section.buttonLook == 'circle', 'transparent-button': section.buttonLook == 'transparent',
              'edgy-button': section.buttonLook == 'edgy', 'full-option-image': (!button.displayText && !button.buttonDescription && button.imageUrl) || (!button.displayText && section.buttonLook !== 'card' && button.imageUrl), 'expand-button': section.buttonLook == 'expandable', 
              'button-height': (section.buttonLook == 'card' || section.buttonLook == 'transparent') && button.buttonDescription, 'button-graphic': !button.displayText && !button.buttonDescription && (section.buttonLook == 'card'
              || section.buttonLook == 'transparent'), 'card-type': section.buttonLook === 'card'}"
                *ngFor="let button of section.buttons; let bIndex=index" [ngStyle]="getSectionBtnXYAxis(button)">
                <button class="flip-card slide-effect" *ngIf="section.buttonLook !== 'expandable'"
                  [ngClass]="{'fadein': button.buttonAnimation === 'fade-in', 'fadeout': button.buttonAnimation === 'fade-out', 'fadein-up': button.buttonAnimation === 'fade-in-up', 'fadein-down': button.buttonAnimation === 'fade-in-down',
                  'fadein-left': button.buttonAnimation === 'fade-in-left', 'fadein-right': button.buttonAnimation === 'fade-in-right', 'enter-hide': button.openNewTab === 'sectionAuto', 'sandwich-mobile-btn': section.buttonLook === 'circle' && !button.displayText, 'no-display': !button.displayText && !button.buttonDescription && !button.imageUrl}"
                  [disabled]="(button.action == 'none' && !button.buttonDescription)"
                  (mouseover)="hover=sIndex+'_'+bIndex" (mouseleave)="hover='none'"
                  (ontouchstart)="hover=sIndex+'_'+bIndex" [ngStyle]="getSectionBtnWidth(button)">
                  <div class="flip-card-inner card-body" *ngIf="button.buttonAnimation === 'flip'"
                    [ngClass]="{'no-cursor': (button.action == 'none')}"
                    [ngStyle]="hover==sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                    <div class="card-front"
                      *ngIf="button.imageUrl || button.displayText || (section.buttonLook === 'circle' && button.displayText)"
                      [ngClass]="{'graphic-height': !button.displayText && (section.buttonLook === 'card'
                    || section.buttonLook === 'transparent')}">
                      <div class="option-icon" *ngIf="!checkUploadItemType(button) && button.imageUrl"
                        [ngStyle]="getOptionImage(button, section)" [attr.aria-label]="button.imageAltText"></div>
                      <video *ngIf="checkUploadItemType(button)" id="{{sIndex}}_{{bIndex}}_video"
                        [src]="this.imageBaseUrl+button.imageUrl" class="video-handler" autoplay="autoplay" loop="loop"
                        preload="auto" playsinline="" webkit-playsinline="" [muted]="true">
                      </video>
                      <div class="option-content" *ngIf="button.displayText"
                        [innerHtml]="button.displayText | safeHtml">
                      </div>
                      <strong style="display: none;">
                        <em>
                          {{button.title}}
                        </em>
                      </strong>
                    </div>
                    <div class="card-back" id="button-desc-{{sIndex}}-{{bIndex}}"
                      [innerHtml]="button.buttonDescription | safeHtml">
                    </div>
                  </div>
                  <div>
                    <div class="button-body"
                      [ngClass]="{'no-cursor': (button.action === 'none'), 'full-option-image': !button.displayText && button.imageUrl, 'center-align-content': !button.imageUrl, 
                  'no-front-content': !button.displayText && !button.imageUrl, 'button-height-auto': (section.buttonLook == 'card' || section.buttonLook == 'transparent') && (button.displayText || button.buttonDescription)}"
                      [ngStyle]="hover==sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                      <div class="card-front-slide"
                        *ngIf="button.imageUrl || button.displayText || (section.buttonLook === 'circle' && button.displayText)"
                        [ngClass]="{'graphic-height': button.displayText || button.buttonDescription && (section.buttonLook === 'card'
                    || section.buttonLook === 'transparent')}">
                        <div class="option-icon" *ngIf="!checkUploadItemType(button) && button.imageUrl"
                          [ngStyle]="getOptionImage(button, section)" [attr.aria-label]="button.imageAltText"></div>
                        <video *ngIf="checkUploadItemType(button)" id="{{sIndex}}_{{bIndex}}_video"
                          [src]="this.imageBaseUrl+button.imageUrl" [ngStyle]="getVideoHeight(button, section)"
                          class="video-handler" autoplay="autoplay" loop="loop" preload="auto" playsinline=""
                          webkit-playsinline="" [muted]="true">
                        </video>
                        <div class="option-content" *ngIf="button.displayText"
                          [innerHtml]="button.displayText | safeHtml">
                        </div>
                      </div>
                      <div *ngIf="button.buttonDescription" class="card-back-slide overlay"
                        [ngStyle]="hover==sIndex+'_'+bIndex ? getSectionButtonOverlayStyle(button, 'hover', section) : getSectionButtonOverlayStyle(button, 'normal', section)"
                        id="button-desc-{{sIndex}}-{{bIndex}}" [innerHtml]="button.buttonDescription | safeHtml"></div>
                    </div>
                  </div>
                  <div id="section-{{sIndex}}-btn-{{bIndex}}" class="btn-auto-view"
                    *ngIf="button.openNewTab === 'button' ||  button.openNewTab === 'buttonAuto'">
                    <app-viewer-container [url]="button.nextAction" [type]="button.action"
                      [altText]="button.nxtAltText">
                    </app-viewer-container>
                  </div>
                </button>
                <mat-accordion *ngIf="section.buttonLook === 'expandable' && section.buttonLayout == 'chameleon'">
                  <mat-expansion-panel [expanded]="sExpandedIndex === sIndex+'-'+bIndex" hideToggle
                    class="action-expand-icon"
                    [ngClass]="{'no-cursor': (button.action == 'none'), 'full-option-image': !button.displayText && button.imageUrl}"
                    [disabled]="(button.action == 'none' && !button.buttonDescription)" id="exp-{{sIndex}}-{{bIndex}}"
                    [ngStyle]="getSecExpandableStyle(button, section)"
                    (opened)="setExpandableStyle('exp-'+sIndex+'-'+bIndex, button, true, 'hover'); sExpandedIndex = sIndex+'-'+bIndex"
                    (closed)="setExpandableStyle('exp-'+sIndex+'-'+bIndex, button, false, 'normal')">
                    <mat-expansion-panel-header>
                      <mat-panel-title *ngIf="button.imageUrl || button.displayText">
                        <div class="option-icon" *ngIf="!checkUploadItemType(button) && button.imageUrl"
                          [ngStyle]="getOptionImage(button, section)" [attr.aria-label]="button.imageAltText"></div>
                        <video *ngIf="checkUploadItemType(button)" id="{{sIndex}}_{{bIndex}}_video"
                          [src]="this.imageBaseUrl+button.imageUrl" [ngStyle]="getVideoHeight(button, section)"
                          class="video-handler" autoplay="autoplay" loop="loop" preload="auto" playsinline=""
                          webkit-playsinline="" [muted]="true">
                        </video>
                        <div class="option-content" [innerHtml]="button.displayText | safeHtml"></div>
                        <mat-icon [hidden]="!button.buttonDescription" class="vertical-icon">
                          keyboard_arrow_down</mat-icon>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div [ngStyle]="getExpandBtnOverlayStyle(button, section)"
                      [innerHtml]="button.buttonDescription | safeHtml"
                      [ngClass]="{'action-cursor': button.action !== 'none' && button.buttonDescription}"
                      class="expand-description">
                    </div>
                    <div class="expand-description child-btn-expand"
                      [ngClass]="{'no-padding': button.buttons.length > 0}" *ngIf="button.buttons.length"
                      [ngStyle]="getExpandBtnOverlayStyle(button, section)">
                      <app-menu-items [items]="button.buttons"
                        (clickedButton)="setExpandableStyle('exp-'+sIndex+'-'+bIndex, button, false, 'normal');">
                      </app-menu-items>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </ngx-slick-carousel>
            <!-----carousel button end--->
            <!-----Basic button------->
            <div class="basic-btn no-sandwich" [ngClass]="{'vertical-btn': section.buttonLayout === 'vertical'}"
              *ngIf="section.buttonLayout === 'horizontal' || section.buttonLayout === 'vertical'">
              <!---Left align button-->
              <div class="btn-cover unique-left"
                *ngIf="leftBtnArray[sIndex]?.length > 0 && section.buttonLook !== 'expandable' && section.buttonLook !== 'popup'"
                [ngClass]="{'view-alloption': section.buttonLayout === 'horizontal', 'vertial-view': section.buttonLayout === 'vertical',
                'sandwich-hide': section.sandwichBtnMobile, 'hamburger-button-panel':  this.isMenuShown && section.sandwichBtnMobile, 'expand-sandwich-menu': section.sandwichBtnMobile}">
                <div *ngFor="let button of leftBtnArray[sIndex]; let bIndex=index">
                  <strong style="display: none;">
                    <em>
                      {{button.title}}
                    </em>
                  </strong>
                  <div class="button-div input-group no-flip"
                    [ngClass]="{'button-card': section.buttonLook == 'card' || section.buttonLook == 'transparent' || (!button.imageUrl && !button.displayText), 'circle-button': section.buttonLook == 'circle', 'transparent-button': section.buttonLook == 'transparent',
                    'edgy-button': section.buttonLook == 'edgy', 'full-option-image': (!button.displayText && !button.buttonDescription && button.imageUrl) || (!button.displayText && section.buttonLook !== 'card' && button.imageUrl), 'no-search-sec': button && button.action !== 'search',
                    'button-height': (section.buttonLook == 'card' || section.buttonLook == 'transparent') && button.buttonDescription, 'button-graphic': !button.displayText && !button.buttonDescription && (section.buttonLook == 'card'
                    || section.buttonLook == 'transparent'), 'card-type': section.buttonLook === 'card', 'full-video': (button.width >= 320 && button.imageUrl && !button.displayText && checkUploadItemType(button))}"
                    [ngStyle]="getSectionBtnXYAxis(button)">
                    <button class="flip-card slide-effect" *ngIf="button && button.action !== 'search'" [ngClass]="{'fadein': button.buttonAnimation === 'fade-in', 'fadeout': button.buttonAnimation === 'fade-out', 'fadein-up': button.buttonAnimation === 'fade-in-up', 'fadein-down': button.buttonAnimation === 'fade-in-down',
                      'fadein-left': button.buttonAnimation === 'fade-in-left', 'fadein-right': button.buttonAnimation === 'fade-in-right', 'enter-hide': button.openNewTab === 'sectionAuto', 'sandwich-mobile-btn': section.buttonLook === 'circle' && !button.displayText,
                      'no-display': !button.displayText && !button.buttonDescription && !button.imageUrl}"
                      [disabled]="(button.action == 'none' && !button.buttonDescription)"
                      (mouseover)="hover='l'+sIndex+'_'+bIndex" (mouseleave)="hover='none'"
                      (ontouchstart)="hover=sIndex+'_'+bIndex" [ngStyle]="getSectionBtnWidth(button)">
                      <div class="button-body"
                        [ngClass]="{'no-cursor': (button.action === 'none'), 'full-option-image': !button.displayText && button.imageUrl, 'center-align-content': !button.imageUrl, 
                        'no-front-content': !button.displayText && !button.imageUrl, 'button-height-auto': (section.buttonLook == 'card' || section.buttonLook == 'transparent') && (button.displayText || button.buttonDescription), 'mobile-image': (button.width >= 401 && button.imageUrl && !button.displayText && !checkUploadItemType(button))}"
                        [ngStyle]="hover=='l'+sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                        <div class="card-front-slide" [ngClass]="{'graphic-height': button.displayText || button.buttonDescription && (section.buttonLook === 'card'
                        || section.buttonLook === 'transparent')}">
                          <div class="option-icon" *ngIf="!checkUploadItemType(button) && button.imageUrl"
                            [ngStyle]="getOptionImage(button, section)" [attr.aria-label]="button.imageAltText"></div>
                          <video *ngIf="checkUploadItemType(button)" id="{{sIndex}}_{{bIndex}}_video"
                            [src]="this.imageBaseUrl+button.imageUrl" [ngStyle]="getVideoHeight(button, section)"
                            class="video-handler" autoplay="autoplay" loop="loop" preload="auto" playsinline=""
                            webkit-playsinline="" [muted]="true">
                          </video>
                          <div class="option-content" [innerHtml]="button.displayText | safeHtml">
                          </div>
                        </div>
                        <div *ngIf="button.buttonDescription" class="card-back-slide overlay"
                          [ngStyle]="hover=='l'+sIndex+'_'+bIndex ? getSectionButtonOverlayStyle(button, 'hover', section) : getSectionButtonOverlayStyle(button, 'normal', section)"
                          id="button-desc-{{sIndex}}-{{bIndex}}" [innerHtml]="button.buttonDescription | safeHtml">
                        </div>
                      </div>
                      <div id="section-{{sIndex}}-btn-{{bIndex}}" class="btn-auto-view"
                        *ngIf="button.openNewTab === 'button' ||  button.openNewTab === 'buttonAuto'">
                        <app-viewer-container [url]="button.nextAction" [type]="button.action"
                          [altText]="button.nxtAltText"></app-viewer-container>
                      </div>
                    </button>
                    <div class="search-box" *ngIf="button && button.action === 'search'">
                      <div class="srch-btn fade-up-element" type="button" (click)="openSearch(button)"
                        [ngStyle]="hover=='l'+sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                        <mat-icon>search</mat-icon>
                        <div *ngIf="button.displayText" class="option-content"
                          [innerHtml]="button.displayText | safeHtml"></div>
                      </div>
                      <div class="search-bar" *ngIf="toggleSearch" [ngClass]="{'search-visible': toggleSearch}">
                        <app-search [button]="button" (closeSearch)="openSearch(button)"></app-search>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-cover unique-left expandable-panel"
                *ngIf="leftBtnArray[sIndex]?.length > 0 && section.buttonLook === 'expandable'"
                [ngClass]="{'view-alloption': section.buttonLayout === 'horizontal', 'vertial-view': section.buttonLayout === 'vertical',
                              'sandwich-hide': section.sandwichBtnMobile, 'hamburger-button-panel':  this.isMenuShown && section.sandwichBtnMobile, 'expand-sandwich-menu': section.sandwichBtnMobile}">
                <mat-accordion>
                  <mat-expansion-panel [hidden]="button.action === 'search'"
                    [expanded]="mExpandedIndex === 'openL-'+sIndex+'-'+lIndex" hideToggle class="action-expand-icon"
                    *ngFor="let button of leftBtnArray[sIndex]; let lIndex=index"
                    [ngClass]="{'no-cursor': (button.action == 'none'), 'no-image': button.displayText, 'full-option-image': !button.displayText && button.imageUrl, 'view-alloption': section.buttonLayout == 'horizontal', 'card-type': section.buttonLook === 'card',
                    'expand-in-mobile': (button.height > 150 && button.imageUrl), 'no-margin': !button.buttonDescription, 'search-exp-btn': button.action === 'search', 'no-search-sec': button && button.action !== 'search',
                    'fadein': button.buttonAnimation === 'fade-in', 'fadeout': button.buttonAnimation === 'fade-out', 'fadein-up': button.buttonAnimation === 'fade-in-up', 'fadein-down': button.buttonAnimation === 'fade-in-down',
                    'fadein-left': button.buttonAnimation === 'fade-in-left', 'fadein-right': button.buttonAnimation === 'fade-in-right'}"
                    [disabled]="(button.action == 'none' && !button.buttonDescription)" id="exL-{{sIndex}}-{{lIndex}}"
                    (mouseover)="hover='exL-'+sIndex+'-'+lIndex" (mouseleave)="'none'"
                    [ngStyle]="hover=='exL-'+sIndex+'-'+lIndex ?
                    expandableHover('exL-'+sIndex+'-'+lIndex, section, button, 'hover') : getSecExpandableStyle(button, section)"
                    (opened)="setExpandableStyle('exL-'+sIndex+'-'+lIndex, button, true, 'hover'); mExpandedIndex = 'openL-'+sIndex+'-'+lIndex"
                    (closed)="setExpandableStyle('exL-'+sIndex+'-'+lIndex, button, false, 'normal');"
                    (click)="$event.stopPropagation()">
                    <strong style="display: none;">
                      <em>
                        {{button.title}}
                      </em>
                    </strong>
                    <mat-expansion-panel-header>
                      <mat-panel-title *ngIf="(button.imageUrl || button.displayText) && button.action !== 'search'"
                        [ngStyle]="getSecExpandableHeaderStyle(button)">
                        <div class="option-icon" *ngIf="!checkUploadItemType(button) && button.imageUrl"
                          [ngStyle]="getOptionImage(button, section)" [attr.aria-label]="button.imageAltText"></div>
                        <video *ngIf="checkUploadItemType(button)" id="{{sIndex}}_{{lIndex}}_videoExp"
                          [src]="this.imageBaseUrl+button.imageUrl" [ngStyle]="getVideoHeight(button, section)"
                          class="video-handler" autoplay="autoplay" loop="loop" preload="auto" playsinline=""
                          webkit-playsinline="" [muted]="true">
                        </video>
                        <div class="option-content" [innerHtml]="button.displayText | safeHtml"></div>
                        <mat-icon *ngIf="button.buttonDescription || button.buttons.length" class="vertical-icon">
                          keyboard_arrow_down</mat-icon>
                      </mat-panel-title>
                      <mat-panel-title class="search-box" *ngIf="button && button.action === 'search'">
                        <div class="srch-btn" type="button" (click)="openSearch(button)">
                          <mat-icon>search</mat-icon>
                          <div *ngIf="button.displayText" class="option-content"
                            [innerHtml]="button.displayText | safeHtml">
                          </div>
                        </div>
                        <div class="search-bar" *ngIf="toggleSearch" [ngClass]="{'search-visible': toggleSearch}">
                          <app-search [button]="button" (closeSearch)="openSearch(button)"></app-search>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="expand-description" *ngIf="button.action !== 'search'"
                      [ngStyle]="getExpandBtnOverlayStyle(button, section)"
                      [ngClass]="{'action-cursor': button.action !== 'none' && button.buttonDescription}">
                      <div class="expanded-detail" [innerHtml]="button.buttonDescription | safeHtml"></div>
                    </div>
                    <div class="expand-description child-btn-expand"
                      [ngClass]="{'no-padding': button.buttons.length > 0}" *ngIf="button.buttons.length"
                      [ngStyle]="getExpandBtnOverlayStyle(button, section)">
                      <app-menu-items [items]="button.buttons"
                        (clickedButton)="setExpandableStyle('exL-'+sIndex+'-'+lIndex, button, false, 'normal');">
                      </app-menu-items>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <div class="expand-search" [hidden]="button && button.action !== 'search'"
                  *ngFor="let button of leftBtnArray[sIndex]; let lIndex=index"
                  [ngClass]="{'no-cursor': (button.action == 'none'), 'no-image': button.displayText, 'full-option-image': !button.displayText && button.imageUrl, 'view-alloption': section.buttonLayout == 'horizontal', 'card-type': section.buttonLook === 'card',
                    'expand-in-mobile': (button.height > 150 && button.imageUrl), 'no-margin': !button.buttonDescription, 'search-exp-btn': button.action === 'search'}"
                  id="exL-{{sIndex}}-{{lIndex}}"
                  (opened)="setExpandableStyle('exL-'+sIndex+'-'+lIndex, button, true, 'hover'); mExpandedIndex = 'openL-'+sIndex+'-'+lIndex"
                  (closed)="setExpandableStyle('exL-'+sIndex+'-'+lIndex, button, false, 'normal');"
                  (click)="$event.stopPropagation()">
                  <strong style="display: none;">
                    <em>
                      {{button.title}}
                    </em>
                  </strong>
                  <div class="search-box" [ngStyle]="getSecExpandableStyle(button, section)">
                    <div class="srch-btn fade-up-element" type="button" (click)="openSearch(button)">
                      <mat-icon>search</mat-icon>
                      <div *ngIf="button.displayText" class="option-content"
                        [innerHtml]="button.displayText | safeHtml">
                      </div>
                    </div>
                    <div class="search-bar" *ngIf="toggleSearch" [ngClass]="{'search-visible': toggleSearch}">
                      <app-search [button]="button" (closeSearch)="openSearch(button)"></app-search>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-cover unique-left popup-btn"
                *ngIf="leftBtnArray[sIndex]?.length > 0 && section.buttonLook === 'popup'"
                [ngClass]="{'view-alloption': section.buttonLayout === 'horizontal', 'vertial-view': section.buttonLayout === 'vertical',
                            'sandwich-hide': section.sandwichBtnMobile, 'hamburger-button-panel':  this.isMenuShown && section.sandwichBtnMobile, 'expand-sandwich-menu': section.sandwichBtnMobile}">
                <div *ngFor="let button of leftBtnArray[sIndex]; let bIndex=index">
                  <strong style="display: none;">
                    <em>
                      {{button.title}}
                    </em>
                  </strong>
                  <div class="button-div input-group no-flip"
                    [ngClass]="{'full-option-image': (!button.displayText && !button.buttonDescription && button.imageUrl) || (!button.displayText && section.buttonLook !== 'card' && button.imageUrl), 'btn-graphic': button.imageUrl,
                                'button-graphic': !button.displayText && !button.buttonDescription, 'full-video': (button.width >= 320 && button.imageUrl && !button.displayText && checkUploadItemType(button))}"
                    [ngStyle]="getSectionBtnXYAxis(button)">
                    <button class="flip-card slide-effect" *ngIf="button && button.action === 'search'" [ngClass]="{'fadein': button.buttonAnimation === 'fade-in', 'fadeout': button.buttonAnimation === 'fade-out', 'fadein-up': button.buttonAnimation === 'fade-in-up', 'fadein-down': button.buttonAnimation === 'fade-in-down',
                      'fadein-left': button.buttonAnimation === 'fade-in-left', 'fadein-right': button.buttonAnimation === 'fade-in-right', 'enter-hide': button.openNewTab === 'sectionAuto',
                      'no-display': !button.displayText && !button.buttonDescription && !button.imageUrl}"
                      [disabled]="(button.action == 'none' && !button.buttonDescription)"
                      (mouseover)="hover='l'+sIndex+'_'+bIndex" (mouseleave)="hover='none'"
                      [ngStyle]="getSectionBtnWidth(button)">
                      <div class="button-body"
                        [ngClass]="{'full-option-image': !button.displayText, 'center-align-content': !button.imageUrl, 'image-mobile-height': button.width > 350,
                          'no-front-content': !button.displayText && !button.imageUrl, 'mobile-image': (button.width >= 401 && button.imageUrl && !button.displayText && !checkUploadItemType(button))}"
                        [ngStyle]="hover=='l'+sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                        <div class="card-front-slide" [ngClass]="{'no-card-img': !button.imageUrl}">
                          <div class="option-icon" *ngIf="!checkUploadItemType(button) && button.imageUrl"
                            [ngStyle]="getOptionImage(button, section)" [attr.aria-label]="button.imageAltText"></div>
                          <video *ngIf="checkUploadItemType(button)" id="{{sIndex}}_{{bIndex}}_video"
                            [src]="this.imageBaseUrl+button.imageUrl" [ngStyle]="getVideoHeight(button, section)"
                            class="video-handler" autoplay="autoplay" loop="loop" preload="auto" playsinline=""
                            webkit-playsinline="" [muted]="true">
                          </video>
                          <div class="option-content" [innerHtml]="button.displayText | safeHtml">
                          </div>
                        </div>
                      </div>
                    </button>
                    <div class="search-box" *ngIf="button && button.action === 'search'">
                      <div class="srch-btn fade-up-element" type="button" (click)="openSearch(button)"
                        [ngStyle]="hover=='l'+sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                        <mat-icon>search</mat-icon>
                        <div *ngIf="button.displayText" class="option-content"
                          [innerHtml]="button.displayText | safeHtml"></div>
                      </div>
                      <div class="search-bar" *ngIf="toggleSearch" [ngClass]="{'search-visible': toggleSearch}">
                        <app-search [button]="button" (closeSearch)="openSearch(button)"></app-search>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!---Center align button-->
              <div class="btn-cover unique-center"
                *ngIf="section.buttonLook !== 'expandable' && section.buttonLook !== 'popup'"
                [ngClass]="{'view-alloption': section.buttonLayout === 'horizontal', 'vertial-view': section.buttonLayout === 'vertical', 'align-left': section.buttonAlignment === 'left', 'align-right': section.buttonAlignment === 'right',
                'sandwich-hide': section.sandwichBtnMobile, 'hamburger-button-panel':  this.isMenuShown && section.sandwichBtnMobile, 'expand-sandwich-menu': section.sandwichBtnMobile, 'unique-c-align': section.uniqueBtnCenter === 'center'}">
                <div *ngFor="let button of centerBtnArray[sIndex]; let bIndex=index" class="btn-layer"
                  [ngClass]="{'no-unique-align': !button.uniqueBtn, 'unique-align': button.uniqueBtn, 'unique-left-vertical': button.uniqueBtn && button.uniqueBtnAlignment === 'left' && section.buttonLayout === 'vertical',
                  'unique-right-vertical': button.uniqueBtn && button.uniqueBtnAlignment === 'right' && section.buttonLayout === 'vertical', 'no-search-sec': button && button.action !== 'search', 'center-unique': button.uniqueBtnAlignment === 'center'}">
                  <!--unique align--->
                  <strong style="display: none;">
                    <em>
                      {{button.title}}
                    </em>
                  </strong>
                  <div *ngIf="section.buttonLook !== 'expandable'" class="button-div input-group no-flip"
                    [ngClass]="{'button-card': section.buttonLook == 'card' || section.buttonLook == 'transparent' || (!button.imageUrl && !button.displayText), 'circle-button': section.buttonLook == 'circle', 'transparent-button': section.buttonLook == 'transparent',
                              'edgy-button': section.buttonLook == 'edgy', 'full-option-image': (!button.displayText && !button.buttonDescription && button.imageUrl) || (!button.displayText && section.buttonLook !== 'card' && button.imageUrl), 
                              'button-height': (section.buttonLook == 'card' || section.buttonLook == 'transparent') && button.buttonDescription, 'button-graphic': !button.displayText && !button.buttonDescription && (section.buttonLook == 'card'
                              || section.buttonLook == 'transparent'), 'card-type': section.buttonLook === 'card', 'full-video': (button.width >= 320 && button.imageUrl && !button.displayText && checkUploadItemType(button)), 'search-position': button.action === 'search'}"
                    [ngStyle]="getSectionBtnXYAxis(button)">
                    <button class="flip-card slide-effect" *ngIf="button && button.action !== 'search'" [ngClass]="{'fadein': button.buttonAnimation === 'fade-in', 'fadeout': button.buttonAnimation === 'fade-out', 'fadein-up': button.buttonAnimation === 'fade-in-up', 'fadein-down': button.buttonAnimation === 'fade-in-down',
                      'fadein-left': button.buttonAnimation === 'fade-in-left', 'fadein-right': button.buttonAnimation === 'fade-in-right', 'enter-hide': button.openNewTab === 'sectionAuto', 'sandwich-mobile-btn': section.buttonLook === 'circle' && !button.displayText,
                      'no-display': !button.displayText && !button.buttonDescription && !button.imageUrl}"
                      [disabled]="(button.action == 'none' && !button.buttonDescription)"
                      (mouseover)="hover='c'+sIndex+'_'+bIndex" (mouseleave)="hover='none'"
                      (ontouchstart)="hover=sIndex+'_'+bIndex" [ngStyle]="getSectionBtnWidth(button)">
                      <div class="button-body"
                        [ngClass]="{'no-cursor': (button.action === 'none'), 'full-option-image': !button.displayText && button.imageUrl, 'center-align-content': !button.imageUrl, 
                          'no-front-content': !button.displayText && !button.imageUrl, 'button-height-auto': (section.buttonLook == 'card' || section.buttonLook == 'transparent') && (button.displayText || button.buttonDescription), 'mobile-image': (button.width >= 401 && button.imageUrl && !button.displayText && !checkUploadItemType(button))}"
                        [ngStyle]="hover=='c'+sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                        <div class="card-front-slide" [ngClass]="{'graphic-height': button.displayText || button.buttonDescription && (section.buttonLook === 'card'
                                                || section.buttonLook === 'transparent')}">
                          <div class="option-icon" *ngIf="!checkUploadItemType(button) && button.imageUrl"
                            [ngStyle]="getOptionImage(button, section)" [attr.aria-label]="button.imageAltText"></div>
                          <video *ngIf="checkUploadItemType(button)" id="{{sIndex}}_{{bIndex}}_video"
                            [src]="this.imageBaseUrl+button.imageUrl" [ngStyle]="getVideoHeight(button, section)"
                            class="video-handler" autoplay="autoplay" loop="loop" preload="auto" playsinline=""
                            webkit-playsinline="" [muted]="true">
                          </video>
                          <div class="option-content" [innerHtml]="button.displayText | safeHtml">
                          </div>
                        </div>
                        <div *ngIf="button.buttonDescription" class="card-back-slide overlay"
                          [ngStyle]="hover=='c'+sIndex+'_'+bIndex ? getSectionButtonOverlayStyle(button, 'hover', section) : getSectionButtonOverlayStyle(button, 'normal', section)"
                          id="button-desc-{{sIndex}}-{{bIndex}}" [innerHtml]="button.buttonDescription | safeHtml">
                        </div>
                      </div>
                      <div id="section-{{sIndex}}-btn-{{bIndex}}" class="btn-auto-view"
                        *ngIf="button.openNewTab === 'button' ||  button.openNewTab === 'buttonAuto'">
                        <app-viewer-container [url]="button.nextAction" [type]="button.action"
                          [altText]="button.nxtAltText"></app-viewer-container>
                      </div>
                    </button>
                    <div class="search-box" *ngIf="button && button.action === 'search'">
                      <div class="srch-btn fade-up-element" type="button" (click)="openSearch(button)"
                        [ngStyle]="hover=='c'+sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                        <mat-icon>search</mat-icon>
                        <div *ngIf="button.displayText" class="option-content"
                          [innerHtml]="button.displayText | safeHtml"></div>
                      </div>
                      <div class="search-bar" *ngIf="toggleSearch" [ngClass]="{'search-visible': toggleSearch}">
                        <app-search [button]="button" (closeSearch)="openSearch(button)"></app-search>
                      </div>
                    </div>
                  </div>
                  <!---unique align end--->
                </div>
              </div>
              <div class="btn-cover unique-center expandable-panel test-expand-467"
                *ngIf="section.buttonLook === 'expandable'"
                [ngClass]="{'view-alloption': section.buttonLayout === 'horizontal', 'vertial-view': section.buttonLayout === 'vertical', 'align-left': section.buttonAlignment === 'left', 'align-right': section.buttonAlignment === 'right', 'align-center': section.buttonAlignment === 'center',
                'sandwich-hide': section.sandwichBtnMobile, 'hamburger-button-panel':  this.isMenuShown && section.sandwichBtnMobile, 'expand-sandwich-menu': section.sandwichBtnMobile, 'unique-c-align': section.uniqueBtnCenter  === 'center'}">
                <mat-accordion>
                  <mat-expansion-panel [hidden]="button.action === 'search'"
                    [expanded]="mExpandedIndex === 'openC-'+sIndex+'-'+cIndex" hideToggle class="action-expand-icon"
                    *ngFor="let button of centerBtnArray[sIndex]; let cIndex=index"
                    [ngClass]="{'no-cursor': (button.action == 'none'), 'no-image': button.displayText, 'full-option-image': !button.displayText && button.imageUrl, 'view-alloption': section.buttonLayout == 'horizontal', 'card-type': section.buttonLook === 'card',
                    'expand-in-mobile': (button.height > 150 && button.imageUrl), 'no-margin': !button.buttonDescription, 'unique-align-center': (section.buttonAlignment === 'left' && button.uniqueBtn && button.uniqueBtnAlignment === 'center')
                    || (section.buttonAlignment === 'right' && button.uniqueBtn && button.uniqueBtnAlignment === 'center'), 'search-exp-btn': button.action === 'search',
                    'no-search-sec': button && button.action !== 'search', 'fadein': button.buttonAnimation === 'fade-in', 'fadeout': button.buttonAnimation === 'fade-out', 'fadein-up': button.buttonAnimation === 'fade-in-up', 'fadein-down': button.buttonAnimation === 'fade-in-down',
                    'fadein-left': button.buttonAnimation === 'fade-in-left', 'fadein-right': button.buttonAnimation === 'fade-in-right'}"
                    [disabled]="(button.action == 'none' && (!button.buttonDescription && !button.buttons.length))"
                    id="exC-{{sIndex}}-{{cIndex}}" (mouseover)="hover='exC-'+sIndex+'-'+cIndex" (mouseleave)="'none'"
                    [ngStyle]="hover=='exC-'+sIndex+'-'+cIndex ? expandableHover('exC-'+sIndex+'-'+cIndex, section, button, 'hover') : getSecExpandableStyle(button, section)"
                    (opened)="setExpandableStyle('exC-'+sIndex+'-'+cIndex, button, true, 'hover'); mExpandedIndex = 'openC-'+sIndex+'-'+cIndex"
                    (closed)="setExpandableStyle('exC-'+sIndex+'-'+cIndex, button, false, 'normal');"
                    (click)="$event.stopPropagation()">
                    <strong style="display: none;">
                      <em>
                        {{button.title}}
                      </em>
                    </strong>
                    <mat-expansion-panel-header>
                      <mat-panel-title *ngIf="(button.imageUrl || button.displayText)"
                        [ngStyle]="getSecExpandableHeaderStyle(button)">
                        <div class="option-icon" *ngIf="!checkUploadItemType(button) && button.imageUrl"
                          [ngStyle]="getOptionImage(button, section)" [attr.aria-label]="button.imageAltText"></div>
                        <video *ngIf="checkUploadItemType(button)" id="{{sIndex}}_{{cIndex}}_videoExp"
                          [src]="this.imageBaseUrl+button.imageUrl" [ngStyle]="getVideoHeight(button, section)"
                          class="video-handler" autoplay="autoplay" loop="loop" preload="auto" playsinline=""
                          webkit-playsinline="" [muted]="true">
                        </video>
                        <div class="option-content" [innerHtml]="button.displayText | safeHtml"></div>
                        <mat-icon *ngIf="button.buttonDescription || button.buttons.length" class="vertical-icon">
                          keyboard_arrow_down</mat-icon>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="expand-description" *ngIf="button.buttonDescription"
                      [ngStyle]="getExpandBtnOverlayStyle(button, section)"
                      [ngClass]="{'action-cursor': button.action !== 'none' && button.buttonDescription}">
                      <div class="expanded-detail" [innerHtml]="button.buttonDescription | safeHtml"></div>
                    </div>
                    <div class="expand-description child-btn-expand"
                      [ngClass]="{'no-padding': button.buttons.length > 0}" *ngIf="button.buttons.length"
                      [ngStyle]="getExpandBtnOverlayStyle(button, section)">
                      <app-menu-items [items]="button.buttons"
                        (clickedButton)="setExpandableStyle('exC-'+sIndex+'-'+cIndex, button, false, 'normal');">
                      </app-menu-items>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <div class="expand-search" [hidden]="button && button.action !== 'search'"
                  *ngFor="let button of centerBtnArray[sIndex]; let cIndex=index"
                  [ngClass]="{'no-cursor': (button.action == 'none'), 'no-image': button.displayText, 'full-option-image': !button.displayText && button.imageUrl, 'view-alloption': section.buttonLayout == 'horizontal', 'card-type': section.buttonLook === 'card',
                  'expand-in-mobile': (button.height > 150 && button.imageUrl), 'no-margin': !button.buttonDescription, 'unique-align-center': (section.buttonAlignment === 'left' && button.uniqueBtn && button.uniqueBtnAlignment === 'center')
                  || (section.buttonAlignment === 'right' && button.uniqueBtn && button.uniqueBtnAlignment === 'center'), 'search-exp-btn': button.action === 'search'}"
                  id="exC-{{sIndex}}-{{cIndex}}"
                  (opened)="setExpandableStyle('exC-'+sIndex+'-'+cIndex, button, true, 'hover'); mExpandedIndex = 'openC-'+sIndex+'-'+cIndex"
                  (closed)="setExpandableStyle('exC-'+sIndex+'-'+cIndex, button, false, 'normal');"
                  (click)="$event.stopPropagation()">
                  <strong style="display: none;">
                    <em>
                      {{button.title}}
                    </em>
                  </strong>
                  <div class="search-box" [ngStyle]="getSecExpandableStyle(button, section)">
                    <div class="srch-btn fade-up-element" type="button" (click)="openSearch(button)">
                      <mat-icon>search</mat-icon>
                      <div *ngIf="button.displayText" class="option-content"
                        [innerHtml]="button.displayText | safeHtml">
                      </div>
                    </div>
                    <div class="search-bar" *ngIf="toggleSearch" [ngClass]="{'search-visible': toggleSearch}">
                      <app-search [button]="button" (closeSearch)="openSearch(button)"></app-search>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-cover unique-center popup-btn"
                *ngIf="centerBtnArray[sIndex]?.length > 0 && section.buttonLook === 'popup'"
                [ngClass]="{'view-alloption': section.buttonLayout === 'horizontal', 'vertial-view': section.buttonLayout === 'vertical', 'align-left': section.buttonAlignment === 'left', 'align-right': section.buttonAlignment === 'right', 'align-center':
                section.buttonAlignment === 'center', 'sandwich-hide': section.sandwichBtnMobile, 'hamburger-button-panel':  this.isMenuShown && section.sandwichBtnMobile, 'expand-sandwich-menu': section.sandwichBtnMobile, 'unique-c-align': section.uniqueBtnCenter === 'center'}">
                <div *ngFor="let button of centerBtnArray[sIndex]; let bIndex=index">
                  <div class="button-div input-group no-flip"
                    [ngClass]="{'full-option-image': (!button.displayText && !button.buttonDescription && button.imageUrl) || (!button.displayText && section.buttonLook !== 'card' && button.imageUrl), 'no-search-sec': button && button.action !== 'search',
                    'button-graphic': !button.displayText && !button.buttonDescription, 'full-video': (button.width >= 320 && button.imageUrl && !button.displayText && checkUploadItemType(button)), 'btn-graphic': button.imageUrl}"
                    [ngStyle]="getSectionBtnXYAxis(button)">
                    <strong style="display: none;">
                      <em>
                        {{button.title}}
                      </em>
                    </strong>
                    <button class="flip-card slide-effect" *ngIf="button && button.action !== 'search'"
                      [ngClass]="{'fadein': button.buttonAnimation === 'fade-in', 'fadeout': button.buttonAnimation === 'fade-out', 'fadein-up': button.buttonAnimation === 'fade-in-up', 'fadein-down': button.buttonAnimation === 'fade-in-down',
                      'fadein-left': button.buttonAnimation === 'fade-in-left', 'fadein-right': button.buttonAnimation === 'fade-in-right', 'enter-hide': button.openNewTab === 'sectionAuto', 'no-display': !button.displayText && !button.buttonDescription && !button.imageUrl }"
                      [disabled]="(button.action == 'none' && !button.buttonDescription)"
                      (mouseover)="hover='l'+sIndex+'_'+bIndex" (mouseleave)="hover='none'"
                      [ngStyle]="getSectionBtnWidth(button)">
                      <div class="button-body"
                        [ngClass]="{'full-option-image': !button.displayText && button.imageUrl, 'center-align-content': !button.imageUrl, 'image-mobile-height': button.width > 350,
                          'no-front-content': !button.displayText && !button.imageUrl, 'mobile-image': (button.width >= 401 && button.imageUrl && !button.displayText && !checkUploadItemType(button))}"
                        [ngStyle]="hover=='l'+sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                        <div class="card-front-slide" [ngClass]="{'no-card-img': !button.imageUrl}">
                          <div class="option-icon" *ngIf="!checkUploadItemType(button) && button.imageUrl"
                            [ngStyle]="getOptionImage(button, section)" [attr.aria-label]="button.imageAltText"></div>
                          <video *ngIf="checkUploadItemType(button)" id="{{sIndex}}_{{bIndex}}_video"
                            [src]="this.imageBaseUrl+button.imageUrl" [ngStyle]="getVideoHeight(button, section)"
                            class="video-handler" autoplay="autoplay" loop="loop" preload="auto" playsinline=""
                            webkit-playsinline="" [muted]="true">
                          </video>
                          <div class="option-content" [innerHtml]="button.displayText | safeHtml">
                          </div>
                        </div>
                      </div>
                    </button>
                    <div class="search-box" *ngIf="button && button.action === 'search'">
                      <div class="srch-btn fade-up-element" type="button" (click)="openSearch(button)"
                        [ngStyle]="hover=='c'+sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                        <mat-icon>search</mat-icon>
                        <div *ngIf="button.displayText" class="option-content"
                          [innerHtml]="button.displayText | safeHtml"></div>
                      </div>
                      <div class="search-bar" *ngIf="toggleSearch" [ngClass]="{'search-visible': toggleSearch}">
                        <app-search [button]="button" (closeSearch)="openSearch(button)"></app-search>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!---Right align button-->
              <div class="btn-cover unique-right"
                *ngIf="rightBtnArray[sIndex]?.length > 0 && section.buttonLook !== 'expandable' && section.buttonLook !== 'popup'"
                [ngClass]="{'view-alloption': section.buttonLayout === 'horizontal', 'vertial-view': section.buttonLayout === 'vertical',
                'sandwich-hide': section.sandwichBtnMobile, 'hamburger-button-panel':  this.isMenuShown && section.sandwichBtnMobile, 'expand-sandwich-menu': section.sandwichBtnMobile}">
                <div *ngFor="let button of rightBtnArray[sIndex]; let bIndex=index">
                  <div class="button-div input-group no-flip"
                    [ngClass]="{'button-card': section.buttonLook == 'card' || section.buttonLook == 'transparent' || (!button.imageUrl && !button.displayText), 'circle-button': section.buttonLook == 'circle', 'transparent-button': section.buttonLook == 'transparent',
                    'edgy-button': section.buttonLook == 'edgy', 'full-option-image': (!button.displayText && !button.buttonDescription && button.imageUrl) || (!button.displayText && section.buttonLook !== 'card' && button.imageUrl), 'no-search-sec': button && button.action !== 'search',
                    'button-height': (section.buttonLook == 'card' || section.buttonLook == 'transparent') && button.buttonDescription, 'button-graphic': !button.displayText && !button.buttonDescription && (section.buttonLook == 'card'
                    || section.buttonLook == 'transparent'), 'card-type': section.buttonLook === 'card', 'full-video': (button.width >= 320 && button.imageUrl && !button.displayText && checkUploadItemType(button))}"
                    [ngStyle]="getSectionBtnXYAxis(button)">
                    <strong style="display: none;">
                      <em>
                        {{button.title}}
                      </em>
                    </strong>
                    <button class="flip-card slide-effect" *ngIf="button && button.action !== 'search'"
                      [ngClass]="{'fadein': button.buttonAnimation === 'fade-in', 'fadeout': button.buttonAnimation === 'fade-out', 'fadein-up': button.buttonAnimation === 'fade-in-up', 'fadein-down': button.buttonAnimation === 'fade-in-down',
                      'fadein-left': button.buttonAnimation === 'fade-in-left', 'fadein-right': button.buttonAnimation === 'fade-in-right', 'enter-hide': button.openNewTab === 'sectionAuto', 'sandwich-mobile-btn': section.buttonLook === 'circle' && !button.displayText, 'no-display': !button.displayText && !button.buttonDescription && !button.imageUrl}"
                      [disabled]="(button.action == 'none' && !button.buttonDescription)"
                      (mouseover)="hover='r'+sIndex+'_'+bIndex" (mouseleave)="hover='none'"
                      (ontouchstart)="hover=sIndex+'_'+bIndex" [ngStyle]="getSectionBtnWidth(button)">
                      <div class="button-body"
                        [ngClass]="{'no-cursor': (button.action === 'none'), 'full-option-image': !button.displayText && button.imageUrl, 'center-align-content': !button.imageUrl, 
                          'no-front-content': !button.displayText && !button.imageUrl, 'button-height-auto': (section.buttonLook == 'card' || section.buttonLook == 'transparent') && (button.displayText || button.buttonDescription), 'mobile-image': (button.width >= 401 && button.imageUrl && !button.displayText && !checkUploadItemType(button))}"
                        [ngStyle]="hover=='r'+sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                        <div class="card-front-slide" [ngClass]="{'graphic-height': button.displayText || button.buttonDescription && (section.buttonLook === 'card'
                                                            || section.buttonLook === 'transparent')}">
                          <div class="option-icon" *ngIf="!checkUploadItemType(button) && button.imageUrl"
                            [ngStyle]="getOptionImage(button, section)" [attr.aria-label]="button.imageAltText"></div>
                          <video *ngIf="checkUploadItemType(button)" id="{{sIndex}}_{{bIndex}}_video"
                            [src]="this.imageBaseUrl+button.imageUrl" [ngStyle]="getVideoHeight(button, section)"
                            class="video-handler" autoplay="autoplay" loop="loop" preload="auto" playsinline=""
                            webkit-playsinline="" [muted]="true">
                          </video>
                          <div class="option-content" [innerHtml]="button.displayText | safeHtml">
                          </div>
                        </div>
                        <div *ngIf="button.buttonDescription" class="card-back-slide overlay"
                          [ngStyle]="hover=='r'+sIndex+'_'+bIndex ? getSectionButtonOverlayStyle(button, 'hover', section) : getSectionButtonOverlayStyle(button, 'normal', section)"
                          id="button-desc-{{sIndex}}-{{bIndex}}" [innerHtml]="button.buttonDescription | safeHtml">
                        </div>
                      </div>
                      <div class="btn-auto-view"
                        *ngIf="button.openNewTab === 'button' ||  button.openNewTab === 'buttonAuto'"
                        id="section-{{sIndex}}-btn-{{bIndex}}">
                        <app-viewer-container [url]="button.nextAction" [type]="button.action"
                          [altText]="button.nxtAltText"></app-viewer-container>
                      </div>
                    </button>
                    <div class="search-box" *ngIf="button && button.action === 'search'">
                      <div class="srch-btn fade-up-element" type="button" (click)="openSearch(button)"
                        [ngStyle]="hover=='r'+sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                        <mat-icon>search</mat-icon>
                        <div *ngIf="button.displayText" class="option-content"
                          [innerHtml]="button.displayText | safeHtml"></div>
                      </div>
                      <div class="search-bar" *ngIf="toggleSearch" [ngClass]="{'search-visible': toggleSearch}">
                        <app-search [button]="button" (closeSearch)="openSearch(button)"></app-search>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-cover unique-right expandable-panel"
                *ngIf="rightBtnArray[sIndex]?.length > 0 && section.buttonLook === 'expandable'"
                [ngClass]="{'view-alloption': section.buttonLayout === 'horizontal', 'vertial-view': section.buttonLayout === 'vertical',
                'sandwich-hide': section.sandwichBtnMobile, 'hamburger-button-panel':  this.isMenuShown && section.sandwichBtnMobile, 'expand-sandwich-menu': section.sandwichBtnMobile}">
                <mat-accordion *ngIf="section.buttonLook === 'expandable'">
                  <mat-expansion-panel [hidden]="button.action === 'search'"
                    [expanded]="mExpandedIndex === 'openR-'+sIndex+'-'+aIndex" hideToggle class="action-expand-icon"
                    *ngFor="let button of rightBtnArray[sIndex]; let aIndex=index"
                    [ngClass]="{'no-cursor': (button.action == 'none'), 'no-image': button.displayText, 'full-option-image': !button.displayText && button.imageUrl, 'view-alloption': section.buttonLayout == 'horizontal', 'card-type': section.buttonLook === 'card',
                    'expand-in-mobile': (button.height > 150 && button.imageUrl), 'no-margin': !button.buttonDescription, 'search-exp-btn': button.action === 'search', 'no-search-sec': button && button.action !== 'search',
                    'fadein': button.buttonAnimation === 'fade-in', 'fadeout': button.buttonAnimation === 'fade-out', 'fadein-up': button.buttonAnimation === 'fade-in-up', 'fadein-down': button.buttonAnimation === 'fade-in-down',
                    'fadein-left': button.buttonAnimation === 'fade-in-left', 'fadein-right': button.buttonAnimation === 'fade-in-right'}"
                    [disabled]="(button.action == 'none' && !button.buttonDescription)" id="exR-{{sIndex}}-{{aIndex}}"
                    (mouseover)="hover='exR-'+sIndex+'-'+aIndex" (mouseleave)="'none'"
                    [ngStyle]="hover=='exR-'+sIndex+'-'+aIndex ? expandableHover('exR-'+sIndex+'-'+aIndex, section, button, 'hover') : getSecExpandableStyle(button, section)"
                    (opened)="setExpandableStyle('exR-'+sIndex+'-'+aIndex, button, true, 'hover'); mExpandedIndex = 'openR-'+sIndex+'-'+aIndex"
                    (closed)="setExpandableStyle('exR-'+sIndex+'-'+aIndex, button, false, 'normal');"
                    (click)="$event.stopPropagation()">
                    <strong style="display: none;">
                      <em>
                        {{button.title}}
                      </em>
                    </strong>
                    <mat-expansion-panel-header>
                      <mat-panel-title *ngIf="(button.imageUrl || button.displayText) && button.action !== 'search'"
                        [ngStyle]="getSecExpandableHeaderStyle(button)">
                        <div class="option-icon" *ngIf="!checkUploadItemType(button) && button.imageUrl"
                          [ngStyle]="getOptionImage(button, section)" [attr.aria-label]="button.imageAltText"></div>
                        <video *ngIf="checkUploadItemType(button)" id="{{sIndex}}_{{aIndex}}_videoExp"
                          [src]="this.imageBaseUrl+button.imageUrl" [ngStyle]="getVideoHeight(button, section)"
                          class="video-handler" autoplay="autoplay" loop="loop" preload="auto" playsinline=""
                          webkit-playsinline="" [muted]="true">
                        </video>
                        <div class="option-content" [innerHtml]="button.displayText | safeHtml"></div>
                        <mat-icon *ngIf="button.buttonDescription || button.buttons.length" class="vertical-icon">
                          keyboard_arrow_down</mat-icon>
                      </mat-panel-title>
                      <mat-panel-title class="search-box" *ngIf="button && button.action === 'search'">
                        <div class="srch-btn" type="button" (click)="openSearch(button)">
                          <mat-icon>search</mat-icon>
                          <div *ngIf="button.displayText" class="option-content"
                            [innerHtml]="button.displayText | safeHtml">
                          </div>
                        </div>
                        <div class="search-bar" *ngIf="toggleSearch" [ngClass]="{'search-visible': toggleSearch}">
                          <app-search [button]="button" (closeSearch)="openSearch(button)"></app-search>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="expand-description" *ngIf="button.action !== 'search'"
                      [ngStyle]="getExpandBtnOverlayStyle(button, section)"
                      [ngClass]="{'action-cursor': button.action !== 'none' && button.buttonDescription}">
                      <div class="expanded-detail" [innerHtml]="button.buttonDescription | safeHtml"></div>
                    </div>
                    <div class="expand-description child-btn-expand"
                      [ngClass]="{'no-padding': button.buttons.length > 0}" *ngIf="button.buttons.length"
                      [ngStyle]="getExpandBtnOverlayStyle(button, section)">
                      <app-menu-items [items]="button.buttons"
                        (clickedButton)="setExpandableStyle('exR-'+sIndex+'-'+aIndex, button, false, 'normal');">
                      </app-menu-items>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <div class="expand-search" [hidden]="button && button.action !== 'search'"
                  *ngFor="let button of rightBtnArray[sIndex]; let aIndex=index"
                  [ngClass]="{'no-cursor': (button.action == 'none'), 'no-image': button.displayText, 'full-option-image': !button.displayText && button.imageUrl, 'view-alloption': section.buttonLayout == 'horizontal', 'card-type': section.buttonLook === 'card',
                    'expand-in-mobile': (button.height > 150 && button.imageUrl), 'no-margin': !button.buttonDescription, 'search-exp-btn': button.action === 'search'}"
                  id="exR-{{sIndex}}-{{aIndex}}"
                  (opened)="setExpandableStyle('exR-'+sIndex+'-'+aIndex, button, true, 'hover'); mExpandedIndex = 'openR-'+sIndex+'-'+aIndex"
                  (closed)="setExpandableStyle('exR-'+sIndex+'-'+aIndex, button, false, 'normal');"
                  (click)="$event.stopPropagation()">
                  <strong style="display: none;">
                    <em>
                      {{button.title}}
                    </em>
                  </strong>
                  <div class="search-box" [ngStyle]="getSecExpandableStyle(button, section)">
                    <div class="srch-btn fade-up-element" type="button" (click)="openSearch(button)">
                      <mat-icon>search</mat-icon>
                      <div *ngIf="button.displayText" class="option-content"
                        [innerHtml]="button.displayText | safeHtml">
                      </div>
                    </div>
                    <div class="search-bar" *ngIf="toggleSearch" [ngClass]="{'search-visible': toggleSearch}">
                      <app-search [button]="button" (closeSearch)="openSearch(button)"></app-search>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-cover unique-right popup-btn"
                *ngIf="rightBtnArray[sIndex]?.length > 0 && section.buttonLook === 'popup'"
                [ngClass]="{'view-alloption': section.buttonLayout === 'horizontal', 'vertial-view': section.buttonLayout === 'vertical',
                'sandwich-hide': section.sandwichBtnMobile, 'hamburger-button-panel':  this.isMenuShown && section.sandwichBtnMobile, 'expand-sandwich-menu': section.sandwichBtnMobile}">
                <div *ngFor="let button of rightBtnArray[sIndex]; let bIndex=index">
                  <div class="button-div input-group no-flip"
                    [ngClass]="{'full-option-image': (!button.displayText && !button.buttonDescription && button.imageUrl) || (!button.displayText && section.buttonLook !== 'card' && button.imageUrl), 'no-search-sec': button && button.action !== 'search', 'btn-graphic': button.imageUrl,
                    'button-graphic': !button.displayText && !button.buttonDescription, 'full-video': (button.width >= 320 && button.imageUrl && !button.displayText && checkUploadItemType(button))}"
                    [ngStyle]="getSectionBtnXYAxis(button)">
                    <strong style="display: none;">
                      <em>
                        {{button.title}}
                      </em>
                    </strong>
                    <button class="flip-card slide-effect" *ngIf="button && button.action !== 'search'" [ngClass]="{'fadein': button.buttonAnimation === 'fade-in', 'fadeout': button.buttonAnimation === 'fade-out', 'fadein-up': button.buttonAnimation === 'fade-in-up', 'fadein-down': button.buttonAnimation === 'fade-in-down',
                      'fadein-left': button.buttonAnimation === 'fade-in-left', 'fadein-right': button.buttonAnimation === 'fade-in-right', 'enter-hide': button.openNewTab === 'sectionAuto',
                      'no-display': !button.displayText && !button.buttonDescription && !button.imageUrl}"
                      [disabled]="(button.action == 'none' && !button.buttonDescription)"
                      (mouseover)="hover='l'+sIndex+'_'+bIndex" (mouseleave)="hover='none'"
                      [ngStyle]="getSectionBtnWidth(button)">
                      <div class="button-body"
                        [ngClass]="{'full-option-image': !button.displayText, 'center-align-content': !button.imageUrl, 'image-mobile-height': button.width > 350,
                        'no-front-content': !button.displayText && !button.imageUrl, 'mobile-image': (button.width >= 401 && button.imageUrl && !button.displayText && !checkUploadItemType(button))}"
                        [ngStyle]="hover=='l'+sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                        <div class="card-front-slide" [ngClass]="{'no-card-img': !button.imageUrl}">
                          <div class="option-icon" *ngIf="!checkUploadItemType(button) && button.imageUrl"
                            [ngStyle]="getOptionImage(button, section)" [attr.aria-label]="button.imageAltText"></div>
                          <video *ngIf="checkUploadItemType(button)" id="{{sIndex}}_{{bIndex}}_video"
                            [src]="this.imageBaseUrl+button.imageUrl" [ngStyle]="getVideoHeight(button, section)"
                            class="video-handler" autoplay="autoplay" loop="loop" preload="auto" playsinline=""
                            webkit-playsinline="" [muted]="true">
                          </video>
                          <div class="option-content" [innerHtml]="button.displayText | safeHtml">
                          </div>
                        </div>
                      </div>
                    </button>
                    <div class="search-box" *ngIf="button && button.action === 'search'">
                      <div class="srch-btn fade-up-element" type="button" (click)="openSearch(button)"
                        [ngStyle]="hover=='r'+sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                        <mat-icon>search</mat-icon>
                        <div *ngIf="button.displayText" class="option-content"
                          [innerHtml]="button.displayText | safeHtml"></div>
                      </div>
                      <div class="search-bar" *ngIf="toggleSearch" [ngClass]="{'search-visible': toggleSearch}">
                        <app-search [button]="button" (closeSearch)="openSearch(button)"></app-search>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-----Basic button end------->
            <!--sandwich for basic--->
            <div class="basic-btn sandwich-mobile" [ngStyle]="getButtonAlignment(section)"
              [attr.aria-label]="section.bgAltText"
              [ngClass]="{'hamburger-button-panel':  this.isMenuShown && section.sandwichBtnMobile, 'left-side-menu': section.buttonAlignment == 'left'}"
              *ngIf="(section.buttonLayout === 'horizontal' || section.buttonLayout === 'vertical') && section.buttons.length > 0 && (section.buttonLook === 'circle' || section.sandwichBtnMobile)">
              <div class="btn-cover unique-center" *ngIf="section.buttonLook !== 'expandable'"
                [ngClass]="{'view-alloption': section.buttonLayout === 'horizontal', 'vertial-view': section.buttonLayout === 'vertical', 'align-left': section.buttonAlignment === 'left', 'align-right': section.buttonAlignment === 'right',
                'sandwich-panel': section.sandwichBtnMobile && !this.isMenuShown, 'expand-sandwich-menu': section.sandwichBtnMobile}">
                <div *ngFor="let button of sandwichBtnArray[sIndex]; let bIndex=index"
                  [ngClass]="{'no-unique-align': !button.uniqueBtn, 'unique-align': button.uniqueBtn}">
                  <strong style="display: none;">
                    <em>
                      {{button.title}}
                    </em>
                  </strong>
                  <div class="button-div input-group no-flip" *ngIf="!button.excludeSandwichBtn" [ngClass]="{'button-card': section.buttonLook == 'card' || section.buttonLook == 'transparent' || (!button.imageUrl && !button.displayText), 'circle-button': section.buttonLook == 'circle', 'transparent-button': section.buttonLook == 'transparent',
                    'edgy-button': section.buttonLook == 'edgy', 'full-option-image': (!button.displayText && !button.buttonDescription && button.imageUrl) || (!button.displayText && section.buttonLook !== 'card' && button.imageUrl), 'no-search-sec': button && button.action !== 'search',
                    'button-height': (section.buttonLook == 'card' || section.buttonLook == 'transparent') && button.buttonDescription, 'button-graphic': !button.displayText && !button.buttonDescription && (section.buttonLook == 'card'
                    || section.buttonLook == 'transparent'), 'card-type': section.buttonLook === 'card'}"
                    [ngStyle]="getSectionBtnXYAxis(button)">
                    <button class="flip-card slide-effect" *ngIf="button && button.action !== 'search'" [ngClass]="{'fadein': button.buttonAnimation === 'fade-in', 'fadeout': button.buttonAnimation === 'fade-out', 'fadein-up': button.buttonAnimation === 'fade-in-up', 'fadein-down': button.buttonAnimation === 'fade-in-down',
                      'fadein-left': button.buttonAnimation === 'fade-in-left', 'fadein-right': button.buttonAnimation === 'fade-in-right', 'enter-hide': button.openNewTab === 'sectionAuto', 'sandwich-mobile-btn': section.buttonLook === 'circle' && !button.displayText,
                      'no-display': !button.displayText && !button.buttonDescription && !button.imageUrl}"
                      [disabled]="(button.action == 'none' && !button.buttonDescription)"
                      (mouseover)="hover='c'+sIndex+'_'+bIndex" (mouseleave)="hover='none'"
                      (ontouchstart)="hover=sIndex+'_'+bIndex" [ngStyle]="getSectionBtnWidth(button)">
                      <div class="button-body"
                        [ngClass]="{'no-cursor': (button.action === 'none'), 'full-option-image': !button.displayText && button.imageUrl, 'center-align-content': !button.imageUrl, 
                          'no-front-content': !button.displayText && !button.imageUrl, 'button-height-auto': (section.buttonLook == 'card' || section.buttonLook == 'transparent') && (button.displayText || button.buttonDescription)}"
                        [ngStyle]="hover=='c'+sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                        <div class="card-front-slide" [ngClass]="{'graphic-height': button.displayText || button.buttonDescription && (section.buttonLook === 'card'
                                                            || section.buttonLook === 'transparent')}">
                          <div class="option-icon" *ngIf="!checkUploadItemType(button) && button.imageUrl"
                            [ngStyle]="getOptionImage(button, section)" [attr.aria-label]="button.imageAltText"></div>
                          <video *ngIf="checkUploadItemType(button)" id="{{sIndex}}_{{bIndex}}_video"
                            [src]="this.imageBaseUrl+button.imageUrl" [ngStyle]="getVideoHeight(button, section)"
                            class="video-handler" autoplay="autoplay" loop="loop" preload="auto" playsinline=""
                            webkit-playsinline="" [muted]="true">
                          </video>
                          <div class="option-content" [innerHtml]="button.displayText | safeHtml">
                          </div>
                        </div>
                        <div *ngIf="button.buttonDescription" class="card-back-slide overlay"
                          [ngStyle]="hover=='c'+sIndex+'_'+bIndex ? getSectionButtonOverlayStyle(button, 'hover', section) : getSectionButtonOverlayStyle(button, 'normal', section)"
                          id="button-desc-{{sIndex}}-{{bIndex}}" [innerHtml]="button.buttonDescription | safeHtml">
                        </div>
                      </div>
                      <div class="btn-auto-view"
                        *ngIf="button.openNewTab === 'button' ||  button.openNewTab === 'buttonAuto'"
                        id="section-{{sIndex}}-btn-{{bIndex}}">
                        <app-viewer-container [url]="button.nextAction" [type]="button.action"
                          [altText]="button.nxtAltText"></app-viewer-container>
                      </div>
                    </button>
                    <div class="search-box" *ngIf="button && button.action === 'search'">
                      <div class="srch-btn fade-up-element" type="button" (click)="openSearch(button)"
                        [ngStyle]="hover=='c'+sIndex+'_'+bIndex ? getSectionButtonStyle(button, 'hover', section) : getSectionButtonStyle(button, 'normal', section)">
                        <mat-icon>search</mat-icon>
                        <div *ngIf="button.displayText" class="option-content"
                          [innerHtml]="button.displayText | safeHtml"></div>
                      </div>
                      <div class="search-bar" *ngIf="toggleSearch" [ngClass]="{'search-visible': toggleSearch}">
                        <app-search [button]="button" (closeSearch)="openSearch(button)"></app-search>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-cover unique-center expandable-panel" *ngIf="section.buttonLook === 'expandable'"
                [ngClass]="{'view-alloption': section.buttonLayout === 'horizontal', 'vertial-view': section.buttonLayout === 'vertical', 'align-left': section.buttonAlignment === 'left', 'align-right': section.buttonAlignment === 'right',
                'sandwich-panel': section.sandwichBtnMobile && !this.isMenuShown, 'expand-sandwich-menu': section.sandwichBtnMobile}">
                <mat-accordion>
                  <mat-expansion-panel [hidden]="button.action === 'search'"
                    [expanded]="mExpandedIndex === sIndex+'-'+aIndex" hideToggle class="action-expand-icon"
                    *ngFor="let button of sandwichBtnArray[sIndex]; let aIndex=index"
                    [ngClass]="{'no-cursor': (button.action == 'none'), 'no-image': button.displayText, 'full-option-image': !button.displayText && button.imageUrl, 'view-alloption': section.buttonLayout == 'horizontal', 'card-type': section.buttonLook === 'card',
                    'expand-in-mobile': (button.height > 150 && button.imageUrl), 'no-search-sec': button && button.action !== 'search', 'fadein': button.buttonAnimation === 'fade-in', 'fadeout': button.buttonAnimation === 'fade-out',
                    'fadein-up': button.buttonAnimation === 'fade-in-up', 'fadein-down': button.buttonAnimation === 'fade-in-down', 'fadein-left': button.buttonAnimation === 'fade-in-left', 'fadein-right': button.buttonAnimation === 'fade-in-right'}"
                    [disabled]="(button.action == 'none' && !button.buttonDescription && !button.buttons.length)"
                    id="ex-{{sIndex}}-{{aIndex}}" [ngStyle]="getSecExpandableStyle(button, section)"
                    (opened)="setExpandableStyle('ex-'+sIndex+'-'+aIndex, button, true, 'hover'); mExpandedIndex = sIndex+'-'+aIndex"
                    (closed)="setExpandableStyle('ex-'+sIndex+'-'+aIndex, button, false, 'normal');"
                    (click)="$event.stopPropagation()">
                    <strong style="display: none;">
                      <em>
                        {{button.title}}
                      </em>
                    </strong>
                    <mat-expansion-panel-header *ngIf="!button.excludeSandwichBtn">
                      <mat-panel-title *ngIf="(button.imageUrl || button.displayText) && button.action !== 'search'"
                        [ngStyle]="getSecExpandableHeaderStyle(button)">
                        <div class="option-icon" *ngIf="!checkUploadItemType(button) && button.imageUrl"
                          [ngStyle]="getOptionImage(button, section)" [attr.aria-label]="button.imageAltText"></div>
                        <video *ngIf="checkUploadItemType(button)" id="{{sIndex}}_{{aIndex}}_videoExp"
                          [src]="this.imageBaseUrl+button.imageUrl" [ngStyle]="getVideoHeight(button, section)"
                          class="video-handler" autoplay="autoplay" loop="loop" preload="auto" playsinline=""
                          webkit-playsinline="" [muted]="true">
                        </video>
                        <div class="option-content" [innerHtml]="button.displayText | safeHtml"></div>
                        <mat-icon *ngIf="button.buttonDescription || button.buttons.length" class="vertical-icon">
                          keyboard_arrow_down</mat-icon>
                      </mat-panel-title>
                      <mat-panel-title class="search-box" *ngIf="button && button.action === 'search'">
                        <div class="srch-btn" type="button" (click)="openSearch(button)">
                          <mat-icon>search</mat-icon>
                          <div *ngIf="button.displayText" class="option-content"
                            [innerHtml]="button.displayText | safeHtml">
                          </div>
                        </div>
                        <div class="search-bar" *ngIf="toggleSearch" [ngClass]="{'search-visible': toggleSearch}">
                          <app-search [button]="button" (closeSearch)="openSearch(button)"></app-search>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="expand-description" *ngIf="button.action !== 'search'"
                      [ngStyle]="getExpandBtnOverlayStyle(button, section)"
                      [ngClass]="{'action-cursor': button.action !== 'none' && button.buttonDescription}">
                      <div class="expanded-detail" [innerHtml]="button.buttonDescription | safeHtml"></div>
                    </div>
                    <div class="expand-description child-btn-expand"
                      [ngClass]="{'no-padding': button.buttons.length > 0}" *ngIf="button.buttons.length"
                      [ngStyle]="getExpandBtnOverlayStyle(button, section)">
                      <app-menu-items [items]="button.buttons"
                        (clickedButton)="setExpandableStyle('ex-'+sIndex+'-'+aIndex, button, false, 'normal');">
                      </app-menu-items>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <div class="expand-search" [hidden]="button && button.action !== 'search'"
                  *ngFor="let button of sandwichBtnArray[sIndex]; let aIndex=index"
                  [ngClass]="{'no-cursor': (button.action == 'none'), 'no-image': button.displayText, 'full-option-image': !button.displayText && button.imageUrl, 'view-alloption': section.buttonLayout == 'horizontal', 'card-type': section.buttonLook === 'card', 'expand-in-mobile': (button.height > 150 && button.imageUrl)}"
                  id="ex-{{sIndex}}-{{aIndex}}"
                  (opened)="setExpandableStyle('ex-'+sIndex+'-'+aIndex, button, true, 'hover'); mExpandedIndex = sIndex+'-'+aIndex"
                  (closed)="setExpandableStyle('ex-'+sIndex+'-'+aIndex, button, false, 'normal');"
                  (click)="$event.stopPropagation()">
                  <strong style="display: none;">
                    <em>
                      {{button.title}}
                    </em>
                  </strong>
                  <div class="search-box" [ngStyle]="getSecExpandableStyle(button, section)">
                    <div class="srch-btn fade-up-element" type="button" (click)="openSearch(button)">
                      <mat-icon>search</mat-icon>
                      <div *ngIf="button.displayText" class="option-content"
                        [innerHtml]="button.displayText | safeHtml">
                      </div>
                    </div>
                    <div class="search-bar" *ngIf="toggleSearch" [ngClass]="{'search-visible': toggleSearch}">
                      <app-search [button]="button" (closeSearch)="openSearch(button)"></app-search>
                    </div>
                  </div>
                </div>
              </div>
              <video class="sandwich-side-panel"
                *ngIf="section && section.backgroundType && section.backgroundType === 'video' || (section.backgroundType === 'none' && hamBgType === 'video')"
                [ngStyle]="getBgVideo(section)" class="video-handler sec-video"
                src="{{imageBaseUrl}}{{section.backgroundSrc ? section.backgroundSrc : hamBgVideo}}" autoplay="autoplay"
                loop="loop" [muted]="true" preload="auto" playsinline="" webkit-playsinline="">
              </video>
              <!---unique align end--->
            </div>
            <div id="section-desc-{{sIndex}}"
              *ngIf="section && (section.description && section.buttonPlacement === 'beforeCanvas')"
              [innerHtml]="section.description | safeHtml" class="fade-up-element section-content">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="stick-bottom">
    <div class="popup-btn pd-lr">
      <button mat-flat-button id="cancel-btn" class="white-button" type="button" (click)="cancel()">
        x
      </button>
    </div>
  </div>
</div>