import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateCompiler, TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateMessageFormatCompiler } from "ngx-translate-messageformat-compiler";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

// Modules
import { AppRoutingModule } from "@app/app.routing";
import { CoreModule } from "@app-core/core.module";
import { AuthenticationModule } from "@app-auth/authentication.module";
import { HomeModule } from "@app-home/home.module";
import { SharedModule } from "@app-shared/shared.module";
import { LayoutModule } from "@app/layout/layout.module";

import { AppComponent } from "@app/app.component";
import { DatePipe } from "@angular/common";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    CoreModule,
    SharedModule,
    AuthenticationModule,
    HomeModule,
    LayoutModule,
    NgxDaterangepickerMd.forRoot(),
    FontAwesomeModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [DatePipe, Title],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
