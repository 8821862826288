<div class="chip-reorder">
  <div class="m-lr-15 field-section">
    <mat-form-field class="ai-suggestion-list p-b-50">
      <mat-icon class="info-ai-suggesion-reorder"
        matTooltip="Pick and Drop suggestions to arrange them in the order of your choice" matTooltipPosition="right">
        info
      </mat-icon>
      <mat-chip-list id="suggestionChipList" #suggestionChipList aria-label="AI Suggestion" cdkDropList
        (cdkDropListDropped)="dropSuggestion($event)" cdkDropListOrientation="vertical" class="suggestion-chip-list">
        <div class="m-t-10">
          <mat-chip class="suggestion-chip" cdkDrag *ngFor="let suggestion of aiSuggestions"
            (removed)="removeSuggestion(suggestion)" [removable]="true">
            {{suggestion}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
        </div>
        <input placeholder="AI Suggestions" [matChipInputFor]="suggestionChipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addSuggestion($event)" class="mat-chip-input">
      </mat-chip-list>
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="save-cncl-btn">
    <button mat-button mat-dialog-close class="save-cncl-cncl white-button"
      (click)="saveReorderInfo('cancel')">Cancel</button>
    <button mat-button [mat-dialog-close]="dialogData" cdkFocusInitial (click)="saveReorderInfo('save')"
      class="save-cncl-save teal-button">Submit</button>
  </div>
</div>