export class Client {
  id: number;
  name: string;
  client_id: string;
  cluster: string;
  corporate_id: string;
  website_url: string;
  contact_person: string;
  contact_number: string;
  primary_email_id: string;
  full_address: string;
  subscription_start_date: string;
  subscription_end_date: string;
  status: string;
  hosting_platform: string;
  tenant_id: string;
  approvale_mechanism: boolean;
  created_on: string;
  updated_on: string;
  updated_by: string;
  nlp_engines_limit: number;
  behavior_limit: number;
  chat_queue_threshold: number;
  call_queue_threshold: number;
  user_license_limit: {};
  api_gateway_url: string;
  host_url: string;
  modules: any;
  channels: any;
  departments: any;
  is_active: boolean;
  client_country_codes: [];
  client_currency: [];
  client_features: {};
  idp_properties: any;
  restriction_policy: any;
  supported_languages: [];
  dateFormat: string;
  idpProvider: string;
  idpType: string;


  public setClientProperties = (propObj: any, gwyOnly: boolean) => {
    if (!gwyOnly) {
      this.id = propObj.id;
      this.name = propObj.name;
      this.client_id = propObj.clientId;
      this.cluster = propObj.assignedOnCluster ? propObj.assignedOnCluster : null;
      this.corporate_id = propObj.corporateId;
      this.website_url = propObj.websiteUrl;
      this.contact_person = propObj.contactPerson;
      this.contact_number = propObj.contactNumber;
      this.primary_email_id = propObj.primaryEmailId;
      this.full_address = propObj.fullAddress;
      this.subscription_start_date = propObj.subscriptionStartDate;
      this.subscription_end_date = propObj.subscriptionEndDate;
      this.status = propObj.status;
      this.hosting_platform = propObj.hostingPlatform;
      this.tenant_id = propObj.tenantId;
      this.approvale_mechanism = propObj.approvaleMechanism;
      this.created_on = propObj.createdOn;
      this.updated_on = propObj.updatedOn;
      this.updated_by = propObj.updatedBy;
      this.nlp_engines_limit = propObj.nlpEnginesLimit;
      this.behavior_limit = propObj.behaviorLimit;
      this.chat_queue_threshold = propObj.chatBroadcastThreshold;
      this.call_queue_threshold = propObj.callBroadcastThreshold;
      this.user_license_limit = propObj.userLicences;
      this.api_gateway_url = propObj.apiGatewayUrl + '/api/v1';
      this.host_url = propObj.hostUrl;
      this.modules = propObj.modules;
      this.channels = propObj.channels;
      this.departments = propObj.departments;
      this.is_active = propObj.isActive;
      this.client_country_codes = propObj.clientCountryCodes;
      this.client_currency = propObj.clientCurrency;
      this.client_features = propObj.clientFeatures;
      this.idp_properties = propObj.idpProperties;
      this.restriction_policy = propObj.restrictionPolicy;
      this.supported_languages = propObj.supportedLanguages;
      this.dateFormat = propObj.dateFormat ? propObj.dateFormat : "DD/MM/YYYY";
      this.idpProvider = propObj.idpProvider ? propObj.idpProvider : null;
      this.idpType = propObj.idpType ? propObj.idpType : null;
    } else {
      this.api_gateway_url = `${propObj}/api/v1`;
    }
  }

  public resetClientProperties() {
    this.id = null;
    this.name = null;
    this.client_id = null;
    this.corporate_id = null;
    this.website_url = null;
    this.contact_person = null;
    this.contact_number = null;
    this.primary_email_id = null;
    this.full_address = null;
    this.subscription_start_date = null;
    this.subscription_end_date = null;
    this.status = null;
    this.hosting_platform = null;
    this.tenant_id = null;
    this.approvale_mechanism = null;
    this.created_on = null;
    this.updated_on = null;
    this.updated_by = null;
    this.nlp_engines_limit = null;
    this.behavior_limit = null;
    this.chat_queue_threshold = null;
    this.call_queue_threshold = null;
    this.user_license_limit = null;
    this.api_gateway_url = null;
    this.host_url = null;
    this.modules = null;
    this.channels = null;
    this.departments = null;
    this.is_active = null;
    this.client_country_codes = null;
    this.client_currency = null;
    this.client_features = null;
    this.idp_properties = null;
    this.restriction_policy = null;
    this.supported_languages = null;
    this.dateFormat = null;
    this.idpProvider = null;
    this.idpType = null;
  }
}
