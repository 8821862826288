export class UnsplashRequest {
  public query: string;
  public page: number;
  public pageSize: number;
  public orientation: string;
  public contentFilter: string;
  public orderBy: string;

  constructor() {
    this.query = "abstract";
    this.page = 1;
    this.pageSize = 30;
    this.orientation = "landscape";
    this.contentFilter = "low";
    this.orderBy = "relevant";
  }
}