import { MessageContent } from "@app/core/msg/message-content";

export class LmHbMessage {

  public sessionId: string;
  public messageType: number;
  public message: MessageContent[] = [];
  public idToken: string;
  public accessToken: string;
  public createdTimestamp: number;
  public updatedTimestamp: number;
  public createdBy: string;
  public updatedBy: string;

  public constructor () {}

  public initInbound = (msgObj: any): LmHbMessage => {
    const leaseMsg: LmHbMessage = new LmHbMessage();
    try {
      if (msgObj) {
        leaseMsg.message = (msgObj.message) ? msgObj.message as MessageContent[] : [new MessageContent("", "")];
        leaseMsg.sessionId = (msgObj.sessionId) ? msgObj.sessionId : "";
        leaseMsg.messageType = (msgObj.messageType) ? msgObj.messageType : "";
        leaseMsg.idToken = (msgObj.idToken) ? msgObj.idToken : "";
        leaseMsg.accessToken = (msgObj.accessToken) ? msgObj.accessToken : "";
        leaseMsg.createdTimestamp = (msgObj.createdTimestamp) ? msgObj.createdTimestamp : "";
        leaseMsg.updatedTimestamp = (msgObj.updatedTimestamp) ? msgObj.updatedTimestamp : "";
        leaseMsg.createdBy = (msgObj.createdBy) ? msgObj.createdBy : "";
        leaseMsg.updatedBy = (msgObj.updatedBy) ? msgObj.updatedBy : "";
      }
    } catch (e) {
      console.log(`Error in parsing the socket messages received. Error is ${e}`);
    }
    return leaseMsg;
  }

  public healthy = (): boolean => {
    return !isNaN(this.messageType) && this.messageType === 6
      && !isNaN(this.createdTimestamp)
      && !isNaN(this.updatedTimestamp)
      && !!this.createdBy && !!this.updatedBy;
  }

}
