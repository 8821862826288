export class GuestCursor {

  private readonly channel: string;
  private readonly receiver: string;

  constructor(chn: string, receiver: string) {
    this.channel = chn;
    this.receiver = receiver;
  }

  channelPointer = (): string => {
    return this.channel;
  }

  receiverName = () => {
    return this.receiver;
  }
}
