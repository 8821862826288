import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AiService {

  public sendDataToEditor = new Subject();
  constructor() {}

  passAIValueToEditor = (data: string) => {
    this.sendDataToEditor.next(data);
  }

  setvalue() {
    return this.sendDataToEditor.asObservable();
  }
}
