import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { LivechatApiService } from "@app/live-chat/services/livechat-api/livechat-api.service";
import { CustomerListService } from "@app/live-chat/services/customer-list/customer-list.service";
import { AgentService } from "@app/live-chat/services/agent/agent.service";
import { AuthenticationService } from "@app/core/services/authentication/authentication.service";
import { ChatService } from "@app-live-chat/services/chat/chat.service";
import { Location } from "@angular/common";
import { StorageService } from "@app/core/services/storage/storage.service";
import { LcConstants } from "@app/live-chat/constants/lc-constants";
import { MessageContent } from "@app/core/msg/message-content";
import { User } from "@app/core/misctypes/user";
import { AgentStatusReq } from "@app/core/misctypes/agent-status-req";
import { AgentStatusResp } from "@app/core/misctypes/agent-status-resp";
import { CoreUtils } from "@app/core/utils/core-utils";
import { Constants } from "@app/core/utils/const";
import { Observable, Subject } from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() toggle = false;
  @Input() tabName: string;
  @Output() toggleEvent = new EventEmitter();
  @ViewChild("fingerprint") fingerprint: ElementRef;
  public clientId: number;
  public currentUser: User;
  public displayedAgentStatus = null;
  public showBackNavigation = false;
  public iconVisible = false;
  public connectedGuestChannelId: string;
  public availabilityStatus: string[] = [];
  public userType: string;

  private _lcCnst: LcConstants;
  private _const: Constants;
  private _utils: CoreUtils;
  private _agentStatusSubject: Subject<any> = new Subject<any>();

  constructor(
    private livechatApiService: LivechatApiService,
    private customerListService: CustomerListService,
    private agentService: AgentService,
    private authenticationService: AuthenticationService,
    private chatService: ChatService,
    private _storage: StorageService,
    private location: Location
  ) {
    this._lcCnst = new LcConstants();
    this._const = new Constants();
    this._utils = new CoreUtils(authenticationService);
  }

  ngOnInit(): void {
    this.availabilityStatus = this._lcCnst.AVAILABILITY_STATUS;
    this.clientId = this.authenticationService.client.id;
    this.currentUser = this.customerListService.agentLoggedIn;
    this.userType = this._utils.getUserType();

    if (this.userType === this._const.CLIENT) {

      const backEndAgentStatusObserver = this.livechatApiService.getAgentCurrentStatus({
        adi: this._storage.storedAdi(),
        fp: this._storage.storedFp()
      });

      backEndAgentStatusObserver.subscribe((resp) => {
        if (resp && resp.body) {
          try {
            const stsResp = JSON.parse(resp.body);
            if (stsResp && stsResp.status && stsResp.agentStatus && stsResp.agentStatus.length > 0) {
              const currentStatus = parseInt(stsResp.agentStatus[0].status, 10);
              const statusIndex = (currentStatus && currentStatus > 0) ? currentStatus : 1;
              this.getAgentDisplayStatusSubject().next(statusIndex);
              this._storage.storeAgentStatus(this.displayedAgentStatus);
            }
          } catch (e) {
            console.log(`Error occurred while getting agent status. Error is ${e}`);
          }
        }
      });
    }

    this.getAgentDisplayStatusObserver().subscribe((current) => {
      if (current >= 0 && current < this.availabilityStatus.length) {

        if (current === this._lcCnst.OFFLINE || current === this._lcCnst.OFFLINE_ALL_DEVICES) {
          this.chatService.showDisconnectedMsg = false;
          if (this.customerListService) {
            this.customerListService.activeChannelId = "";
            this.customerListService.showOffline(this.connectedGuestChannelId);
            this.customerListService.disconnectAgent(current);
          }
          this.agentService.disconnect();
          this.chatService.disconnectAllGuestChats(current);
          this.chatService.emptyAllGuests();
          this.customerListService.sendHeaderData({
            message: [new MessageContent(this._lcCnst.AGENT_OFFLINE, this._lcCnst.AGENT_OFFLINE)]
          });
        }
        if (current === this._lcCnst.ONLINE) {
          this.setOnline();
        }
        if (current === this._lcCnst.AWAY) {
          this.chatService.showDisconnectedMsg = true;
          this.setOnline();
        }
        this.displayedAgentStatus = this.availabilityStatus[current];
      }
    });

    const statusMatched: string[] = this.availabilityStatus.slice(2).filter(as => this.displayedAgentStatus === as);
    if (statusMatched.length > 0) {
      this.setOnline();
    } else {
      this.getAgentDisplayStatusSubject().next(this._lcCnst.OFFLINE);
    }

    this.customerListService.getHeaderData().subscribe((response) => {
      if (!!response) {
        if (response.channelId !== undefined) {
          this.connectedGuestChannelId = response.channelId;
        }
      }
    });
  }

  ngAfterViewInit() {
    const canvas = !!this.fingerprint ? this.fingerprint.nativeElement : undefined;
    if (!!canvas) {
      const fp = this._utils.produceFingerprint(canvas);
      this._storage.storeFp(fp);
      console.log(`This browser fingerprint is ${fp}`);
    }
  }

  public changeAgentStatus = (state: number) => {

    const storedAdi: string = !!this._storage.storedAdi() ? this._storage.storedAdi() : "";
    const storedFp: string = !!this._storage.storedFp() ? this._storage.storedFp() : "";
    let agentStatusReq: AgentStatusReq = new AgentStatusReq();
    agentStatusReq = {
      ...agentStatusReq,
      ...{
        clientId: this.clientId,
        agentAlias: this.authenticationService.getUserAlias(),
        agentName: this.authenticationService.getUserFullName(),
        agentId: this.currentUser.cognitoUserId,
        status: state,
        adi: storedAdi,
        fp: storedFp,
        email: this.currentUser.email,
      }
    }

    const backEndAgentStatusUpdateObserver = this.livechatApiService.changeAgentStatus(agentStatusReq);
    backEndAgentStatusUpdateObserver.subscribe((res: any) => {
      if (res && res.body) {
        const statusJson: AgentStatusResp = JSON.parse(res.body);
        if (statusJson && statusJson.status && statusJson.adi) {
          if (!!statusJson.adi) {
            this._storage.storeAdi(statusJson.adi);
          }
          const current = statusJson.agentStatus;
          if (current) {
            this._storage.storeAgentStatus(this.availabilityStatus[state]);
            this.getAgentDisplayStatusSubject().next(state)
          }
        }
      }
    }, (err: any) => {
      console.log(`header-component: Any errors ? ${err}`, err);
    });
  }

  public mouseEnter = () => {
    this.iconVisible = true;
  }

  public mouseLeave = () => {
    this.iconVisible = false;
  }

  public goBack = () => {
    this.location.back();
  }

  private setOnline = () => {
    const inQObserver = this.customerListService.connect();
    inQObserver.subscribe(
      (res) => {
        this.customerListService.onWsMessage(res);
      },
      (error) => {
        console.log(`Header-comp: in-queue ws error: ${JSON.stringify(error)}`);
        this.changeAgentStatus(this._lcCnst.OFFLINE);
      },
      () => {
        console.log(`Header-comp:: Completed customer list ws`);
        this.customerListService.sendHeaderData({
          message: [new MessageContent(this._lcCnst.AGENT_OFFLINE, this._lcCnst.AGENT_OFFLINE)]
        });
        this.changeAgentStatus(this._lcCnst.OFFLINE);
      }
    );

    const trfChatObserver = this.agentService.connect();
    trfChatObserver.subscribe(
      (res) => {
        console.log(`Header-comp:: Agent service transfer ws ${JSON.stringify(res)}`);
        this.agentService.onWsMessage(res);
      },
      (err: any) => {
        console.log(`Header-comp: Agent service transfer ws, err: ${err}`);
      },
      () => {
        console.log(`Header-comp: Completed agent transfer ws`);
      });
  }

  private getAgentDisplayStatusSubject = (): Subject<any> => {
    return this._agentStatusSubject;
  }

  private getAgentDisplayStatusObserver = (): Observable<any> => {
    return this._agentStatusSubject.asObservable();
  }
}
