import { Department } from "@app/core/misctypes/department";

export class AgentStatusReq {
  public clientId: number;
  public agentAlias: string;
  public agentName: string;
  public adi: string;
  public fp: string;
  public agentId: string;
  public status: number;
  public departments: Department[] = [];
  public email: string;
  public page: string;
}
