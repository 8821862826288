<div class="sentinel-logo-bg login-page">
  <video autoplay class="cmsbgvdo">
    <source src="/assets/img/cmslogin.mp4">
  </video>
  <div class="logo-bg">
    <mat-card class="login-card">
      <mat-card-content>
        <form [formGroup]="loginForm" (ngSubmit)="authenticateLogin()">
          <mat-form-field class="mat-form-field-wrapper" appearance="legacy">
            <mat-label>Email</mat-label>
            <p></p>
            <input matInput type="email" formControlName="username" required autofocus>
            <mat-error>
              {{getEmailErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="mat-form-field-wrapper" appearance="legacy">
            <mat-label>Password</mat-label>
            <p></p>
            <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="password" required>
            <button class="eyeicon-password" mat-icon-button matSuffix type="button" (click)="hidePass = !hidePass"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass"
              [title]="hidePass ? 'Hide password' : 'Show password'">
              <div *ngIf="loginForm.get('password').value">
                <img class="showeye" src="./assets/img/eye.png" alt="show-password">
                <img class="hideeye" src="./assets/img/eyehide.png" alt="hide-password">
              </div>
            </button>
            <mat-error>
              {{getPWDErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-card-actions>
            <button mat-raised-button color="primary" class="login-btn" type="submit">Login</button>
            <a routerLink="/forgot-password" routerLinkActive="active" class="reset-pwd"
              (click)="navigateToForgotPassword()">Reset Password</a>
          </mat-card-actions>
        </form>
      </mat-card-content>
      <mat-card-footer>
        <div class="mat-error">{{error}}</div>
      </mat-card-footer>
    </mat-card>

    <div class="camie-heading">
      <div class="camie-text">
        <h1>DigitalChameleon</h1>
        <h4>The No-code Launchpad for Your Ideas</h4>
      </div>
    </div>
    <p class="copyright-sentinel">DigitalChameleon.ai
    </p>
  </div>
</div>