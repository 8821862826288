import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Subject } from "rxjs";
import { ApiService } from "@app-core/services/api/api.service";
import { AuthenticationService } from "@app/core/services/authentication/authentication.service";
import { StorageService } from "@app/core/services/storage/storage.service";

@Injectable({
  providedIn: "root"
})
export class ConnectService {
  public url;
  public param;
  public clientId;
  public endPoint;
  public toggleTelephony = new Subject<any>();

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private authService: AuthenticationService,
    private _storage: StorageService
  ) {
    this.param = new HttpParams();
    this.clientId = this.authService.client ? this.authService.client.id : null;
    this.param = this.param.set("clientId", this.clientId);
  }

  getConnectUrl() {
    this.endPoint = `${this.authService.client.api_gateway_url}/clientmanagement/getConnectUrl`;
    return this.apiService.apicall(this.endPoint, this.param, "GET_WITH_PARAM");
  }
}
