<div class="cms-logo-bg resetcms">
  <video autoplay class="cmsbgvdo">
    <source src="/assets/img/cmslogin.mp4">
  </video>
  <div class="logo-bg">
    <mat-card class="login-card">
      <mat-card-content>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
          <mat-form-field class="mat-form-field-wrapper" appearance="legacy">
            <mat-label>Email</mat-label>
            <p></p>
            <input matInput type="email" placeholder="" formControlName="email" required autofocus>
            <mat-error>
              {{getEmailErrorMessage()}}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mat-form-field-wrapper" appearance="legacy">
            <mat-label>Password</mat-label>
            <p></p>
            <input matInput type="password" placeholder="" formControlName="password" required>
            <mat-error>
              {{getPWDErrorMessage()}}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mat-form-field-wrapper" appearance="legacy">
            <mat-label>New Password</mat-label>
            <p></p>
            <input matInput type="password" placeholder="" formControlName="newPassword" required>
            <mat-error>
              {{getNewPWDErrorMessage()}}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mat-form-field-wrapper" appearance="legacy">
            <mat-label>Confirm New Password</mat-label>
            <p></p>
            <input matInput type="password" placeholder="" formControlName="confirmNewPassword" required>
            <mat-error>
              {{getConfirmNewPWDErrorMessage()}}
              {{error}}
            </mat-error>
          </mat-form-field>
          <mat-card-actions class="text-center">
            <button mat-raised-button class="login-btn" type="submit">Reset Password</button>
            <button routerLink='/login' class="forgetpwrd" routerLinkActive="active" type="button"
              (click)="goHome()">Back</button>
          </mat-card-actions>
        </form>
      </mat-card-content>
      <mat-card-footer>
        <div class="mat-error">{{error}}</div>
      </mat-card-footer>
    </mat-card>
    <div class="camie-heading">
      <div class="camie-text">
        <h1>DigitalChameleon</h1>
        <h4>The No-code Launchpad for Your Ideas</h4>
      </div>
    </div>
    <p class="copyright-cms">DigitalChameleon.ai</p>
  </div>
</div>