import { Component, OnInit, OnChanges, ViewChild, ElementRef, Input, AfterViewInit } from "@angular/core";
import { AnalyticsConstant } from "@app/analytics/constants/analytics.constant";
import * as d3 from "d3";

@Component({
  selector: "app-barchart",
  templateUrl: "./barchart.component.html",
  styleUrls: ["./barchart.component.scss"]
})
export class BarchartComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild("chart") public chartContainer: ElementRef;
  @Input() public data: (string | number)[][] = [];
  @Input() public label: string;
  @Input() public type: string;
  public margin = { top: 20, bottom: 55, left: 30, right: 20 };
  public width: number;
  public height: number;
  public _analyticsConst: AnalyticsConstant;

  constructor() {
    this._analyticsConst = new AnalyticsConstant();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.chartContainer && this.data && this.data.length > 0) {
      this.drawChart();
    }
  }

  ngOnChanges() {
    if (this.chartContainer && this.data && this.data.length > 0) {
      this.drawChart();
    }
  }

  private drawChart = () => {
    const element: HTMLDivElement = this.chartContainer.nativeElement;
    this.width = this.type === this._analyticsConst.FULL_WIDTH
      ? this._analyticsConst.BAR_CHART_FULL_WIDTH
      : this._analyticsConst.BAR_CHART_WIDTH;
    this.height = this._analyticsConst.BAR_CHART_HEIGHT;
    const barWidth: number = this._analyticsConst.BAR_CHART_BAR_WIDTH;
    const spaceBetweenBar: number = this._analyticsConst.BAR_CHART_SPACE_BTWN_BAR;
    this.getModifiedWidth(barWidth, spaceBetweenBar);

    d3.select(element).selectAll("svg").remove();
    let svg = d3.select(element).append("svg")
      .attr("width", this.width + this.margin.left + this.margin.right + this._analyticsConst.BAR_CHART_SVG_WIDTH_BUFFER)
      .attr("height", this.height + this.margin.top + this.margin.bottom);

    let chart = svg.append("g")
      .attr("class", "bars")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`);

    const xDomain: string[] = this.data.map(d => d[3].toString());
    const yDomain: number[] = [0, d3.max(this.data, d => Number(d[1]))];

    const xScale: d3.ScaleBand<string> = d3.scaleBand().padding(0.1).domain(xDomain).rangeRound([0, this.width]);
    const yScale: d3.ScaleLinear<number, number> = d3.scaleLinear().domain(yDomain).range([this.height, 0]);

    let colors = d3.scaleLinear().domain([0, this.data.length]).range(<any[]>["#E6AAAA", "#CFDDF2"]);

    let xAxis = svg.append("g")
      .attr("class", "axis axis-x")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top + this.height})`)
      .call(d3.axisBottom(xScale).tickSizeInner(0));

    let yAxis = svg.append("g")
      .attr("class", "axis axis-y")
      .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`)
      .call(d3.axisLeft(yScale).tickSizeInner(0));

    xScale.domain(this.data.map(d => d[3].toString()));
    yScale.domain([0, d3.max(this.data, d => Number(d[1]))]).nice();
    colors.domain([0, this.data.length]);
    xAxis.transition().call(d3.axisBottom(xScale)
      .tickSizeInner(0)
      .tickFormat((d, i) => {
        return this.data[i][0].toString();
      }))
      .selectAll(".tick")
      .attr("transform", (d, i) => {
        const pos: number = (i === 0)
          ? (spaceBetweenBar + (barWidth / 2))
          : (spaceBetweenBar + ((barWidth + spaceBetweenBar) * i) + (barWidth / 2));
        return `translate(${pos},0)`;
      })
      .selectAll("text")
      .attr("transform", "translate(0,0)rotate(-45)")
      .style("text-anchor", "end");

    yAxis.transition().call(d3.axisLeft(yScale).tickSizeInner(0)
      .tickFormat((d: number) => {
        return parseInt(d.toString()) === d ? d.toString() : "";
      }));

    let update = chart.selectAll(".bar")
      .data(this.data);
    update.exit().remove();

    chart.selectAll(".bar").transition()
      .attr("x", d => xScale(d[3]))
      .attr("y", d => yScale(d[1]))
      .attr("width", (d, i) => barWidth)
      .attr("height", d => this.height - yScale(d[1]))
      .style("fill", (d, i) => colors(i));

    update
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d, i) => i === 0 ? spaceBetweenBar : (spaceBetweenBar + ((barWidth + spaceBetweenBar) * i)))
      .attr("y", d => yScale(0))
      .attr("width", barWidth)
      .attr("height", 0)
      .style("fill", (d, i) => colors(i))
      .transition()
      .delay((d, i) => i * 10)
      .attr("y", d => yScale(Number(d[1])))
      .attr("height", d => this.height - yScale(Number(d[1])));

    update
      .enter()
      .append("text")
      .attr("class", "yAxis-label")
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .attr("x", (d, i) => i === 0
        ? (spaceBetweenBar + (barWidth / 2))
        : (spaceBetweenBar + ((barWidth + spaceBetweenBar) * i) + (barWidth / 2)))
      .attr("y", d => yScale(Number(d[1])) - 3)
      .text(d => d[1]);

    let tooltip = d3.select(element)
      .append("div")
      .classed("chart-tooltip", true)
      .style("display", "none")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "5px")
      .style("position", "absolute");

    chart
      .on("mouseover", () => {
        d3.select(".chart-tooltip").style("display", "block");
      })
      .on("mouseout", () => {
        d3.select(".chart-tooltip").style("display", "none");
      })
      .on("mousemove", () => {
        if (d3 && d3.event && d3.event.srcElement
          && d3.event.srcElement.__data__
          && d3.event.srcElement.__data__[0]
          && d3.event.srcElement.__data__[1]) {
          let html = "";
          if (this.label === this._analyticsConst.JOURNEY_SESSION_COUNT
            || this.label === this._analyticsConst.CONVERSATIONS
            || this.label === this._analyticsConst.FORMS
            || this.label === this._analyticsConst.WORKFLOWS) {
            html = `${d3.event.srcElement.__data__[2]} (${d3.event.srcElement.__data__[3]}) : ${d3.event.srcElement.__data__[1]} sessions`;
          } else {
            html = `${d3.event.srcElement.__data__[0]} : ${d3.event.srcElement.__data__[1]} sessions`;
          }
          d3.select(".chart-tooltip")
            .style("left", d3.event.pageX - 100 + "px")
            .style("top", d3.event.pageY - 200 + "px")
            .html(html.trim());
        } else {
          d3.select(".chart-tooltip").style("display", "none");
        }
      });
  }

  private getModifiedWidth = (barWidth: number, spaceBetweenBar: number) => {
    const totalSpace: number = barWidth + spaceBetweenBar;
    this.width = this.data.length * totalSpace > this.width
      ? this.data.length * totalSpace
      : this.width;
  }
}