import { LoginCfg } from "@app/core/misctypes/login-cfg";
import { IdpInfo } from "@app/core/misctypes/idp-info";

export class LoginConfigResp {

  public id: number;
  public clientId: string;
  public loginCfg: LoginCfg;
  public idpInfo: IdpInfo;
  public exeStatus = false;
  public exeCode: number;

  constructor (s: boolean, c: number) {
    this.exeStatus = s;
    this.exeCode = c;
  }
}
