import { LeadDetails } from "@app/user-management/types/lead-details";
import { ClientSubModule } from "@app/core/misctypes/client-sub-module";
import { Department } from "@app/core/misctypes/department";
import { Role } from "@app/core/misctypes/role";
import { Privilege } from "@app/rtmc/types/privileges";
import { ClientAgentInfo } from "@app/core/misctypes/client-agent-info";

export class User {
  public azureObjectId: string;
  public callTeamLead: LeadDetails;
  public callThreshold: number;
  public callTierLevel: number;
  public chatTeamLead: LeadDetails;
  public clientId: number;
  public clientSubmoduleDtos: ClientSubModule[] = [];
  public cognitoUserId: string;
  public departments: Department[] = [];
  public userDepartments: Department[] = [];
  public clientDepartments: Department[] = [];
  public displayImage: string;
  public firstName: string;
  public lastName: string;
  public alias: string;
  public email: string;
  public enabled = true;
  public id: number;
  public phone: string;
  public roles: Role[] = [];
  public status: string;
  public tempPass: string;
  public threshold: number;
  public tierLevel: number;
  public userCreateDate: number;
  public userLastModifiedDate: number;
  public userName: string;
  public userStatus: string;
  public videoTeamLead: LeadDetails;
  public videoThreshold: number;
  public videoTierLevel: number;
  public privileges: Privilege[] = [];
  public broadcastedConnectionsCount: number;
  public connectedConnectionsCount: number;
  public agentAlias: string;
  public agentName: string;
  public uiPolicies: number[] = [];
  public serverPolicies: number[] = [];
  public calendarTypes: string[] = [];
  public agentInfos: ClientAgentInfo [] = [];

  constructor() { }

  public setUser = (user: User) => {
    this.azureObjectId = user.azureObjectId;
    this.callTeamLead = user.callTeamLead;
    this.callThreshold = user.callThreshold;
    this.callTierLevel = user.callTierLevel;
    this.chatTeamLead = user.chatTeamLead;
    this.clientId = user.clientId;
    this.clientSubmoduleDtos = user.clientSubmoduleDtos;
    this.cognitoUserId = user.cognitoUserId;
    this.departments = user.departments;
    this.clientDepartments = user.clientDepartments;
    this.userDepartments = user.userDepartments;
    this.agentInfos = user.agentInfos;
    this.displayImage = user.displayImage;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.alias = user.alias;
    this.userName = user.userName;
    this.email = user.email;
    this.enabled = user.enabled;
    this.id = user.id;
    this.phone = user.phone;
    this.roles = user.roles;
    this.status = user.status;
    this.tempPass = user.tempPass;
    this.threshold = user.threshold;
    this.tierLevel = user.tierLevel;
    this.userCreateDate = user.userCreateDate;
    this.userLastModifiedDate = user.userLastModifiedDate;
    this.userName = user.userName;
    this.userStatus = user.userStatus;
    this.videoTeamLead = user.videoTeamLead;
    this.videoThreshold = user.videoThreshold;
    this.videoTierLevel = user.videoTierLevel;
    this.privileges = user.privileges;
    this.uiPolicies = user.uiPolicies;
    this.serverPolicies = user.serverPolicies;
    this.calendarTypes = user.calendarTypes;
  }

  public resetUser = () => {
    this.azureObjectId = null;
    this.callTeamLead = null;
    this.callThreshold = null;
    this.callTierLevel = null;
    this.chatTeamLead = null;
    this.clientId = null;
    this.clientSubmoduleDtos = [];
    this.cognitoUserId = null;
    this.departments = [];
    this.agentInfos = [];
    this.clientDepartments = [];
    this.userDepartments = [];
    this.displayImage = null;
    this.firstName = null;
    this.lastName = null;
    this.alias = null;
    this.userName = null;
    this.email = null;
    this.enabled = null;
    this.id = null;
    this.phone = null;
    this.roles = [];
    this.status = null;
    this.tempPass = null;
    this.threshold = null;
    this.tierLevel = null;
    this.userCreateDate = null;
    this.userLastModifiedDate = null;
    this.userName = null;
    this.userStatus = null;
    this.videoTeamLead = null;
    this.videoThreshold = null;
    this.videoTierLevel = null;
    this.privileges = [];
    this.uiPolicies = [];
    this.serverPolicies = [];
    this.calendarTypes = [];
  }
}
