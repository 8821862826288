export class AuthToken {

  public accessToken: string;
  public idToken: string;

  constructor(act: string, idt: string) {
    this.accessToken = act;
    this.idToken = idt;
  }
}
