export const responseMessages = {
  sentinel: {
    LOGIN_GENERIC_FAILURE: "Sorry, unable to login. Please double check your Username and Password",
    INITIATE_FORGOT_PASSWORD_FAILURE: "Forgot password flow initiation could not be done",
    COMPLETE_FORGOT_PASSWORD_FAILURE: "Forgot password flow completion could not be done",
    LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE: "The request is not supported by the login provider.",
    INITIATE_RESET_PASSWORD_FAILURE: "Oops, there was an error in resetting your password at the moment. Please try again in some time.",
    INITIATE_RESET_PASSWORD_SUCCESS: "Verification code sent to your email.",
    RESET_PASSWORD_FAILURE: "Oops, there was an error in resetting your password. Please try again.",
    LOGIN_CONFIRM_EMAIL_FAILURE: "Login email must be confirmed first",
    LOGIN_FORCE_PWD_RESET_FAILURE: "Temporary password must be changed first. Please wait ...",
    LOGIN_PWD_RESET_REQUIRED_FAILURE: "Password reset is requested. Please wait ...",
    CONFIRMATION_CODE_VERIFICATION_FAILURE: "Please enter the correct verification code to login",
    CONFIRMATION_CODE_VERIFICATION_SUCCESS: "Your Username is verified successfully.",
    CONFIRMATION_CODE_PARTIAL_VERIFICATION_FAILURE: "Your Username is verified successfully, but the phone number associated could not be marked as verified, please contact your administrator.",
    NEW_CONFIRMATION_CODE_REQUIRED_FAILURE: "Your verification code has expired, please contact your administrator.",
    COMPLETE_FORCED_RESET_PASSWORD_FAILURE: "Forced password reset flow completion could not be done",
    SMS_MFA_REQUIRED_FAILURE: "Please provide OTP received to your mobile",
    SMS_MFA_CODE_VERIFICATION_FAILURE: "Please enter the correct OTP to login.",
    SMS_MFA_CODE_SESSION_EXPIRED_FAILURE: "The OTP has expired. Please request a new one",
    LOGIN_INACTIVE_USER_FAILURE: "Sorry, User seems to be inactive, Please contact your administrator",
    RESEND_OTP_FAILURE: "Sorry, unable to resend OTP. Please try again"
  },
  cms: {
    LOGIN_GENERIC_FAILURE: "Sorry, unable to login. Please double check your Username and Password",
    LOGIN_CONFIRM_EMAIL_FAILURE: "Login email must be confirmed first",
    LOGIN_FORCE_PWD_RESET_FAILURE: "Temporary password must be changed first",
    LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE: "The request is not supported by the login provider.",
    LOGIN_USERNAME_CONFIRMATION_FAILURE: "Your verification code has expired, please contact your administrator.",
    LOGIN_USERNAME_CONFIRMATION_SUCCESS: "Verification confirmed",
    INITIATE_FORGOT_PASSWORD_FAILURE: "Forgot password flow initiation could not be done",
    INITIATE_RESET_PASSWORD_FAILURE: "Oops, there was an error in resetting your password at the moment. Please try again in some time.",
    INITIATE_RESET_PASSWORD_SUCCESS: "Verification code sent to your email.",
    COMPLETE_FORGOT_PASSWORD_FAILURE: "Forgot password flow completion could not be done",
    COMPLETE_FORCED_RESET_PASSWORD_FAILURE: "Forced password reset flow completion could not be done",
    RESET_PASSWORD_FAILURE: "Oops, there was an error in resetting your password. Please try again.",
    SMS_MFA_REQUIRED_FAILURE: "Please provide OTP received to your mobile",
    SMS_MFA_CODE_VERIFICATION_FAILURE: "Please enter the correct OTP to login.",
    SMS_MFA_CODE_SESSION_EXPIRED_FAILURE: "The OTP has expired. Please request a new one",
    LOGIN_INACTIVE_USER_FAILURE: "Sorry, User seems to be inactive, Please contact your administrator",
    RESEND_OTP_FAILURE: "Sorry, unable to resend OTP. Please try again"
  }
};
