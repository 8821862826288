import { NgModule } from "@angular/core";
import { SharedModule } from "@app-shared/shared.module";
import { LayoutRoutingModule } from "@app/layout/layout-routing.module";
import { LayoutComponent } from "@app/layout/pages/layout/layout.component";
import { FooterComponent } from "@app/layout/pages/footer/footer.component";

@NgModule({
  declarations: [LayoutComponent, FooterComponent],
  imports: [SharedModule, LayoutRoutingModule],
})
export class LayoutModule { }
