<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="text-comp">
  <mat-tab label="Content">
    <div style="height: 100%;">
      <ngx-ace-editor [(ngModel)]="textContent" [mode]="mode" theme="eclipse" [durationBeforeCallback]="1000"
        [autoUpdateContent]="true"></ngx-ace-editor>
    </div>
  </mat-tab>
  <mat-tab label="View" *ngIf="mode === 'html'">
    <div class="view-content-scroll" [innerHtml]="textContent"></div>
  </mat-tab>
</mat-tab-group>
<div mat-dialog-actions class="button-bar">
  <button mat-flat-button class="white-button m-r-5" type="button" (click)="submit('cancel')">Cancel</button>
  <button mat-flat-button class="teal-button m-r-5" type="button" (click)="submit('save')"
    [mat-dialog-close]="dialogData" cdkFocusInitial>Save</button>
</div>