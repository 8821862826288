import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  @Input() toggle: boolean;
  @Output() toggleEvent = new EventEmitter();
  @Input() tabName: string;

  constructor() { }

  ngOnInit(): void { }
}