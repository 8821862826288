export class AnalyticsConstant {
  public readonly AM = "AM";
  public readonly PM = "PM";
  public readonly LIKED = "Liked";
  public readonly DISLIKED = "Disliked";
  public readonly NEUTRAL = "Neutral";
  public readonly NOT_RECORDED = "Not Recorded";
  public readonly SESSION_COUNT_PER_DAY = "Session Count Per Day";
  public readonly LIKED_DISLIKED_COUNT = "Liked vs Disliked Count";
  public readonly JOURNEY_SESSION_COUNT = "Journey vs Session Count";
  public readonly MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  public readonly NLP_ENGINE = "nlpEngine";
  public readonly INTENT = "intent";
  public readonly AI_MSG_TIMESTAMP = "aiMessageTimestamp";
  public readonly ISSUED_TIMESTAMP = "issuedTimestamp";
  public readonly BROADCAST_TIMESTAMP = "broadcastTimestamp";
  public readonly CHAT_EPISODE_DURATION = "chatEpisodeDuration";
  public readonly FIRST_EPISODE_RESP_DURATION = "firstResponseDuration";
  public readonly ARRIVED_IN_Q_TIMESTAMP = "arrivedInqTimestamp";
  public readonly PICKED_TIMESTAMP = "pickedTimestamp";
  public readonly CLOSURE_EPISODE_TIME = "closureEpisodeTime";
  public readonly FIRST_RESPONSE_TIMESTAMP = "firstResponseTimestamp";
  public readonly STATUS_DELETED_REQUESTED = "DELETED_REQUESTED";
  public readonly JOURNEY_COUNT = "Journey Count";
  public readonly SESSION_COUNT = "Session Count";
  public readonly DC_EMAIL_EXT = "@digitalchameleon.ai";
  public readonly TOP_AGENT = "Top Agent";
  public readonly AGENT_ALIAS = "agentAlias";
  public readonly AGENT_AVAILABILITY = "agentAvailability";
  public readonly AGENT_STATUS = "agentStatus";
  public readonly STATUS_START_TIME = "statusStartTime";
  public readonly STATUS_END_TIME = "statusEndTime";
  public readonly PAGE_LOAD_TIMESTAMP = "pageLoadTimestamp";
  public readonly EVENT_TIMESTAMP = "eventTimestamp";
  public readonly TYPE_LIKED_DISLIKED_COUNT = "likedDislikedCount";
  public readonly CLICKED_TYPED_AI_COUNT = "clickedTypedAICount";
  public readonly AI_SESSION_GENDER_COUNT = "aiSessionGenderCount";
  public readonly MISSED_CHAT_REASON_COUNT = "missedChatReasonCount";
  public readonly LC_SESSION_GENDER_COUNT = "lcSessionGenderCount";

  public readonly CONVERSATIONS = "Conversations";
  public readonly FORMS = "Forms";
  public readonly WORKFLOWS = "Workflows";
  public readonly CHATBOTS = "Chatbots";
  public readonly LIVECHATS = "Live Chats";
  public readonly BOTH = "both";
  public readonly ALL = "all";
  public readonly DAY = "day";
  public readonly WEEK = "week";
  public readonly MONTH = "month";
  public readonly HOUR = "hour";
  public readonly PAGE_TITLE = "pageTitle";
  public readonly ACTION_NAME = "actionName";
  public readonly NA = "N/A";
  public readonly SEVEN_DAYS = "sevenDays";
  public readonly THIRTY_DAYS = "thirtyDays";
  public readonly FULL_WIDTH = "full-width";
  public readonly SIX_MONTHS = "sixMonths";
  public readonly TWELVE_MONTHS = "twelveMonths";
  public readonly PAGE_VIEWS = "Page Views";
  public readonly SESSIONS = "sessions";
  public readonly MOMENT_DATE = "_d";
  public readonly DAYS = "days";
  public readonly HOURS = "hours";
  public readonly MINUTES = "minutes";
  public readonly SECONDS = "seconds";
  public readonly CHART = "chart";
  public readonly CHART_Y = "chartY";
  public readonly ALL_TYPES = "All";
  public readonly NONE = "None";
  public readonly MORE = "more";
  public readonly WIDTH = "width";
  public readonly FULL_CLIENT_NAME = "fullClientName";

  public readonly JOURNEY_NAME = "journeyName";
  public readonly WEB_PAGE = "webpage";
  public readonly ALL_SELECTED = "allSelected";
  public readonly ALL_WF_OPTION_SELECTED = "allWFOptionSelected";
  public readonly PAGE_TITLE_VAL = "pageTitleVal";
  public readonly SELECTED_WF_NAMES = "selectedWFNames";
  public readonly WORKFLOW_TYPE_WF = "wf";
  public readonly WORKFLOW_TYPE_FM = "fm";
  public readonly WORKFLOW_TYPE_BOTH = "both";
  public readonly WF_BEGIN_TIMESTAMP = "wfBeginTimestamp";
  public readonly WF_CHART = "wfChart";
  public readonly WF_CHART_Y = "wfChartY";
  public readonly ALL_WEB_BRAND_SELECTED = "allWebBrandSelected";
  public readonly ALL_EVENT_BRAND_SELECTED = "allEventBrandSelected";
  public readonly ALL_CHATBOT_BRAND_SELECTED = "allChatbotBrandSelected";
  public readonly WEB_PAGE_BRAND_VAL = "webpageBrandVal";
  public readonly EVENT_BRAND_VAL = "eventBrandVal";
  public readonly CHATBOT_BRAND_VAL = "chatbotBrandVal";
  public readonly CHATBOT_CHART = "chatbotChart";
  public readonly CHATBOT_CHART_Y = "chatbotChartY";
  public readonly CHATBOT_TITLE = "chatbotTitle";
  public readonly ALL_CHATBOT_SELECTED = "allChatbotTSelected";
  public readonly CHATBOT_VAL = "chatbotTVal";
  public readonly BRAND_FILTER = "brandFilter";
  public readonly ALL_BRAND_SELECTED = "allTBrandSelected";
  public readonly WF_BRAND_VAL = "wfBrandVal";
  public readonly LC_BRAND_VAL = "lcBrandVal";
  public readonly TOP_BRAND_VAL = "topBrandVal";
  public readonly PAGE_URL = "pageUrl";
  public readonly CURRENT_PAGE = "currentPage";
  public readonly DEPARTMENT_NAME = "departmentName";
  public readonly DEPARTMENT_ID = "departmentId";
  public readonly LIVECHAT = "livechat";
  public readonly LC_T_VAL = "lcTVal";
  public readonly AGENT_POOL = "agentPool";
  public readonly DEPT_AVAIL = "departmentAvailability";
  public readonly LC_CHART = "lcChart";
  public readonly LC_CHART_Y = "lcChartY";

  public readonly UI_POLICY_MODULE_NAME_ANALYTICS = "analytics-management";

  public readonly WEBSITE = "WEBSITE";
  public readonly EVENT = "EVENT";
  public readonly WORKFLOWS_TAB = "WORKFLOWS";
  public readonly CHATBOT = "CHATBOT";
  public readonly LIVE_CHAT = "LIVE_CHAT";
  public readonly AGENT_AVAIL_TAB = "AGENT_AVAILABILITY";
  public readonly DEPT_AVAIL_TAB = "DEPT_AVAILABILITY";

  public readonly DAY_INTERVAL = 30;
  public readonly MONTH_INTERVAL = 12;
  public readonly HOUR_INTERVAL = 168;
  public readonly HOUR_SPACE_BTWN_CIRCLE = 6;
  public readonly DAY_SPACE_BTWN_CIRCLE = 36;
  public readonly MONTH_SPACE_BTWN_CIRCLE = 90;
  public readonly WEBPAGE_CHART_INIT_WIDTH = 1080;
  public readonly CIRCLE_HOUR_INTERVAL = 24;
  public readonly CIRCLE_DAY_INTERVAL = 2;
  public readonly LINECHART_TOOLTIP_TOP = 230;
  public readonly LINECHART_TOOLTIP_left = 150;
  public readonly CLIENT_SEARCH_CHAR_LENGTH = 4;

  public readonly BAR_CHART_WIDTH = 500;
  public readonly BAR_CHART_FULL_WIDTH = 1070;
  public readonly BAR_CHART_HEIGHT = 250;
  public readonly BAR_CHART_BAR_WIDTH = 36;
  public readonly BAR_CHART_SPACE_BTWN_BAR = 8;

  public readonly AREA_CHART_WIDTH = 500;
  public readonly AREA_CHART_HEIGHT = 245;
  public readonly AREA_CHART_SPACE_BTWN_LINE = 36;

  public readonly LOLLI_CHART_WIDTH = 260;
  public readonly LOLLI_CHART_HEIGHT = 350;
  public readonly LOLLI_CHART_SPACE_BTWN_BAR = 20;

  public readonly HORI_BAR_CHART_WIDTH = 390;
  public readonly HORI_BAR_CHART_HEIGHT = 350;
  public readonly HORI_BAR_CHART_BAR_WIDTH = 26;
  public readonly HORI_BAR_CHART_SPACE_BTWN_BAR = 5;

  public readonly PT_BAR_WIDTH_THRESHOLD = 89;
  public readonly PT_BAR_WIDTH_BUFFER = 8;
  public readonly SVG_WIDTH_BUFFER = 90;
  public readonly LINE_CHART_HORIZONTAL_BUFFER = 130;

  public readonly BAR_CHART_SVG_WIDTH_BUFFER = 30;
}