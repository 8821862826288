export class StatusNode {

  private readonly respectiveStatus: string;
  private outwardTransitions: string [] = [];

  constructor(status: string, transitions: string []) {
    this.respectiveStatus = status;
    if (!!transitions && Array.isArray(transitions)) {
      this.outwardTransitions.push(...transitions);
    }
  }

  public fromStatus = (): string => {
    return this.respectiveStatus;
  }

  public applicableTransitions = (): string [] => {
    return this.outwardTransitions;
  }

  public isApplicable = (attempted: string): boolean => {
    let applicable = false;
    if(!!attempted) {
      const allowed: string = this.outwardTransitions.find(ot => ot === attempted);
      applicable = !!allowed;
    }
    return applicable;
  }
}
