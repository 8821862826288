<div class="library-container">
  <h2 mat-dialog-title>Library</h2>
  <mat-form-field appearance="outline">
    <input matInput placeholder="Search Images..." [(ngModel)]="searchText" (ngModelChange)="onSearch()">
  </mat-form-field>
  <mat-paginator #paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="[]"
    (page)="changePage($event)"></mat-paginator>
</div>
<div *ngIf="showLoader" class="loader-container">
  <div class="spinner-border spinner-position"></div>
</div>
<div *ngIf="showError" class="loader-container">
  <p class="lead text-center m-t-10">Unable to find any images!</p>
</div>
<mat-dialog-content *ngIf="!showLoader" class="library-content">
  <mat-radio-group class="image-container justify-content-between flex-wrap p-t-5" aria-label="Select an option"
    [(ngModel)]="selectedImage">
    <mat-radio-button class="image-panel" *ngFor="let imgInfo of images; trackBy: trackById;"
      [value]="imgInfo.ufUrl.regular" (change)="imageInfo = imgInfo">
      <img [src]="imgInfo.ufUrl.regular" width="200" loading="lazy" alt="unsplash regular pic">
    </mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>
<div mat-dialog-actions class="button-bar">
  <button mat-flat-button type="button" class="white-button m-r-5" mat-dialog-close>Cancel</button>
  <button mat-flat-button type="submit" class="teal-button m-r-5" (click)="onSelection()">Select</button>
</div>