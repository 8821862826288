export class SRType {

  public readonly SR_GUEST: string = "GUEST";
  // This role must be ROLE_LC, but there is a typo in LC module which must be fixed
  public readonly SR_LC: string = "ROLE_CC";
  public readonly SR_AGENT: string = "AGENT";
  public readonly SR_WF: string = "WF";
  public readonly SR_AI: string = "AI";
  public readonly SR_FM: string = "FM";

  constructor() {}
}
