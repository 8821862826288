import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ColorPickerInfo } from "@app/core/misctypes/color-picker-info";
import { StorageService } from "@app/core/services/storage/storage.service";


@Component({
  selector: "app-color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"]
})
export class ColorPickerComponent implements OnInit {

  @Input() cPickerId: number;
  @Input() pickedColor: string;
  @Input() showDeleteIcon: boolean = false;
  @Input() colorPickerPosition: string = 'bottom-left';
  @Output() clearSelectedColor = new EventEmitter();
  @Output() selectedColor = new EventEmitter();
  public recentColors: string[] = [];

  constructor(public _storage: StorageService) { }

  ngOnInit(): void {
    this.recentColors = this._storage.storedRecentColors();
  }

  public storeChangedColor = (ev: ColorPickerInfo) => {
    if (this.recentColors && ev && ev.color && !this.recentColors.includes(ev.color)) {
      if (this.recentColors.length >= 12) {
        this.recentColors.shift();
      }
      this.recentColors.push(ev.color);
      this._storage.storeRecentColors(this.recentColors);
    }
  }

  public onColorChange = (ev: string) => {
    if (ev) {
      this.selectedColor.emit(ev);
    }
  }

  public clearColor = (ev: string): void => {
    if (ev) {
      this.clearSelectedColor.emit(ev);
    }
  }

}