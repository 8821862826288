export class ResetPwdInfo {

  private readonly un: string;
  private readonly pwd: string;
  private readonly newPwd: string;

  constructor(un: string, pwd: string, npw: string) {
    this.un = un;
    this.pwd = pwd;
    this.newPwd = npw;
  }

  public healthy = (): boolean => {
    return !!(this.un && this.pwd && this.newPwd);
  }

  public getUserName = (): string => {
    return this.un;
  }

  public getPassword = (): string => {
    return this.pwd;
  }

  public getNewPassword = (): string => {
    return this.newPwd;
  }
}
