<div class="sentinel-logo-bg confirm-page">
  <video autoplay class="cmsbgvdo">
    <source src="/assets/img/cmslogin.mp4">
  </video>

  <div class="logo-bg">
    <mat-card class="login-card">
      <mat-card-content>
        <form [formGroup]="mfaCodeForm" (ngSubmit)="confirmCode()">
          <div>
            <ng-otp-input (onInputChange)="onOtpChange($event)" (keyup.enter)="confirmCode()"
              [config]="{length:6, allowNumbersOnly: true}">
            </ng-otp-input>
            <div class="f-12 m-t-15">Please enter the OTP sent to your mobile</div>
          </div>
          <mat-card-actions>
            <button mat-raised-button color="primary" class="sentinel-btn login-btn" type="submit">Confirm</button>
            <button mat-raised-button color="primary" class="sentinel-btn login-btn" type="button" (click)="resendOTP()">Resend OTP</button>
          </mat-card-actions>
        </form>
      </mat-card-content>
      <mat-card-footer>
        <div class="mat-error">{{error}}</div>
      </mat-card-footer>
    </mat-card>

    <div class="camie-heading">
      <div class="camie-text">
        <h1>DigitalChameleon</h1>
        <h4>The No-code Launchpad for Your Ideas</h4>
      </div>
    </div>
    <p class="copyright-cms">DigitalChameleon.ai</p>
  </div>
</div>