<div class="sentinel-logo-bg confirm-page">
  <video autoplay class="cmsbgvdo">
    <source src="/assets/img/cmslogin.mp4">
  </video>

  <div class="logo-bg">
    <mat-card class="callback-card">
      <mat-card-content>
        <form [formGroup]="confirmCodeForm" (ngSubmit)="confirmCode()">
          <mat-form-field class="mat-form-field-wrapper" appearance="legacy">
            <mat-label>Email</mat-label>
            <p></p>
            <input matInput type="email" formControlName="username" required autofocus>
            <mat-error>
              {{getEmailErrorMessage()}}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mat-form-field-wrapper" appearance="legacy">
            <mat-label>Temporary Password</mat-label>
            <p></p>
            <input matInput [type]="hideTempPass ? 'password' : 'text'" formControlName="password" required>
            <button type="button" mat-icon-button matSuffix (click)="hideTempPass = !hideTempPass"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideTempPass">
              <mat-icon>{{hideTempPass ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error>
              {{getPWDErrorMessage()}}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="mat-form-field-wrapper" appearance="legacy">
            <mat-label>New Password</mat-label>
            <p></p>
            <input matInput [type]="hideNewPass ? 'password' : 'text'" formControlName="newPassword" required
              (keyup)="checkPwdValidity()">
            <button type="button" mat-icon-button matSuffix (click)="hideNewPass = !hideNewPass"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNewPass">
              <mat-icon>{{hideNewPass ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error>
              {{getNewPWDErrorMessage()}}
            </mat-error>
          </mat-form-field>

          <div *ngIf="confirmCodeForm.value.newPassword" class="pwd-checks pwd-checks-position">
            <p class="remove-margin">Password must contain:</p>
            <p class="remove-margin" [ngClass]="{'pwd-check-error':confirmCodeForm.get('newPassword').hasError('minlength'),
               'pwd-check-no-error': !confirmCodeForm.get('newPassword').hasError('minlength')}">
              <mat-icon>
                {{confirmCodeForm.get('newPassword').hasError('minlength') ? 'error_outline' : 'check'}}
              </mat-icon>
              Minimum eight characters
            </p>

            <p class="remove-margin"
              [ngClass]="{'pwd-check-error':isUpperCaseCharErr,'pwd-check-no-error': !isUpperCaseCharErr}">
              <mat-icon> {{isUpperCaseCharErr ? 'error_outline' : 'check'}}</mat-icon>
              Uppercase letter
            </p>

            <p class="remove-margin"
              [ngClass]="{'pwd-check-error':isLowerCaseCharErr,'pwd-check-no-error': !isLowerCaseCharErr}">
              <mat-icon> {{isLowerCaseCharErr ? 'error_outline' : 'check'}}</mat-icon>
              Lowercase letter
            </p>

            <p class="remove-margin"
              [ngClass]="{'pwd-check-error':isNumberCharErr,'pwd-check-no-error': !isNumberCharErr}">
              <mat-icon> {{isNumberCharErr ? 'error_outline' : 'check'}}</mat-icon>
              Number
            </p>

            <p class="remove-margin"
              [ngClass]="{'pwd-check-error':isSpecialCharErr,'pwd-check-no-error': !isSpecialCharErr}">
              <mat-icon> {{isSpecialCharErr ? 'error_outline' : 'check'}}</mat-icon>
              Special character
            </p>
          </div>

          <mat-form-field class="mat-form-field-wrapper" appearance="legacy">
            <mat-label>Confirm New Password</mat-label>
            <p></p>
            <input matInput [type]="hideConfPass ? 'password' : 'text'" placeholder=""
              formControlName="confirmNewPassword" required>
            <button type="button" mat-icon-button matSuffix (click)="hideConfPass = !hideConfPass"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfPass">
              <mat-icon>{{hideConfPass ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error>
              {{getConfirmNewPWDErrorMessage()}}
              {{error}}
            </mat-error>
          </mat-form-field>

          <mat-card-actions>
            <button mat-raised-button color="primary" class="sentinel-btn login-btn" type="submit">Confirm</button>
          </mat-card-actions>
        </form>
      </mat-card-content>
      <mat-card-footer>
        <div class="mat-error">{{error}}</div>
      </mat-card-footer>
    </mat-card>

    <div class="camie-heading">
      <div class="camie-text">
        <h1>DigitalChameleon</h1>
        <h4>The No-code Launchpad for Your Ideas</h4>
      </div>
    </div>
    <p class="copyright-cms">DigitalChameleon.ai
    </p>
  </div>
</div>