export class LcConstants {
  public readonly _AI = "AI";
  public readonly _LC = "LC";
  public readonly _FM = "FM";
  public readonly _WF = "WF";

  public readonly AGENT_STATUS = "SUBMITTED";
  public readonly CHAT_CLOSED = "CHAT_CLOSED";
  public readonly AGENT_OFFLINE = "OFFLINE";
  public readonly CHAT_TIMEOUT_ERROR_MSG = "Chat has been timed out";
  public readonly AVAILABILITY_STATUS = ["", "offline", "online", "away", "offline"];

  public readonly OFFLINE = 1;
  public readonly ONLINE = 2;
  public readonly AWAY = 3;
  public readonly OFFLINE_ALL_DEVICES = 4;
  public readonly REASON_CODE_CHAT_CLOSED = 4100;
  public readonly REASON_CODE_AGENT_OFFLINE_THIS_DEVICE = 4109;
  public readonly REASON_CODE_AGENT_OFFLINE_ALL_DEVICES = 4110;
  public readonly REASON_DESC_CHAT_CLOSED = "CHAT_CLOSED";
  public readonly REASON_DESC_AGENT_OFFLINE_THIS_DEVICE = "AGENT_OFFLINE_THIS_DEVICE_ONLY";
  public readonly REASON_DESC_AGENT_OFFLINE_ALL_DEVICES = "AGENT_OFFLINE_ON_ALL_DEVICES";
}
