import { JourneyLevelAttributes } from "@app/core/msg/journey-level-attributes";
import { ResolvedTrigger } from "@app/core/msg/resolved-trigger";
import { MessageContent } from "./message-content";
import { SRType } from "@app/core/msg/sr-type";
import { MsgType } from "@app/core/msg/msg-type";
import { DepartmentInfo } from "@app/core/misctypes/department-info";

export class ChannelMessage {
  public userSessionUuid: string;
  public sourcePage?: string;
  public browser?: string;
  public device?: string;
  public os?: string;
  public senderRole?: string;
  public senderName?: string;
  public senderDisplayName?: string;
  public channelId: string;
  public responderMode: string;
  public transferFrom?: string;
  public transferTo?: string;
  public receiverName?: string;
  public guestId: string;
  public agentName?: string;
  public message: MessageContent[] = [];
  public messageType: number;
  public date: Date;
  public isHelpful = true;
  public isHandled = true;
  public intent?: string;
  public isUserDataEncrypted = false;
  public sorrySuggestions: string[] = [];
  public suggestionsOnDislike: string[] = [];
  public dislikedSuggestions?: string;
  public isEdited = false;
  public isApplyModeContinued = false;
  public formName?: string;
  public isClientFormChanged = false;
  public redirectMessages?: string;
  public qType?: string;
  public editedResponse: string[] = [];
  public nextQuestion?: string;
  public clientId: string;
  public authToken: string;
  public messageKey: string;
  public botName: string;
  public sentiment?: string;
  public isRefresh = false;
  public transferredGuestUsername?: string;
  public defaultDepartment: DepartmentInfo;
  public linkedDepartments: DepartmentInfo[] = [];
  public timestamp: number;
  public chatRefId?: string;
  public sessionSleepModeCount = 0;
  public sessionResumeCount = 0;
  public switchToFormView = false;
  public isMultilingualButtonClick = false;
  public multilingualButtonChoice?: string;
  public inputFlag?: string;
  public cognitoId?: string;
  public journey?: string;
  public agentUserUUId?: string;
  public milestoneInfo: string[] = [];
  public formId?: string;
  public formProgress?: number;
  public isClientBehaviourChanged = false;
  public isJourneyChanged = false;
  public workflowOptions: string[] = [];
  public refMessageId?: string;
  public sentimentScore = 0;
  public behaviourId?: string;
  public behaviourName?: string;
  public currentWorkflowId?: string;
  public executedWorkflowList: string[] = [];
  public workflowNodeId?: string;
  public workflowNodeHTMLType?: string;
  public workflowNodeText?: string;
  public workflowMessage: string[] = [];
  public formMessages: string[] = [];
  public workflowEnd = false;
  public isBlacklisted = false;
  public modeContext: string[] = [];
  public referrer?: string;
  public currentPage?: string;
  public fbSenderId?: string;
  public city?: string;
  public state?: string;
  public country?: string;
  public latitude?: string;
  public longitude?: string;
  public sourceIp?: string;
  public sex?: string;
  public age?: number;
  public profession?: string;
  public weather?: string;
  public category?: string;
  public clientName?: string;
  public trackingKey?: string;
  public respBehaviourId?: string;
  public nlpId?: string;
  public epochTime?: number;
  public isCruiseControlled?: boolean = false;
  public isSkipped?: boolean = false;
  public newUow?: boolean = false;
  public fastLane?: boolean = false;
  public aiMessages?: string[] = [];
  public replyToQueues?: string[] = [];
  public transferredBackToBotBy?: string;
  public accessToken: string;
  public voluntaryAction?: string;
  public intentResolved = false;
  public isTransferredBackToBot = false;
  public triggerResult: ResolvedTrigger[] = [];
  public formInfo: string[] = [];
  public placeholders: string[] = [];
  public errorMessages: string[] = [];
  public jnyLvlAttr: JourneyLevelAttributes[] = [];
  public isNew?: boolean = false;
  public isOffline?: boolean;
  public isConnected?: boolean = false;
  public createdTimestamp: number;
  public updatedTimestamp: number;
  public createdBy: string;
  public updatedBy: string;
  public guestTimer?: number = 0;
  public episodeReferenceId: string;
  public uowId: string;

  private readonly _srType: SRType;
  private readonly _msgType: MsgType;

  constructor() {
    this._msgType = new MsgType();
    this._srType = new SRType();
  }

  initInbound = (resp: any): ChannelMessage => {
    let chnMsg: ChannelMessage;
    try {
      const msgObj = (resp && resp.data) ? JSON.parse(resp.data) : undefined;
      if (msgObj) {
        chnMsg = new ChannelMessage();
        chnMsg.userSessionUuid = (msgObj.userSessionUuid) ? msgObj.userSessionUuid : "";
        chnMsg.sourcePage = (msgObj.sourcePage) ? msgObj.sourcePage : "";
        chnMsg.browser = (msgObj.browser) ? msgObj.browser : "";
        chnMsg.device = (msgObj.device) ? msgObj.device : "";
        chnMsg.os = (msgObj.os) ? msgObj.os : "";
        chnMsg.senderRole = (msgObj.senderRole) ? msgObj.senderRole : "";
        chnMsg.senderName = (msgObj.senderName) ? msgObj.senderName : "";
        chnMsg.senderDisplayName = (msgObj.senderDisplayName) ? msgObj.senderDisplayName : "";
        chnMsg.channelId = (msgObj.channelId) ? msgObj.channelId : "";
        chnMsg.responderMode = (msgObj.responderMode) ? msgObj.responderMode : "";
        chnMsg.transferFrom = (msgObj.transferFrom) ? msgObj.transferFrom : "";
        chnMsg.transferTo = (msgObj.transferTo) ? msgObj.transferTo : "";
        chnMsg.receiverName = (msgObj.receiverName) ? msgObj.receiverName : "";
        chnMsg.guestId = (msgObj.guestId) ? msgObj.guestId : "";
        chnMsg.agentName = (msgObj.agentName) ? msgObj.agentName : "";
        chnMsg.message = (msgObj.message) ? msgObj.message as MessageContent[] : [new MessageContent("", "")];
        chnMsg.messageType = (msgObj.messageType) ? msgObj.messageType : "";
        chnMsg.date = (msgObj.date) ? msgObj.date : new Date();
        chnMsg.isHelpful = (msgObj.isHelpful) ? msgObj.isHelpful : true;
        chnMsg.isHandled = (msgObj.isHandled) ? msgObj.isHandled : true;
        chnMsg.intent = (msgObj.intent) ? msgObj.intent : "";
        chnMsg.isUserDataEncrypted = (msgObj.isUserDataEncrypted) ? msgObj.isUserDataEncrypted : false;
        chnMsg.sorrySuggestions = (msgObj.sorrySuggestions) ? msgObj.sorrySuggestions : [];
        chnMsg.suggestionsOnDislike = (msgObj.suggestionsOnDislike) ? msgObj.suggestionsOnDislike : [];
        chnMsg.dislikedSuggestions = (msgObj.dislikedSuggestions) ? msgObj.dislikedSuggestions : "";
        chnMsg.isEdited = (msgObj.isEdited) ? msgObj.isEdited : false;
        chnMsg.isApplyModeContinued = (msgObj.isApplyModeContinued) ? msgObj.isApplyModeContinued : false;
        chnMsg.formName = (msgObj.formName) ? msgObj.formName : "";
        chnMsg.isClientFormChanged = (msgObj.isClientFormChanged) ? msgObj.isClientFormChanged : false;
        chnMsg.redirectMessages = (msgObj.redirectMessages) ? msgObj.redirectMessages : "";
        chnMsg.qType = (msgObj.qType) ? msgObj.qType : "";
        chnMsg.editedResponse = (msgObj.editedResponse) ? msgObj.editedResponse : [];
        chnMsg.nextQuestion = (msgObj.nextQuestion) ? msgObj.nextQuestion : "";
        chnMsg.clientId = (msgObj.clientId) ? msgObj.clientId : "";
        chnMsg.authToken = (msgObj.authToken) ? msgObj.authToken : "";
        chnMsg.messageKey = (msgObj.messageKey) ? msgObj.messageKey : "";
        chnMsg.botName = (msgObj.botName) ? msgObj.botName : "";
        chnMsg.sentiment = (msgObj.sentiment) ? msgObj.sentiment : "";
        chnMsg.isRefresh = (msgObj.isRefresh) ? msgObj.isRefresh : false;
        chnMsg.transferredGuestUsername = (msgObj.transferredGuestUsername) ? msgObj.transferredGuestUsername : "";
        chnMsg.defaultDepartment = (msgObj.defaultDepartment) ? msgObj.defaultDepartment : null;
        chnMsg.linkedDepartments = (msgObj.linkedDepartments) ? msgObj.linkedDepartments : [];
        chnMsg.timestamp = (msgObj.timestamp) ? msgObj.timestamp : new Date().getTime();
        chnMsg.chatRefId = (msgObj.chatRefId) ? msgObj.chatRefId : "";
        chnMsg.sessionSleepModeCount = (msgObj.sessionSleepModeCount) ? msgObj.sessionSleepModeCount : 0;
        chnMsg.sessionResumeCount = (msgObj.sessionResumeCount) ? msgObj.sessionResumeCount : 0;
        chnMsg.switchToFormView = (msgObj.switchToFormView) ? msgObj.switchToFormView : false;
        chnMsg.isMultilingualButtonClick = (msgObj.isMultilingualButtonClick) ? msgObj.isMultilingualButtonClick : false;
        chnMsg.multilingualButtonChoice = (msgObj.multilingualButtonChoice) ? msgObj.multilingualButtonChoice : "";
        chnMsg.inputFlag = (msgObj.inputFlag) ? msgObj.inputFlag : "";
        chnMsg.cognitoId = (msgObj.cognitoId) ? msgObj.cognitoId : "";
        chnMsg.journey = (msgObj.journey) ? msgObj.journey : "";
        chnMsg.agentUserUUId = (msgObj.agentUserUUId) ? msgObj.agentUserUUId : "";
        chnMsg.milestoneInfo = (msgObj.milestoneInfo) ? msgObj.milestoneInfo : [];
        chnMsg.formId = (msgObj.formId) ? msgObj.formId : "";
        chnMsg.formProgress = (msgObj.formProgress) ? msgObj.formProgress : "";
        chnMsg.isClientBehaviourChanged = (msgObj.isClientBehaviourChanged) ? msgObj.isClientBehaviourChanged : false;
        chnMsg.isJourneyChanged = (msgObj.isJourneyChanged) ? msgObj.isJourneyChanged : false;
        chnMsg.workflowOptions = (msgObj.workflowOptions) ? msgObj.workflowOptions : [];
        chnMsg.refMessageId = (msgObj.refMessageId) ? msgObj.refMessageId : "";
        chnMsg.sentimentScore = (msgObj.sentimentScore) ? msgObj.sentimentScore : 0;
        chnMsg.behaviourId = (msgObj.behaviourId) ? msgObj.behaviourId : "";
        chnMsg.behaviourName = (msgObj.behaviourName) ? msgObj.behaviourName : "";
        chnMsg.currentWorkflowId = (msgObj.currentWorkflowId) ? msgObj.currentWorkflowId : "";
        chnMsg.executedWorkflowList = (msgObj.executedWorkflowList) ? msgObj.executedWorkflowList : [];
        chnMsg.workflowNodeId = (msgObj.workflowNodeId) ? msgObj.workflowNodeId : "";
        chnMsg.workflowNodeHTMLType = (msgObj.workflowNodeHTMLType) ? msgObj.workflowNodeHTMLType : "";
        chnMsg.workflowNodeText = (msgObj.workflowNodeText) ? msgObj.workflowNodeText : "";
        chnMsg.workflowMessage = (msgObj.workflowMessage) ? msgObj.workflowMessage : [];
        chnMsg.formMessages = (msgObj.formMessages) ? msgObj.formMessages : [];
        chnMsg.workflowEnd = (msgObj.workflowEnd) ? msgObj.workflowEnd : false;
        chnMsg.isBlacklisted = (msgObj.isBlacklisted) ? msgObj.isBlacklisted : false;
        chnMsg.modeContext = (msgObj.modeContext) ? msgObj.modeContext : [];
        chnMsg.referrer = (msgObj.referrer) ? msgObj.referrer : "";
        chnMsg.fbSenderId = (msgObj.fbSenderId) ? msgObj.fbSenderId : "";
        chnMsg.city = (msgObj.city) ? msgObj.city : "";
        chnMsg.state = (msgObj.state) ? msgObj.state : "";
        chnMsg.country = (msgObj.country) ? msgObj.country : "";
        chnMsg.latitude = (msgObj.latitude) ? msgObj.latitude : "";
        chnMsg.longitude = (msgObj.longitude) ? msgObj.longitude : "";
        chnMsg.transferredBackToBotBy = (msgObj.transferredBackToBotBy) ? msgObj.transferredBackToBotBy : "";
        chnMsg.accessToken = (msgObj.accessToken) ? msgObj.accessToken : "";
        chnMsg.voluntaryAction = (msgObj.voluntaryAction) ? msgObj.voluntaryAction : "";
        chnMsg.intentResolved = (msgObj.intentResolved) ? msgObj.intentResolved : false;
        chnMsg.isTransferredBackToBot = (msgObj.isTransferredBackToBot) ? msgObj.isTransferredBackToBot : false;
        chnMsg.createdTimestamp = (msgObj.createdTimestamp) ? msgObj.createdTimestamp : -1;
        chnMsg.updatedTimestamp = (msgObj.updatedTimestamp) ? msgObj.updatedTimestamp : -1;
        chnMsg.createdBy = (msgObj.createdBy) ? msgObj.createdBy : "";
        chnMsg.updatedBy = (msgObj.updatedBy) ? msgObj.updatedBy : "";
        chnMsg.triggerResult = chnMsg.triggerResult.concat((msgObj.triggerResult) ? msgObj.triggerResult as ResolvedTrigger[] : []);
        chnMsg.formInfo = chnMsg.formInfo.concat((msgObj.formInfo) ? msgObj.formInfo : []);
        chnMsg.placeholders = chnMsg.placeholders.concat((msgObj.placeholders) ? msgObj.placeholders : []);
        chnMsg.errorMessages = chnMsg.errorMessages.concat((msgObj.errorMessages) ? msgObj.errorMessages : []);
        chnMsg.jnyLvlAttr = chnMsg.jnyLvlAttr.concat((msgObj.jnyLvlAttr) ? msgObj.jnyLvlAttr as JourneyLevelAttributes[] : []);
        chnMsg.episodeReferenceId = (msgObj.episodeReferenceId) ? msgObj.episodeReferenceId : "";
        chnMsg.nlpId = (msgObj.nlpId) ? msgObj.nlpId : "";
      }
    } catch (e) {
      console.log(`Error in parsing the socket messages received. Error is ${e}`);
    }
    console.log(`channel message content is ${JSON.stringify(chnMsg)}`);
    return chnMsg;
  }

  initOutbound = (): ChannelMessage => {
    const chnMsg = new ChannelMessage();
    chnMsg.senderRole = this._srType.SR_LC;
    return chnMsg;
  }

  healthy = (chn: ChannelMessage): boolean => {

    const conversational: boolean = chn.messageType === this._msgType.MT_CONVERSATION;

    const timestamped: boolean = chn.createdTimestamp > 0
      && chn.updatedTimestamp > 0
      && chn.createdTimestamp <= chn.updatedTimestamp;

    return !!(
      chn
      && chn.channelId
      && chn.clientId
      && chn.accessToken
      && chn.userSessionUuid
      && chn.messageType
      // CHN messages - outbound from FE usually does not have message-key
      // But CHN messages inbound from down stream server MUST have a message-key
      && (chn.messageKey || (!chn.messageKey && conversational))
      && chn.responderMode
      && chn.senderName
      && timestamped
    );
  }

  healthyTerminalMsg = (chn: ChannelMessage): boolean => {
    /*let terminalMsg = false;
    const isCloseMsg: boolean = chn.messageType === this._msgType.MT_AGENT_SOCKET_GRACEFULLY_CLOSED;
    const timestamped: boolean = chn.createdTimestamp > 0
      && chn.updatedTimestamp > 0
      && chn.createdTimestamp <= chn.updatedTimestamp;

    terminalMsg = [
      this._msgType.MT_AGENT_SOCKET_GRACEFULLY_CLOSED,
      this._msgType.MT_AGENT_STATUS_OFFLINE,
      this._msgType.MT_AGENT_LEAVE,
      this._msgType.MT_UNKNOWN_ERROR_AGENT,
      this._msgType.MT_CONNECTION_TIMED_OUT
    ].some(mt => mt === chn.messageType);

    return !!(
      chn
      && chn.channelId
      && chn.clientId
      && chn.messageType
      && terminalMsg
      && (chn.messageKey || (!chn.messageKey && isCloseMsg))
      && chn.responderMode
      && chn.senderName
      && timestamped
    );*/
    return true;
  }

  outboundForControlMsg = (chn: ChannelMessage, ctrlType: number): boolean => {

    /*let isControlMsg = false;
    const timestamped: boolean = chn.createdTimestamp > 0
      && chn.updatedTimestamp > 0
      && chn.createdTimestamp <= chn.updatedTimestamp;

    if (ctrlType) {
      isControlMsg = [
        this._msgType.MT_TRANSFER_WF_SUCCESS,
        this._msgType.MT_TRANSFER_AI_SUCCESS,
        this._msgType.MT_INITIAL_GREETING,
        this._msgType.MT_INITIAL_SESSION_INFO,
        this._msgType.MT_TRANSFER_TO_FM_REQUEST,
        this._msgType.MT_TRANSFER_TO_WF_REQUEST,
        this._msgType.MT_TRANSFER_TO_LC_REQUEST,
        this._msgType.MT_TRANSFER_TO_AI_REQUEST,
        this._msgType.MT_RESUME_INCOMPLETE,
        this._msgType.MT_RESTORE_ACTIVE
      ].some(mt => mt === ctrlType);
    }
    return !!(
      chn
      && chn.channelId
      && chn.clientId
      && chn.accessToken
      && chn.userSessionUuid
      && chn.messageType
      && isControlMsg
      && chn.responderMode
      && chn.senderName
      && timestamped
    );*/
    return true;
  }
}
