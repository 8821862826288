export const environment = {
  production: false,
  apiUrl: "https://ey1mfx9bdg.execute-api.ap-southeast-2.amazonaws.com/sbv1/api/v1",
  behaviourUrl: "https://ey1mfx9bdg.execute-api.ap-southeast-2.amazonaws.com/sbv1/api/v1/behaviourresponsemanagement",
  webSocketBaseUrl: "wss://backend-sbv1-api.camiesandbox.au/api/v1/livechatmanagement",
  webSocketRTMUrl: "wss://backend-sbv1-api.camiesandbox.au/api/v1/rtmmanagement",
  webSocketClientUrl: "wss://backend-sbv1-api.camiesandbox.au/api/v1/clientmanagement",
  webSocketSentinelUrl: "wss://backend-sbv1-api.camiesandbox.au/api/v1/chameleon-sentinel",
  environmentName: "local",
  /* apiUrl: "http://192.168.15.7:9999/api/v1",
  behaviourUrl: "http://192.168.15.7:9999/api/v1/behaviourresponsemanagement",
  webSocketBaseUrl: "ws://192.168.15.7:81/api/v1/livechatmanagement",
  webSocketRTMUrl: "ws://192.168.15.7:16000/api/v1/rtmmanagement",
  webSocketClientUrl: "ws://192.168.15.7:84/api/v1/clientmanagement",
  webSocketSentinelUrl: "ws://192.168.15.7:18000/api/v1/chameleon-sentinel",
  environmentName: "local", */
}
