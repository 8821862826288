import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { AnalyticsConstant } from "@app/analytics/constants/analytics.constant";
import { ValueCount } from "@app/analytics/types/value-count";
import * as d3 from "d3";

@Component({
  selector: "app-lollipopchart",
  templateUrl: "./lollipopchart.component.html",
  styleUrls: ["./lollipopchart.component.scss"]
})
export class LollipopchartComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild("chart") public chartContainer: ElementRef;
  @Input() public data: ValueCount[] = [];
  @Input() public label: string;
  public _analyticsConst: AnalyticsConstant;

  constructor() {
    this._analyticsConst = new AnalyticsConstant();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.chartContainer && this.data && this.data.length > 0) {
      this.drawChart();
    }
  }

  ngOnChanges() {
    if (this.chartContainer && this.data && this.data.length > 0) {
      this.drawChart();
    }
  }

  drawChart = () => {
    let element: HTMLDivElement = this.chartContainer.nativeElement;
    const margin = { top: 17, right: 30, bottom: 30, left: 110 };
    const width = this._analyticsConst.LOLLI_CHART_WIDTH;
    let height = this._analyticsConst.LOLLI_CHART_HEIGHT;
    const spaceBetweenBar = this._analyticsConst.LOLLI_CHART_SPACE_BTWN_BAR;
    height = this.getModifiedHeight(spaceBetweenBar);

    d3.select(element).selectAll("svg").remove();
    let svg = d3.select(element)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const yDomain: number[] = [0, d3.max(this.data, d => d.value)];
    const x: d3.ScaleLinear<number, number> = d3.scaleLinear()
      .domain(yDomain)
      .range([0, width]).nice();

    svg.append("g")
      .attr("transform", "translate(0,-17)rotate(360)")
      .call(d3.axisBottom(x).tickSizeInner(0)
        .tickFormat((d: number) => {
          return parseInt(d.toString()) === d ? d.toString() : "";
        }))
      .selectAll("text")
      .style("text-anchor", "end");

    const y: d3.ScaleBand<string> = d3.scaleBand()
      .range([0, height])
      .domain(this.data.map(d => { return d.name; }))
      .padding(2);

    svg.append("g")
      .call(d3.axisLeft(y).tickSizeInner(0))
      .selectAll(".tick")
      .attr("transform", (d, i) => {
        const pos: number = (i === 0)
          ? spaceBetweenBar
          : (spaceBetweenBar + (spaceBetweenBar * i));
        return `translate(0, ${pos})`;
      })
      .selectAll("text")
      .style("text-anchor", "end");

    svg.selectAll("myline")
      .data(this.data)
      .enter()
      .append("line")
      .attr("x1", d => { return x(d.value); })
      .attr("x2", x(0))
      .attr("y1", (d, i) => i === 0 ? spaceBetweenBar : (spaceBetweenBar + (spaceBetweenBar * i)))
      .attr("y2", (d, i) => i === 0 ? spaceBetweenBar : (spaceBetweenBar + (spaceBetweenBar * i)))
      .attr("stroke", "#E6AAAA")

    svg.selectAll("mycircle")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("cx", d => { return x(d.value); })
      .attr("cy", (d, i) => i === 0 ? spaceBetweenBar : (spaceBetweenBar + (spaceBetweenBar * i)))
      .attr("r", "4")
      .style("fill", "#E6AAAA")
      .attr("stroke", "black");

    svg
      .selectAll("myline")
      .data(this.data)
      .enter()
      .append("text")
      .attr("class", "yAxis-label")
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .attr("x", d => x(d.value) + 20)
      .attr("y", (d, i) => i === 0
        ? spaceBetweenBar + 5
        : spaceBetweenBar + 5 + (spaceBetweenBar * i))
      .text(d => d.value);

    let tooltip = d3.select(element)
      .append("div")
      .classed("chart-tooltip", true)
      .style("display", "none")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "5px")
      .style("position", "absolute");

    svg
      .on("mouseover", () => {
        d3.select(".chart-tooltip").style("display", "block");
      })
      .on("mouseout", () => {
        d3.select(".chart-tooltip").style("display", "none");
      })
      .on("mousemove", () => {
        if (d3 && d3.event && d3.event.srcElement
          && d3.event.srcElement.__data__
          && d3.event.srcElement.__data__.name
          && d3.event.srcElement.__data__.value) {
          let html = "";
          html = `${d3.event.srcElement.__data__.name} : ${d3.event.srcElement.__data__.value} sessions`;
          d3.select(".chart-tooltip")
            .style("left", d3.event.pageX - 100 + "px")
            .style("top", d3.event.pageY - 200 + "px")
            .html(html.trim());
        } else {
          d3.select(".chart-tooltip").style("display", "none");
        }
      });
  }

  private getModifiedHeight = (spaceBetweenBar: number) => {
    let height: number = this.data.length * spaceBetweenBar > this._analyticsConst.LOLLI_CHART_HEIGHT
      ? this.data.length * spaceBetweenBar
      : this._analyticsConst.LOLLI_CHART_HEIGHT;
    return height;
  }
}