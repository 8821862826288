import { Injectable } from "@angular/core";
import { Agent } from "@app/live-chat/models/agent/agent.model";
import { WebSocketSubject, webSocket } from "rxjs/webSocket";
import { environment } from "@environments/environment";
import { AuthenticationService } from "@app/core/services/authentication/authentication.service";
import { Subject, Observable } from "rxjs";
import { CoreUtils } from "@app/core/utils/core-utils";
import { MsgType } from "@app/core/msg/msg-type";
import { Constants } from "@app/core/utils/const";
import { ChannelMessage } from "@app/core/msg/channel-message";
import { AuthToken } from "@app/core/models/auth-token";
import { StorageService } from "@app/core/services/storage/storage.service";

@Injectable({
  providedIn: "root",
})
export class AgentService {

  public agent: Agent;
  public transferWs: WebSocketSubject<any>;
  public transferWSUrl;
  public username;
  public clientId;
  public transferringGuest = new Subject();
  public agentList = [];
  private readonly _coreUtils: CoreUtils;
  private _msgTypes: MsgType;
  private readonly _const: Constants;
  private readonly _storage: StorageService;

  constructor(private readonly authenticationService: AuthenticationService) {
    this.username = this.authenticationService.currentUser.userName;
    this.clientId = this.authenticationService.client.id;
    this._const = new Constants();
    this._coreUtils = new CoreUtils();
    this._msgTypes = new MsgType();
    this._storage = new StorageService();
  }

  connect() {

    // Limitation in cognito pools when connected with SSO in Azure, user-names are padded with #
    // Those are not allowed to be used in socket-urls
    const normalizedUserName: string = this.username
      .replace("#", "~")
      .replace("#", "~");

    const fp: string = !!this._storage.storedFp() ? this._storage.storedFp() : "";
    const adi: string = !!this._storage.storedAdi() ? this._storage.storedAdi() : "";

    const jwtToken: AuthToken = this.authenticationService.getStoredAuthToken();
    const idToken: string = !!jwtToken ? jwtToken.idToken : "";
    const accessToken: string = !!jwtToken ? jwtToken.accessToken : "";

    const socketUrlTokens: string [] = [
      `${environment.webSocketBaseUrl}/transferchat?cluster=${this.authenticationService.client.cluster}`,
      `fp=${fp}`, `adi=${adi}`, `id=${idToken}`, `acc=${accessToken}`
    ];
    this.transferWSUrl = `${socketUrlTokens.join("&")}`;

    if (this.transferWs === undefined || this.transferWs.closed) {
      this.transferWs = webSocket({url: this.transferWSUrl});
      setInterval(() => this.transferWs.next(this._coreUtils.generateHeartbeatMsg("")), 30000);
    }
    return this.transferWs.asObservable();
  }

  disconnect = () => {
    if (this.transferWs && !this.transferWs.isStopped) {
      console.log(`Disconnecting transfer-ws`);
      this.transferWs.complete();
      this.transferWs.unsubscribe();
    }
  }

  setTransferringGuest = (): Observable<any> => {
    return this.transferringGuest.asObservable();
  }

  onWsMessage = (message: ChannelMessage) => {
    switch (message.messageType) {
      case this._msgTypes.MT_A2A_TRANSFER_QUEUED:
        this.processTransferStart(message);
        break;
    }
  }

  processTransferStart = (msg) => {
    console.log(`Getting the current transferred chat ${msg}`);
    this.transferringGuest.next(msg);
  }
}
