import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { ModuleList } from "@app/shared/module-list";
import { AuthConstants } from "@app/core/constant/auth-constants";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {

  private _const: AuthConstants;

  constructor(
    private readonly authSvc: AuthenticationService,
    private readonly router: Router
  ) {
    this._const = new AuthConstants();
  }

  public canActivate = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) => {
    let activate = false;
    if (state.url) {
      const urlTokens: string[] = state.url.split("?");
      if (urlTokens && urlTokens.length > 0) {
        const urlToken: string = urlTokens[0];
        activate = this.checkUserLogin(route, urlToken);
      }
    }
    return activate;
  }

  public redirectToLogin = () => {
    /*this.authSvc.getLoginUrl().subscribe(
      (response) => {
        if (response.status === 200) {
          window.location.href = response.body.loginPageUrl;
        }
      },
      (error) => {
        console.log(`Error in getting login url. Error is ${error}`);
        this.router.navigate([`/${this._const.KEY_LOGIN}`]);
      }
    );*/
  }

  public checkPrivileges = (url: string) => {
    const data = ModuleList.modules.filter((item) => {
      return item.path === url;
    });
    let approval = false;
    if (data && data.length > 0) {
      const moduleId = data[0].moduleId;
      if (this.authSvc.currentUser.clientSubmoduleDtos) {
        this.authSvc.currentUser.clientSubmoduleDtos.forEach((item) => {
          if (url === `${this._const.KEY_SLASH}${this._const.KEY_LIVECHAT}`
            && moduleId === item.submodulesDto.id && !approval) {
            approval = true;
          }
          if (moduleId === item.submodulesDto.id) {
            approval = true;
          }
        });
      }
    }

    return approval;
  }

  public checkUserLogin = (route: ActivatedRouteSnapshot, url: string): boolean => {
    if (!this.authSvc.sessionInactive()) {
      if (url === `${this._const.KEY_SLASH}`
        && this.checkPrivileges(`${this._const.KEY_SLASH}${this._const.KEY_RTMC}`)) {
        this.router.navigate([`${this._const.KEY_SLASH}${this._const.KEY_RTMC}`]);
        return false;
      } else if (url !== `${this._const.KEY_SLASH}` && !this.checkPrivileges(url)) {
        this.router.navigate([`${this._const.KEY_SLASH}`]);
        return false;
      }
      return true;
    }
    this.redirectToLogin();
    return false;
  }
}
