import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { AnalyticsConstant } from "@app/analytics/constants/analytics.constant";
import { SessionsCount } from "@app/analytics/types/sessions-count";
import * as d3 from "d3";

@Component({
  selector: "app-areachart",
  templateUrl: "./areachart.component.html",
  styleUrls: ["./areachart.component.scss"]
})
export class AreachartComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild("chart") public chartContainer: ElementRef;
  @Input() public data: SessionsCount[] = [];
  @Input() public label: string;
  @Input() public type: string;
  @Input() public maxValue?: number;
  public _analyticsConst: AnalyticsConstant;

  constructor() {
    this._analyticsConst = new AnalyticsConstant();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.chartContainer && this.data) {
      this.drawChart();
    }
  }

  ngOnChanges() {
    if (this.chartContainer && this.data) {
      this.drawChart();
    }
  }

  public drawChart = () => {
    let element: HTMLDivElement = this.chartContainer.nativeElement;
    let margin = { top: 15, right: 10, bottom: 38, left: 40 };
    let width = this._analyticsConst.AREA_CHART_WIDTH;
    const height = this._analyticsConst.AREA_CHART_HEIGHT;
    const spaceBetweenLine = this._analyticsConst.AREA_CHART_SPACE_BTWN_LINE;
    width = this.getModifiedWidth(spaceBetweenLine);

    d3.select(element).selectAll("svg").remove();
    let svg = d3.select(element)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    let xDomain: string[] = this.data.map(d => this.type === this._analyticsConst.FULL_WIDTH ? d.displayDate : d.date);
    let x = d3.scaleBand().padding(0.1).domain(xDomain).rangeRound([0, width]);

    if (this.label === this._analyticsConst.SESSION_COUNT_PER_DAY) {
      svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x).tickSizeInner(0))
        .selectAll(".tick")
        .attr("transform", (d: string) => {
          const pos: number = x(d);
          return `translate(${pos},0)`;
        })
        .attr("class", "area")
        .selectAll("text")
        .attr("transform", "translate(10,60)rotate(+90)")
        .style("text-anchor", "end");
    } else {
      svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x).tickSizeInner(0).tickFormat(
          (d, i) => this.type === this._analyticsConst.FULL_WIDTH
            && this.data.length > 15 && i % 2 !== 0
            ? "" : d)
        )
        .selectAll(".tick")
        .attr("transform", (d: string) => {
          const pos: number = x(d);
          return `translate(${pos},0)`;
        })
        .attr("class", "area")
        .selectAll("text")
        .style("text-anchor", "middle")
        .style("font-size", "11px")
        .style("font-family", "Roboto, 'Helvetica Neue'")
        .style("margin-top", "10px'");
    }

    let y = d3.scaleLinear()
      .domain([0, this.type === this._analyticsConst.FULL_WIDTH
        ? this.maxValue
        : d3.max(this.data, d => { return +d.value; })])
      .range([height, 0])
      .nice();

    svg.append("g")
      .call(d3.axisLeft(y).tickSizeInner(0)
        .tickFormat((d: number) => {
          return parseInt(d.toString()) === d ? d.toString() : "";
        }))
      .selectAll("text")
      .style("font-size", "11px")
      .style("font-family", "Roboto, 'Helvetica Neue'");

    svg.append("path")
      .datum(this.data)
      .attr("fill", "#fafafa")
      .attr("stroke", "#e99696")
      .attr("stroke-width", 2.5)
      .attr("d", (d3 as any).area()
        .x(d => { return x(this.type === this._analyticsConst.FULL_WIDTH ? d.displayDate : d.date) })
        .y0(y(0))
        .y1(d => { return y(d.value) }));

    svg
      .selectAll("area")
      .data(this.data)
      .enter()
      .append("text")
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .attr("x", d => x(this.type === this._analyticsConst.FULL_WIDTH ? d.displayDate : d.date) + 1)
      .attr("y", d => y(d.value) - 5)
      .text((d) => {
        return d.value;
      });

    let tooltip = d3.select(element)
      .append("div")
      .classed("chart-tooltip", true)
      .style("display", "none")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "5px")
      .style("position", "absolute");

    svg
      .on("mouseover", () => {
        d3.select(".chart-tooltip").style("display", null);
      })
      .on("mouseout", () => {
        d3.select(".chart-tooltip").style("display", "none");
      })
      .on("mousemove", () => {
        if (d3 && d3.event && d3.event.srcElement
          && d3.event.srcElement.__data__
          && d3.event.srcElement.__data__.date
          && (d3.event.srcElement.__data__.value
            || d3.event.srcElement.__data__.value === 0)) {
          const showText: string = this.type === this._analyticsConst.FULL_WIDTH ? this._analyticsConst.PAGE_VIEWS : this._analyticsConst.SESSIONS;
          const tooltipText: string = `${d3.event.srcElement.__data__.date} : ${d3.event.srcElement.__data__.value} ${showText}`;
          const topPx: number = this.type === this._analyticsConst.FULL_WIDTH ? 230 : 200;
          const leftPx: number = this.type === this._analyticsConst.FULL_WIDTH ? 110 : 150;
          d3.select(".chart-tooltip")
            .style("left", d3.event.pageX - leftPx + "px")
            .style("top", d3.event.pageY - topPx + "px")
            .html(tooltipText.trim());
        } else {
          d3.select(".chart-tooltip").style("display", "none");
        }
      });
  }

  private getModifiedWidth = (spaceBetweenLine: number) => {
    let width: number = this.data.length * spaceBetweenLine > this._analyticsConst.AREA_CHART_WIDTH
      ? this.data.length * spaceBetweenLine
      : this._analyticsConst.AREA_CHART_WIDTH;
    return width;
  }
}