import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { AuthConstants } from "@app/core/constant/auth-constants";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { StorageService } from "@app/core/services/storage/storage.service";
import { LoginRespInfo } from "@app-auth/pages/login/login-resp-info";
import { responseCode } from "@app/core/services/endpoint-list/endpoint-resp-code";
import { responseMessages } from "@app/core/services/endpoint-list/endpoint-resp-messages";
import { CoreUtils } from "@app/core/utils/core-utils";
import { AuthToken } from "@app/core/models/auth-token";

@Component({
  selector: "app-mfa",
  templateUrl: "./mfa-sentinel.component.html",
  styleUrls: ["./mfa-sentinel.component.scss"],
})
export class MFAComponent implements OnInit {
  public loading = false;
  public submitted = false;
  public mfaCodeForm: FormGroup;
  public _username = new FormControl("", [Validators.required, Validators.email]);
  public _code = new FormControl("", [Validators.required]);
  public error = "";
  private accessToken: string;
  private sessionId: string;
  private mfaType: number;
  private _const: AuthConstants;
  private _util: CoreUtils;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private authSvc: AuthenticationService,
    private _storage: StorageService
  ) {
    // handles the response from SSO
    this._const = new AuthConstants();
    this._util = new CoreUtils(authSvc);
    this.mfaCodeForm = this.fb.group({
      code: this._code,
      username: this._username
    }, {});
  }

  ngOnInit(): void {
    if (this.route && this.route.snapshot && this.route.snapshot.queryParams
      && this.route.snapshot.queryParams.accessToken) {
      this.accessToken = this.route.snapshot.queryParams.accessToken;
      this.mfaType = this.route.snapshot.queryParams.mfaType;
      this.sessionId = this.route.snapshot.queryParams.sessionId;
      if (this.route.snapshot.queryParams.username) {
        this._username.setValue(this.route.snapshot.queryParams.username);
      }
    }
  }

  public onOtpChange = (otp: string) => {
    if (otp.length === 6) {
      this._code.setValue(otp);
    } else {
      this._code.setValue("");
    }
  }

  public confirmCode = async () => {
    if (this.mfaCodeForm.invalid) {
      return;
    }
    this.submitted = true;
    this.loading = true;

    if (this.mfaCodeForm.valid) {
      try {
        const username: string = this.mfaCodeForm.get(this._const.USER_NAME).value;
        const code: string = this.mfaCodeForm.get(this._const.CODE).value;

        const verifyMFACodePromise: Promise<LoginRespInfo> = new Promise<LoginRespInfo>((resolve) => {
          this.authSvc.verifyMFACode(username, this.sessionId, code, this.accessToken, this.mfaType)
            .subscribe((response) => {
              if (response.status === 200) {
                let lri: LoginRespInfo;
                if ([
                  responseCode.sentinel.VERIFY_SMS_MFA_CODE_FAILURE_CODE,
                  responseCode.sentinel.VERIFY_SMS_MFA_CODE_SESSION_EXPIRED_CODE,
                  responseCode.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE
                ].some(c => c === response.body.exeCode)) {
                  lri = new LoginRespInfo(response.body.exeCode, false);
                } else {
                  if (responseCode.sentinel.VERIFY_SMS_MFA_CODE_SUCCESS_CODE === response.body.exeCode) {
                    lri = new LoginRespInfo(response.body.exeCode, true);
                  }
                }

                if (responseCode.sentinel.VERIFY_SMS_MFA_CODE_FAILURE_CODE === response.body.exeCode) {
                  this.error = responseMessages.sentinel.SMS_MFA_CODE_VERIFICATION_FAILURE;
                  resolve(lri);
                } else if (responseCode.sentinel.VERIFY_SMS_MFA_CODE_SESSION_EXPIRED_CODE === response.body.exeCode) {
                  this.error = responseMessages.sentinel.SMS_MFA_CODE_SESSION_EXPIRED_FAILURE;
                  resolve(lri);
                } else if (responseCode.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE === response.body.exeCode) {
                  this.error = responseMessages.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE;
                  resolve(lri);
                } else {
                  const token: AuthToken = new AuthToken(response.body.jwtAccessToken, response.body.jwtIdToken);
                  this._storage.storeJWTTokens(JSON.stringify(token));
                  resolve(lri);
                }
              }
            }, (error) => {
              console.log(error);
              resolve(new LoginRespInfo(responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE, false));
            });
        });

        const mfaOk: LoginRespInfo = await verifyMFACodePromise;
        if (mfaOk && mfaOk.healthy()) {
          if (mfaOk.isMfaOk()) {
            // Attempting to load the login page
            await this._util.sleep(2000);
            this.goHome();
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  public goToLogin = () => {
    this.router.navigate([`${this._const.KEY_LOGIN}`], { queryParams: { accessToken: this.accessToken } });
  }

  public goHome = () => {
    this.router.navigate([`${this._const.KEY_SLASH}`], {});
  }

  public getEmailErrorMessage = (): string => {
    let errorMsg: string;
    if (this._username.hasError(this._const.REQUIRED)) {
      errorMsg = this._const.EMAIL_MANDATORY_MSG;
    } else {
      errorMsg = this._username.hasError(this._const.EMAIL) ? this._const.INVALID_EMAIL_ERROR_MSG : "";
    }
    return errorMsg;
  }

  public getCodeErrorMessage = (): string => {
    let errorMsg: string;
    if (this._code.hasError(this._const.REQUIRED)) {
      errorMsg = this._const.CODE_MANDATORY_MSG;
    } else {
      errorMsg = this._code.hasError(this._const.CODE) ? this._const.INVALID_CODE_ERROR_MSG : "";
    }
    return errorMsg;
  }

  public resendOTP = async () => {
    try {
      const username: string = this.mfaCodeForm.get(this._const.USER_NAME).value;
      const password = this.getLoginPassword();
      const anonToken = this.route.snapshot.queryParams.accessToken;

      const resendOTPPromise: Promise<LoginRespInfo> = new Promise<LoginRespInfo>((resolve) => {
        this.authSvc
          .resendOTPSentinel(username, password, anonToken)
          .subscribe((response) => {
            if (response.status === 200) {
              let lri: LoginRespInfo;
              if ([
                responseCode.sentinel.RESEND_OTP_FAILURE_CODE
              ].some(c => c === response.body.exeCode)) {
                lri = new LoginRespInfo(response.body.exeCode, false);
              } else {
                lri = new LoginRespInfo(response.body.exeCode, true);
              }

              if (responseCode.sentinel.RESEND_OTP_FAILURE_CODE === response.body.exeCode) {
                this.error = responseMessages.sentinel.RESEND_OTP_FAILURE;
                resolve(lri);
              } else {
                const token: AuthToken = new AuthToken(response.body.jwtAccessToken, response.body.jwtIdToken);
                this._storage.storeJWTTokens(JSON.stringify(token));
                lri.registerSessions([response.body.challengeToken]);
                resolve(lri);
              }
            } else {
              this.error = responseMessages.cms.RESEND_OTP_FAILURE;
              resolve(new LoginRespInfo(responseCode.cms.LOGIN_GENERIC_FAILURE_CODE, false));
            }
          }, (error) => {
            console.log(error);
            this.error = responseMessages.cms.RESEND_OTP_FAILURE;
            resolve(new LoginRespInfo(responseCode.cms.LOGIN_GENERIC_FAILURE_CODE, false));
          });
      });

      const resendOTPOk: LoginRespInfo = await resendOTPPromise;
      if (resendOTPOk && resendOTPOk.healthy() && resendOTPOk.isResendOTPOk()) {
        await this._util.sleep(2000);
        await this.router.navigate([`${this._const.KEY_MFA}`], {
          queryParams: {
            mfaType: `${0x02}`,
            sessionId: `${resendOTPOk.withChallengeToken()}`,
            accessToken: `${anonToken}`,
            username: username
          }
        });
        this.sessionId = this.route.snapshot.queryParams.sessionId;
      }
    } catch (err) {
      console.log(err);
    }
  }

  private getLoginPassword = (): string => {
    let password = "";
    this.authSvc.userLoginPassword.subscribe((pwd: string) => {
      password = pwd ? pwd : "";
    });
    return password;
  }
}
