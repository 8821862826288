<mat-form-field appearance="legacy" [ngClass]="(displayValue === 'countryCode' || displayValue === 'modifiedTimeZoneValue' || displayValue === 'currencyAbbr'
  || displayValue === 'triggerId' || displayValue === 'isdCode' || displayValue === 'jnyId' || displayValue === 'timezoneValue' || displayValue === 'value'
  || displayValue === 'id')
  ? 'multi-select' : 'multi-select mat-select-search mat-select-align'">
  <mat-label>{{label}} <span *ngIf="isRequired" class="required">*</span></mat-label>
  <mat-select [formControl]="singleSelectCtrl" (selectionChange)="selectedValueChanged($event)">

    <mat-select-trigger *ngIf="isSelectTriggerRequired">
      {{ selectTriggerValue }}
    </mat-select-trigger>

    <mat-option>
      <ngx-mat-select-search [formControl]="searchCtrl" placeholderLabel="Search..."
        noEntriesFoundLabel="'No match found'"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="isNoneRequired" value="">None</mat-option>
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option[displayValue]">
      {{option[displayName]}}
      <span *ngIf="isAddChameleonLogo(option)">
        <span *ngIf="isAddDivider(option)" class="show-divider"></span>
        <img src="./assets/img/DC-Logo-Black-Version.png" class="option-chameleon-logo" alt="DC">
      </span>
    </mat-option>
  </mat-select>
</mat-form-field>