import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { WebSocketSubject, webSocket } from "rxjs/webSocket";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { environment } from "@environments/environment";
import { Picked } from "@app/core/models/picked.model";
import { GuestCursor } from "@app/core/models/guest-cursor.model";
import { MessageContent } from "@app/core/msg/message-content";
import { MsgType } from "@app/core/msg/msg-type";
import { ChannelMessage } from "@app/core/msg/channel-message";
import { CoreUtils } from "@app/core/utils/core-utils";
import { Constants } from "@app/core/utils/const";
import { SRType } from "@app/core/msg/sr-type";
import { User } from "@app/core/misctypes/user";
import { StorageService } from "@app/core/services/storage/storage.service";
import { AuthToken } from "@app/core/models/auth-token";
import { LcConstants } from "@app/live-chat/constants/lc-constants";

export const JOIN = 1;
export const CONNECT = 2;
export const CHAT = 3;
export const JOIN_NEW = 4;
export const HEARTBEAT = 6;
export const TYPING = 9;
export const SENDEROLE = "GUEST";
export const AGENTSENDEROLE = "ROLE_CC";
export const GUEST_AND_AGENT_CONNECTED = 27;

@Injectable({
  providedIn: "root",
})
export class CustomerListService {

  public guests = [];
  public interactedGuests: any[] = [];
  // identity of the active tab in customer list
  public activeIds: string[] = [];
  public timeoutGuests: Picked[] = [];

  public mySubject = new Subject();
  public headerData = new Subject();
  public unreadGuests = new Subject();
  public activePanelSubject: Subject<any> = new Subject<any>();
  public wsForCustomerList: WebSocketSubject<any>;
  public wsForPickCustomer: WebSocketSubject<any>;

  public clientId;
  public cluster;
  public currentUserData: ChannelMessage;
  // this point to the currently active guest in the guest list
  public guestCursor: GuestCursor;
  public agentLoggedIn: User;
  public activeChannelId: string;
  private _msgTypes: MsgType;
  private readonly _coreUtils: CoreUtils;
  private readonly _const: Constants;
  private readonly _lcConst: LcConstants;
  private readonly _srType: SRType;
  private readonly _storage: StorageService;

  constructor(private readonly authenticationService: AuthenticationService) {
    this.clientId = this.authenticationService.client.id;
    this.agentLoggedIn = this.authenticationService.currentUser;
    this.cluster = this.authenticationService.client.cluster;
    this._msgTypes = new MsgType();
    this._coreUtils = new CoreUtils();
    this._const = new Constants();
    this._lcConst = new LcConstants();
    this._srType = new SRType();
    this._storage = new StorageService();
  }

  connect = () => {
    // Limitation in cognito pools when connected with SSO in Azure, user-names are padded with #
    // Those are not allowed to be used in socket-urls
    const normalizedUserName: string = this.agentLoggedIn.userName
      .replace("#", "~")
      .replace("#", "~");

    const fp: string = !!this._storage.storedFp() ? this._storage.storedFp() : "";
    const adi: string = !!this._storage.storedAdi() ? this._storage.storedAdi() : "";

    const jwtToken: AuthToken = this.authenticationService.getStoredAuthToken();
    const idToken: string = !!jwtToken ? jwtToken.idToken : "";
    const accessToken: string = !!jwtToken ? jwtToken.accessToken : "";

    const socketUrlTokens: string [] = [
      // replace # with ^ due to limitation in web-socket creation
      `${environment.webSocketBaseUrl}/inqueue?cluster=${this.cluster}`,
      `fp=${fp}`, `adi=${adi}`, `id=${idToken}`, `acc=${accessToken}`
    ];
    const webSocketUrlForList = `${socketUrlTokens.join("&")}`;
    if (this.wsForCustomerList === undefined || this.wsForCustomerList.closed) {
      this.wsForCustomerList = webSocket({url: webSocketUrlForList});
      setInterval(() => this.wsForCustomerList.next(this._coreUtils.generateHeartbeatMsg("")), 30000);
    }
    return this.wsForCustomerList.asObservable();
  }

  sendHeaderData = (data: any) => {
    this.headerData.next(data);
  }

  guestsWithUnreadCount = (): Observable<any> => {
    return this.unreadGuests.asObservable();
  }

  getHeaderData = (): Observable<any> => {
    return this.headerData.asObservable();
  }

  getActivePanels = (): Observable<any> => {
    return this.activePanelSubject.asObservable();
  }

  addActivePanel = (panels: string[]) => {
    this.activePanelSubject.next((panels) ? panels : []);
  }

  disconnectAgent = (stateCode: number) => {
    if (this.wsForCustomerList && !this.wsForCustomerList.isStopped) {
      console.log(`Disconnecting all guest-ws with complete due to agent being offline`);
      const effCode: number = (stateCode === 1)
        ? this._lcConst.REASON_CODE_AGENT_OFFLINE_THIS_DEVICE : this._lcConst.REASON_CODE_AGENT_OFFLINE_ALL_DEVICES;
      const effReason: string = (stateCode === 1)
        ? this._lcConst.REASON_DESC_AGENT_OFFLINE_THIS_DEVICE : this._lcConst.REASON_DESC_AGENT_OFFLINE_ALL_DEVICES;
      this.wsForCustomerList.error({ code: effCode, reason: effReason });
      this.wsForCustomerList.complete();
      this.wsForCustomerList.unsubscribe();
    }
    this.guests = [];
    this.unreadGuests.next(this.guests);
    this.headerData.next([]);
    this.activeIds = [];
    this.interactedGuests = [];
    this.activePanelSubject.next(this.activeIds);
    this.guestCursor = undefined;
  }

  onWsMessage = (message) => {
    switch (message.messageType) {
      case JOIN:
      case JOIN_NEW:
        this.processJoin(message);
        break;
      case CONNECT:
        this.processConnect(message);
        break;
      case this._msgTypes.LEAVE:
        this.processLeave(message);
        break;
      case this._msgTypes.CONNECTION_TIMED_OUT:
        this.onConnectionTimeout(message);
        break;
    }
  }

  private processJoin = (message: any) => {
    console.log(`Join and join-new: ${JSON.stringify(message)}`);
    message = {
      ...message,
      ...{
        isConnected: false,
        isRefresh: false,
        isOffline: (message.isOffline ? message.isOffline : false),
        unreadMsgCount: 0
      }
    };

    const guest = message;
    const knownBefore = this.guests.filter((e) => e.channelId === guest.channelId);
    if (knownBefore && knownBefore.length > 0) {
      knownBefore[0].isOffline = false;
      knownBefore[0].isRefresh = guest.isRefresh;
      if (knownBefore[0].agentName === "") {
        knownBefore[0].agentName = guest.agentName
      }
    } else {
      this.guests.unshift(guest);
    }
    this.unreadGuests.next(this.guests);
  }

  private processConnect = (message: any) => {
    console.log(`connect ${JSON.stringify(message)}`);
    if (message.newConn !== undefined && message.newConn === true) {
      this.guestCursor = new GuestCursor(message.channelId, message.receiverName);
      this.wsForPickCustomer.next(message);
      this.guests.forEach((element) => {
        if (element.channelId === message.channelId) {
          element.isConnected = true;
        }
      });
    }
  }

  private processLeave = (message: ChannelMessage) => {
    if (message && message.channelId) {
      this.showOffline(message.channelId);
      this.sendHeaderData({
        message: [new MessageContent(this._msgTypes.LEAVE.toString(), this._msgTypes.LEAVE.toString())],
        channelMsg: message
      });
    }
    this.unreadGuests.next(this.guests);
  }

  private onConnectionTimeout = (message: ChannelMessage) => {
    if (message && message.channelId) {
      const pCfg: object = {
        _chn: message.channelId,
        _clt: this.clientId,
        _us: message.userSessionUuid,
        _erf: message.episodeReferenceId,
        _modes: message.modeContext,
        _wfNodeId: message.workflowNodeId,
        _wId: message.currentWorkflowId,
        _accessToken: message.accessToken
      };
      this.timeoutGuests.push(new Picked(pCfg));
      this.showOffline(message.channelId);
      this.sendHeaderData({
        message: [new MessageContent(this._msgTypes.CONNECTION_TIMED_OUT.toString(), this._msgTypes.CONNECTION_TIMED_OUT.toString())],
        channelId: message.channelId,
        guestId: message.guestId
      });
    }
    this.unreadGuests.next(this.guests);
  }

  refreshAllGuestUnreadCount = (pickedGuests: Picked[]) => {
    this.guests.forEach((guest) => {
      const matched: Picked[] = pickedGuests.filter(p => p.channel() === guest.channelId);
      if (matched && matched.length > 0) {
        guest.unreadMsgCount = matched[0].unreadCount();
        console.log(`updating unread count for guest ${guest.userSessionUuid}, count ${guest.unreadMsgCount}`);
      }
    });
    this.unreadGuests.next(this.guests);
  }

  onCruiseControl = (channelId: string) => {
    if (channelId) {
      this.guests.forEach(guest => {
        if (guest.channelId === channelId) {
          guest.isCruiseControl = true;
          guest.isInterveneSucc = false;
        }
      });
    }
  }

  public onInterveneSucc = (channelId: string) => {
    if (channelId) {
      this.guests.forEach(guest => {
        if (guest.channelId === channelId) {
          guest.isInterveneSucc = true;
        }
      });
    }
  }

  removeFromList = (channelId: string) => {
    const removeIndex = this.guests
      .map((item) => {
        if (item.isConnected === false) return item.channelId;
      })
      .indexOf(channelId);
    if (removeIndex > -1) {
      this.guests.splice(removeIndex, 1);
    }
  }

  showOffline = (channelId) => {
    if (channelId) {
      this.guests.forEach(guest => {
        if (guest.channelId === channelId) {
          guest.isConnected = false;
          guest.isOffline = true;
          guest.isCruiseControl = false;
          guest.isInterveneSucc = false;
        }
      });
    }
  }

  refreshInteracted = (guest: any) => {
    if (guest) {
      this.interactedGuests = this.interactedGuests.filter(ig => !(ig.channelId === guest.channelId));
      // add in new guy
      this.interactedGuests.push({
        channelId: guest.channelId,
        sessionId: guest.userSessionUuid
      });
    }
  }

  removeInteracted = (chn: string) => {
    if (chn) {
      this.interactedGuests = this.interactedGuests.filter(guest => guest.channelId !== chn);
    }
  }

  hasInteracted = (chn: string) => {
    return (chn) ? this.interactedGuests.filter(guest => guest.channelId === chn) : [];
  }

  removeGuest = (chn: string) => {
    if (chn) {
      this.guests = this.guests.filter(guest => guest.channelId !== chn);
    }
  }
}
