<div mat-dialog-content class="btn-drg">
  <div class="btn-drg-header">
    Button Hierarchy
  </div>
  <ng-template #tmplNode let-node="node" let-pIndex="pIndex" let-cIndex="cIndex">
    <div class="node-item" [attr.data-id]="node.id" [attr.id]="'node-'+node.id">
      <div class="node-title">
        <span [innerHtml]="node.btnTitle ? node.btnTitle : node.displayText"></span>
      </div>

      <button [ngClass]="{ 'parent-delete': cIndex > -1 }" class="delete-hierarchy"
        (click)="deleteButton(pIndex, cIndex)" mat-icon-button aria-label="delete parent button">
        <mat-icon>delete</mat-icon>
      </button>

      <div *ngIf="node.isExpanded || node.buttons.length" class="node-buttons" cdkDropList
        [cdkDropListData]="node.buttons" [id]="node.id" [cdkDropListConnectedTo]="dropTargetIds"
        (cdkDropListDropped)="drop($event)" [cdkDropListSortingDisabled]="true">
        <div *ngFor="let child of node.buttons; let i = index" cdkDrag [cdkDragData]="child.id"
          (cdkDragMoved)="dragMoved($event)">
          <ng-container *ngTemplateOutlet="tmplNode,context:{node:child, pIndex:pIndex, cIndex:i}"></ng-container>
        </div>
      </div>
    </div>
  </ng-template>

  <div cdkDropList [cdkDropListData]="nodes" [id]="'main'" [cdkDropListConnectedTo]="dropTargetIds"
    (cdkDropListDropped)="drop($event)" [cdkDropListSortingDisabled]="true">
    <div *ngFor="let node of nodes; let i = index" cdkDrag [cdkDragData]="node.id" (cdkDragMoved)="dragMoved($event)">
      <ng-container *ngTemplateOutlet="tmplNode,context:{node:node, pIndex:i, cIndex:-1}"></ng-container>
    </div>
  </div>
</div>

<div mat-dialog-actions class="savecncl-btn">
  <button mat-button mat-dialog-close class="savecncl-cncl white-button"
    (click)="saveHierarchyInfo('cancel')">Cancel</button>
  <button mat-button [mat-dialog-close]="dialogData" cdkFocusInitial (click)="saveHierarchyInfo('save')"
    class="savecncl-save teal-button">Submit</button>
</div>