import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { AuthConstants } from "@app/core/constant/auth-constants";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { StorageService } from "@app/core/services/storage/storage.service";
import { LoginRespInfo } from "@app-auth/pages/login/login-resp-info";
import { responseCode } from "@app/core/services/endpoint-list/endpoint-resp-code";
import { responseMessages } from "@app/core/services/endpoint-list/endpoint-resp-messages";
import { CoreUtils } from "@app/core/utils/core-utils";

@Component({
  selector: "app-confirm",
  templateUrl: "./confirm-sentinel.component.html",
  styleUrls: ["./confirm-sentinel.component.scss"],
})
export class ConfirmComponent implements OnInit {
  public loading = false;
  public submitted = false;
  public confirmCodeForm: FormGroup;
  public _username = new FormControl("", [Validators.required, Validators.email]);
  public _code = new FormControl("", [Validators.required]);
  public error = "";
  public success = "";
  private accessToken: string;
  private confirmationType: number;
  private _const: AuthConstants;
  private _util: CoreUtils;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private authSvc: AuthenticationService,
    private _storage: StorageService
  ) {
    // handles the response from SSO
    this._const = new AuthConstants();
    this._util = new CoreUtils(authSvc);
    this.confirmCodeForm = this.fb.group({
      code: this._code,
      username: this._username
    }, {});
  }

  ngOnInit(): void {
    if (this.route && this.route.snapshot && this.route.snapshot.queryParams
      && this.route.snapshot.queryParams.accessToken) {
      this.accessToken = this.route.snapshot.queryParams.accessToken;
      this.confirmationType = this.route.snapshot.queryParams.confirmationType
      if (this.route.snapshot.queryParams.username) {
        this._username.setValue(this.route.snapshot.queryParams.username);
      }
    }
  }

  public confirmCode = async () => {
    this.submitted = true;
    if (this.confirmCodeForm.invalid) {
      return;
    }
    this.loading = true;

    if (this.confirmCodeForm.valid) {
      try {
        const username: string = this.confirmCodeForm.get(this._const.USER_NAME).value;
        const code: string = this.confirmCodeForm.get(this._const.CODE).value;

        const verifyConfirmationCodePromise: Promise<LoginRespInfo> = new Promise<LoginRespInfo>((resolve) => {
          this.authSvc.verifyConfirmationCode(username, code, this.accessToken, this.confirmationType).subscribe((response) => {
            if (response.status === 200) {
              let lri: LoginRespInfo;
              if ([
                responseCode.sentinel.VERIFY_CONFIRMATION_CODE_FAILURE_CODE,
                responseCode.sentinel.LOGIN_ONLY_USERNAME_CONFIRMED_ERROR_CODE,
                responseCode.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE,
                responseCode.sentinel.VERIFY_CONFIRMATION_NEW_CODE_REQUIRED_ERROR_CODE
              ].some(c => c === response.body.exeCode)) {
                lri = new LoginRespInfo(response.body.exeCode, false);
              } else {
                if (responseCode.sentinel.LOGIN_BOTH_USERNAME_PHONE_CONFIRMATION_SUCCESS_CODE === response.body.exeCode
                  || responseCode.sentinel.LOGIN_PWD_RESET_CONFIRMATION_SUCCESS_CODE === response.body.exeCode) {
                  lri = new LoginRespInfo(response.body.exeCode, true);
                }
              }

              if (responseCode.sentinel.VERIFY_CONFIRMATION_CODE_FAILURE_CODE === response.body.exeCode) {
                this.error = responseMessages.sentinel.CONFIRMATION_CODE_VERIFICATION_FAILURE;
                resolve(lri);
              } else if (responseCode.sentinel.LOGIN_ONLY_USERNAME_CONFIRMED_ERROR_CODE === response.body.exeCode) {
                this.error = responseMessages.sentinel.CONFIRMATION_CODE_PARTIAL_VERIFICATION_FAILURE;
                resolve(lri);
              } else if (responseCode.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE === response.body.exeCode) {
                this.error = responseMessages.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE;
                resolve(lri);
              } else if (responseCode.sentinel.VERIFY_CONFIRMATION_NEW_CODE_REQUIRED_ERROR_CODE === response.body.exeCode) {
                this.error = responseMessages.sentinel.NEW_CONFIRMATION_CODE_REQUIRED_FAILURE;
                resolve(lri);
              } else {
                this.error = "";
                this.success = responseMessages.sentinel.CONFIRMATION_CODE_VERIFICATION_SUCCESS
                resolve(lri);
              }
            }
          }, (error) => {
            console.log(error);
            resolve(new LoginRespInfo(responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE, false));
          });
        });

        const confirmationOk: LoginRespInfo = await verifyConfirmationCodePromise;
        if (confirmationOk && confirmationOk.healthy()) {
          if (confirmationOk.isConfirmationOk()) {
            // Attempting to load the login page
            await this._util.sleep(2000);
            this.goToLogin();
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  public goToLogin = () => {
    this.router.navigate([`${this._const.KEY_LOGIN}`], { queryParams: { accessToken: this.accessToken } });
  }

  public getEmailErrorMessage = (): string => {
    let errorMsg: string;
    if (this._username.hasError(this._const.REQUIRED)) {
      errorMsg = this._const.EMAIL_MANDATORY_MSG;
    } else {
      errorMsg = this._username.hasError(this._const.EMAIL) ? this._const.INVALID_EMAIL_ERROR_MSG : "";
    }
    return errorMsg;
  }

  public getCodeErrorMessage = (): string => {
    let errorMsg: string;
    if (this._code.hasError(this._const.REQUIRED)) {
      errorMsg = this._const.CODE_MANDATORY_MSG;
    } else {
      errorMsg = this._code.hasError(this._const.CODE) ? this._const.INVALID_CODE_ERROR_MSG : "";
    }
    return errorMsg;
  }
}
