import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit, OnChanges } from "@angular/core";
import { AnalyticsConstant } from "@app/analytics/constants/analytics.constant";
import * as d3 from "d3";

@Component({
  selector: "app-horizontal-barchart",
  templateUrl: "./horizontal-barchart.component.html",
  styleUrls: ["./horizontal-barchart.component.scss"]
})
export class HorizontalBarchartComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("chart") public chartContainer: ElementRef;
  @Input() public data: (string | number)[][] = [];
  @Input() public label: string;
  public _analyticsConst: AnalyticsConstant;

  constructor() {
    this._analyticsConst = new AnalyticsConstant();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.chartContainer && this.data && this.data.length > 0) {
      this.drawChart();
    }
  }

  ngOnChanges() {
    if (this.chartContainer && this.data && this.data.length > 0) {
      this.drawChart();
    }
  }

  private drawChart = () => {
    const element: HTMLDivElement = this.chartContainer.nativeElement;
    const margin = { top: 17, right: 30, bottom: 30, left: 130 };
    const width = this._analyticsConst.HORI_BAR_CHART_WIDTH;
    let height = this._analyticsConst.HORI_BAR_CHART_HEIGHT;
    const barWidth = this._analyticsConst.HORI_BAR_CHART_BAR_WIDTH;
    const spaceBetweenBar = this._analyticsConst.HORI_BAR_CHART_SPACE_BTWN_BAR;
    height = this.getModifiedHeight(barWidth, spaceBetweenBar);

    d3.select(element).selectAll("svg").remove();
    const svg = d3.select(element)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const yDomain: number[] = [0, d3.max(this.data, d => Number(d[1]))];
    const xAxis: d3.ScaleLinear<number, number> = d3.scaleLinear().domain(yDomain).range([0, width]).nice();

    svg.append("g")
      .attr("transform", "translate(0,-17)rotate(360)")
      .call(d3.axisBottom(xAxis).tickSizeInner(0)
        .tickFormat((d: number) => {
          return parseInt(d.toString()) === d ? d.toString() : "";
        }))
      .selectAll("text")
      .style("text-anchor", "end");

    const yAxis: d3.ScaleBand<string> = d3.scaleBand()
      .range([0, height])
      .domain(this.data.map(d => { return d[0].toString(); }))
      .padding(0.1);

    svg.append("g")
      .call(d3.axisLeft(yAxis).tickSizeInner(0))
      .selectAll(".tick")
      .attr("transform", (d, i) => {
        const pos: number = (i === 0)
          ? spaceBetweenBar + (barWidth / 2)
          : spaceBetweenBar + ((barWidth + spaceBetweenBar) * i) + (barWidth / 2);
        return `translate(0, ${pos})`;
      });

    const chart = svg.selectAll("myRect")
      .data(this.data)
      .enter()
      .append("rect")
      .attr("x", xAxis(0))
      .attr("y", (d, i) => i === 0 ? spaceBetweenBar : spaceBetweenBar + ((barWidth + spaceBetweenBar) * i))
      .attr("width", d => { return xAxis(Number(d[1])); })
      .attr("height", barWidth)
      .attr("fill", "#E6AAAA");

    svg.selectAll("myRect")
      .data(this.data)
      .enter()
      .append("text")
      .attr("class", "yAxis-label")
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .attr("y", (d, i) => i === 0
        ? spaceBetweenBar + (33 / 2)
        : spaceBetweenBar + ((barWidth + spaceBetweenBar) * i) + (33 / 2))
      .attr("x", d => xAxis(Number(d[1])) + 10)
      .text(d => d[1]);

    d3.select(element)
      .append("div")
      .classed("chart-tooltip", true)
      .style("display", "none")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "5px")
      .style("position", "absolute");

    chart
      .on("mouseover", () => {
        d3.select(".chart-tooltip").style("display", "block");
      })
      .on("mouseout", () => {
        d3.select(".chart-tooltip").style("display", "none");
      })
      .on("mousemove", () => {
        if (d3 && d3.event && d3.event.srcElement
          && d3.event.srcElement.__data__
          && d3.event.srcElement.__data__[0]
          && d3.event.srcElement.__data__[1]
          && d3.event.srcElement.__data__[2]) {
          let html = "";
          if (this.label === this._analyticsConst.TOP_AGENT) {
            html = `${d3.event.srcElement.__data__[2]} : ${d3.event.srcElement.__data__[1]} sessions`;
          } else {
            html = `${d3.event.srcElement.__data__[0]} : ${d3.event.srcElement.__data__[1]} sessions`;
          }
          d3.select(".chart-tooltip")
            .style("left", d3.event.pageX - 100 + "px")
            .style("top", d3.event.pageY - 200 + "px")
            .html(html.trim());
        } else {
          d3.select(".chart-tooltip").style("display", "none");
        }
      });
  }

  private getModifiedHeight = (barWidth: number, spaceBetweenBar: number) => {
    const totalSpace = barWidth + spaceBetweenBar;
    let height: number = this.data.length * totalSpace > this._analyticsConst.HORI_BAR_CHART_HEIGHT
      ? this.data.length * totalSpace
      : this._analyticsConst.HORI_BAR_CHART_HEIGHT;
    return height;
  }
}