<div class="sentinel-logo-bg forget-page">
  <video autoplay class="cmsbgvdo">
    <source src="/assets/img/cmslogin.mp4">
  </video>
  <div class="logo-bg">
    <mat-card class="login-card">
      <mat-card-content>
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
          <mat-form-field class="mat-form-field-wrapper" appearance="legacy">
            <mat-label>Email</mat-label>
            <p></p>
            <input matInput type="email" formControlName="username" required autofocus>
            <mat-error>
              {{getEmailErrorMessage()}}
            </mat-error>
          </mat-form-field>
          <mat-card-actions>
            <button mat-raised-button color="primary" type="submit" class="sentinel-btn">Submit</button>
          </mat-card-actions>
        </form>
      </mat-card-content>
      <mat-card-footer>
        <div *ngIf="error" class="mat-error m-t-40 text-center verify-msg">
          <mat-icon>check_circle_outline</mat-icon>{{error}}
        </div>
      </mat-card-footer>
    </mat-card>
    <div class="camie-heading">
      <div class="camie-text">
        <h1>DigitalChameleon</h1>
        <h4>The No-code Launchpad for Your Ideas</h4>
      </div>
    </div>
    <p class="copyright-sentinel">DigitalChameleon.ai</p>
  </div>
</div>