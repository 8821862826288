<div class="page-topbar sidebar_shift no-print">
  <div class="quick-area">
    <div class="float-left">
      <ul class="info-menu list-unstyled">
        <li class="topbar-toggle-wrap list-inline-item">
          <button class="btn m-l-10" *ngIf="showBackNavigation" (click)="goBack()">
            <mat-icon class="icon">home</mat-icon>
          </button>

          <h1 class="title heading-text sidebar_shift" [ngClass]="{ sidebar_shift: toggle, chatshift: !toggle}">
            {{ tabName }}</h1>
        </li>
      </ul>
    </div>
    <div class="float-left">
      <div *ngIf="tabName === 'Live Chats'" class="btn-group top-btn">
        <div ngbDropdown class="drop-menu profile-status">
          <div
            [ngClass]="displayedAgentStatus === 'online' ? 'primary-bg' : displayedAgentStatus === 'offline' ? 'offline-bg' : 'away-bg'"
            class="user-status" aria-hidden="true" ngbDropdownToggle id="dropdownBasic1" (mouseover)="mouseEnter()"
            (mouseleave)="mouseLeave()">
            <mat-icon [hidden]="!iconVisible">keyboard_arrow_down</mat-icon>
          </div>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="width: auto;">
            <a (click)="changeAgentStatus(2)" class="dropdown-item" ngbDropdownItem>
              <mat-icon class="f-15 status-circle text-green">fiber_manual_record</mat-icon>
              Online
            </a>
            <a (click)="changeAgentStatus(3)" class="dropdown-item" ngbDropdownItem>
              <mat-icon class="f-15 status-circle text-orange">fiber_manual_record</mat-icon>
              Away
            </a>
            <a (click)="changeAgentStatus(1)" class="dropdown-item" ngbDropdownItem>
              <mat-icon class="f-15 status-circle text-offline">fiber_manual_record</mat-icon>
              Offline (This Device)
            </a>
            <a (click)="changeAgentStatus(4)" class="dropdown-item" ngbDropdownItem>
              <mat-icon class="f-15 status-circle text-offline">fiber_manual_record</mat-icon>
              Offline (All Devices)
            </a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <canvas #fingerprint id="lc-agent-fp" hidden="true" width="120" height="30"></canvas>
    </div>
