<div class="card mt-4">
  <h4 class="card-header">Admin</h4>
  <div class="card-body">
    <p>This page can be accessed <u>only by administrators</u>.</p>
    <p class="mb-1">All users from secure (admin only) api end point:</p>
    <div *ngIf="loading" class="spinner-border spinner-border-sm"></div>
    <ul *ngIf="users">
      <li *ngFor="let user of users">{{user.firstName}} {{user.lastName}}</li>
    </ul>
  </div>
</div>
