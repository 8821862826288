import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
// @ts-ignore
import { LoginComponent } from "@app-auth/pages/login/login.component";
import { ResetPasswordComponent } from "@app-auth/pages/reset-password/reset-password.component";
import { LogoutComponent } from "./authentication/pages/logout/logout.component";
import { ForgotPasswordComponent } from "@app-auth/pages/forgot-password/forgot-password.component";
import { CallbackComponent } from "@app-auth/pages/callback/callback.component";
import { ConfirmComponent } from "@app-auth/pages/confirm/confirm.component";
import { ForgotpassCompleteComponent } from "@app-auth/pages/forgotpass-complete/forgotpass-complete.component";
import { ForcedResetCompleteComponent } from "@app-auth/pages/forced-reset-complete/forced-reset-complete.component";
import { MFAComponent } from "@app-auth/pages/mfa/mfa.component";
import { SsoHelpComponent } from "@app-auth/pages/sso-help/sso-help.component";
import { CallbackOAuthComponent } from "./authentication/pages/callback-oauth/callback-oauth.component";

const routes: Routes = [
  { path: "", loadChildren: () => import("./layout/layout.module").then(m => m.LayoutModule), },
  { path: "login", component: LoginComponent },
  { path: "callback", component: CallbackComponent },
  { path: "oAuthCallback", component: CallbackOAuthComponent },
  { path: "sso-help", component: SsoHelpComponent },
  { path: "mfa", component: MFAComponent },
  { path: "confirm", component: ConfirmComponent },
  { path: "forced-reset-complete", component: ForcedResetCompleteComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "forgotpass-complete", component: ForgotpassCompleteComponent },
  { path: "logout", component: LogoutComponent },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
