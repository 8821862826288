import { Component, OnInit, ViewChild } from "@angular/core";
import { NavigationComponent } from "@app-shared/components/navigation/navigation.component";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { ConnectCallComponent } from "@app-shared/components/connect-call/connect-call.component";
import { ToggleService } from "@app/shared/services/toggle.service";
import { AuthenticationService } from "@app/core/services/authentication/authentication.service";
import { Title } from "@angular/platform-browser";
import { Constants } from "@app/core/utils/const";

@Component({
  selector: "app-layout",
  templateUrl: "./layout-sentinel.component.html",
  styleUrls: ["./layout-sentinel.component.scss"],
})
export class LayoutComponent implements OnInit {
  @ViewChild(NavigationComponent) child: NavigationComponent;
  @ViewChild(ConnectCallComponent) connectChild: ConnectCallComponent;

  public toggle = true;
  public tabName: string;
  public showNavAndHead = true;
  public applyStyle = true;
  public loading = true;

  private _const: Constants;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly toggleService: ToggleService,
    private readonly router: Router,
    private readonly authSvc: AuthenticationService,
    private titleService: Title
  ) {

    console.log(`Layout sentinel component ...`);
    this._const = new Constants();
    this.authSvc.timeoutObservable.subscribe(
      (res: boolean) => {
        this.loading = res;
        if (this.router.url === "/" && this.authSvc.currentUser.clientSubmoduleDtos) {
          const privilege = this.authSvc.currentUser.clientSubmoduleDtos.filter(
            (item) => {
              return item.submodulesDto.id === 10;
            }
          );
          if (privilege) {
            this.router.navigate(["/rtmc"]);
          }
        }
      }
    );

    this.tabName = this.route.snapshot.firstChild.data.title;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(
          this.router.routerState,
          this.router.routerState.root
        );
        this.applyStyle = false;
        this.showNavAndHead = true;
        this.addRemoveClass(true);

        if (title[title.length - 1] !== null || title[title.length - 1] !== "") {
          switch (title[title.length - 1]) {
            case "Update Journey":
              const journeyName = this.router.routerState.root.snapshot.queryParams.journeyName;
              this.changeTabName((journeyName && journeyName !== "") ? journeyName : title[title.length - 1]);
              break;
            case "Response Management":
              const behaName = this.router.routerState.root.snapshot.queryParams.name;
              this.changeTabName((behaName && behaName !== "") ? behaName : title[title.length - 1]);
              break;
            case "B&B Builder":
              const behavName = this.router.routerState.root.snapshot.queryParams.behaviourName;
              this.changeTabName((behavName && behavName !== "") ? behavName : title[title.length - 1]);
              break;
            case "Create Trigger":
              const trigger = this.router.routerState.root.snapshot.queryParams.triggerName;
              this.changeTabName((trigger && trigger !== "") ? trigger : title[title.length - 1]);
              break;
            case "Create API":
              const apiName = this.router.routerState.root.snapshot.queryParams.name;
              this.changeTabName((apiName && apiName !== "") ? apiName : title[title.length - 1]);
              break;
            case "Chatbots":
              const nlpName: string = this.router.routerState.root.snapshot.queryParams.nlpName;
              this.changeTabName((nlpName && nlpName !== "") ? `${title[title.length - 1]} - ${nlpName}` : title[title.length - 1]);
              break;
            case "Responses":
              const respName: string = this.router.routerState.root.snapshot.queryParams.name;
              this.changeTabName((respName && respName !== "") ? `${title[title.length - 1]} - ${respName}` : title[title.length - 1]);
              break;
            case "Brands & Behaviours":
              const bBName: string = this.router.routerState.root.snapshot.queryParams.behaviourName;
              this.changeTabName((bBName && bBName !== "") ? `${title[title.length - 1]} - ${bBName}` : title[title.length - 1]);
              break;
            default:
              this.changeTabName(title[title.length - 1]);
          }
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    window.addEventListener("message", this.handleOrientation.bind(this));
    this.titleService.setTitle("DigitalChameleon Sentinel");
    this.toggleService.getToggle().subscribe((response) => {
      this.addRemoveClass(response);
    });
    await this.authSvc.loadInitialSentinelLoginInfo({});
  }

  ngAfterViewInit() {
    if (this.child !== undefined) {
      this.child.sidebarCollapse(this.toggle);
    }
  }

  private handleOrientation = (msg: MessageEvent) => {
    const iframe = document.getElementById(this._const.CHATBOT) as HTMLIFrameElement
    if (msg.origin === this._const.HELP_SENTINEL_URL) {
      const postableEvt = msg && msg.data && msg.data.evtName ? msg.data : null;
      if (postableEvt && iframe) {
        if (postableEvt.evtName === this._const.LOADED) {
          iframe?.contentWindow?.postMessage({
            evtName: this._const.IFRAME,
            autoLaunch: false,
          }, "*");
        } else if (postableEvt.evtName === this._const.CHAT_WINDOW) {
          iframe.classList.add(this._const.CLS_CHAT_WINDOW);
          iframe.classList.remove(this._const.CLS_CHAT_BTN);
          iframe.classList.remove(this._const.CLS_CHAT_EXPAND);

        } else if (postableEvt.evtName === this._const.EXPAND) {
          iframe.classList.add(this._const.CLS_CHAT_EXPAND);
          iframe.classList.remove(this._const.CLS_CHAT_WINDOW);
          iframe.classList.remove(this._const.CLS_CHAT_BTN);
        } else {
          iframe.classList.add(this._const.CLS_CHAT_BTN);
          iframe.classList.remove(this._const.CLS_CHAT_WINDOW);
          iframe.classList.remove(this._const.CLS_CHAT_EXPAND);
        }
      }
      iframe?.contentWindow?.postMessage({
        call: this._const.PAGE_URL,
        value: document.location.href
      }, "*");
    }
  }

  public getTitle = (state, parent) => {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  sidebarCollapse = (event) => {
    this.toggle = event;
    this.child.sidebarCollapse(this.toggle);
    if (this.connectChild) {
      this.connectChild.sidebarCollapse(this.toggle);
    }
  }

  changeTabName = (data) => {
    this.tabName = data;
  }

  public addRemoveClass = (bool) => {
    this.showNavAndHead = bool;
    const mainElement = document.getElementById("main-content");
    if (mainElement) {
      mainElement.classList.remove("sidebar_shift");
      mainElement.classList.remove("chat_shift");
      if (bool) {
        this.toggle = true;
        if (this.child !== undefined) {
          this.child.sidebarCollapse(this.toggle);
        }
        mainElement.classList.add("section-shift");
      } else {
        mainElement.classList.remove("section-shift");
      }
    }
  }
}
