import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../material.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { HttpClient } from "@angular/common/http";
import { EditorComponent } from "@app-shared/components/editor/editor.component";
import { HeaderComponent } from "@app-shared/components/header/header.component";
import { NavigationComponent } from "@app-shared/components/navigation/navigation.component";
import { AdminComponent } from "./components/admin/admin.component";
import { AlertComponent } from "./components/alert/alert.component";
import { ConnectCallComponent } from "./components/connect-call/connect-call.component";
import { HideIfUnauthorisedDirective } from "./directives/hide-if-unauthorised.directive";
import { TimerComponent } from "./components/timer/timer.component";
import { VideoCallComponent } from "@app-shared/components/video-call/video-call.component";
import { SingleSelectSearchComponent } from "@app-shared/components/single-select-search/single-select-search.component";
import { MultiSelectSearchComponent } from "./components/multi-select-search/multi-select-search.component";
import { ColorPickerComponent } from "./components/color-picker/color-picker.component";
import { BarchartComponent } from "./components/charts/barchart/barchart.component";
import { DonutchartComponent } from "./components/charts/donut chart/donutchart.component";
import { AreachartComponent } from "./components/charts/area chart/areachart.component";
import { LollipopchartComponent } from "./components/charts/lollipop chart/lollipopchart.component";
import { ColorPickerModule } from "ngx-color-picker";
import { ButtonDragDropTreeComponent } from "./components/button-drag-drop-tree/button-drag-drop-tree.component";
import { PreviewDialogComponent } from "./components/preview-dialog/preview-dialog.component";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { UploadComponent } from "./components/upload/upload.component";
import { HorizontalBarchartComponent } from "@app-shared/components/charts/horizontal barchart/horizontal-barchart.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { LinechartComponent } from "@app-shared/components/charts/line chart/linechart.component";
import { CommonHelpDocumentComponent } from "./components/common-help-document/common-help-document.component";
import { CKEditorModule } from "ckeditor4-angular";
import { TextContentComponent } from './components/text-content/text-content.component';
import { AceEditorModule } from "@postfinance/ngx-ace-editor-wrapper";
import { WebPreviewComponent } from './components/web-preview/web-preview.component';
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SwiperModule } from "swiper/angular";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { UnsplashImagesDialogComponent } from "@app-shared/components/unsplash-images-dialog/unsplash-images-dialog.component";
import { ChipListReorderComponent } from "./components/chip-list-reorder/chip-list-reorder.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    EditorComponent,
    HeaderComponent,
    NavigationComponent,
    AdminComponent,
    AlertComponent,
    ConnectCallComponent,
    HideIfUnauthorisedDirective,
    TimerComponent,
    VideoCallComponent,
    SingleSelectSearchComponent,
    MultiSelectSearchComponent,
    ColorPickerComponent,
    BarchartComponent,
    DonutchartComponent,
    AreachartComponent,
    LollipopchartComponent,
    ButtonDragDropTreeComponent,
    PreviewDialogComponent,
    UploadComponent,
    HorizontalBarchartComponent,
    LinechartComponent,
    CommonHelpDocumentComponent,
    TextContentComponent,
    WebPreviewComponent,
    SafeHtmlPipe,
    UnsplashImagesDialogComponent,
    ChipListReorderComponent
  ],
  imports: [
    ColorPickerModule,
    CommonModule,
    RouterModule,
    AngularEditorModule,
    CKEditorModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    NgxDocViewerModule,
    AceEditorModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    SlickCarouselModule,
    SwiperModule
  ],
  exports: [
    // modules
    CommonModule,
    AngularEditorModule,
    CKEditorModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    TranslateModule,
    // components
    EditorComponent,
    HeaderComponent,
    NavigationComponent,
    AlertComponent,
    ConnectCallComponent,
    TimerComponent,
    VideoCallComponent,
    SingleSelectSearchComponent,
    MultiSelectSearchComponent,
    ColorPickerComponent,
    BarchartComponent,
    DonutchartComponent,
    AreachartComponent,
    LollipopchartComponent,
    ColorPickerModule,
    UploadComponent,
    NgxDocViewerModule,
    HorizontalBarchartComponent,
    DragDropModule,
    LinechartComponent,
    AceEditorModule,
    SlickCarouselModule,
    SwiperModule,
    SafeHtmlPipe
  ],
  providers: []
})
export class SharedModule { }
