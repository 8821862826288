import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthConstants } from "@app/core/constant/auth-constants";
import { CoreUtils } from "@app/core/utils/core-utils";
import { ResetPwdInfo } from "@app-auth/pages/reset-password/reset-pwd-info";
import { responseCode } from "@app/core/services/endpoint-list/endpoint-resp-code";
import { LoginRespInfo } from "@app-auth/pages/login/login-resp-info";
import { responseMessages } from "@app/core/services/endpoint-list/endpoint-resp-messages";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password-sentinel.component.html",
  styleUrls: ["./reset-password-sentinel.component.scss"]
})
export class ResetPasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup;
  public email = new FormControl("", [Validators.required, Validators.email]);
  public password = new FormControl("", [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]);
  public newPassword = new FormControl("", [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]);
  public confirmNewPassword = new FormControl("", [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]);
  public error: any;

  private _const: AuthConstants;
  private _util: CoreUtils;

  constructor(
    private fb: FormBuilder,
    private authSvc: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) {

    this._const = new AuthConstants();
    this._util = new CoreUtils(authSvc);
    const email: string = this.route.snapshot.queryParams.uName;

    this.resetPasswordForm = this.fb.group({
      email: [(email ? email : ""), [Validators.required]],
      password: ["", [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]],
      newPassword: ["", [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]],
      confirmNewPassword: ["", [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]]
    }, {});

  }

  ngOnInit(): void { }

  public resetPassword = async () => {
    if (!this.resetPasswordForm.valid) {
      return;
    }

    if (this.resetPasswordForm.valid) {
      try {
        const un = this.resetPasswordForm.get("email").value;
        const pwd = this.resetPasswordForm.get("password").value;
        const npw = this.resetPasswordForm.get("newPassword").value;
        const cnfPwd = this.resetPasswordForm.get("confirmNewPassword").value;

        if (!(cnfPwd && npw && cnfPwd === npw)) {
          this.error = "New password and confirm new password must match";
          return;
        }

        const anonToken: string = this.route.snapshot.queryParams.accessToken;
        const sentinelType: string = this.route.snapshot.queryParams.uType;
        const resetPwdInfo: ResetPwdInfo = new ResetPwdInfo(un, pwd, npw);

        if (resetPwdInfo.healthy()) {
          const resetPwdPromise: Promise<LoginRespInfo> = new Promise<LoginRespInfo>((resolve) => {
            if (sentinelType === this._const.MODE_EXISTING) {
              this.authSvc.sentinelResetPassword(resetPwdInfo, anonToken).subscribe(response => {
                if (response.status === 200) {
                  let lri;
                  if (responseCode.sentinel.RESET_PASSWORD_SUCCESS_CODE === response.body.exeCode) {
                    lri = new LoginRespInfo(response.body.exeCode, true);
                    lri.registerErrors(response.body.exeErrorMsg);
                  } else {
                    lri = new LoginRespInfo(response.body.exeCode, false);
                    lri.registerErrors(response.body.exeErrorMsg);
                  }

                  if (responseCode.sentinel.RESET_PASSWORD_FAILURE_CODE === response.body.exeCode) {
                    this.error = lri.hasErrors()
                      ? lri.listErrors().join("\n") : responseMessages.sentinel.RESET_PASSWORD_FAILURE;
                    resolve(lri);
                  } else if (responseCode.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE === response.body.exeCode) {
                    this.error = lri.hasErrors()
                      ? lri.listErrors().join("\n") : responseMessages.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE;
                    resolve(lri);
                  } else {
                    resolve(lri);
                  }
                } else {
                  resolve(new LoginRespInfo(responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE, false));
                }
              }, (error) => {
                console.log(error);
                resolve(new LoginRespInfo(responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE, false));
              });
            }
            if (sentinelType === this._const.MODE_NEW) {
              this.authSvc.newSentinelResetPassword(resetPwdInfo, anonToken).subscribe(response => {
                if (response.status === 200) {
                  let lri;
                  if (responseCode.sentinel.LOGIN_SUCCESS_CODE === response.body.exeCode) {
                    lri = new LoginRespInfo(response.body.exeCode, true);
                    lri.registerErrors(response.body.exeErrorMsg);
                  } else {
                    lri = new LoginRespInfo(response.body.exeCode, false);
                    lri.registerErrors(response.body.exeErrorMsg);
                  }

                  if (responseCode.sentinel.LOGIN_FAILURE_CODE === response.body.exeCode) {
                    this.error = lri.hasErrors()
                      ? lri.listErrors().join("\n") : responseMessages.sentinel.RESET_PASSWORD_FAILURE;
                    resolve(lri);
                  } else if (responseCode.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE === response.body.exeCode) {
                    this.error = lri.hasErrors()
                      ? lri.listErrors().join("\n") : responseMessages.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE;
                    resolve(lri);
                  } else {
                    resolve(lri);
                  }
                } else {
                  resolve(new LoginRespInfo(responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE, false));
                }
              }, (error) => {
                console.log(error);
                resolve(new LoginRespInfo(responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE, false));
              });
            }
          });

          const resetPwd: LoginRespInfo = await resetPwdPromise;
          if (resetPwd && resetPwd.healthy()) {
            if (resetPwd.isResetPwdOk()) {
              // Attempting to load the login page
              await this._util.sleep(2000);
              await this.router.navigate([`${this._const.KEY_LOGIN}`], {
                queryParams: {
                  accessToken: `${anonToken}`
                }
              });
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  public goHome = () => {
    const anonToken = this.route.snapshot.queryParams.accessToken;
    this.router.navigate([`${this._const.KEY_LOGIN}`], {
      queryParams: {
        accessToken: `${anonToken}`
      }
    });
  }

  public getEmailErrorMessage = () => {
    if (this.email.hasError("required")) {
      return "Required";
    }
    return this.email.hasError("email") ? "Not a valid email" : "";
  }

  getPWDErrorMessage = () => {
    if (this.password.hasError("required")) {
      return "Required";
    }
    return this.password.hasError("password") ? "Not a valid password" : "";
  }

  public getNewPWDErrorMessage = () => {
    if (this.newPassword.hasError("required")) {
      return "Please provide a new password";
    }
    return this.newPassword.hasError("newPassword") ? "Not a valid password" : "";
  }

  public getConfirmNewPWDErrorMessage = () => {
    if (this.confirmNewPassword.hasError("required")) {
      return "Please provide confirm new password";
    }
    return this.confirmNewPassword.hasError("confirmNewPassword") ? "Not a valid password" : "";
  }
}
