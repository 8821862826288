<div class="field-group col-sm-12 upload-field cmn-preview btn-dsc btn-uploadimg">
  <div class="row uploadflex">
    <div class="col-sm-10">
      <div class="field-group multi-selection-dropdown">
        <app-single-select-search [label]="'Attach an Asset'" [options]="assetList" [displayName]="'assetName'"
          [displayValue]="'id'" [selectedValue]="assetId" [isShowChameleonLogo]="isShowChameleonLogo"
          [isRequired]="isRequired" (optionSelected)="assetSelected($event)"
          *ngIf="isAssetApiCalled && isAssetTypeChanged">
        </app-single-select-search>
      </div>
    </div>
    <div class="preview-bg" [ngClass]="{'image-display': addClassExtra, 'no-img-display': !addClassExtra}">
      <div class="col-sm-12">
        <div class="logo-upload fixupload">
          <button mat-icon-button [matMenuTriggerFor]="uploadMatMenu">
            <label class="custom-file-upload label"></label>
          </button>
          <mat-menu #uploadMatMenu="matMenu" class="upload-menu-container" panelClass="upload-menu-container">
            <label><strong> Insert File From </strong></label>
            <button mat-menu-item class="menu-button" (click)="fetchFromDevice()">
              <mat-icon> devices </mat-icon> Device
            </button>
            <button *ngIf="assetType.includes('image')" mat-menu-item class="menu-button" (click)="fetchFromLibrary()">
              <mat-icon>cloud_download</mat-icon> Library
            </button>
          </mat-menu>
          <input #uploadFileBtn id="upload-file-btn" type="file" (input)="uploadFileFormDevice($event)"
            autocomplete="off" [accept]="acceptableFileTypes()" [disabled]="isUploadDisabled" />
        </div>
      </div>
      <div *ngIf="assetType && assetType.includes(getUrlType())" class="preview-img-icon-set">
        <div *ngIf="displayPreviewBtn" [ngClass]="{'disable-div': disablePreviewBtn}">
          <mat-icon
            *ngIf="((assetType === 'image' || getUrlType() === 'image') && ((assetIdUrl && isAssetApiCalled) || assetUrl)) || ((assetType === 'video' || getUrlType() === 'video') && (assetIdUrl || assetUrl) && !assetLink)"
            class="preview-icon" (click)="clickEvent()" [matTooltipPosition]="'above'" matTooltip="Preview">
            remove_red_eye
          </mat-icon>
          <mat-icon *ngIf="assetType === 'document'" class="preview-icon" (click)="previewAsset()"
            [matTooltipPosition]="'above'" matTooltip="Preview">
            remove_red_eye
          </mat-icon>
        </div>
        <div class="preview text-right">
          <img
            *ngIf="(assetType === 'image' || getUrlType() === 'image') && ((assetIdUrl && isAssetApiCalled) || assetUrl)"
            src="{{imgBaseUrl}}{{assetId ? assetIdUrl : assetUrl}}" loading="lazy" alt="preview image">
          <video
            *ngIf="(assetType === 'video' || getUrlType() === 'video') && ((assetIdUrl && isAssetApiCalled) || assetUrl) && !assetLink"
            src="{{imgBaseUrl}}{{assetId ? assetIdUrl : assetUrl}}" controls [muted]="'muted'">
            <track label="English" kind="captions" srclang="en" src="">
          </video>
          <iframe *ngIf="assetLink && assetType==='video'" class="pdf-frame" [src]="safeLinkUrl"
            title="preview-pdf"></iframe>
          <img *ngIf="assetType === 'document'" src="../assets/img/document-uploaded.png" loading="lazy"
            alt="Problem in loading image">
        </div>
        <a *ngIf="displayDeleteBtn" [ngClass]="{'disable-div': disableDeleteBtn}">
          <mat-icon class="dltmt-icn" [matTooltipPosition]="'above'" matTooltip="Delete"
            (click)="deleteButtonImg()">delete</mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>