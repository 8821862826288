import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  private httpOption: HttpHeaders;
  constructor(private http: HttpClient) { }

  public apicall(URL: string, data: any, type: string, param?, withoutHeader: boolean = false):
    Observable<any> {
    this.httpOption = new HttpHeaders({
    });
    let apiResponse;
    switch (type) {
      case "GET": {
        if (withoutHeader) {
          apiResponse = this.http.get(URL).pipe(map(this.extractData));
        } else {
          apiResponse = this.http
            .get(URL, { headers: this.httpOption })
            .pipe(map(this.extractData));
        }

        break;
      }
      case "GET_WITH_PARAM": {
        if (withoutHeader) {
          apiResponse = this.http.get(URL).pipe(map(this.extractData));
        } else {
          apiResponse = this.http
            .get(URL, { params: data })
            .pipe(map(this.extractData));
        }

        break;
      }
      case "POST": {
        if (withoutHeader) {
          apiResponse = this.http.post(URL, data).pipe(map(this.extractData));
        } else {
          apiResponse = this.http
            .post(URL, data, { params: param })
            .pipe(map(this.extractData));
        }
        break;
      }
      case "PUT": {
        if (withoutHeader) {
          apiResponse = this.http.put(URL, data).pipe(map(this.extractData));
        } else {
          apiResponse = this.http
            .put(URL, data, { headers: this.httpOption })
            .pipe(map(this.extractData));
        }
        break;
      }
      case "PATCH": {
        if (withoutHeader) {
          apiResponse = this.http.patch(URL, data).pipe(map(this.extractData));
        } else {
          apiResponse = this.http
            .patch(URL, data, { headers: this.httpOption })
            .pipe(map(this.extractData));
        }
        break;
      }
      case "DELETE": {
        if (withoutHeader) {
          apiResponse = this.http.delete(URL).pipe(map(this.extractData));
        } else {
          apiResponse = this.http
            .delete(URL, { headers: this.httpOption })
            .pipe(map(this.extractData));
        }
        break;
      }
      case "DELETE_WITH_PARAM": {
        if (withoutHeader) {
          apiResponse = this.http.delete(URL).pipe(map(this.extractData));
        } else {
          apiResponse = this.http
            .delete(URL, { params: data })
            .pipe(map(this.extractData));
        }
        break;
      }
      default: {
        apiResponse = null;
        break;
      }
    }
    return apiResponse;
  }

  private extractData(res: Response) {
    return res || {}; // If 'res' is null, it returns empty object
  }
}
