import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "@app-core/services/authentication/authentication.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { responseCode } from "@app/core/services/endpoint-list/endpoint-resp-code";
import { StorageService } from "@app/core/services/storage/storage.service";
import { AuthToken } from "@app/core/models/auth-token";
import { AuthConstants } from "@app/core/constant/auth-constants";
import { responseMessages } from "@app/core/services/endpoint-list/endpoint-resp-messages";
import { LoginConfigResp } from "@app/core/misctypes/login-config-resp";
import { LoginCfg } from "@app/core/misctypes/login-cfg";
import { LoginRespInfo } from "@app-auth/pages/login/login-resp-info";
import { CoreUtils } from "@app/core/utils/core-utils";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-login",
  templateUrl: "./login-sentinel.component.html",
  styleUrls: ["./login-sentinel.component.scss"],
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public loading = false;
  public returnUrl: string;
  public email = new FormControl("", [Validators.required, Validators.email]);
  public pwd = new FormControl("", [Validators.required, Validators.pattern(/^[A-Za-z]\\w{7,14}$/)]);
  public error: any;
  public hidePass: boolean = true;

  private _const: AuthConstants;
  private _util: CoreUtils;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authSvc: AuthenticationService,
    private formBuilder: FormBuilder,
    private _storage: StorageService,
    private titleService: Title
  ) {
    this._const = new AuthConstants();
    this._util = new CoreUtils(authSvc);
    if (this.router.url !== `${this._const.KEY_SLASH}${this._const.KEY_LOGIN}`) {
      this.authSvc.storeAuthTokens();
    }
  }

  ngOnInit() {
    this.titleService.setTitle("DigitalChameleon Sentinel");
    this.error = "";
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required]
    });
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  public navigateToResetPassword = async () => {
    const cfgResp = await this.authSvc.negotiateSentinelLoginStrategy();
    const cfgOk = cfgResp && cfgResp.exeStatus && cfgResp.loginCfg;
    if (cfgOk && cfgResp.loginCfg.anonAccessToken) {
      this.router.navigate([`${this._const.KEY_RESET_PASSWORD}`], {
        queryParams: {
          accessToken: `${cfgResp.loginCfg.anonAccessToken}`,
          uType: this._const.MODE_EXISTING
        }
      });
    } else {
      this.error = responseMessages.sentinel.INITIATE_RESET_PASSWORD_FAILURE;
    }
  }

  public navigateToForgotPassword = async () => {
    const cfgResp = await this.authSvc.negotiateSentinelLoginStrategy();
    const cfgOk = cfgResp && cfgResp.exeStatus && cfgResp.loginCfg;
    if (cfgOk && cfgResp.loginCfg.anonAccessToken) {
      this.router.navigate([`${this._const.KEY_FORGOT_PASSWORD}`], {
        queryParams: {
          accessToken: `${cfgResp.loginCfg.anonAccessToken}`
        }
      });
    } else {
      this.error = responseMessages.sentinel.INITIATE_FORGOT_PASSWORD_FAILURE;
    }
  }

  public authenticateLogin = async () => {
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    if (this.loginForm.valid) {
      try {
        const username: string = this.loginForm && this.loginForm.get("username")
          && this.loginForm.get("username").value
          ? this.loginForm.get("username").value.trim().toLowerCase() : "";
        const password = this.loginForm.get("password").value;
        const anonToken = this.route.snapshot.queryParams.accessToken;
        this.authSvc.userLoginPassword.next(password);

        const standardLoginPromise: Promise<LoginRespInfo> = new Promise<LoginRespInfo>((resolve) => {
          this.authSvc
            .loginStandaloneSentinel(username, password, anonToken)
            .subscribe((response) => {
              if (response.status === 200 && response.body) {
                let lri;
                if ([
                  responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE,
                  responseCode.sentinel.LOGIN_CONFIRM_EMAIL_FAILURE_CODE,
                  responseCode.sentinel.LOGIN_FORCE_PWD_RESET_FAILURE_CODE,
                  responseCode.sentinel.LOGIN_PWD_RESET_REQUIRED_FAILURE_CODE,
                  responseCode.sentinel.LOGIN_INACTIVE_USER_FAILURE_CODE,
                  responseCode.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE,
                  responseCode.sentinel.SMS_MFA_REQUIRED_ERROR_CODE
                ].some(c => c === response.body.exeCode)) {
                  lri = new LoginRespInfo(response.body.exeCode, false);
                } else {
                  lri = new LoginRespInfo(response.body.exeCode, true);
                }

                if (responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE === response.body.exeCode) {
                  this.error = responseMessages.sentinel.LOGIN_GENERIC_FAILURE;
                  resolve(lri);
                } else if (responseCode.sentinel.LOGIN_CONFIRM_EMAIL_FAILURE_CODE === response.body.exeCode) {
                  this.error = responseMessages.sentinel.LOGIN_CONFIRM_EMAIL_FAILURE;
                  resolve(lri);
                } else if (responseCode.sentinel.LOGIN_FORCE_PWD_RESET_FAILURE_CODE === response.body.exeCode) {
                  this.error = responseMessages.sentinel.LOGIN_FORCE_PWD_RESET_FAILURE;
                  lri.registerSessions([response.body.challengeToken]);
                  resolve(lri);
                } else if (responseCode.sentinel.LOGIN_INACTIVE_USER_FAILURE_CODE === response.body.exeCode) {
                  this.error = responseMessages.sentinel.LOGIN_INACTIVE_USER_FAILURE;
                  resolve(lri);
                } else if (responseCode.sentinel.SMS_MFA_REQUIRED_ERROR_CODE === response.body.exeCode) {
                  lri.registerSessions([response.body.challengeToken]);
                  resolve(lri);
                } else if (responseCode.sentinel.LOGIN_PWD_RESET_REQUIRED_FAILURE_CODE === response.body.exeCode) {
                  this.error = responseMessages.sentinel.LOGIN_PWD_RESET_REQUIRED_FAILURE;
                  resolve(lri);
                } else if (responseCode.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE_CODE === response.body.exeCode) {
                  this.error = responseMessages.sentinel.LOGIN_PROVIDER_UNSUPPORTED_OPERATION_FAILURE;
                  resolve(lri);
                } else {
                  const token: AuthToken = new AuthToken(response.body.jwtAccessToken, response.body.jwtIdToken);
                  this._storage.storeJWTTokens(JSON.stringify(token));
                  this.authSvc.monitorSentinelAccessToken();
                  resolve(lri);
                }
              } else {
                resolve(new LoginRespInfo(responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE, false));
              }
            }, (error) => {
              console.log(error);
              resolve(new LoginRespInfo(responseCode.sentinel.LOGIN_GENERIC_FAILURE_CODE, false));
            });
        });

        const loginOk: LoginRespInfo = await standardLoginPromise;
        if (loginOk && loginOk.healthy()) {
          if (loginOk.isLoginOk()) {
            // Attempting to load the workspace
            await this.router.navigate([`${this._const.KEY_SLASH}`], {});
          } else {
            if (loginOk.withCode() === responseCode.sentinel.LOGIN_CONFIRM_EMAIL_FAILURE_CODE) {
              await this._util.sleep(2000);
              await this.router.navigate([`${this._const.KEY_CONFIRM}`], {
                queryParams: {
                  confirmationType: `${0x01}`,
                  accessToken: `${anonToken}`,
                  username: username
                }
              });
            }
            if (loginOk.withCode() === responseCode.sentinel.LOGIN_PWD_RESET_REQUIRED_FAILURE_CODE) {
              await this._util.sleep(2000);
              await this.router.navigate([`${this._const.KEY_FORGOT_PASSWORD_COMPLETE}`], {
                queryParams: {
                  confirmationType: `${0x02}`,
                  accessToken: `${anonToken}`
                }
              });
            }
            if (loginOk.withCode() === responseCode.sentinel.LOGIN_FORCE_PWD_RESET_FAILURE_CODE) {
              await this._util.sleep(2000);
              await this.router.navigate([`${this._const.KEY_FORCED_RESET_COMPLETE}`], {
                queryParams: {
                  sessionId: `${loginOk.withChallengeToken()}`,
                  accessToken: `${anonToken}`,
                  username: username,
                  password: password
                }
              });
            }
            if (loginOk.withCode() === responseCode.sentinel.SMS_MFA_REQUIRED_ERROR_CODE) {
              await this._util.sleep(2000);
              await this.router.navigate([`${this._const.KEY_MFA}`], {
                queryParams: {
                  mfaType: `${0x02}`,
                  sessionId: `${loginOk.withChallengeToken()}`,
                  accessToken: `${anonToken}`,
                  username: username
                }
              });
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  getEmailErrorMessage = () => {
    if (this.email.hasError("required")) {
      return "Required";
    }
    return this.email.hasError("email") ? "Not a valid email" : "";
  }

  getPWDErrorMessage = () => {
    if (this.pwd.hasError("required")) {
      return "Required";
    }
    return this.pwd.hasError("pwd") ? "Not a valid password" : "";
  }
}
