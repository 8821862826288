import { NgModule } from "@angular/core";
import { SharedModule } from "@app-shared/shared.module";
// @ts-ignore
import { LoginComponent } from "./pages/login/login.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { LogoutComponent } from "./pages/logout/logout.component";
import { AppRoutingModule } from "@app/app.routing";
import { ForgotPasswordComponent } from "@app-auth/pages/forgot-password/forgot-password.component";
import { CallbackComponent } from "@app-auth/pages/callback/callback.component";
import { ConfirmComponent } from "@app-auth/pages/confirm/confirm.component";
import { ForgotpassCompleteComponent } from "@app-auth/pages/forgotpass-complete/forgotpass-complete.component";
import { ForcedResetCompleteComponent } from "@app-auth/pages/forced-reset-complete/forced-reset-complete.component";
import { MFAComponent } from "@app-auth/pages/mfa/mfa.component";
import { NgOtpInputModule } from "ng-otp-input";
import { SsoHelpComponent } from "@app-auth/pages/sso-help/sso-help.component";
import { CallbackOAuthComponent } from "./pages/callback-oauth/callback-oauth.component";

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    LogoutComponent,
    CallbackComponent,
    CallbackOAuthComponent,
    SsoHelpComponent,
    ConfirmComponent,
    ForgotpassCompleteComponent,
    ForcedResetCompleteComponent,
    MFAComponent
  ],
  imports: [
    AppRoutingModule,
    SharedModule,
    NgOtpInputModule
  ]
})
export class AuthenticationModule { }
