export class AuthConstants {
  public readonly KEY_SLASH = "/";
  public readonly KEY_LOGIN = "login";
  public readonly KEY_CALLBACK = "callback";
  public readonly SSO_HELP = "sso-help";
  public readonly KEY_CONFIRM = "confirm";
  public readonly KEY_FORGOT_PASSWORD = "forgot-password";
  public readonly KEY_RESET_PASSWORD = "reset-password";
  public readonly KEY_FORGOT_PASSWORD_COMPLETE = "forgotpass-complete";
  public readonly KEY_FORCED_RESET_COMPLETE = "forced-reset-complete";
  public readonly KEY_MFA = "mfa";
  public readonly KEY_LIVECHAT = "livechat"
  public readonly KEY_JOURNEY_BUILDER = "journeyBuilder";
  public readonly KEY_RTMC = "rtmc";
  public readonly MODE_EXISTING = "existing";
  public readonly MODE_NEW = "new";
  public readonly REQUIRED = "required";
  public readonly EMAIL = "email";
  public readonly CODE = "code";
  public readonly USER_NAME = "username";
  public readonly PASSWORD = "password";
  public readonly NEW_PASSWORD = "newPassword";
  public readonly CONFIRM_NEW_PASSWORD = "confirmNewPassword";
  public readonly MIN_LENGTH = "minlength";
  public readonly PATTERN = "pattern";
  public readonly ERROR = "error";
  public readonly ERROR_DESCRIPTION = "error_description";
  public readonly USER_MANAGEMENT = "/userManagement";

  public readonly EMAIL_MANDATORY_MSG = "Required";
  public readonly TEMPORARY_PASSWORD_MANDATORY_MSG = "Please provide your temporary password";
  public readonly NEW_PASSWORD_MANDATORY_MSG = "Please provide a new password";
  public readonly CONFIRM_PASSWORD_MANDATORY_MSG = "Please provide confirm password";
  public readonly INVALID_EMAIL_ERROR_MSG = "Not a valid email";
  public readonly INVALID_PASSWORD_ERROR_MSG = "Not a valid password";
  public readonly CODE_MANDATORY_MSG = "Required";
  public readonly INVALID_CODE_ERROR_MSG = "Code must be provided";
  public readonly PASSWORD_VALIDATION_ERROR_MSG = "Please check password validations";
  public readonly CONFIRM_PASSWORD_MATCH_ERROR_MSG = "New password and confirm new password must match";
  public readonly ERROR_LOGGING_USER = "Error in logging the User";
  public readonly GOOGLE_CAL_AUTH_SCOPE = "https://www.googleapis.com/auth/calendar";
  public readonly ZOHO_CONSENT_AUTH_SCOPE = "https://accounts.zoho.com.au";

  public readonly UPPER_CASE_CHAR_REGEX = /[A-Z]/;
  public readonly LOWER_CASE_CHAR_REGEX = /[a-z]/;
  public readonly NUMBER_CHAR_REGEX = /[0-9]/;
  public readonly SPECIAL_CHAR_REGEX = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  constructor() { }
}
