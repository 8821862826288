import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { UnsplashImageInfo } from "@app/asset/misc/unsplash-image-info";
import { UnsplashRequest } from "@app/asset/misc/unsplash-request";
import { UnsplashResponse } from "@app/asset/misc/unsplash-response";
import { UnsplashResults } from "@app/asset/misc/unsplash-results";
import { AssetService } from "@app/asset/services/asset.service";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-unsplash-images-dialog",
  templateUrl: "./unsplash-images-dialog.component.html",
  styleUrls: ["./unsplash-images-dialog.component.scss"]
})
export class UnsplashImagesDialogComponent implements OnInit, AfterViewInit {

  public searchText: string = "";
  public selectedImageUrl: string = "";
  public showLoader: boolean = false;
  public showError: boolean = false;
  public selectedImage: string = "";
  public page: number = 1;
  public pageSize: number = 30;
  public pageLength: number = 100;
  public images: UnsplashResults[] = [];
  public imageInfo: UnsplashResults = null;
  public searchedSubject: Subject<string> = new Subject<string>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<UnsplashImagesDialogComponent>,
    private assetService: AssetService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getRandomImages();
  }

  ngAfterViewInit(): void {
    this.searchedSubject.pipe(debounceTime(1200)).subscribe((sTxt: string) => {
      if (sTxt && sTxt.length >= 3) {
        this.images = [];
        this.page = 1;
        this.paginator.pageIndex = 0;
        this.searchImages(sTxt);
      }
    });
  }

  public onSearch = () => {
    if (this.searchText) {
      this.page = 1;
      this.searchedSubject.next(this.searchText);
    }
  }

  private getRandomImages = () => {
    const imageRequest: UnsplashRequest = new UnsplashRequest();
    imageRequest.page = this.page;
    this.showLoader = true;
    this.assetService.getUnsplashImages(imageRequest).subscribe((response: UnsplashResponse) => {
      if (response && response.exeStatus && response.results && response.results.length > 0) {
        this.images = response.results;
        this.pageLength = response.totalPages;
        this.showLoader = false;
        this.showError = false;
      } else {
        this.showLoader = false;
        this.showError = true;
      }
      this.cdr.detectChanges();
    }, (error) => {
      console.log(`Error while fetching unsplash images. Error is ${error}`);
    });
  }

  public searchImages = (searchKeyword: string) => {
    if (searchKeyword) {
      this.showLoader = true;
      const imageRequest: UnsplashRequest = new UnsplashRequest();
      imageRequest.query = searchKeyword;
      imageRequest.page = this.page;
      this.assetService.getUnsplashImages(imageRequest).subscribe((response: UnsplashResponse) => {
        if (response && response.exeStatus && response.results && response.results.length > 0) {
          this.images = response.results;
          this.pageLength = response.totalPages;
          this.showLoader = false;
          this.showError = false;
        } else {
          this.showLoader = false;
          this.showError = true;
        }
        this.cdr.detectChanges();
      }, (error) => {
        console.log(`Error while fetching unsplash images. Error is ${error}`);
      });
    }
  }

  public onSelection = () => {
    const imgInfo: UnsplashImageInfo = {
      id: this.imageInfo.id,
      url: this.selectedImage,
      fileName: this.imageInfo.description,
      altText: this.imageInfo.description
    };
    if (this.selectedImage) {
      this.dialogRef.close(imgInfo);
    }
  }

  public trackById = (index: number, image: UnsplashResults): string => {
    return image?.id;
  }

  public changePage = (event: PageEvent) => {
    this.page = (event.pageIndex === 0 ? 1 : (event.pageIndex + 1));
    if (this.searchText) {
      this.searchImages(this.searchText);
    } else {
      this.getRandomImages();
    }
  }
}
