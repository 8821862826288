import { MessageContent } from "@app/core/msg/message-content";

export class HbMessage {
  public message: MessageContent [] = [];
  public messageType: number;
  public channelId: string;
  public receiverName: string;
  public type: string;
  public createdTimestamp: number;
  public updatedTimestamp: number;
  public createdBy: string;
  public updatedBy: string;

  public constructor (messageType: number, channelId: string, type: string) {
    this.messageType = messageType;
    this.channelId = channelId;
    this.type = type;
  }
}
