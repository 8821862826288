import { Component, OnInit, ElementRef, ViewChild, Input, AfterViewInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { AuthenticationService } from "@app/core/services/authentication/authentication.service";
import { ConnectService } from "@app-core/services/connect/connect.service";
import { StorageService } from "@app/core/services/storage/storage.service";
import { User } from "@app/core/misctypes/user";
import { ClientSubModule } from "@app/core/misctypes/client-sub-module";
import { Constants } from "@app/core/utils/const";
import { ConnectUrls } from "@app/core/misctypes/connect-urls";

declare let connect: any;

@Component({
  selector: "app-connect-call",
  templateUrl: "./connect-call.component.html",
  styleUrls: ["./connect-call.component.scss"],
})
export class ConnectCallComponent implements OnInit, AfterViewInit {
  @ViewChild("containerDiv") containerDiv: ElementRef;
  @ViewChild("oktaIframe") iframeView: ElementRef;
  @Input() toggle: boolean;
  @Input() showBackground: boolean = false;

  public ssoUrl = null;
  public safeUrl = null;
  public isConnectInitialized = false;
  public ccpUrl = null;
  public isRoleTelephony = false;
  public currentUser: User;
  public clientId: number;
  private _const: Constants;

  constructor(
    public sanitizer: DomSanitizer,
    private connectService: ConnectService,
    private authService: AuthenticationService,
    private _storage: StorageService
  ) {
    this.clientId = Number(this._storage.storedClientId());
    this._const = new Constants();
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    if (this.authService
      && this.authService.currentUser
      && this.authService.currentUser.clientSubmoduleDtos
      && this.authService.currentUser.clientSubmoduleDtos.length > 0) {
      const clientModules: ClientSubModule[] = this.authService.currentUser.clientSubmoduleDtos;
      const telephonyModule: ClientSubModule[] = clientModules.filter((m) =>  m.submodulesDto.id === 8);
      if (telephonyModule.length > 0) {
        this.isRoleTelephony = true;
        this._storage.storeHasTelephonyAccess(this._const.TRUE);
      } else {
        this._storage.storeHasTelephonyAccess(this._const.FALSE);
      }
    }
    if (this.isRoleTelephony) {
      this.fetchConnectUrl();
      if (window.addEventListener) {
        window.addEventListener(
          "message",
          this.receiveMessage.bind(this),
          false
        );
      }
    }
  }

  ngAfterViewInit() {
    if (this.isRoleTelephony) {
      if (document.getElementById("oktaIframe")) {
        document.getElementById("oktaIframe").remove();
      }
      this.initConnect();
      this.isConnectInitialized = true;
    }
  }

  public sidebarCollapse = (event: boolean) => {
    this.toggle = event;
  }

  receiveMessage(event: any) {
    if (event.data.event === "agent::update" && !this.isConnectInitialized) {
      document.getElementById("oktaIframe").remove();
      this.initConnect();
      this.isConnectInitialized = true;
    }
  }

  initConnect() {
    const containerDiv = document.getElementById("containerDiv");
    connect.core.initCCP(containerDiv, {
      ccpUrl: this.ccpUrl,
      loginPopup: false,
      softphone: { allowFramedSoftphone: true },
    });
  }

  private fetchConnectUrl = () => {
    this.connectService.getConnectUrl().subscribe((data: ConnectUrls) => {
      if (data && data.samlUrl) {
        this.ssoUrl = data.samlUrl;
        this.ccpUrl = data.ccpUrl;
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.ssoUrl);
      }
    }, (error: Error) => {
      console.log(`Error occured while fetching ConnectUrl. Error is ${error}`);
    });
  }
}