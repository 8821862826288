import { Component, OnInit, Input } from "@angular/core";
import { ChannelMessage } from "@app/core/msg/channel-message";
import { Subscription, Observable, timer } from "rxjs";

@Component({
  selector: "app-timer",
  templateUrl: "./timer.component.html",
  styleUrls: ["./timer.component.scss"],
})
export class TimerComponent implements OnInit {
  @Input() guest: ChannelMessage;
  private subscription: Subscription;
  public minutes: number;
  public seconds: number;
  public time: number;
  public everySecond: Observable<number> = timer(0, 1000);

  constructor() { }

  ngOnInit() {
    this.subscription = this.everySecond.subscribe((seconds) => {
      const time: number = this.guest.guestTimer ? this.guest.guestTimer  + seconds : seconds;
      this.minutes = Math.floor(time / 60);
      this.seconds = Math.floor(time - this.minutes * 60);
      this.time = time;
    });
  }

  ngOnDestroy(): void {
    if (!this.guest.isOffline) {
    this.guest.guestTimer = this.time;
    }
    this.subscription.unsubscribe();
  }
}